<script setup>
import {
  ref,
  onMounted,
  watchEffect,
  watch,
  onBeforeUnmount,
  computed,
  defineEmits
} from "vue";
import RocColorPicker from "@/components/ui/RocColorPicker.vue";
import RocIcon from "@/components/ui/RocIcon.vue";
import RocSwitch from "@/components/ui/RocSwitch.vue";
import RocButton from "@/components/ui/RocButton.vue";
import RocPopper from "@/components/ui/RocPopper.vue";
import RocRange from "@/components/ui/RocRange.vue";
import RocDropdownMenu from "@/components/ui/RocDropdownMenu.vue";
import RocDropdownToggle from "@/components/ui/RocDropdownToggle.vue";
import { MDBDropdown, MDBDropdownItem } from "mdb-vue-ui-kit";
import { useStore } from "vuex";
import IAcknowledge from "@/components/settings/IAcknowledge.vue";
import CaptureZonesOverlay from "@/components/cameras/CaptureZonesOverlay.vue";
import RocToast from "@/components/ui/RocToast.vue";

const store = useStore();
const emit = defineEmits(["close"]);

const settingsMenuRef = ref(null);
const settingsMenuIconRef = ref(null);
const colorPickerRef = ref(null);
const cameraId = ref(null);
const cameraName = ref(null);
const cameraUrl = ref(null);
const camera = ref(null);
const cameraPreview = ref(null);
const showAcknowledge = ref(false); //if true, don't show instructions
const showTooltip = ref(false);
const displayToast = ref(false);
const activeDisableMenuToggle = ref(false);
const captureZonesActive = ref(false);
const includeSwitch = ref(false);
const opacityValue = ref(0.75);
const colorValue = ref("rgb(0,255,240)");
const captureZonesImageData = ref({});
const captureZonesCoordinates = ref([]);
const liveCamState = ref(false);
const pageLoaded = ref(false);

const captureZonesSettings = computed(() => {
  return {
    enabled: captureZonesActive.value,
    opacity: opacityValue.value,
    color: colorValue.value,
    include: includeSwitch.value,
    imageData: captureZonesImageData.value,
    coordinates: captureZonesCoordinates.value
  };
});

let socket;

onMounted(async () => {
  //listen to messages from the parent window
  window.addEventListener("message", messageEventHandler);
  //send message to parent window that child is ready
  window.opener.postMessage(JSON.stringify({ type: "childReady" }));
});

const videoServiceId = ref(null);

async function messageEventHandler(event) {
  try {
    const eventObject = JSON.parse(event.data);
    //handle initial load
    if (eventObject.type === "initialData") {
      showAcknowledge.value = !eventObject.data.firstTimeInstructions; //if true, don't show instructions
      cameraName.value = eventObject.data.cameraName;
      cameraId.value = eventObject.data.cameraGuid;
      cameraPreview.value = eventObject.data.cameraPreviewSrc;
      cameraUrl.value = eventObject.data.cameraUrl;
      videoServiceId.value = eventObject.data.videoServiceId;

      if (cameraId.value) {
        camera.value = store.getters["cameras/findByGUID"](cameraId.value);
        liveCamState.value = camera.value?.online; //camera managed by online now, but only for new cams, so fall back to state in case
        //turn on socket, doesn't necessarily turn on camera stream just primes it
        await turnOnSocket();
      }

      //if capture zones are already set, populate settings with those values
      if (camera.value?.captureZones || eventObject.data.captureZones) {
        //if capture zones is being edited before saving camera config, give most recent edits priority by using ?? nullish coalescing operator
        captureZonesActive.value =
          eventObject.data.captureZones?.enabled ??
          camera.value.captureZones.enabled;
        opacityValue.value =
          eventObject.data.captureZones?.opacity ??
          camera.value.captureZones.opacity;
        colorValue.value =
          eventObject.data.captureZones?.color ??
          camera.value.captureZones.color;
        includeSwitch.value =
          eventObject.data.captureZones?.include ??
          camera.value.captureZones.include;
        captureZonesImageData.value =
          eventObject.data.captureZones?.imageData ??
          camera.value.captureZones.imageData;
        captureZonesCoordinates.value =
          eventObject.data.captureZones?.coordinates ??
          camera.value.captureZones.coordinates;
      }
      //to make sure before canvas is drawn that all info is loaded to prevent race conditon
      pageLoaded.value = true;
      //if instructions have been shown, start camera update snapshots, otherwise wait until instructions are shown
      if (showAcknowledge.value === false && liveCamState.value === false) {
        await getCameraStreamPreview({
          url: cameraUrl.value,
          username: camera.value?.user,
          password: camera.value?.password,
          systemTimestamps: true,
          videoServiceId: videoServiceId.value
        });
      }
    }
  } catch (e) {
    console.log("error", e);
  }
}

const cameraPreviewSrc = computed(() => {
  return cameraPreview.value;
});

const showUpdateSnapshot = ref(false);

async function getCameraStreamPreview(connectionString) {
  const minDelay = 15000;
  try {
    const startTime = Date.now();
    const connectionTestResults = await store.dispatch(
      "cameras/testCameraConnection",
      connectionString
    );
    if (
      connectionTestResults.status === "success" &&
      connectionTestResults.preview
    ) {
      //get base64 string from cameraPreview to compare against connectionTestResults.preview
      const base64String = cameraPreview.value?.split(",")[1];
      if (base64String) {
        //if connection was success, and image not equal to each other, show updateSnapshot and change preview
        if (connectionTestResults.preview.trim() !== base64String.trim()) {
          showUpdateSnapshot.value = true;
          setTimeout(() => {
            showUpdateSnapshot.value = false;
          }, 1800);
          cameraPreview.value =
            "data:image/jpg;base64, " + connectionTestResults.preview;
        }
      }
    } else {
      //if connection test fails, throw error to disable snapshot updating
      throw new Error(`${connectionTestResults?.message ?? ""}`);
    }
    const runTime = Date.now() - startTime;
    const waitTime = minDelay - runTime;
    if (waitTime > 0) {
      await new Promise((resolve) => setTimeout(resolve, waitTime));
    }
    getCameraStreamPreview(connectionString);
  } catch (error) {
    console.error(
      `Error calling testCameraConnectionString, snapshot updating disabled ${error.message}`
    );
  }
}

async function turnOnSocket() {
  const payload = `feed=livestream&topic=${cameraId.value}`;
  socket = await store.dispatch("auth/getSocketIO", payload);
  socket.on(cameraId.value, (payload) => {
    cameraPreview.value = "data:image/png;base64, " + payload.image;
  });
}

onBeforeUnmount(() => {
  if (socket) {
    socket.close();
  }
  //remove event listeners
  window.removeEventListener("message", messageEventHandler);
});

const settingsToggle = ref(false);

function openCloseSettings() {
  settingsToggle.value = !settingsToggle.value;
}

//add event listener to settings menu
watchEffect((onRefInvalidate) => {
  //check if settingsToggle is true before adding event listener.
  if (settingsToggle.value) {
    // Attach click event listener to the document body when div is visible
    document.body.addEventListener("click", handleOutsideClick, {
      passive: true
    });
    // Remove event listener when component is unmounted or div is hidden
    onRefInvalidate(() => {
      document.body.removeEventListener("click", handleOutsideClick, {
        passive: true
      });
    });
  }
});

const handleOutsideClick = (event) => {
  // Check if the clicked element is outside the target div
  if (settingsMenuRef.value && !settingsMenuRef.value.contains(event.target)) {
    //if the click is on the settings menu icon do not perform function
    if (
      settingsMenuIconRef.value &&
      !settingsMenuIconRef.value.contains(event.target)
    ) {
      settingsToggle.value = false;
    }
  }
};

function revert() {
  includeSwitch.value = false;
  opacityValue.value = 0.75;
  colorValue.value = "rgb(0,255,240)";
}

function createCaptureZone() {
  if (!captureZonesExist.value) {
    captureZonesOverlayRef.value.addCaptureZone();
  }
}

const captureZonesExist = computed(() => {
  return captureZonesCoordinates.value.length > 0;
});

const captureZonesOverlayRef = ref(null);

async function saveCaptureZones() {
  //Call stack: *save button click* saveCaptureZones() -> -emit- CaptureZones data -> captureZonesUpdate() -> ~continue~ saveCaptureZones()
  captureZonesOverlayRef.value.saveCaptureZones();

  let formattedZones = JSON.parse(JSON.stringify(captureZonesSettings.value));
  window.opener.postMessage(
    JSON.stringify({ type: "captureZoneSettings", data: formattedZones }),
    "*"
  );

  displayToast.value = true;
}

function captureZonesUpdate(data) {
  let formattedCoordinates = data.captureZones
    ? JSON.parse(JSON.stringify(data.captureZones))
    : [];

  //these go into captureZonesSettings computed property to send to parent
  captureZonesImageData.value = data.imageData;
  captureZonesCoordinates.value = formattedCoordinates;
}

function closeAcknowledge(acknowledged) {
  showAcknowledge.value = false;
  //show tooltip after agreeing to acknowledge
  if (acknowledged) {
    showTooltip.value = true;
  }
  //if no agree, close caputure zones editor
  else {
    window.close();
  }
}

function closeTooltip() {
  showTooltip.value = false;
}

//when instructions and acknowledge are complete, send message to parent to update user config
watch(showTooltip, async (nv) => {
  if (!nv) {
    let firstTimeInstructions = {
      instructionsShown: true
    };
    window.opener.postMessage(
      JSON.stringify({ type: "instructions", data: firstTimeInstructions }),
      "*"
    );

    //start camera stream after instructions are shown
    await getCameraStreamPreview({
      url: cameraUrl.value,
      username: camera.value?.user,
      password: camera.value?.password,
      systemTimestamps: true,
      videoServiceId: videoServiceId.value
    });
  }
});

const enableSave = ref(false);

function enableDisableSave(bool) {
  enableSave.value = bool;
}
</script>

<template>
  <div class="page-container">
    <div class="header">
      <div class="overwatch-title-large">{{ cameraName }}</div>
      <teleport to="body">
        <!-- if tool tip is showing, cover entire page in a gray div to make the user interact w tooltip before continue -->
        <div class="backdrop" v-if="showTooltip"></div>
        <div class="add-button">
          <RocPopper
            arrow
            :show="showTooltip"
            placement="left"
            :popperType="'tooltip'"
            :locked="true"
            class="popper"
            style="margin-left: auto;"
          >
            <RocIcon
              :style="{ cursor: captureZonesExist ? 'default' : 'pointer' }"
              icon="addButton"
              color="primary"
              :disabled="captureZonesExist"
              size="lg"
              @click="createCaptureZone(), (showTooltip = false)"
            />
            <template #content>
              <div class="d-flex align-items-center" style="width: 365px">
                <RocIcon
                  icon="exit"
                  color="white"
                  size="xs"
                  style="margin-right: auto; cursor: pointer;"
                  @click="closeTooltip()"
                />
                <div>Add a zone to be included/excluded to the camera</div>
              </div>
            </template>
          </RocPopper>
        </div>
      </teleport>
      <RocButton
        v-if="captureZonesSettings"
        class="save-button"
        @click="saveCaptureZones()"
        :disabled="!enableSave"
      >
        <div>
          Save
        </div>
      </RocButton>
    </div>
    <CaptureZonesOverlay
      v-if="pageLoaded"
      ref="captureZonesOverlayRef"
      :image="cameraPreviewSrc"
      :zones="captureZonesSettings"
      :zoneColor="colorValue"
      :zoneOpacity="opacityValue"
      :zoneInclude="includeSwitch"
      @captureZonesEmit="captureZonesUpdate"
      @enableSave="enableDisableSave"
    />
    <div class="settings-footer">
      <div class="overwatch-title-small">Capture Zones</div>
      <div class="updating-snapshot-div" v-if="showUpdateSnapshot">
        <div class="updating-snapshot-text">Updating Snapshot</div>
      </div>
      <MDBDropdown
        dropend
        v-model="activeDisableMenuToggle"
        align="start"
        class="active-disabled-menu-container"
      >
        <RocDropdownToggle
          @click="activeDisableMenuToggle = !activeDisableMenuToggle"
        >
          <RocButton type="menu" class="active-disabled-button">
            <div
              :class="{
                'active-bubble': captureZonesActive,
                'disabled-bubble': !captureZonesActive
              }"
            ></div>
            <div>
              {{ captureZonesActive ? "Active" : "Disabled" }}
            </div>
            <RocIcon
              icon="inputArrow"
              :rotate90="true"
              size="xs"
              color="black"
              style="margin-left: auto;"
            />
          </RocButton>
        </RocDropdownToggle>
        <RocDropdownMenu
          class="active-disabled-dropdown-menu"
          @click="activeDisableMenuToggle = !activeDisableMenuToggle"
        >
          <MDBDropdownItem
            href="#"
            @click="enableDisableSave(true), (captureZonesActive = true)"
          >
            <div class="active-disabled-dropdown-menu-item">
              <div class="active-bubble"></div>
              <div>Active</div>
            </div>
          </MDBDropdownItem>
          <MDBDropdownItem
            href="#"
            @click="enableDisableSave(true), (captureZonesActive = false)"
          >
            <div class="active-disabled-dropdown-menu-item">
              <div class="disabled-bubble"></div>
              <div>Disabled</div>
            </div>
          </MDBDropdownItem>
        </RocDropdownMenu>
      </MDBDropdown>
      <div>Exclude</div>
      <RocSwitch
        :isActive="includeSwitch"
        @switch-toggled="includeSwitch = $event"
        @click="enableDisableSave(true)"
      />
      <div>Include</div>
      <div
        class="settings-icon"
        @click="openCloseSettings()"
        ref="settingsMenuIconRef"
      >
        <RocIcon icon="settings" color="black" size="md" />
      </div>

      <div class="settings-menu" v-if="settingsToggle" ref="settingsMenuRef">
        <RocIcon
          @click="settingsToggle = false"
          style="margin-left: auto; cursor: pointer;"
          icon="exit"
          color="black"
          size="sm"
        />
        <div class="settings-menu-items">
          <div class="overwatch-title-small">Capture Zones</div>
          <div class="menu-item-container">
            <div>Opacity</div>
            <input class="opacity-input" v-model="opacityValue" />
          </div>
          <div class="menu-item-container">
            <div style="width: 100%">
              <RocRange v-model="opacityValue" :min="0" :max="1" :step="0.01" />
            </div>
          </div>
          <div class="menu-item-container">
            <div>Color</div>
            <RocColorPicker
              v-model="colorValue"
              placement="left"
              ref="colorPickerRef"
            />
          </div>
        </div>
        <div class="revert" @click="revert()">
          Revert
        </div>
      </div>
    </div>
    <RocToast
      v-if="displayToast"
      style="width: 400px; height: 75px"
      message="Capture zone(s) have been saved"
      :timeout="3000"
      @autoClose="displayToast = !displayToast"
    />
  </div>

  <IAcknowledge
    v-if="showAcknowledge"
    @confirm="closeAcknowledge(true)"
    @close="closeAcknowledge(false)"
  >
    <div>
      Once a Capture Zone is defined and saved as included or excluded,
      <span style="font-weight: 700"
        >only included data will be stored and retrieved
      </span>
      until the zone is cleared and saved.
    </div>
  </IAcknowledge>
</template>

<style scoped lang="scss">
.page-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100vh;
}

.header {
  display: flex;
  align-items: center;
  padding: var(--spacing-s);
  height: 70px;
  flex: 0 0 auto;
  background-color: var(--overwatch-secondary);
}

.settings-footer {
  display: flex;
  align-items: center;
  height: 70px;
  width: 100%;
  flex: 0 0 auto;
  padding: var(--spacing-s);
  gap: var(--spacing-s);
  color: var(--overwatch-neutral-100);
  background-color: var(--overwatch-secondary);
}

.updating-snapshot-div {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: var(--spacing-s);
  bottom: calc(var(--spacing-s) + 70px);
  height: 75px;
  width: 250px;
  opacity: 0.75;
  padding: var(--spacing-l);
  gap: var(--spacing-s);
  background-color: var(--overwatch-primary);
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 var(--overwatch-overlay);
  animation: fade-in-out 2s ease-in-out;
}

.updating-snapshot-text {
  @include overwatch-title-med;
  color: var(--overwatch-button-text);
}

@keyframes fade-in-out {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.75;
  }
  100% {
    opacity: 0;
  }
}

.active-disabled-button {
  @include overwatch-body-small;
  display: flex;
  align-items: center;
  gap: var(--spacing-base);
  padding: var(--spacing-s);
  width: 115px;
}

.backdrop {
  position: fixed;
  top: env(safe-area-inset-top);
  left: 0;
  height: calc(100vh - env(safe-area-inset-top));
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 2;
}

.add-button {
  position: absolute;
  top: 18px;
  right: 0;
  z-index: 3;
  margin-right: var(--spacing-s);
}

.save-button {
  position: absolute;
  top: 100px;
  right: 0;
  z-index: 2;
  margin-right: var(--spacing-s);
}

//dropend button remove from mdb styling
.dropend .dropdown-toggle::after {
  display: none;
}

.active-bubble {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  box-shadow: 0 0 4px 0 var(--overwatch-light-success);
  background-image: linear-gradient(
    to bottom,
    #e7f0e6 0%,
    #7ec778 39%,
    var(--overwatch-light-success) 100%
  );
}

.disabled-bubble {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-image: radial-gradient(
    circle at 50% 50%,
    #c9c9c9,
    #c5c5c5 0%,
    #b8b8b8 32%,
    var(--overwatch-light-neutral-300) 71%
  );
}

.active-disabled-dropdown-menu {
  margin-bottom: 8px;
  margin-left: 2px;
}

.active-disabled-dropdown-menu-item {
  display: flex;
  align-items: center;
  width: 100%;
  gap: var(--spacing-base);
  padding: var(--spacing-base) 0;
}

.settings-icon {
  margin-left: auto;
  cursor: pointer;
  position: relative;
}

/* menu popup */
.settings-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--spacing-s);
  position: absolute;
  height: 235px;
  width: 325px;
  bottom: 75px;
  right: 10px;
  background-color: var(--overwatch-secondary);
  border-radius: 5px;
  z-index: 2;
}

/* container for all menu items */
.settings-menu-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-s);
  width: 100%;
  align-items: flex-start;
  padding-bottom: var(--spacing-s);
  padding-right: var(--spacing-s);
  padding-left: var(--spacing-s);
}

/* container for individual menu items */
.menu-item-container {
  display: flex;
  align-items: center;
  gap: var(--spacing-s);
  width: 100%;
}

.opacity-input {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  border-radius: 5px;
  background-color: var(--overwatch-neutral-500);
  border: 1px solid var(--overwatch-neutral-300);
  padding: var(--spacing-base);
}

input {
  all: unset;
}

.revert {
  color: var(--overwatch-primary);
  cursor: pointer;
  margin-left: auto;
  text-decoration: underline;
}
</style>
