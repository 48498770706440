<template>
  <div class="apikey-card">
    <div class="d-flex flex-row" style="width: 100%;">
      <div
        class="d-flex flex-row align-items-center"
        style="width: 95%;  padding: 4px !important;"
      >
        <div
          class="apikey-name"
          style="flex: 1; margin-right: 12px;"
          :title="apikey.label"
        >
          {{ apikey.label }}
        </div>
        <div
          v-if="!isMobile"
          class="apikey-value"
          style="flex: 2;"
          :title="apikey.idkey"
        >
          {{ apikey.idkey }}
        </div>
      </div>
      <div
        class="d-flex flex-row justify-content-end"
        style="width: 5%;  padding: 4px !important;"
      >
        <div
          class="disabled align-self-center"
          :class="{ 'hide-visibility': apikey.enabled }"
          style="margin-left: 20px;"
        >
          <RocIcon class="disabled-desktop" icon="exit" size="md" color="red" />
          <RocIcon class="disabled-mobile" icon="exit" size="md" color="red" />
        </div>
        <div class="align-self-center" style="margin-left: 20px;">
          <MDBDropdown v-model="dropdownOptions" align="end">
            <MDBDropdownToggle
              style="color:black; -webkit-appearance: none; -moz-appearance: none; appearance: none; padding: 8px 12px 8px;'"
              tag="a"
              @click="dropdownOptions = !dropdownOptions"
            >
              <RocIcon icon="kebab" size="sm" color="black" />
            </MDBDropdownToggle>
            <RocDropdownMenu aria-labelledby="dropdownMenuButton">
              <MDBDropdownItem href="" @click.prevent="toggleEnabled">
                <div>{{ stateText }}</div>
              </MDBDropdownItem>
              <MDBDropdownItem divider />
              <MDBDropdownItem href="" @click.prevent="editApiKey">
                <div>Edit</div>
              </MDBDropdownItem>
              <MDBDropdownItem divider />
              <MDBDropdownItem href="" @click.prevent="deleteApiKey">
                <div class="delete">Delete</div>
              </MDBDropdownItem>
            </RocDropdownMenu>
          </MDBDropdown>
        </div>
      </div>
    </div>
    <div
      v-if="isMobile"
      class="apikey-value"
      style="padding: 4px !important;"
      :title="apikey.idkey"
    >
      {{ apikey.idkey }}
    </div>
    <div
      class="d-flexalign-items-center"
      style="padding: 4px 8px 4px !important;"
    >
      <div v-if="apikey.notes" class="apikey-notes">{{ apikey.notes }}</div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import {
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownToggle
} from "mdb-vue-ui-kit";
import RocIcon from "@/components/ui/RocIcon.vue";
import RocDropdownMenu from "../ui/RocDropdownMenu.vue";

export default {
  name: "ApiKeySettings",
  emits: ["toggleState", "edit", "delete"],
  props: {
    apikey: Object
  },
  components: {
    MDBDropdown,
    MDBDropdownItem,
    MDBDropdownToggle,
    RocIcon,
    RocDropdownMenu
  },
  setup(props, context) {
    const dropdownOptions = ref(false);
    const stateText = computed(() => {
      return props.apikey.enabled ? "Disable" : "Enable";
    });

    function toggleEnabled() {
      context.emit("toggleState");
      dropdownOptions.value = !dropdownOptions.value;
    }

    function editApiKey() {
      context.emit("edit");
      dropdownOptions.value = !dropdownOptions.value;
    }

    function deleteApiKey() {
      context.emit("delete");
      dropdownOptions.value = !dropdownOptions.value;
    }

    const windowWidth = ref(window.innerWidth);
    const isMobile = computed(() => {
      return windowWidth.value <= 480;
    });
    onMounted(() => {
      window.addEventListener("resize", () => {
        windowWidth.value = window.innerWidth;
      });
    });

    return {
      dropdownOptions,
      toggleEnabled,
      stateText,
      editApiKey,
      deleteApiKey,
      isMobile
    };
  }
};
</script>

<style scoped lang="scss">
.apikey-card {
  width: 100% !important;
  margin-bottom: 5px !important;
  border-radius: 5px !important;
  padding: var(--spacing-base) !important;
  margin: 0;
  background: var(--overwatch-secondary);
  border: 1px solid var(--overwatch-neutral-300);
  margin-right: 0rem !important;
}

.apikey-name {
  font-size: 16px;
  font-weight: 600;
  margin: 0px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.apikey-value {
  font-size: 12px;
  font-weight: 600;
  margin: 0px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  text-wrap: wrap;
}

.apikey-notes {
  font-size: 12px;
  font-weight: 600;
  margin: 0px;
  display: inline-block;
  max-width: 100%;
  text-wrap: wrap;
}

.disabled {
  color: var(--overwatch-error);
  @include overwatch-body-small;
  line-height: 21px;
}

.dropdown-toggle:after {
  display: none;
}

.disabled-mobile {
  display: none;
}

.hide-visibility {
  visibility: hidden;
}

.key-data {
  flex-direction: row;
}

@media (max-width: 480px) {
  .disabled-mobile {
    margin-top: 3px;
    display: block;
  }

  .disabled-desktop {
    display: none;
  }
  .key-data {
    flex-direction: column;
  }
}
</style>
