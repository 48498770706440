<template>
  <div :class="{ 'active-bubble': model, 'disabled-bubble': !model }"></div>
</template>

<script setup>
const model = defineModel();
</script>

<style scoped>
.active-bubble {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  box-shadow: 0 0 4px 0 var(--overwatch-light-success);
  background-image: linear-gradient(
    to bottom,
    #e7f0e6 0%,
    #7ec778 39%,
    var(--overwatch-light-success) 100%
  );
}

.disabled-bubble {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-image: radial-gradient(
    circle at 50% 50%,
    #c9c9c9,
    #c5c5c5 0%,
    #b8b8b8 32%,
    var(--overwatch-light-neutral-300) 71%
  );
}
</style>
