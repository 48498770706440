<template>
  <div>
    <div>
      <MDBDatatable
        class="usergroup-users-table add-users-table"
        style="margin-top: 10px;"
        :dataset="usersNotInGroupData"
        selectable
        multi
        :pagination="false"
        :loading="isLoading"
        @selected-rows="handleSelectedRows"
      />
    </div>
    <div class="buttons">
      <RocButton type="secondary" @click="closeDialog">Cancel</RocButton>
      <RocButton @click="pressSave">Save</RocButton>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { MDBDatatable } from "mdb-vue-ui-kit";
import RocButton from "@/components/ui/RocButton.vue";

export default {
  name: "AddUsersToGroup",
  emits: ["close", "save"],
  props: {
    group: {
      type: Object
    },
    existingUsers: {
      type: Array
    }
  },
  components: {
    MDBDatatable,
    RocButton
  },
  setup(props, context) {
    const store = useStore();

    onMounted(async () => {
      await loadUsers();
    });

    const allUsers = ref([]);

    const isLoading = ref(false);

    async function loadUsers() {
      isLoading.value = true;
      try {
        const userResponse = await store.dispatch("settings/getUsers");
        if (userResponse.status === "success") {
          allUsers.value = userResponse.users;
        }
      } catch (err) {
        console.log(err);
      }

      isLoading.value = false;
    }

    const usersAlreadyInGroup = ref(
      props.existingUsers ? props.existingUsers : []
    );

    const usersNotInGroup = computed(() => {
      return allUsers.value.filter(
        (user) =>
          !usersAlreadyInGroup.value.map((x) => x._id).includes(user._id)
      );
    });

    const usersNotInGroupData = computed(() => {
      return {
        columns: ["_id", "First name", "Last Name", "Email"],
        rows: usersNotInGroup.value.map((user) => {
          return [user._id, user.firstname, user.lastname, user.email];
        })
      };
    });

    const selectedUsers = ref([]);

    function handleSelectedRows(rows) {
      selectedUsers.value = rows.map((x) => {
        return {
          email: x.email,
          firstname: x["first name"],
          lastname: x["last name"],
          _id: x._id
        };
      });
    }

    function pressSave() {
      console.log(selectedUsers.value);
      context.emit("save", selectedUsers.value);
      context.emit("close");
    }

    function closeDialog() {
      context.emit("close");
    }

    return {
      isLoading,
      usersNotInGroupData,
      handleSelectedRows,
      pressSave,
      closeDialog
    };
  }
};
</script>

<style scoped>
.buttons {
  display: flex;
  padding-top: 1rem;
  justify-content: right;
  gap: var(--spacing-s);
}
</style>

<style>
/* style to hide user _id column */
.add-users-table th:nth-child(2) {
  display: none;
}

.add-users-table td:nth-child(2) {
  display: none;
}
</style>
