<script setup>
import { ref, onMounted, computed } from 'vue';
import PasswordChange from '@/components/settings/PasswordChange.vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import RocLogo from '@/components/ui/RocLogo.vue';
import RocButton from '@/components/ui/RocButton.vue';

const email = ref('');
const token1 = ref('');
const token2 = ref('');
const backgroundSrc = ref('');
const route = useRoute();
const router = useRouter();
const store = useStore();
const responseMessage = ref('');
const showResponseMessage = ref(false);
const isLoading = ref(false);
const isError = ref(false);
const status = ref("");

onMounted(async () => {
    isLoading.value = true;
    await initUI();
    email.value = route.query.email ?? '';
    token1.value = route.query.token ?? '';
    const result = await store.dispatch('auth/validateOTPToken', { email: email.value, otp: token1.value });
    if(result?.status === "success"){
        token2.value = result.token;
    }
    else{
        showResponseMessage.value = true;
        isError.value = true;
        responseMessage.value = 'Invalid token. Please refresh the page or try again.';
    }
    isLoading.value = false;
});

async function initUI() {
    let configValue = "Login-Gradient.svg";
    let images = require.context("@/assets/login/");
    backgroundSrc.value = images("./" + configValue);
}

const bgStyle = computed(() => {
    return {
        "background-image": "url(" + backgroundSrc.value + ")"
    };
});

async function resetPassword(payload){
    isLoading.value = true;
    const result = await store.dispatch('auth/resetPasswordRequest', {
        email: email.value,
        otp: token2.value,
        password: payload.newPassword
    })
    
    isLoading.value = false;
    if(result.status === 200 && !result.details){
        showResponseMessage.value = true;
        isError.value = false;
        responseMessage.value = "Password reset successful. Please return to the login page.";
    } else if (result?.details) {
        isError.value = false;
        showResponseMessage.value = false;
        status.value = result?.details.join("|");
    } else {
        showResponseMessage.value = true;
        isError.value = true;
        responseMessage.value = 'Password reset failed. Please try again.';
    }
};

function returnToLogin(){
    router.push({ name: 'login' });
}

</script>

<template>
    <div class="bg-container" :style="bgStyle">
        <div class="reset-card">
            <div class="logo-and-title">
                <RocLogo />
                <div class="overwatch-title-large">
                    Reset Password
                </div>
            </div>
            <div class="reset-content">
                <PasswordChange @verified="resetPassword" :errorString="status" v-if="!isLoading  && !showResponseMessage"/>
                <div class="response" v-if="!isLoading && showResponseMessage">
                    <div :class="isError ? 'error' : 'success'">
                        {{ responseMessage }}
                    </div>
                    <RocButton @click="returnToLogin" style="width: 100%;">Login</RocButton>
                </div>

                <div class="loading" v-if="isLoading">
                    <roc-spinner/>
                    <div v-if="!token2">
                        Validating token...
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.bg-container {
    background-image: v-bind('backgroundSrc');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 100%;
    min-width: 860px;
    min-height: 700px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.reset-card {
    padding: var(--spacing-m);
    background-color: var(--overwatch-secondary);
    min-width: 580px;
    width: 581px;
    min-height: 566px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--overwatch-neutral-100);
    box-shadow: 0 4px 8px 0 var(--overwatch-overlay);
    border-radius: 15px;
}

.logo-and-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-m);
}

.reset-content {
  width: 460px;
  justify-content: center;
}

.loading{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: var(--spacing-l);
}

.response{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-m);
    width: 100%;
    margin-top: var(--spacing-m);
}

.error {
    color: var(--overwatch-error);
}

.success {
    color: var(--overwatch-success);
}

@media (max-width: 480px) {
.bg-container {
    position: static;
    padding-top: 20vh;
    padding-bottom: 20vh;
    padding-right: var(--spacing-m);
    padding-left: var(--spacing-m);
    min-width: 0;
    min-height: 0;
    overflow: hidden !important;
}
.reset-card {
    float: none;
    min-width: 0;
    width: 100vw;
    padding: var(--spacing-xxl) var(--spacing-xl);
    margin: var(--spacing-l) 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.reset-content{
    width: 100%;
}

.login-panel {
    width: 100% !important;
}
}
</style>
