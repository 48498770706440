<template>
  <div class="wrapper">
    <ClusterEncounterVideo
      v-if="globalVideoPlaybackEncounter"
      :caseId="caseId"
      @find-cluster="sendMessageFindCluster"
    />
  </div>
</template>

<script setup>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import ClusterEncounterVideo from "@/components/clusters/ClusterEncounterVideo.vue";

const store = useStore();

const props = defineProps(["caseId"]);

window.addEventListener("message", messageEventHandler);

const globalVideoPlaybackEncounter = computed(() => {
  return store.getters["clusters/videoPlaybackEncounter"];
});

onMounted(() => {
  window.opener.postMessage(JSON.stringify({ type: "childReady" }));
});

function messageEventHandler(event) {
  try {
    const eventObject = JSON.parse(event.data);

    if (eventObject.type === "initialData") {
      store.commit("clusters/setVideoPlaybackEncounter", eventObject.encounter);
      store.commit("clusters/setClusters", eventObject.clusters);
    }

    if (eventObject.type === "encounterChange") {
      store.commit("clusters/setVideoPlaybackEncounter", eventObject.data);
    }
  } catch (e) {}
}

window.addEventListener("beforeunload", () => {
  const payload = { type: "videoWindowClosed" };
  window.opener.postMessage(JSON.stringify(payload));
});

function sendMessageFindCluster(cluster) {
  const payload = { type: "findCluster", data: cluster };
  window.opener.postMessage(JSON.stringify(payload));
}
</script>

<style scoped lang="scss">
.wrapper {
  padding: var(--spacing-m);
}
</style>
