<script setup>
import { ref, computed, reactive, onMounted, watch } from "vue";
import RocIcon from "@/components/ui/RocIcon";
import RocInput from "@/components/ui/RocInput";
import RocButton from "@/components/ui/RocButton";
import MyPopperWrapper from "@/components/ui/MyPopperWrapper";
import RocTimezoneSingleSelect from "@/components/ui/RocTimezoneSingleSelect";
import DateHelper from "@/js/dateHelper";
import RocPopper from "@/components/ui/RocPopper";
import ManualParser from "@/components/cases/wizard/ManualParser.vue";

import { DatePicker } from "v-calendar";
import { DateTime } from "luxon";

const props = defineProps({
  files: {
    type: Array,
    required: true,
    default: []
  },
  defaultTimezone: {
    type: String,
    required: false,
    default: ""
  }
});

const emit = defineEmits(["change-cameranames", "change-datetimes"]);

const dh = new DateHelper();

const fileRegexPatterns = ref({
  synology: "(.+)-(\\d{4})(\\d{2})(\\d{2})-(\\d{2})(\\d{2})(\\d{2})",
  genetec: "(.+)_(\\d+)-(\\d+)-(\\d+)_(\\d+)h(\\d+)min(\\d+)s(\\d+)ms",
  milestone: "(.+).video.(\\d{4})(\\d{2})(\\d{2})-(\\d{2}).(\\d{2}).(\\d{2})",
  spectrum:
    "(.+)_(\\d{4})_(\\d{2})_(\\d{2})_(\\d{1,2})([AaPp][Mm])_(\\d{2})_(\\d{2})",
  generic: "(\\d{4})(\\d{2})(\\d{2})_(\\d{2})(\\d{2})(\\d{2})_(.+)"
});

const fileRegexTypesWithFiles = computed(() => {
  return Object.keys(fileRegexPatterns.value).filter(
    (key) => key in typeToFilenames && typeToFilenames[key].length > 0
  );
});

const fileRegexPatternOrder = ref({
  synology: {
    cameraname: 0,
    year: 1,
    month: 2,
    day: 3,
    hour: 4,
    min: 5,
    sec: 6,
    ms: null,
    ampm: null,
    timezone: null
  },
  genetec: {
    cameraname: 0,
    year: 1,
    month: 2,
    day: 3,
    hour: 4,
    min: 5,
    sec: 6,
    ms: 7,
    ampm: null,
    timezone: null
  },
  milestone: {
    cameraname: 0,
    year: 1,
    month: 2,
    day: 3,
    hour: 4,
    min: 5,
    sec: 6,
    ms: null,
    ampm: null,
    timezone: null
  },
  spectrum: {
    cameraname: 0,
    year: 1,
    month: 2,
    day: 3,
    hour: 4,
    min: 6,
    sec: 7,
    ms: null,
    ampm: 5,
    timezone: null
  },
  generic: {
    cameraname: 6,
    year: 0,
    month: 1,
    day: 2,
    hour: 3,
    min: 4,
    sec: 5,
    ms: null,
    ampm: null,
    timezone: null
  }
});

const isProcessing = ref(false);

onMounted(() => {
  isProcessing.value = true;
  processFiles();
  isProcessing.value = false;
});

const typeToFilenames = reactive({}); // Need a mapping from regex types to the filenames
const filenameToType = reactive({});
const filenamesToParsed = reactive({}); // Need a mapping from filenames to the groups
const uncategorized = ref([]);

function processFiles() {
  for (let file of props.files) {
    const split = file.fileHandle.name.split(".");
    const filename = split.slice(0, split.length - 1).join("."); // Filename without extension. Assuming it ends in .xxx

    let matched = false;
    for (const [regexType, regexVal] of Object.entries(
      fileRegexPatterns.value
    )) {
      if (!typeToFilenames[regexType]) {
        typeToFilenames[regexType] = [];
      }

      let regex = new RegExp(regexVal);

      const result = filename.match(regex);

      const patternMatched = result && filename === result[0];
      if (patternMatched) {
        typeToFilenames[regexType].push(filename);
        filenameToType[filename] = regexType;
        filenamesToParsed[filename] = result.slice(1);

        populateCameraAndDatetime(filename);

        matched = true;
        break;
      } else {
        // Set timezones to default even if it's not match
        filenamesToTimezone[filename] = props.defaultTimezone;
      }
    }
    if (!matched) {
      uncategorized.value.push(filename);
    }
  }

  // Autoselect first uncategorized file.
  if (uncategorized.value.length > 0) {
    selectedFilename.value = uncategorized.value[0];
  }
}

const isOpenRefs = ref([]);
function toggleFolder(idx) {
  isOpenRefs.value[idx] = !isOpenRefs.value[idx];
}

const selectedFilename = ref("");

const filenamesToCameraname = reactive({});
const filenamesToDatetime = reactive({});
const filenamesToTimezone = reactive({}); // This one is for visual consistency.

function populateCameraAndDatetime(filename) {
  const parts = filenamesToParsed[filename];
  const pattern = fileRegexPatternOrder.value[filenameToType[filename]];

  const cameraname = parts[pattern.cameraname];
  const year = parts[pattern.year];
  const month = parts[pattern.month];
  const day = parts[pattern.day];
  let hour = parts[pattern.hour] || 0;
  const min = parts[pattern.min] || 0;
  const sec = parts[pattern.sec] || 0;
  const ms = parts[pattern.ms] || 0;
  const ampm = parts[pattern.ampm];

  const timezone = parts[pattern.timezone];
  // TODO: Parse timezone

  // Convert AMPM to 24 hours
  if (ampm && (ampm === "PM" || ampm === "pm") && hour < 12) {
    hour = parseInt(hour) + 12;
  }

  filenamesToCameraname[filename] = cameraname;

  filenamesToTimezone[filename] = props.defaultTimezone;
  const dateObject = DateTime.fromObject(
    {
      year: year,
      month: month,
      day: day,
      hour: hour,
      minute: min,
      second: sec,
      millisecond: ms
    },
    { zone: filenamesToTimezone[filename] }
  );
  filenamesToDatetime[filename] = dateObject.toJSDate();
}

// Move calendar to selected file's parsed datetime.
const calendar = ref();
watch(selectedFilename, async (nv) => {
  if (filenamesToDatetime[nv]) {
    await calendar.value.move(filenamesToDatetime[nv]);
  } else {
    await calendar.value.move(new Date());
  }
});

const isShowingCalendar = ref(false);

const computedDatetimeString = computed(() => {
  if (!selectedFilename.value) {
    return "";
  }
  if (!filenamesToDatetime[selectedFilename.value]) {
    return "No datetime set";
  }
  const datetime = filenamesToDatetime[selectedFilename.value];

  const luxonDate = DateTime.fromJSDate(datetime).setZone(
    filenamesToTimezone[selectedFilename.value]
  );
  return dh.getFullDatetimeStringFromLuxonDate(luxonDate);
});

function emitDetails() {
  emit("change-cameranames", filenamesToCameraname);
  emit("change-datetimes", filenamesToDatetime);
}

/**
 * Parse uncategorized strings using manual parser options
 */
function manuallyParseUncategorized(parseOptions) {
  for (let filename of uncategorized.value) {
    manuallyParseFilename(parseOptions, filename);
  }
}

/**
 * Helper for manuallyParseUncategorized
 */

function manuallyParseFilename(parseOptions, filename) {
  let occurrenceSelectionIndices = [
    ...filename.matchAll(new RegExp(parseOptions.splitString, "gi"))
  ].map((a) => a.index);
  let splitIndex;
  if (parseOptions.orderSelection === "start") {
    splitIndex =
      occurrenceSelectionIndices[parseOptions.occurrenceSelection - 1];
  } else {
    splitIndex =
      occurrenceSelectionIndices[
        occurrenceSelectionIndices.length - parseOptions.occurrenceSelection
      ];
  }
  let cameraName;
  let restOfString;
  if (parseOptions.cameraNameOrder === "before") {
    cameraName = filename.substring(0, splitIndex);
    restOfString = filename.substring(
      splitIndex + parseOptions.splitString.length,
      filename.length
    );
  } else {
    cameraName = filename.substring(
      splitIndex + parseOptions.splitString.length,
      filename.length
    );
    restOfString = filename.substring(0, splitIndex);
  }
  const startDatetime = DateTime.fromFormat(
    restOfString,
    parseOptions.dateFormatString,
    {
      zone: filenamesToTimezone[filename]
    }
  ).toJSDate();

  // Final result
  filenamesToCameraname[filename] = cameraName;
  if (startDatetime instanceof Date && !isNaN(startDatetime)) {
    // Check for invalid dates.
    filenamesToDatetime[filename] = startDatetime;
  }
}

defineExpose({
  emitDetails
});
</script>

<template>
  <div style="height: 100%;">
    <roc-spinner v-if="isProcessing" />
    <div v-else style="height: 100%; display: flex; flex-direction: column;">
      <div v-if="uncategorized?.length > 0">
        <div class="uncategorized-warning">
          <RocIcon icon="error" color="red" size="md" />
          <div class="overwatch-body-med">
            <div>
              There have been files which the wizard was unable to autoparse.
            </div>
            <div>
              Please make sure the files have the correct camera name and date.
            </div>
          </div>
        </div>
        <RocPopper
          placement="bottom"
          :popperType="'tooltip'"
          :locked="true"
          class="manual-parser-popper"
        >
          <RocButton style="margin-top: var(--spacing-s);">
            Manually Bulk Parse Unparsed Files
          </RocButton>
          <template #content="{close}">
            <ManualParser
              @apply="
                (e) => {
                  manuallyParseUncategorized(e);
                  close();
                }
              "
              :example-filename="uncategorized[0]"
              :timezone="defaultTimezone"
            />
          </template>
        </RocPopper>
      </div>
      <div class="columns">
        <div class="files">
          <div v-if="uncategorized?.length > 0">
            <div class="files-header">
              Unparsed files
            </div>
            <ul>
              <li
                v-for="filename of uncategorized"
                class="file-item"
                :class="{ selected: selectedFilename === filename }"
                @click="selectedFilename = filename"
              >
                <RocIcon
                  :icon="
                    filenamesToCameraname[filename]?.length > 0 &&
                    filenamesToDatetime[filename]
                      ? 'check'
                      : 'exit'
                  "
                  :color="
                    filenamesToCameraname[filename]?.length > 0 &&
                    filenamesToDatetime[filename]
                      ? 'primary'
                      : 'red'
                  "
                  size="xs"
                />
                {{ filename }}
              </li>
            </ul>
          </div>
          <div
            v-if="files.length > uncategorized.length"
            style="margin-top: var(--spacing-s);"
          >
            <div class="files-header">
              Parsed files
            </div>
            <ul class="outer-list">
              <li
                v-for="[idx, type] in fileRegexTypesWithFiles.entries()"
                :ref="() => isOpenRefs.push(false)"
              >
                <a @click="toggleFolder(idx)">
                  {{ type }}
                  <RocIcon size="xs" icon="downArrow" :flip="isOpenRefs[idx]" />
                </a>
                <ul v-show="isOpenRefs[idx]">
                  <li
                    v-for="filename of typeToFilenames[type]"
                    class="file-item"
                    :class="{ selected: selectedFilename === filename }"
                    @click="selectedFilename = filename"
                  >
                    <RocIcon
                      :icon="
                        filenamesToCameraname[filename]?.length > 0 &&
                        filenamesToDatetime[filename]
                          ? 'check'
                          : 'exit'
                      "
                      :color="
                        filenamesToCameraname[filename]?.length > 0 &&
                        filenamesToDatetime[filename]
                          ? 'primary'
                          : 'red'
                      "
                      size="xs"
                    />
                    {{ filename }}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div class="settings">
          <div
            v-show="selectedFilename"
            style="overflow-wrap: anywhere; width: 100%;"
          >
            <div class="overwatch-title-small">
              {{ selectedFilename }}
            </div>
            <div class="setting-row">
              <div>Camera Name</div>
              <RocInput v-model="filenamesToCameraname[selectedFilename]" />
            </div>
            <div class="setting-row">
              <div>Datetime</div>
              <MyPopperWrapper style="width: 100%; margin: 0; border: 0;">
                <div
                  class="input-rectangle"
                  style="cursor: pointer; width: 100%;"
                  @click="isShowingCalendar = !isShowingCalendar"
                >
                  <RocIcon color="primary" size="sm" icon="calendar" />
                  <input
                    class="date-display-input"
                    placeholder="Add date and time"
                    :value="computedDatetimeString"
                    readonly
                  />
                </div>
                <template #content>
                  <div class="date-box">
                    <DatePicker
                      ref="calendar"
                      v-model="filenamesToDatetime[selectedFilename]"
                      :timezone="filenamesToTimezone[selectedFilename]"
                      mode="dateTime"
                      is24hr
                    />
                    <RocTimezoneSingleSelect
                      :currently-selected="
                        filenamesToTimezone[selectedFilename]
                      "
                      @selection-changed="
                        (v) => (filenamesToTimezone[selectedFilename] = v)
                      "
                    />
                  </div>
                </template>
              </MyPopperWrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
ul {
  @include overwatch-body-small;
  margin: var(--spacing-base) 0;
  list-style: none;
  padding-left: 0;
}

ul.outer-list {
  @include overwatch-body-med;
  list-style-type: none;
  text-transform: capitalize;
  padding: 0;
}

a {
  display: flex;
  align-items: center;
  gap: var(--spacing-base);
  margin-top: var(--spacing-s);
  margin-left: var(--spacing-s);
}

li.file-item {
  display: block;
  padding: var(--spacing-base);
  text-wrap: wrap;
}

li.file-item:hover {
  background-color: var(--overwatch-neutral-400);
}

.selected {
  background-color: var(--overwatch-neutral-300);
}

.columns {
  flex: 1;
  margin-top: var(--spacing-s);
  gap: var(--spacing-base);
  min-height: 0;

  display: flex;
}

.files {
  @include overwatch-body-med;
  height: 100%;
  // padding: var(--spacing-s);
  background-color: var(--overwatch-neutral-500);
  border-radius: 3px;
  overflow-wrap: break-word;
  overflow: auto;

  scrollbar-width: thin;
  flex: 1;
}

.files-header {
  @include overwatch-title-small;
  padding: var(--spacing-m);
  padding-left: var(--spacing-s);
  padding-bottom: var(--spacing-base);
}

.settings {
  height: 100%;
  flex: 2;
}

.setting-row {
  margin-top: var(--spacing-s);
}

.datetime-text {
  @include overwatch-body-small;
}

.datetime-text:hover {
  color: var(--overwatch-primary);
}

.timezone-select {
  padding: var(--spacing-xs);
  flex: 1;
}

.date-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-s);
  border-radius: 4px;
  padding: var(--spacing-s);
  background-color: var(--overwatch-neutral-400);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
}

.date-box :deep(.vc-container) {
  --rounded-full: 5px;
  background-color: var(--overwatch-secondary);
  --blue-600: var(--overwatch-button-primary);
  --blue-200: var(--overwatch-neutral-400);
  --blue-700: var(--overwatch-primary);
  --slide-duration: 0px;
  --slide-timing: 0s;
  --slide-translate: steps(5, end);
  border: none;
}

.date-box :deep(.vc-slide-down-leave-active) {
  position: relative !important;
  width: 50%;
}

.date-box :deep(.vc-header) {
  /* Change padding here for title */
  padding: 3px 16px 0px 16px;
}

.date-box :deep(.vc-weekday) {
  @include overwatch-body-xsmall;
  line-height: normal;
  letter-spacing: normal;
  color: var(--overwatch-neutral-100);
  display: flex;
  justify-content: center;
  text-transform: uppercase;
}

.date-box :deep(.vc-container) {
  --rounded-full: 5px;
  background-color: var(--overwatch-secondary);
  --blue-600: var(--overwatch-button-primary);
  --blue-200: var(--overwatch-neutral-400);
  --blue-700: var(--overwatch-primary);
  --slide-duration: 0px;
  --slide-timing: 0s;
  --slide-translate: steps(5, end);
  border: none;
}

.date-box :deep(.vc-title) {
  color: var(--overwatch-neutral-100);
}

.date-box :deep(.vc-container) {
  --rounded-full: 5px;
  background-color: var(--overwatch-secondary);
  --blue-600: var(--overwatch-button-primary);
  --blue-200: var(--overwatch-neutral-400);
  --blue-700: var(--overwatch-primary);
  --slide-duration: 0px;
  --slide-timing: 0s;
  --slide-translate: steps(5, end);
  border: none;
}

.date-box :deep(.vc-day-content.vc-focusable) {
  color: var(--overwatch-button-hover);
  --blue-900: var(--overwatch-button-text);
  --white: var(--overwatch-button-text);

  &.is-disabled {
    color: var(--overwatch-dark-neutral-400);
  }
}

.date-box :deep(.vc-day-content:hover) {
  background-color: var(--overwatch-button-primary);
  /* opacity: 0.6; */
  color: var(--overwatch-button-text);
  /* --blue-900: var(--overwatch-neutral-100); */
}

.date-box :deep(.vc-day-content:focus) {
  background-color: var(--overwatch-button-primary);
  color: var(--overwatch-button-text);
  --blue-900: var(--overwatch-button-text);
}

.date-box :deep(.vc-svg-icon) {
  color: var(--overwatch-button-primary);
}

.date-box :deep(.vc-day-content) {
  @include overwatch-body-med;
  line-height: normal;
  letter-spacing: normal;
}

.date-box :deep(.vc-weekday) {
  @include overwatch-body-xsmall;
  line-height: normal;
  letter-spacing: normal;
  color: var(--overwatch-neutral-100);
  display: flex;
  justify-content: center;
  text-transform: uppercase;
}

.uncategorized-warning {
  display: flex;

  border-radius: 5px;
  border: solid 1px var(--overwatch-error);
  align-items: center;
  gap: var(--spacing-l);
  padding: var(--spacing-s) 0 var(--spacing-s) var(--spacing-l);
  // padding-left: var(--spacing-l);
}
.input-rectangle {
  height: 45px;
  border-radius: 5px;
  border: solid 1px var(--overwatch-neutral-300);
  color: var(--overwatch-neutral-100);
  background-color: var(--overwatch-neutral-500);

  display: flex;
  align-items: center;
  padding: var(--spacing-s) var(--spacing-m) var(--spacing-s) var(--spacing-m);

  gap: 10px;
}
.date-display-input {
  @include overwatch-body-small;
  flex: 1;
  border: 0px;
  margin: 0 auto;
  text-align: left;
  color: var(--overwatch-neutral-100);
  background-color: var(--overwatch-neutral-500);
  cursor: pointer;
}

.manual-parser-popper :deep(.popper) {
  background-color: var(--overwatch-neutral-500);
}
.manual-parser-popper :deep(.popper:hover) {
  background-color: var(--overwatch-neutral-500);
}
</style>
