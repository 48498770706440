<template>
  <div class="boundary">
    <div v-if="detections.length" class="detections-in-video">
      Detections in Video Clip
    </div>
    <div v-else class="detections-in-video">
      No Detections Found in Video Clip
    </div>
    <div
      class="d-flex justify-content-start"
      style="margin-top: 8px;   overflow: auto;"
    >
      <div
        v-for="detection in detections"
        :key="detection._id"
        style="margin-right: 3px;"
      >
        <detection :detection="detection" @timestamp-clicked="setTimestamp" />
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, toRefs, watch } from "vue";
import { useStore } from "vuex";
import Detection from "@/components/encounters/Detection";

export default {
  name: "DetectionsPicker",
  emits: ["timestamp-update"],
  components: { Detection },
  props: {
    cameraId: {
      type: String,
      required: true
    },
    start: {
      type: Number,
      required: true
    },
    stop: {
      type: Number,
      required: true
    }
  },
  setup(props, context) {
    const isLoading = ref(false);
    const store = useStore();
    const detections = ref([]);
    const iStart = ref(props.start);
    let { start, stop } = toRefs(props);

    onMounted(async () => {
      await loadDetections(props.cameraId, iStart.value, props.stop);
    });

    watch([start, stop], async ([newStart, newStop]) => {
      await loadDetections(props.cameraId, newStart, newStop);
    });

    /*watch(() => {props.start, props.stop}, () => {
      console.log('hello: ', props.start);
    })*/

    async function loadDetections(cameraId, start, stop) {
      const url = "/rest/v1/camera/detections";
      const postBody = {
        cameraId: cameraId,
        start_timestamp: start,
        stop_timestamp: stop
      };
      const httpPayload = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(postBody)
      };

      isLoading.value = true;
      try {
        const responseData = await store.dispatch(
          "auth/fetchJSON",
          { url: url, payload: httpPayload },
          { root: true }
        );
        if (responseData.status === "success") {
          detections.value = responseData.detections;
        }
      } catch (error) {
        error.value = error.message || "Something went wrong!";
      }
      isLoading.value = false;
    }

    function setTimestamp(time) {
      //console.log("selected time: ", time);
      context.emit("timestamp-update", time);
    }

    return {
      isLoading,
      detections,
      setTimestamp,
      iStart
    };
  }
};
</script>

<style scoped lang="scss">
.detections-in-video {
  color: var(--overwatch-neutral-100);
  @include overwatch-body-med;
  line-height: 25px;
}

.boundary {
  width: 100%;
}

.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 1.5s linear;
}

.hidden {
  visibility: hidden;
  opacity: 0.3;
  transition: visibility 0s 2s, opacity 2s linear;
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
