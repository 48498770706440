export default {
  async searchByTemplateId(context, payload) {
    if (!payload.forceRefresh && !context.getters.shouldUpdate) {
      return;
    }

    context.commit("setEncounters", []);

    const templateId = context.getters.searchTemplateId;
    const url =
      payload.modality === "face"
        ? "/rest/v1/face/search/template"
        : "/rest/v1/object/search/template";
    let filter = {};
    filter.cameras =
      context.rootGetters["cameras/encounterFilterSelectedCameras"];

    const httpPayload = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        type: "template",
        templateId: templateId,
        filter: filter,
        modality: payload.modality
      })
    };

    const responseData = await context.dispatch(
      "auth/fetchJSON",
      {
        url: url,
        payload: httpPayload
      },
      { root: true }
    );

    const encounters = [];
    if (responseData.status === "success") {
      responseData.result.forEach((key) => {
        const matchResult = key;
        const encounter = {
          id: matchResult._id,
          timestamp: matchResult.timestamp,
          cameraName: matchResult.cameraName,
          cameraId: matchResult.cameraId,
          personId: matchResult.personId,
          templateId: matchResult.templateId,
          probe_tn: matchResult.tn,
          isNew: false,
          similarity: matchResult.similarity,
          objectType: payload.modality === "face" ? "face" : "object",
          md: matchResult.md,
          track_info: matchResult.track_info,
          video: matchResult.video,
          attributes: matchResult.attributes,
          label: matchResult.label,
          trackFinalized: true // we're querying static encounters here, they're all considered finalized
        };

        //should be fixed in the server...temp
        if (encounter.similarity > 0) {
          encounters.push(encounter);
        }
      });
    }

    context.commit("setEncounters", encounters);
    if (encounters.length) {
      return true;
    } else {
      return false;
    }
  },
  async searchByImageBytes(context, payload) {
    if (!payload.forceRefresh && !context.getters.shouldUpdate) {
      return;
    }

    context.commit("setEncounters", []);
    let filter = {};
    filter.cameras =
      context.rootGetters["cameras/encounterFilterSelectedCameras"];

    const url =
      payload.modality === "face"
        ? "/rest/v1/face/search/image"
        : "/rest/v1/object/search/image";
    const httpPayload = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        bytes: payload.imgData,
        filter: filter,
        modality: payload.modality
      })
    };
    const responseData = await context.dispatch(
      "auth/fetchJSON",
      { url: url, payload: httpPayload },
      { root: true }
    );

    const encounters = [];
    if (responseData.status === "success") {
      responseData.result.forEach((key) => {
        const matchResult = key;
        const encounter = {
          id: matchResult._id,
          timestamp: matchResult.timestamp,
          cameraName: matchResult.cameraName,
          cameraId: matchResult.cameraId,
          personId: matchResult.personId,
          templateId: matchResult.templateId,
          probe_tn: matchResult.tn,
          isNew: false,
          similarity: matchResult.similarity,
          objectType: payload.modality === "face" ? "face" : "object",
          md: matchResult.md,
          track_info: matchResult.track_info,
          video: matchResult.video,
          attributes: matchResult.attributes,
          trackFinalized: true // we're querying static encounters here, they're all considered finalized
        };

        //should be fixed in the server...temp
        if (encounter.similarity > 0) {
          encounters.push(encounter);
        }
      });
    }

    context.commit("setEncounters", encounters);
    if (encounters.length) {
      return true;
    } else {
      return false;
    }
  },
  async loadEncounters(context, payload) {
    if (!payload.forceRefresh && !context.getters.shouldUpdate) {
      return;
    }

    let filter = {};
    filter.cameras =
      context.rootGetters["cameras/encounterFilterSelectedCameras"];
    filter.watchlists =
      context.rootGetters["watchlists/encounterFilterSelectedWatchlists"];

    // Shallow copy here, modifying filter.analytics in the next line is affecting the analytics filter
    // array in other parts of code.
    filter.analytics = [...context.rootGetters["encounters/analyticsFilter"]];

    /**
     * Sean C: This snippet is here to replace 'vechicle' analytic with the modalities used in the SDK.
     * The modalities are 'car', 'truck', 'bus', 'motorcycle', 'bicycle', 'military_vehicle' as of 11/02/23.
     */
    const analyticsFilterVehicleIndex = filter.analytics.indexOf("vehicle");
    if (analyticsFilterVehicleIndex > -1) {
      const sdkModalities = [
        "car",
        "truck",
        "bus",
        "motorcycle",
        "bicycle",
        "military_vehicle"
      ];
      filter.analytics.splice(analyticsFilterVehicleIndex, 1, ...sdkModalities);
    }

    filter.matchesOnly = context.getters.matchesOnly;
    filter.adjudicationFilter = context.getters.adjudicationFilter;

    if (context.getters.getDTFilter && context.getters.getDTFilter.length > 0) {
      //convert date ranges to UTC
      filter.datesFilter = context.getters.getDTFilter.map((dateRange) => {
        return {
          start: new Date(dateRange.start).valueOf(),
          end: new Date(dateRange.end).valueOf()
        };
      });
    }

    filter.attributesFilter = context.getters.attributesFilter;
    filter.searchText = context.getters.getSearchText;

    const url = `/rest/v1/face/encounters`;
    const httpPayload = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: {
        filter: filter,
        cursor: context.getters.cursor,
        pageSize: context.getters.queryPageSize
      }
    };
    const responseData = await context.dispatch(
      "auth/fetchJSON",
      { url: url, payload: httpPayload },
      { root: true }
    );

    if (responseData.status === "success") {
      context.commit("setCursor", responseData.cursor);
      context.commit("setMoreData", responseData.more);

      const encounters = [];
      const encountersResponse = responseData.encounters;
      for (const key in encountersResponse) {
        const encounter = {};
        if (encountersResponse[key].objectType === "object_lp_match") {
          encountersResponse[key].probe_tn = encountersResponse[key].probeTN;
          delete encountersResponse[key].probeTN; //this is terrible, but changing will require significant rework and test, object properties must be standardized MSP
          Object.assign(encounter, encountersResponse[key]);
          Object.assign(encounter, {
            id: encountersResponse[key]._id,
            cameraId: encountersResponse[key].cameraId,
            isNew: false
          });
        } else if (encountersResponse[key].objectType === "object_match") {
          encountersResponse[key].probe_tn = encountersResponse[key].probeTN;
          delete encountersResponse[key].probeTN;
          Object.assign(encounter, encountersResponse[key]);
          Object.assign(encounter, {
            id: encountersResponse[key]._id,
            cameraId: encountersResponse[key].cameraId,
            isNew: false,
            cameraName: encountersResponse[key].probeImageCameraName,
            candidate_tn: encountersResponse[key].tn,
            notes: encountersResponse[key].notes
          });
        } else {
          Object.assign(encounter, {
            id: encountersResponse[key]._id,
            timestamp: encountersResponse[key].timestamp,
            cameraName: encountersResponse[key].cameraName,
            cameraId: encountersResponse[key].cameraId,
            personId: encountersResponse[key].personId,
            templateId: encountersResponse[key].templateId,
            probe_tn: encountersResponse[key].tn,
            isNew: false,
            objectType: encountersResponse[key].type,
            md: encountersResponse[key].md,
            track_info: encountersResponse[key].track_info,
            video: encountersResponse[key].video,
            age: encountersResponse[key].age,
            spoofScore: encountersResponse[key].spoofScore,
            contextImageMediaId: encountersResponse[key].contextImageMediaId,
            adjudication: encountersResponse[key].adjudication,
            attributes: encountersResponse[key].attributes,
            confidence: encountersResponse[key].confidence
          });
        }

        if (encountersResponse[key].type === "object") {
          encounter.label = encountersResponse[key].label;
        }

        if (encountersResponse[key].type === "face_match") {
          encounter.lastname = encountersResponse[key].lastname;
          encounter.firstname = encountersResponse[key].firstname;
          encounter.similarity = encountersResponse[key].similarity;
          encounter.cameraName = encountersResponse[key].probeFaceCameraName;
          encounter.cameraId = encountersResponse[key].cameraId;
          encounter.candidate_tn = encountersResponse[key].tn;
          encounter.probe_tn = encountersResponse[key].probeFaceTN;
          encounter.watchlistName = encountersResponse[key].watchlistName;
          encounter._watchlistId = encountersResponse[key]._watchlistId;
          encounter.notes = encountersResponse[key].notes;
          encounter.watchlistedFaceId =
            encountersResponse[key].watchlistedFaceId;
        }

        // we're querying static encounters here, they're all considered finalized
        encounter.trackFinalized = true;
        encounters.push(encounter);

        if (payload.isAppend) {
          context.commit("appendEncounter", encounter);
        }
      }
      if (!payload.isAppend) {
        context.commit("setEncounters", encounters);
      }
      if (encounters.length) {
        return true;
      }
    }

    return false;
  },

  /*"This action is used to bypass user selected encounter filters and allow general loading of known encounters
  on a watchlisted face while reusing the rest of the encounter paging infrastructure.
  This is used when it is necessary to list matches for a watchlisted face without filters getting committed"*/
  async loadEncountersWatchlistedFace(context, payload) {
    let filter = {};
    filter.watchlistedFaceIds = payload.watchlistedFaceIds;
    //pulling all cameras available instead of just ones selected in filter
    let fetchCameras = context.rootGetters["cameras/cameras"];
    let allCamGUIDs = [];
    fetchCameras.forEach((cam) => {
      allCamGUIDs.push(cam.GUID);
    });
    filter.cameras = allCamGUIDs;
    //matchesOnly will always be true here to return the query needed
    filter.matchesOnly = true;
    filter.watchlists = payload.watchlistIds;

    const url = `/rest/v1/face/encounters`;
    const httpPayload = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: {
        filter: filter,
        cursor: payload.cursor,
        pageSize: context.getters.queryPageSize
      }
    };
    const responseData = await context.dispatch(
      "auth/fetchJSON",
      { url: url, payload: httpPayload },
      { root: true }
    );
    // we're returning all the response data to the caller here as opposed to storing in vuex
    const responseObject = {
      encounters: [],
      moreData: false,
      cursor: null
    };
    if (responseData.status === "success") {
      responseObject.cursor = responseData.cursor;
      responseObject.moreData = responseData.more;

      const encountersResponse = responseData.encounters;
      for (const key in encountersResponse) {
        const encounter = {};
        Object.assign(encounter, {
          id: encountersResponse[key]._id,
          timestamp: encountersResponse[key].timestamp,
          cameraName: encountersResponse[key].cameraName,
          cameraId: encountersResponse[key].cameraId,
          personId: encountersResponse[key].personId,
          templateId: encountersResponse[key].templateId,
          probe_tn: encountersResponse[key].tn,
          isNew: false,
          objectType: encountersResponse[key].type,
          md: encountersResponse[key].md,
          track_info: encountersResponse[key].track_info,
          video: encountersResponse[key].video,
          age: encountersResponse[key].age,
          spoofScore: encountersResponse[key].spoofScore,
          contextImageMediaId: encountersResponse[key].contextImageMediaId,
          adjudication: encountersResponse[key].adjudication,
          attributes: encountersResponse[key].attributes
        });
        encounter.lastname = encountersResponse[key].lastname;
        encounter.firstname = encountersResponse[key].firstname;
        encounter.similarity = encountersResponse[key].similarity;
        encounter.cameraName = encountersResponse[key].probeFaceCameraName;
        (encounter.cameraId = encountersResponse[key].cameraName),
          (encounter.candidate_tn = encountersResponse[key].tn);
        encounter.probe_tn = encountersResponse[key].probeFaceTN;
        encounter.watchlistName = encountersResponse[key].watchlistName;
        encounter._watchlistId = encountersResponse[key]._watchlistId;
        encounter.notes = encountersResponse[key].notes;
        encounter.watchlistedFaceId = encountersResponse[key].watchlistedFaceId;

        responseObject.encounters.push(encounter);
      }
    }
    return responseObject;
  },
  async updateFaceEncounter(context, payload) {
    const url = `/rest/v1/face/bestfacetemplate/${payload.id}`;
    const httpPayload = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    };
    const responseData = await context.dispatch(
      "auth/fetchJSON",
      { url: url, payload: httpPayload },
      { root: true }
    );
    return responseData;
  },
  async updateObjectEncounter(context, payload) {
    const url = `/rest/v1/object/bestobjtemplate/${payload.id}`;
    const httpPayload = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    };
    const responseData = await context.dispatch(
      "auth/fetchJSON",
      { url: url, payload: httpPayload },
      { root: true }
    );
    return responseData;
  },
  async getAllAttributes(context, payload) {
    const url = `/rest/v1/${payload.type}/tags`;
    const httpPayload = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    };
    const responseData = await context.dispatch(
      "auth/fetchJSON",
      { url: url, payload: httpPayload },
      { root: true }
    );
    if (responseData && responseData.status === "success") {
      if (payload.type === "object") {
        context.commit("setAllObjectAttributes", responseData.tags);
      } else if (payload.type === "face") {
        if (!responseData.tags || responseData.tags.length == 0) {
          context.commit("setAllFaceAttributes", []);
        } else {
          context.commit("setAllFaceAttributes", responseData.tags);
        }
      }
    }
    return responseData;
  }
};
