import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

const getDefaultState = () => {
  return {
    requests: []
  };
};

const state = getDefaultState();

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  getDefaultState
};
