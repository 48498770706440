<script setup>
import { DatePicker } from "v-calendar";
import RocIcon from "@/components/ui/RocIcon.vue";
import RocButton from "@/components/ui/RocButton.vue";
import MyPopperWrapper from "../ui/MyPopperWrapper.vue";
import { DefineProps, ref, onMounted } from "vue";
import moment from "moment";

const props = defineProps({
  modelValue: {
    type: String
  },
  minDate: {
    type: String
  },
  errorMessage: {
    type: String,
    required: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  placeholder: {
    type: String,
    default: "Add date and time"
  },
  clearButton: Boolean
});

const emits = defineEmits(["update:modelValue"]);

onMounted(() => {
  document.addEventListener("click", handleWrapperClick);
});
const togglePopper = ref(false);
const popperWrapper = ref(null);
const date = ref(props.modelValue);
const selectedDate = ref(props.modelValue ? props.modelValue.slice(0, 10) : "");

const handleWrapperClick = (event) => {
  if (popperWrapper.value && !popperWrapper.value.$el.contains(event.target)) {
    togglePopper.value = false;
  }
};

function togglePopperWindow() {
  if (!props.disabled) togglePopper.value = !togglePopper.value;
}

/*  @params string
    Creates a date using moment. That vaue is emitted to the parent in 
    a format like 2024-04-26T14:45:11-04:00 Time must be kept in order for Min date to work properly.
*/
const dateChange = (newDateSelected) => {
  const formattedDate = moment(newDateSelected).format();
  selectedDate.value = format(formattedDate);
  emits("update:modelValue", formattedDate);
  togglePopper.value = false;
};

function format(string) {
  return string.slice(0, 10).replaceAll("/", "-");
}

function clearInput() {
  date.value = null;
  selectedDate.value = null;
  emits("update:modelValue", null);
}
</script>

<template>
  <div>
    <MyPopperWrapper
      :locked="true"
      offsetDistance="2"
      offsetSkid="-71"
      :show="togglePopper"
      ref="popperWrapper"
      @close:popper="togglePopper = false"
      @click="handleWrapperClick"
      style="display: block"
    >
      <div @click="togglePopperWindow">
        <div
          class="input-rectangle"
          :class="{ inputError: false }"
          style="cursor: pointer;"
        >
          <RocIcon color="primary" size="md" icon="calendar" />
          <input
            class="date-display-input"
            :placeholder="placeholder"
            :value="selectedDate"
            readonly
            :disabled="disabled"
          />
          <div
            class="clear-button"
            v-if="clearButton && !disabled"
            @click.stop="clearInput()"
          >
            <RocIcon color="black" size="xs" icon="exit" />
          </div>
        </div>
      </div>
      <template #content v-if="togglePopper">
        <div class="small-Popper" style="padding-left: 0px; padding-top: 10px;">
          <div class="d-flex">
            <DatePicker
              v-model="date"
              transition="none"
              mode="date"
              :min-date="minDate"
            >
            </DatePicker>
          </div>
          <div class="line-breaker" style="width: 100%"></div>
          <div class="buttons-footer">
            <div style="display: contents;">
              <RocButton
                class="cancelApplyButton"
                type="secondary"
                size="dialogButton"
                @click="togglePopperWindow()"
              >
                <span
                  class="overwatch-body-small"
                  style="color: var(--overwatch-button-text)"
                  >Cancel</span
                >
              </RocButton>
              <RocButton
                class="saveButton"
                type="primary"
                size="dialogButton"
                @click="dateChange(date)"
              >
                <span
                  class="overwatch-body-small"
                  style="color: var(--overwatch-button-text)"
                  >Apply</span
                >
              </RocButton>
            </div>
          </div>
        </div>
      </template>
    </MyPopperWrapper>
  </div>
</template>

<style scoped lang="scss">
.input-rectangle {
  box-sizing: border-box;
  height: 45px;
  border-radius: 5px;
  border: solid 1px var(--overwatch-neutral-300);
  color: var(--overwatch-neutral-100);
  background-color: var(--overwatch-neutral-500);

  display: flex;
  align-items: center;
  padding: var(--spacing-s) var(--spacing-m) var(--spacing-s) var(--spacing-m);

  gap: 10px;
}

.inputError {
  border-radius: 5px;
  border: solid 1px var(--overwatch-light-error);
  background-color: var(--overwatch-neutral-500);
  padding: var(--spacing-s) var(--spacing-s) var(--spacing-s) var(--spacing-s);
  cursor: v-bind(cursor);
  display: flex;
  align-items: center;
}

.date-display-input {
  border: 0px;
  margin: 0 auto;
  text-align: left;
  color: var(--overwatch-neutral-100);
  background-color: var(--overwatch-neutral-500);
  cursor: pointer;
}

.date-display-input::placeholder {
  color: var(--overwatch-neutral-300);
}

.cancelApplyButton {
  height: 45px;
  width: 100%;
}

.saveButton {
  background: var(--overwatch-primary);
  color: var(--overwatch-button-text) !important;
  font-size: 14px;
  height: 45px;
  width: 100%;
}

.buttons-footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: 10px 0px 10px 0px;
  padding: 0px 24px 0px 24px;
}

.small-Popper :deep(.vc-container) {
  --rounded-full: 5px;
  background-color: var(--overwatch-secondary);
  --blue-600: var(--overwatch-button-primary);
  --blue-200: var(--overwatch-neutral-400);
  --blue-700: var(--overwatch-primary);
  --slide-duration: 0px;
  --slide-timing: 0s;
  --slide-translate: steps(5, end);
  border: none;
}

.small-Popper :deep(.vc-slide-down-leave-active) {
  position: relative !important;
  width: 50%;
}

.small-Popper :deep(.vc-header) {
  /* Change padding here for title */
  padding: 3px 16px 0px 16px;
}

.small-Popper :deep(.vc-weekday) {
  @include overwatch-body-xsmall;
  line-height: normal;
  letter-spacing: normal;
  color: var(--overwatch-neutral-100);
  display: flex;
  justify-content: center;
  text-transform: uppercase;
}

.small-Popper {
  width: 300px;
  min-height: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;

  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  background-color: var(--overwatch-secondary);
}

.small-Popper :deep(.vc-container) {
  --rounded-full: 5px;
  background-color: var(--overwatch-secondary);
  --blue-600: var(--overwatch-button-primary);
  --blue-200: var(--overwatch-neutral-400);
  --blue-700: var(--overwatch-primary);
  --slide-duration: 0px;
  --slide-timing: 0s;
  --slide-translate: steps(5, end);
  border: none;
}

.small-Popper :deep(.vc-title) {
  color: var(--overwatch-neutral-100);
}

.small-Popper :deep(.vc-container) {
  --rounded-full: 5px;
  background-color: var(--overwatch-secondary);
  --blue-600: var(--overwatch-button-primary);
  --blue-200: var(--overwatch-neutral-400);
  --blue-700: var(--overwatch-primary);
  --slide-duration: 0px;
  --slide-timing: 0s;
  --slide-translate: steps(5, end);
  border: none;
}

.small-Popper :deep(.vc-day-content.vc-focusable) {
  color: var(--overwatch-button-hover);
  --blue-900: var(--overwatch-button-text);
  --white: var(--overwatch-button-text);

  &.is-disabled {
    color: var(--overwatch-dark-neutral-400);
  }
}

.small-Popper :deep(.vc-day-content:hover) {
  background-color: var(--overwatch-button-primary);
  /* opacity: 0.6; */
  color: var(--overwatch-button-text);
  /* --blue-900: var(--overwatch-neutral-100); */
}

.small-Popper :deep(.vc-day-content:focus) {
  background-color: var(--overwatch-button-primary);
  color: var(--overwatch-button-text);
  --blue-900: var(--overwatch-button-text);
}

.small-Popper :deep(.vc-svg-icon) {
  color: var(--overwatch-button-primary);
}

.small-Popper :deep(.vc-day-content) {
  @include overwatch-body-med;
  line-height: normal;
  letter-spacing: normal;
}

.line-breaker {
  box-sizing: border-box;
  height: 1px;
  width: 100%;
  border: 1px solid var(--overwatch-accent);
  margin-top: 8px;
  margin-bottom: 8px;
}

.small-Popper :deep(.vc-weekday) {
  @include overwatch-body-xsmall;
  line-height: normal;
  letter-spacing: normal;
  color: var(--overwatch-neutral-100);
  display: flex;
  justify-content: center;
  text-transform: uppercase;
}

.clear-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 4px;
}
</style>
