export default {
  resetState(state, defaultState) {
    // Merge rather than replace so we don't lose observers
    Object.assign(state, defaultState);
  },
  setInactivityTimeout(state, payload) {
    state.inactivityTimeout = payload;
  },
  setLastActivity(state, payload) {
    state.lastActivity = payload;
  },
  setSharedUserAction(state, payload) {
    state.sharedUserAction = payload;
  }
}