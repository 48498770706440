import actions from "./actions.js";
import mutations from "./mutations.js";
import getters from "./getters.js";

const getDefaultState = () => {
  return {
    workflows: [],
    editingWorkflow: null
  };
};

const state = getDefaultState();

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  getDefaultState
};
