<template>
  <section>
    <base-card>
      <h2>Page not found</h2>
      <p>
        <br />You may be attempting to access a resource that's no longer
        available and/or requires different permissions.
      </p>
    </base-card>
  </section>
</template>
