export default {
  retention(state) {
    return state.retention;
  },
  spoofThreshold(state) {
    return state.spoof_threshold;
  },
  getServerConfig(state) {
    return state.server_config;
  },
  getDeviceInfo(state) {
    return state.deviceInfo;
  },
  isEnrollButtonEnabled(state) {
    return state.is_enroll_button_enabled;
  },
  isExamineButtonEnabled(state) {
    return state.is_examine_button_enabled;
  },
  taggedFacesWatchlistConfig(state) {
    return state.taggedFacesWatchlistConfig;
  },
  faceTaggingEnabled(state) {
    return state.faceTaggingEnabled;
  },
  reverseSearchObjModalities(state) {
    return state.reverseSearchObjModalities;
  },
  watchlistColors(state) {
    return state.watchlistColors;
  },
  watchlistDefaultColor(state) {
    return state.watchlistDefaultColor;
  },
  encounterCardUX(state) {
    return state.encounterCardUX;
  },
  userGroups(state) {
    return state.userGroups;
  },
  allVideoServiceIds(state) {
    return state.allVideoServiceIds;
  },
  lowestLoadVideoServiceId(state) {
    return state.lowestLoadVideoServiceId;
  },
  watchlistAdditionalInfoFields(state) {
    return state.watchlistAdditionalInfoFields;
  },
  getLicenseFlags(state) {
    return state.flags;
  },
  getDarkMode(state) {
    return state.darkMode !== null
      ? state.darkMode
      : window.matchMedia("(prefers-color-scheme: dark)")?.matches ?? false;
  },
  getCaptureZonesInstructionsShown(state) {
    return state.captureZonesInstructionsShown;
  },
  isMissionsDisabled(state) {
    return state.isMissionsDisabled;
  },
  windowWidth(state) {
    return state.windowWidth;
  },
  isMobile(state) {
    return state.isMobile;
  }
};
