<template>
  <div>
    <div class="d-flex dialog flex-column">
      <div class="d-flex flex-row" style="width: 100%; gap: var(--spacing-s);">
        <div style="flex: 1; margin-bottom: var(--spacing-s);">
          <div>First Name</div>
          <RocInput
            placeholder="Enter first name"
            v-if="!isHidden('firstname')"
            :disabled="isDisabled('firstname')"
            label="First Name"
            v-model="firstname"
            style="flex:1"
          />
        </div>
        <div style="flex: 1; margin-bottom: var(--spacing-s);">
          <div>Last Name</div>
          <RocInput
            placeholder="Enter last name"
            v-if="!isHidden('lastname')"
            :disabled="isDisabled('lastname')"
            v-model="lastname"
            style="flex:1"
          />
        </div>
      </div>
      <div
        class="d-flex flex-row"
        style="width: 100%; margin-bottom: var(--spacing-s);"
      >
        <div style="flex: 1;" v-if="!isHidden('email')">
          <div>Email</div>
          <RocInputEmail
            :disabled="isDisabled('email')"
            :placeholder="'Enter email address'"
            v-model="email"
            style="flex: 1"
          />
        </div>
        <div v-if="!isHidden('phoneNumber')" style="flex: 1;">
          <div>Phone</div>
          <RocInputPhone
            :disabled="isDisabled('phoneNumber')"
            :placeholder="'Enter phone number'"
            v-model="phoneNumber"
            style="flex: 1"
          />
        </div>
      </div>
      <div style="margin-bottom: var(--spacing-s);">
        <div>Notes</div>
        <RocTextArea
          rows="3"
          placeholder="Enter any notes here"
          v-model="notes"
        />
      </div>

      <div
        class="d-flex"
        style="flex-direction: row; justify-content: flex-end; gap: var(--spacing-s)"
      >
        <RocButton
          type="secondary"
          @click="$emit('close')"
          :disabled="isCruding"
        >
          Cancel
        </RocButton>
        <RocButton
          type="primary"
          :disabled="!isCreatable"
          v-if="entitlement && !isCruding"
          @click="emitEdit"
        >
          Save
        </RocButton>
        <RocButton
          :disabled="!isCreatable"
          v-else-if="!isCruding"
          style="background: var(--overwatch-primary); color: white;"
          @click="emitCreate"
        >
          Create Invite
        </RocButton>
        <roc-spinner v-else-if="isCruding" />
      </div>
      <div
        class="d-flex"
        v-if="crudError.length > 0"
        style="flex-direction: row; justify-content: flex-end; color: var(--overwatch-error);"
      >
        {{ crudError }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { MDBRadio, MDBSelect } from "mdb-vue-ui-kit";
import BaseDialog from "@/components/ui/BaseDialog";
import { useStore } from "vuex";
import RocInput from "../ui/RocInput.vue";
import RocInputPhone from "../ui/RocInputPhone.vue";
import RocInputEmail from "../ui/RocInputEmail.vue";
import RocButton from "@/components/ui/RocButton";
import RocTextArea from "@/components/ui/RocTextArea.vue";

export default {
  components: {
    MDBRadio,
    MDBSelect,
    BaseDialog,
    RocButton,
    RocInput,
    RocTextArea,
    RocInputPhone,
    RocInputEmail
  },
  props: {
    entitlement: Object,
    crudError: String,
    isCruding: Boolean
  },
  emits: ["close", "edit", "create"],
  setup(props, context) {
    const store = useStore();

    const firstname = ref(props.entitlement ? props.entitlement.firstname : "");
    const lastname = ref(props.entitlement ? props.entitlement.lastname : "");

    const email = ref(props.entitlement ? props.entitlement.email : "");
    const phoneNumber = ref(
      props.entitlement ? props.entitlement.phoneNumber : ""
    );
    const notes = ref(props.entitlement ? props.entitlement.notes : "");

    const windowWidth = ref(window.innerWidth);
    const isCreatable = computed(() => {
      const firstnameOk = isRequired("firstname")
        ? firstname.value.length > 0
        : true;
      const lastnameOk = isRequired("lastname")
        ? lastname.value.length > 0
        : true;
      const emailOk = isRequired("email") ? email.value.length > 0 : true;
      const phoneNumberOk = isRequired("phoneNumber")
        ? phoneNumber.value.length > 0
        : true;
      return firstnameOk && lastnameOk && emailOk && phoneNumberOk;
    });

    onMounted(() => {
      window.addEventListener("resize", () => {
        windowWidth.value = window.innerWidth;
      });
    });

    async function emitCreate() {
      const payload = {
        email: email.value,
        phoneNumber: phoneNumber.value,
        notes: notes.value,
        firstname: firstname.value,
        lastname: lastname.value
      };
      context.emit("create", payload);
    }

    async function emitEdit() {
      const payload = {
        id: props.entitlement._id,
        update: {
          email: email.value,
          phoneNumber: phoneNumber.value,
          notes: notes.value,
          firstname: firstname.value,
          lastname: lastname.value
        }
      };
      context.emit("edit", payload);
    }

    function isRequired(field) {
      const enrollConfig = store.getters["rocenroll/enrollConfig"];
      const requiredFields = _.get(
        enrollConfig,
        "entitlements.requiredFields",
        []
      ); // default to not required
      return requiredFields.includes(field);
    }

    function isDisabled(field) {
      if (props.entitlement && field === "phoneNumber") {
        // can't edit phone number
        return true;
      }
      const enrollConfig = store.getters["rocenroll/enrollConfig"];
      const disabledFields = _.get(
        enrollConfig,
        "entitlements.disabledFields",
        []
      ); // default to not disabled
      return disabledFields.includes(field);
    }

    function isHidden(field) {
      const enrollConfig = store.getters["rocenroll/enrollConfig"];
      const hiddenFields = _.get(enrollConfig, "entitlements.hiddenFields", []); // default to not hidden
      return hiddenFields.includes(field);
    }

    return {
      firstname,
      lastname,
      email,
      phoneNumber,
      notes,
      emitCreate,
      emitEdit,
      isCreatable,
      isRequired,
      isDisabled,
      isHidden
    };
  }
};
</script>

<style scoped>
.inputTitle {
  font-size: 12px;
}

.dialog {
  width: 400px;
  gap: var(--spacing-m);
}

.radioButtonGroup {
  border-radius: 25px;
}
</style>

<style>
/* Class for selected radio button */
.btn-check:checked + label {
  background-color: blue;
  color: white;
}

.radiobtn {
  border-radius: 25px;
}
</style>
