<template>
  <MDBCard
    :class="{
      'image-card': media.type === 'image',
      'video-card': media.type === 'video'
    }"
    style="box-sizing: content-box !important;"
    :key="media.name"
  >
    <MDBCardBody v-if="isUploading">
      <div style="display: flex; flex-direction: column;">
        <div style="display:flex; align-items: center; gap: var(--spacing-m)">
          <roc-spinner />
          Uploading...
        </div>
        {{ media.name }}
      </div>
    </MDBCardBody>
    <MDBCardBody v-else>
      <div
        style="display: flex; flex-direction: row-reverse; align-items: center;"
      >
        <MDBDropdown v-model="dropdownOptions" @click.stop>
          <MDBDropdownToggle
            @click="dropdownOptions = !dropdownOptions"
            style="color:black;
            -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;"
            tag="a"
          >
            <RocIcon
              color="black"
              size="sm"
              icon="kebab"
              style="margin-left: var(--spacing-s); margin-bottom: var(--spacing-base)"
            />
          </MDBDropdownToggle>
          <RocDropdownMenu
            style="box-shadow: 4px 4px 15px 4px rgba(0, 0, 0, 0.3);"
          >
            <MDBDropdownItem
              href=""
              @click.prevent="
                $emit('delete');
                dropdownOptions = false;
              "
              style="color:var(--overwatch-error);"
            >
              <div>
                Delete
              </div>
            </MDBDropdownItem>
          </RocDropdownMenu>
        </MDBDropdown>
        <RocCheckbox ref="checkboxRef" v-model="isChecked" @click.stop />
        <RocIcon
          :icon="media.type === 'video' ? 'cameraOn' : 'image'"
          style="margin-right: auto;"
        />
      </div>
      {{ media.name }}

      <AuthImg
        v-if="media.type === 'image'"
        class="thumbnail-image"
        :src="`/rest/v1/image/casemedia/${media._id}/tn`"
      />
    </MDBCardBody>
  </MDBCard>
</template>

<script>
import { ref, computed, watch } from "vue";
import {
  MDBCard,
  MDBCardBody,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownItem
} from "mdb-vue-ui-kit";
import RocCheckbox from "@/components/ui/RocCheckbox";
import AuthImg from "@/components/ui/AuthImg";
import RocIcon from "@/components/ui/RocIcon";
import RocDropdownMenu from "../ui/RocDropdownMenu.vue";

export default {
  name: "CaseMediaCard",
  components: {
    MDBCard,
    MDBCardBody,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownItem,
    RocCheckbox,
    AuthImg,
    RocIcon,
    RocDropdownMenu
  },
  props: ["media", "uploading", "isSelected"],
  emits: ["check", "delete"],
  setup(props, context) {
    const isUploading = computed(() => props.uploading);

    const dropdownOptions = ref(false);

    const isChecked = ref(false);
    watch(isChecked, (nv) => {
      context.emit("check", nv);
    });

    watch(
      () => props.isSelected,
      (nv) => {
        if (nv === 1 || nv === -1) {
          isChecked.value = nv === 1;
        }
      }
    );

    return {
      isChecked,
      isUploading,
      dropdownOptions
    };
  }
};
</script>

<style scoped>
.image-card {
  height: 400px;
}

.card-body {
  background-color: var(--overwatch-secondary);
  border-radius: 5px;
}

.highlighted {
  border: 1px solid var(--overwatch-button-primary) !important;
  border-radius: 0.5rem;
}

.thumbnail-image {
  display: block;
  max-width: 500px;
  max-height: 300px;
}
</style>
