<template>
  <div class="filter-dialog">
    <!-- Title -->
    <div v-if="editingFilter" class="overwatch-title-med">
      Edit Saved Filter
    </div>
    <div v-else class="overwatch-title-med">
      New Saved Filter
    </div>
    <div class="dialog-row">
      <span class="overwatch-body-med">Name</span>
      <RocInput
        v-model="filterName"
        placeholder="Enter a name for your saved filter"
      />
    </div>

    <div class="dialog-row">
      <span class="overwatch-body-med" style="margin-bottom: var(--spacing-s)"
        >Saved Filter Items</span
      >
      <div>
        <SavedViewBadges
          :clusterFilterMode="true"
          popupType="filterUpdate"
          :filter="filterBadgeValue"
        />
      </div>
    </div>

    <div class="dialog-row error-message">
      {{ errorMessage }}
    </div>

    <div class="button-div">
      <a v-if="editingFilter" @click="deleteFilter()" class="delete-filter"
        >Delete Filter</a
      >
      <RocButton type="secondary" @click="closeDialog()">Cancel</RocButton>
      <RocButton @click="saveFilters()" :disabled="filterName.length === 0"
        >Save
      </RocButton>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import RocButton from "@/components/ui/RocButton";
import RocInput from "@/components/ui/RocInput";
import SavedViewBadges from "@/components/encounters/SavedViewBadges.vue";

export default {
  name: "SavedClusterFiltersPopup",
  components: {
    RocInput,
    RocButton,
    SavedViewBadges
  },
  props: {
    editingFilter: {
      type: Object,
      required: false
    }
  },
  emits: ["save", "close"],
  setup(props, context) {
    const store = useStore();

    const userConfig = ref(
      store.getters["auth/userConfig"] ? store.getters["auth/userConfig"] : {}
    );

    const filterName = ref(props.editingFilter ? props.editingFilter.name : "");

    const filterBadgeValue = computed(() => {
      const filter = {};

      if (props.editingFilter) {
        filter.matchesOnly = props.editingFilter.matchesOnly;
        filter.tags = props.editingFilter.tags;
        filter.cams = props.editingFilter.cams;
        filter.analytics = props.editingFilter.analytics;
        filter.dateRange = props.editingFilter.dateRange;
      } else {
        filter.matchesOnly = store.getters["clusters/watchlistMatchFilter"];
        filter.tags = store.getters["clusters/attributeFilters"];
        filter.cams = store.getters["clusters/cameraFilters"];
        filter.analytics = store.getters["clusters/analyticsFilters"];
        filter.dateRange = store.getters["clusters/dtRangeFilter"];
      }

      return filter;
    });

    function closeDialog() {
      context.emit("close");
    }

    const errorMessage = ref("");
    watch(filterName, (nv) => {
      // Reset error message if filter name changes.
      if (errorMessage.value) {
        errorMessage.value = "";
      }
    });

    async function saveFilters() {
      if (!userConfig.value.savedClusterFilters) {
        userConfig.value.savedClusterFilters = [];
      }

      // Check if name exists
      if (!props.editingFilter) {
        // Skip if editing mode
        for (let f of userConfig.value.savedClusterFilters) {
          if (f.name.trim() === filterName.value.trim()) {
            errorMessage.value = "Name already exists.";
            return;
          }
        }
      }

      const newSavedFilter = {};

      newSavedFilter.name = filterName.value.trim();
      for (const [key, value] of Object.entries(filterBadgeValue.value)) {
        newSavedFilter[key] = value;
      }

      if (props.editingFilter) {
        const indexOfEditingFilter = userConfig.value.savedClusterFilters.indexOf(
          props.editingFilter
        );
        userConfig.value.savedClusterFilters.splice(
          indexOfEditingFilter,
          1,
          newSavedFilter
        );
      } else {
        userConfig.value.savedClusterFilters.push(newSavedFilter);
      }

      const response = await store.dispatch(
        "auth/updateUserConfig",
        userConfig.value
      );
      if (response?.status === "success") {
        context.emit("save");
      } else {
        errorMessage.value = "There was an error with saving user config.";
      }
    }

    // Delete filter in edit mode.
    async function deleteFilter() {
      const indexOfEditingFilter = userConfig.value.savedClusterFilters.indexOf(
        props.editingFilter
      );
      userConfig.value.savedClusterFilters.splice(indexOfEditingFilter, 1);
      const response = await store.dispatch(
        "auth/updateUserConfig",
        userConfig.value
      );
      if (response?.status === "success") {
        context.emit("close");
      } else {
        errorMessage.value = "There was an error with deleting user config.";
      }
    }

    return {
      filterBadgeValue,
      closeDialog,
      saveFilters,
      filterName,
      errorMessage,
      deleteFilter
    };
  }
};
</script>

<style scoped lang="scss">
.filter-dialog {
  @include overwatch-body-med;

  padding-top: var(--spacing-xl);

  display: flex;
  flex-direction: column;
  gap: var(--spacing-m);

  width: 350px;
}

.button-div {
  margin-top: $--spacing-s;

  display: flex;

  gap: $--spacing-s;

  margin-left: auto;
}

.dialog-row {
  display: flex;
  flex-direction: column;
}

.error-message {
  @include overwatch-body-small;
  color: var(--overwatch-error);
}

.delete-filter {
  @include overwatch-body-xsmall;
  align-self: center;
  color: var(--overwatch-error);
  text-decoration: underline;
  cursor: pointer;
  margin-right: auto;
}
</style>
