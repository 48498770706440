import mutations from "./mutations.js";
import getters from "./getters.js";
import actions from "./actions.js";

const getDefaultState = () => {
  return {
    cases: [],
    editingCase: null, // Selected case for editing
    cameras: [], // Used for storing selected case cameras
    activeMissionId: null,
    previousMissionId: null,
    importerVisible: false,
    isCaseProcessing: false,
    isCaseReprocessing: false,
    dateTimeParserValue: {},
    selectedTable: "live",
    loadedOnce: false // Track if case load was attempted before flashing "no missions" bar
  };
};

const state = getDefaultState();

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  getDefaultState
};
