<template>
  <div class="overwatch-body-large" v-if="!isLoading">
    <p>Role</p>
    <RocSelect
      style="width: 100%"
      :availableOptions="roles"
      :optionLabel="'label'"
      :optionValue="'_id'"
      :placeholder="'Select Role'"
      :currently-selected="selectedRoleId"
      @selection-changed="selectedRoleId = $event"
      :disabled="isLoggedIn"
    />
    <div class="name" style="margin-top: var(--spacing-m);">
      <div>
        <p>First Name</p>
        <RocInput
          v-model="firstName"
          :placeholder="'First Name'"
          :errorMessage="validFirstName"
          @blur="validateFields($event, 'firstName')"
        />
      </div>
      <div>
        <p>Last Name</p>
        <RocInput
          v-model="lastName"
          :placeholder="'Last Name'"
          :errorMessage="validLastName"
          @blur="validateFields($event, 'lastName')"
        />
      </div>
    </div>
    <div style="margin-top: var(--spacing-m);">
      <div>
        <p>Email Address</p>
      </div>
      <RocInput
        v-model="emailAddress"
        :errorMessage="validEmail"
        @blur="validateFields($event, 'email')"
        :disabled="mode === 'update'"
      />
    </div>
    <div style="margin-top: var(--spacing-m);">
      <PasswordInputs :isSubmitting="isSubmitting" @verify="getVerification" />
    </div>
    <div style="margin-top: var(--spacing-m);">
      <p>Notes</p>
      <RocTextArea
        rows="3"
        placeholder="Enter any notes here"
        v-model="notes"
      />
    </div>
    <div style="margin-top: var(--spacing-m);">
      <p>User Groups</p>
      <filterMultiSelect
        mode="tags"
        :close-on-select="false"
        placeholder-text="Select User Groups"
        :available-items="allUserGroups"
        :currently-selected="selectedUserGroupIds"
        @selection-changed="validateFields($event, 'multiSelect')"
        :enableClear="false"
        :showError="markUserGroupError"
      />
      <p class="status">
        {{ validUserGroup }}
      </p>
    </div>
    <div v-if="statusText.indexOf('|') === -1" class="status">{{ statusText }}</div>
    <div v-else class="status">
      <ul style="margin-top: var(--spacing-s); list-style-type: disc;">
        <li v-for="item in statusText.split('|')">
          {{ item }}
        </li>
      </ul>
    </div>
    <div class="buttons">
        <RocButton type="secondary" @click="closeDialog">Cancel</RocButton>
        <RocButton @click="pressSave">Save</RocButton>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import _ from "lodash";
import PasswordInputs from "@/components/settings/PasswordInputs.vue";
import filterMultiSelect from "@/components/ui/filterMultiSelect";
import RocButton from "@/components/ui/RocButton.vue";
import RocInput from "@/components/ui/RocInput.vue";
import RocSelect from "@/components/ui/RocSelect.vue";
import RocTextArea from "@/components/ui/RocTextArea.vue";

export default {
  components: {
    PasswordInputs,
    filterMultiSelect,
    RocButton,
    RocInput,
    RocSelect,
    RocTextArea
  },
  props: {
    // is the user being edited. If null, consider it to be in "add user" mode.
    user: {
      type: Object
    }
  },
  emits: ["close", "save"],

  setup(props, context) {
    const store = useStore();
    const isLoading = ref(true);
    const mode = ref("create"); // 'create' or 'update'.
    const selectedRoleId = ref();
    const firstName = ref("");
    const lastName = ref("");
    const emailAddress = ref("");
    const statusText = ref("");
    const roles = ref([]);
    const notes = ref("");
    const validFirstName = ref(undefined);
    const validLastName = ref(undefined);
    const validEmail = ref(undefined);
    const validUserGroup = ref(undefined);
    const markUserGroupError = ref(false);
    const allUserGroups = computed(() => {
      return store.getters["settings/userGroups"];
    });
    const selectedUserGroupIds = ref([]);

    if (props.user) {
      // Fill in fields if user is being edited
      mode.value = "update";
      selectedRoleId.value = props.user._userAccessId;
      firstName.value = props.user.firstname;
      lastName.value = props.user.lastname;
      emailAddress.value = props.user.email;
      notes.value = props.user.notes;
    }

    function closeDialog() {
      context.emit("close");
    }

    function validateFields(event, field) {
      if (field === "firstName") {
        validFirstName.value =
          event.trim() == "" ? "Enter a first name." : undefined;
      } else if (field === "lastName") {
        validLastName.value =
          event.trim() == "" ? "Enter a last name." : undefined;
      } else if (field === "email") {
        validEmail.value =
          event.trim() == "" ? "Enter an email address." : undefined;
      } else if (field === "multiSelect") {
        validUserGroup.value =
          event.value.length === 0
            ? "Enter at least one User Group."
            : undefined;
        selectedUserGroupIds.value = event.value;
        markUserGroupError.value = event.value.length === 0 ? true : false;
      }
    }

    const isSubmitting = ref(false);
    function pressSave() {
      isSubmitting.value = true;
    }

    const verificationResult = ref(null);
    function getVerification(payload) {
      verificationResult.value = payload;
    }

    watch(verificationResult, (newValue, oldValue) => {
      const result = verificationResult.value;
      if (result.verified) {
        const newPassword = result.newPassword;
        if (mode.value === "update") {
          updateUser(newPassword);
        } else if (mode.value === "create") {
          createUser(newPassword);
        }
      } else {
        /* TODO: Special case where no new password for editing users.
            Come up with something cleaner. The password input's error status pops up but the
            request goes through.
            I think ideally the password input wouldn't be in the same dialog as the other settings
            (since this could be seen as passing an empty password) -Sean
         */

        if (mode.value === "update") {
          if (result.newPassword === "" && result.currentPassword === "") {
            updateUser();
          }
        }
      }
      isSubmitting.value = false;
    });

    async function updateUser(newPassword) {
      try {
        if (userGroupsRequired()) {
          return;
        }
        let payload = {
          email: props.user.email,
          firstname: firstName.value,
          lastname: lastName.value,
          _userAccessId: selectedRoleId.value,
          notes: notes.value,
          userGroups: selectedUserGroupIds.value
        };

        if (newPassword) {
          payload.password = newPassword;
        }

        const responseData = await store.dispatch(
          "settings/updateUser",
          payload
        );
        if (responseData && responseData.status == "success") {
          context.emit("save");
          context.emit("close");
        } else {
          statusText.value = responseData
            ? (! _.isEmpty(responseData.details)) ? responseData.details.join("|") : responseData.message
            : "Unable to Update User";
          // clear status text after a few seconds sort of like a Toast
          setTimeout(() => {
            statusText.value = "";
          }, 3000);
        }
      } catch (err) {
        console.log(err);
      }
    }

    async function createUser(newPassword) {
      try {
        if (userGroupsRequired()) {
          return;
        }
        const userUpdatePayload = {
          email: emailAddress.value,
          firstname: firstName.value,
          lastname: lastName.value,
          password: newPassword,
          _userAccessId: selectedRoleId.value,
          notes: notes.value,
          userGroups: selectedUserGroupIds.value
        };
        const responseData = await store.dispatch(
          "settings/createUser",
          userUpdatePayload
        );
        if (responseData && responseData.status == "success") {
          context.emit("save");
          context.emit("close");
        } else {
          statusText.value = responseData
            ? (! _.isEmpty(responseData.details)) ? responseData.details.join("|") : responseData.message
            : "Unable to Create User";
          // clear status text after a few seconds sort of like a Toast
          setTimeout(() => {
            statusText.value = "";
          }, 3000);
        }
      } catch (err) {
        console.log(err);
      }
    }

    function userGroupsRequired() {
      if (selectedUserGroupIds.value.length === 0) {
        statusText.value = "Must assign to at least one UserGroup";
        // clear status text after a few seconds sort of like a Toast
        setTimeout(() => {
          statusText.value = "";
        }, 3000);
        return true;
      }
      return false;
    }

    const isLoggedIn = computed(function() {
      if (!props.user) {
        return false;
      }
      return props.user.email === store.getters["auth/email"];
    });

    loadUserAccess();
    async function loadUserAccess() {
      const response = await store.dispatch("settings/getUserAccess");
      if (response && response.userAccess) {
        roles.value = response.userAccess;
        isLoading.value = false;
      }
    }

    loadUserGroups();
    async function loadUserGroups() {
      try {
        let updatingSysAdmin =
          mode.value === "update" &&
          isUpdatingSysAdmin(allUserGroups.value, props.user._id) &&
          isLoggedIn.value;
        // iterate available usergroups to check if selected or readonly
        for (let i = 0; i < allUserGroups.value.length; i++) {
          allUserGroups.value[i].value = allUserGroups.value[i]._id;
          // if this usergroup is the system group, and the current user is not part of the system group, mark it disabled so it is readonly
          // all entities are in the system group, but only admins in the system group can modify this
          if (
            updatingSysAdmin ||
            (allUserGroups.value[i].isSysAdmin &&
              !userGroupContainsCurrentUser(allUserGroups.value[i]))
          ) {
            allUserGroups.value[i].disabled = true;
          } else {
            allUserGroups.value[i].disabled = false;
          }
          if (mode.value === "create") {
            // if creating, auto select all available usergroups
            selectedUserGroupIds.value.push(allUserGroups.value[i].value);
          } else if (
            mode.value === "update" &&
            userGroupContainsUpdatingUser(
              allUserGroups.value[i],
              props.user._id
            )
          ) {
            // if updating, auto select only usergroups that already contain the currently updating user
            selectedUserGroupIds.value.push(allUserGroups.value[i].value);
          }
        }
      } catch (err) {
        console.log(err);
      }
    }

    // check if currently logged in user is a part of provided usergroup
    function userGroupContainsCurrentUser(userGroup) {
      return userGroup.users.find((user) => {
        if (user.email === store.getters["auth/email"]) {
          return true;
        }
      });
    }

    // check if provided user id is a part of provided usergroup
    function userGroupContainsUpdatingUser(userGroup, userId) {
      return userGroup.users.find((user) => {
        if (user._id === userId) {
          return true;
        }
      });
    }

    function isUpdatingSysAdmin(userGroups, userId) {
      for (let i = 0; i < userGroups.length; i++) {
        if (userGroups[i].isSysAdmin) {
          return userGroups[i].users.find((user) => {
            if (user._id === userId) {
              return true;
            }
          });
        }
      }
      return false;
    }

    return {
      mode,
      selectedRoleId,
      firstName,
      lastName,
      emailAddress,
      closeDialog,
      pressSave,
      isSubmitting,
      getVerification,
      isLoggedIn,
      statusText,
      roles,
      notes,
      allUserGroups,
      selectedUserGroupIds,
      validateFields,
      validFirstName,
      validLastName,
      validEmail,
      isLoading,
      validUserGroup,
      markUserGroupError
    };
  }
};
</script>

<style scoped lang="scss">
p {
  margin: 0 0 0 0;
}

select {
  padding: 12px;
  @include overwatch-body-med;
  width: 50%;
  background-color: var(--overwatch-neutral-500);
  color: var(--overwatch-neutral-100);
  border: solid 1px var(--overwatch-neutral-300);
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  background-image: linear-gradient(
      45deg,
      transparent 50%,
      var(--overwatch-neutral-400) 50%
    ),
    linear-gradient(135deg, var(--overwatch-neutral-400) 50%, transparent 50%),
    linear-gradient(to right, transparent, transparent);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  border-color: var(--overwatch-neutral-300);
}

select:focus {
  background-image: linear-gradient(
      45deg,
      var(--overwatch-neutral-400) 50%,
      transparent 50%
    ),
    linear-gradient(135deg, transparent 50%, var(--overwatch-neutral-400) 50%),
    linear-gradient(to right, transparent, transparent);
  background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
    calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

select:disabled {
  background-color: var(--overwatch-neutral-400);
}

.input-text {
  @include overwatch-body-med;
}

.buttons {
  display: flex;
  padding-top: 1rem;
  justify-content: right;
  gap: var(--spacing-s);
}

.name {
  display: flex;
  gap: 0.5rem;
}
.name div {
  width: 100%;
}
.status {
  color: var(--overwatch-error);
}

@media (max-width: 480px) {
  select {
    width: 100%;
    margin-bottom: 10px;
  }
}
</style>
