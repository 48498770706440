<template>
  <div
    class="plate-card"
    @mouseover="setMouseOverStyle"
    @mouseleave="setMouseLeaveStyle"
  >
    <!--<img class='face-img' :src="imgData" :style="imgStyle"/>-->
    <!--<auth-img class='face-img' :src="watchlistedPlate.enrollment_image_preview" :style="imgStyle"/>-->
    <div :style="imgStyle" @click="$emit('showDetails')">
      <div class="d-flex justify-content-center">
        <div
          style="position: relative; border: 0px solid var(--overwatch-error); margin-top: 2px;"
        >
          <img src="@/assets/img/blank-plate-gray.png" />
          <div class="plate-number center">
            {{ watchlistedPlate.plateNumber }}
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-content-start"
        style="margin-top: 10px; margin-left: 15px;"
      >
        <div class="text-details-name">
          {{ watchlistedPlate.fullname }}
        </div>
      </div>
      <div
        style="display:flex; flex-direction: column; padding-left: 15px; padding-right: 15px; padding-top: 10px;"
      >
        <div class="d-flex justify-content-between" style="height: 30px">
          <p>Color</p>
          {{ watchlistedPlate.color }}
        </div>
        <div class="d-flex justify-content-between" style="height: 30px">
          <p>Make</p>
          {{ watchlistedPlate.make }}
        </div>
        <div class="d-flex justify-content-between" style="height: 30px">
          <p>Model</p>
          {{ watchlistedPlate.model }}
        </div>
        <div class="d-flex justify-content-between" style="height: 30px">
          <p>Year</p>
          {{ watchlistedPlate.year }}
        </div>
      </div>
    </div>
    <div
      v-if="!restricted"
      style="position: absolute; bottom: var(--spacing-xs); right: var(--spacing-base);"
    >
      <div
        style="width: 30px; height: 25px; cursor: pointer; position: relative;"
      >
        <div>
          <MDBDropdown v-model="dropdownOptions" align="end">
            <MDBDropdownToggle
              style="color:black; -webkit-appearance: none; -moz-appearance: none; appearance: none; width: 20px; height: 20px;"
              tag="a"
            >
              <div
                @click="dropdownOptions = true"
                style="width: 30px; height: 30px;"
              >
                <RocIcon icon="kebab" size="sm" :rotate270="true" />
              </div>
            </MDBDropdownToggle>
            <RocDropdownMenu
              style="font-size: var(--spacing-s);"
              aria-labelledby="dropdownMenuButton"
            >
              <MDBDropdownItem href="" @click.prevent="toggleEnabled">
                <div class="d-flex justify-content-between">
                  <div class="">{{ stateText }}</div>
                  <RocIcon
                    v-if="stateText == 'Disable'"
                    icon="nomatch"
                    size="sm"
                  />
                  <RocIcon v-else icon="check" size="sm" />
                </div>
              </MDBDropdownItem>
              <MDBDropdownItem divider />
              <MDBDropdownItem
                href=""
                @click.prevent="deleteFromWatchlist"
                v-if="isAdmin"
              >
                <div class="d-flex justify-content-between">
                  <div class="">Delete</div>
                  <RocIcon
                    class="Badge-Icon"
                    icon="trash"
                    size="sm"
                    color="red"
                  />
                </div>
              </MDBDropdownItem>
              <!-- not implementing move license plate yet -->
              <!-- <MDBDropdownItem divider />
              <MDBDropdownItem href="" @click.prevent>
                <div @click="moveToWatchlist">Move</div>
              </MDBDropdownItem> -->
            </RocDropdownMenu>
          </MDBDropdown>
        </div>
      </div>
    </div>
    <div
      v-if="!watchlistedPlate.enabled"
      style="position: absolute; left: calc(50% - 40px); top: 47px;"
    >
      <span class="disabled">DISABLED</span>
    </div>
    <div style="position: absolute; bottom: var(--spacing-s);">
      <div class="face-card-name">{{ watchlistedPlate.firstname }}</div>
      <div class="face-card-name">{{ watchlistedPlate.lastname }}</div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, ref, watch, onMounted } from "vue";
import {
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownToggle
} from "mdb-vue-ui-kit";
import RocDropdownMenu from "@/components/ui/RocDropdownMenu";
import RocIcon from "@/components/ui/RocIcon.vue";

export default {
  name: "WatchlistEditor.vue",
  emits: ["toggleEnabled", "deleteElement", "moveElement", "showDetails"],
  props: {
    watchlistedPlate: Object,
    restricted: Boolean
  },
  components: {
    MDBDropdown,
    MDBDropdownItem,
    MDBDropdownToggle,
    RocDropdownMenu,
    RocIcon
  },
  setup(props, context) {
    const store = useStore();
    const windowWidth = ref(window.innerWidth);
    onMounted(() => {
      window.addEventListener("resize", () => {
        windowWidth.value = window.innerWidth;
      });
    });
    const isMobile = computed(() => {
      return windowWidth.value <= 480;
    });

    const showOptions = ref(isMobile.value ? true : false); // Always showOptions on mobile.

    const imgStyle = ref({});
    const dropdownOptions = ref(false);

    const isAdmin = computed(function() {
      return store.getters["auth/isAdmin"];
    });

    if (!props.watchlistedPlate.enabled) {
      setDisabled();
    }

    watch(showOptions, () => {
      if (showOptions.value == false) {
        dropdownOptions.value = false;
      }
    });

    function setDisabled() {
      imgStyle.value = { opacity: 0.35 };
    }

    function setMouseOverStyle() {
      if (isMobile.value) {
        return;
      }
      showOptions.value = true;
      if (props.watchlistedPlate.enabled) {
        imgStyle.value = { filter: "brightness(110%)" };
      }
    }

    function setMouseLeaveStyle() {
      if (isMobile.value) {
        return;
      }
      showOptions.value = false;
      if (props.watchlistedPlate.enabled) {
        imgStyle.value = {};
      }
    }

    const stateText = computed(function() {
      if (props.watchlistedPlate.enabled) {
        return "Disable";
      } else {
        return "Enable";
      }
    });

    function toggleEnabled() {
      const modifiedWatchlistedLicensePlate = props.watchlistedPlate;
      modifiedWatchlistedLicensePlate.enabled = !props.watchlistedPlate.enabled;
      if (!modifiedWatchlistedLicensePlate.enabled) {
        setDisabled();
      }
      context.emit("toggleEnabled", modifiedWatchlistedLicensePlate);
      dropdownOptions.value = false;
    }

    function deleteFromWatchlist() {
      context.emit("deleteElement");
      dropdownOptions.value = false;
    }

    function moveToWatchlist() {
      dropdownOptions.value = false;
      context.emit("moveElement");
    }

    return {
      isAdmin,
      imgStyle,
      setMouseOverStyle,
      setMouseLeaveStyle,
      dropdownOptions,
      stateText,
      toggleEnabled,
      deleteFromWatchlist,
      moveToWatchlist
    };
  }
};
</script>

<style scoped lang="scss">
.plate-card {
  position: relative;
  box-sizing: border-box;
  height: 274px;
  width: 228px;
  background-color: var(--overwatch-secondary);
  margin-top: 6px;
  margin-right: 6px;
}

.face-card:hover {
  transform: scale(1);
  transition: transform 0.5s;
  z-index: 1;
  box-shadow: 0 2px 12px 0 rgba(0, 19, 58, 0.5);
}

.face-card-name {
  height: 20px;
  width: 122px;
  color: #000000;
  @include overwatch-title-xsmall;
  line-height: 21px;
  margin-left: 10px;
  margin-top: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}

/*
.face-card img:hover {
  filter: brightness(50%);
  cursor: pointer;
}*/

.face-img {
  border-radius: 4px 4px 0px 0px;
  width: 140px;
  height: auto;
  margin-bottom: 6px;
  position: absolute;
}

.disabled {
  height: var(--spacing-s);
  width: 83px;
  color: var(--overwatch-error);
  @include overwatch-body-med;
  letter-spacing: 1px;
  line-height: var(--spacing-s);
}

.dropdown-toggle:after {
  display: none;
}
.header-options-dd {
  @include overwatch-body-med;
  color: #000000;
}

.plate-number {
  color: var(--overwatch-light-neutral-100);
  font-family: Sans-serif;
  font-size: 20px;
  overflow: hidden;
}

.center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 5px;
}

.text-details-name {
  @include overwatch-body-small;
  overflow: hidden;
}

.text-details-misc {
  @include overwatch-body-xsmall;
  line-height: 120%;
  gap: 20px;
  overflow: hidden;
}

@media (max-width: 480px) {
  .plate-card {
    width: 200px;
  }
}
</style>
