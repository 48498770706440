<template>
  <MDBCard class="card">
    <MDBCardBody>
      <slot></slot>
    </MDBCardBody>
  </MDBCard>
</template>

<script>
import { MDBCard, MDBCardBody } from "mdb-vue-ui-kit";

export default {
  components: {
    MDBCard,
    MDBCardBody
  }
};
</script>

<style scoped>
.card {
  border-radius: 12px 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0rem;
  margin: 0rem 4px;
  max-width: 40rem;
}
</style>
