<template>
  <div class="wrapper" ref="wrapperRef" @mousedown="mouseDownHandler">
    <div class="timeline">
      <div
        class="interval"
        v-for="interval of intervals"
        :style="computeIntervalStyle(interval)"
      ></div>
    </div>
    <div class="seeker">
      <div class="current-progress" :style="`width: ${computedWidth}%;`">
        <RocIcon
          class="indicator"
          icon="timelineIndicator"
          color="black"
          size="sm"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch, computed } from "vue";
import RocIcon from "@/components/ui/RocIcon";

const emit = defineEmits(["pause", "seek"]);
const props = defineProps({
  intervals: {
    type: Array,
    default: []
  },
  videoStart: {
    type: Number
  },
  videoEnd: {
    type: Number
  },
  currentTime: {
    // Seconds
    type: Number,
    default: 0
  },
  duration: {
    // Seconds
    type: Number,
    default: 100
  }
});

/** Seeker Code */

/**
 * 1. Click on seeker to start "drag mode"
 * 2. Entire document has a mouse handler to determine where mouse is
 * 3. When 'mouseup', drag mode is deactivated, and handlers are removed from document.
 */

const isDragMode = ref(false);

const boundingRect = ref();
const wrapperRef = ref(null);
onMounted(() => {
  boundingRect.value = wrapperRef.value?.getBoundingClientRect();
});
window.addEventListener("resize", () => {
  boundingRect.value = wrapperRef.value?.getBoundingClientRect();
});

function seek(e) {
  const start = boundingRect.value.left;
  const end = boundingRect.value.right;

  let boundedEventX;
  if (e.x < start) {
    boundedEventX = start;
  } else if (e.x > end) {
    boundedEventX = end;
  } else {
    boundedEventX = e.x;
  }

  const relativePositionOfEvent = (boundedEventX - start) / (end - start);

  // Update the width of the seeker real time so that it looks responsive.
  const relativePositionPercent = relativePositionOfEvent * 100;

  computedWidth.value = relativePositionPercent;

  // Emit the seekTime separately to prevent lagginess.
  const seekTime = props.duration * relativePositionOfEvent;
  emit("seek", seekTime);
}

function mouseDownHandler(e) {
  isDragMode.value = true;
  emit("pause");
  seek(e);

  document.addEventListener("mousemove", mouseMoveHandler);
  document.addEventListener("mouseup", mouseUpHandler);
}

function mouseMoveHandler(e) {
  seek(e);
}

function mouseUpHandler() {
  isDragMode.value = false;
  document.removeEventListener("mousemove", mouseMoveHandler);
  document.removeEventListener("mouseup", mouseUpHandler);
}

const computedWidth = ref((props.currentTime / props.duration) * 100);
watch(
  () => props.currentTime,
  (nv) => {
    if (!isDragMode.value) {
      computedWidth.value = (props.currentTime / props.duration) * 100;
    }
  }
);

/** Interval Code */
const intervals = computed(() => {
  const output = [];
  console.log(props.intervals);
  if (props.intervals.length > 0 && props.videoStart && props.videoEnd) {
    for (let interval of props.intervals) {
      // TODO: This when i get back
      const convertedInterval = [
        Math.max(
          0,
          (interval[0] - props.videoStart) / (props.videoEnd - props.videoStart)
        ),
        Math.min(
          1,
          (interval[1] - props.videoStart) / (props.videoEnd - props.videoStart)
        )
      ];
      output.push(convertedInterval);
    }
    console.log(output);
    return output;
  }
  return [];
});

function computeIntervalStyle(interval) {
  // Width: percentage different between end and start
  // Left: Percentage of start value

  const left = interval[0] * 100;

  // Give a minimum of 0.1% width, for tracks that are too short
  const width = Math.max((interval[1] - interval[0]) * 100, 0.1);
  return `
    left: ${left}%;
    width: ${width}%;
  `;
}
</script>

<style scoped lang="scss">
.timeline {
  display: flex;
  height: var(--spacing-s);
  background-color: var(--overwatch-neutral-400);
  cursor: pointer;
  position: relative;
}

.interval {
  position: absolute;
  height: 100%;
  background-color: var(--overwatch-success);
}

.seeker {
  width: 100%;
  height: 2px;
  background-color: transparent;
  cursor: pointer;
}

.current-progress {
  position: relative;
  height: 100%;
  background-color: var(--overwatch-button-primary);
  cursor: pointer;
}

.indicator {
  position: absolute;

  // Eyeballed
  top: -16px;
  right: -7px;
  cursor: pointer;
}
</style>
