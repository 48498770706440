<template>
  <div></div>
</template>

<script>
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  name: "logout",
  setup() {
    const store = useStore();
    const router = useRouter();

    async function logout() {
      if (!store.getters["auth/isSSO"]) {
        await store.dispatch("saveUserConfig");
      }
      // wipe vuex cache - user specific settings are stored with the logged in user
      await store.dispatch("resetAllDefaults");
      // reload preAuthConfig to account for any UI customization or other features that apply even when not logged in
      await store.dispatch("auth/loadPreAuthConfig");
      // reset theme to system setting
      const systemDarkMode = window.matchMedia("(prefers-color-scheme: dark)")
        ?.matches;
      document.documentElement.setAttribute(
        "data-theme",
        systemDarkMode ? "darkMode" : "lightMode"
      );

      // sharedUserAction is used to signal actions across tabs/windows
      store.commit("shared/setSharedUserAction", { type: "logout", source: "app" });

      // execute logout
      await store.dispatch("auth/logout");

      // NB: in the SSO case, nothing below gets executed because
      // it has already redirected the browser to the IDP to sign out
      await router.push("/login");
    }

    logout();
  }
};
</script>

<style scoped></style>
