<template>
  <div>
    <CameraLiveFeed />
  </div>
</template>

<script>
import CameraLiveFeed from "@/components/cameras/CameraLiveFeed";

export default {
  name: "CameraLiveFeedPopup",
  components: {
    CameraLiveFeed
  },
  setup() {
    // Send a message to the parent window when the child window is closed
    window.addEventListener("beforeunload", () => {
      window.opener.postMessage("childWindowClosed", "*");
    });
  }
};
</script>

<style scoped>
/* Your styles here */
</style>
