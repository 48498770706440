<template>
  <div class="d-flex card flex-column">
    <div class="d-flex justify-content-between" style="height: 100%;">
      <div v-if="name" class="align-self-center" style="flex: 1; ">
        <div class="user-name">{{ name }}</div>
      </div>
      <div v-else style="flex: 1;">
        <div class="user-name">–</div>
      </div>
      <div :style="statusStyle" @click="isComplete ? showEnrollment() : ''">
        <MDBBadge v-if="isPending" class="statusBadge pending" pill
          >Pending
        </MDBBadge>
        <MDBBadge v-else-if="isComplete" class="statusBadge complete" pill
          >Enrolled
        </MDBBadge>
        <MDBBadge v-else-if="isExpired" class="statusBadge expired" pill
          >Expired
        </MDBBadge>
        <MDBBadge v-else-if="isRevoked" class="statusBadge revoked" pill
          >Revoked
        </MDBBadge>
      </div>
      <div class="align-self-center" style="flex: 2;">
        <div class="entitlement-Date">{{ startDate }}</div>
      </div>
      <div class="align-self-center" style="flex: 2;">
        <div class="entitlement-Date">{{ expirationDate }}</div>
      </div>
      <div v-if="!isCruding">
        <MDBDropdown v-model="isShowingDropdown" align="end">
          <MDBDropdownToggle
            style="color:black;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                padding: 15px;"
            tag="a"
            @click="isShowingDropdown = !isShowingDropdown"
          >
            <RocIcon color="black" size="sm" icon="kebab" />
          </MDBDropdownToggle>
          <RocDropdownMenu aria-labelledby="dropdownMenuButton">
            <span v-if="isEditable">
              <MDBDropdownItem href="" @click.prevent="emitEdit">
                <div class="d-flex justify-content-between">
                  Edit
                  <RocIcon color="black" size="sm" icon="edit" />
                </div>
              </MDBDropdownItem>
              <MDBDropdownItem divider />
            </span>
            <span v-if="isRevokable">
              <MDBDropdownItem href="" @click.prevent="emitRevoke">
                <div class="d-flex justify-content-between">
                  Revoke
                  <RocIcon color="black" size="sm" icon="nomatch" />
                </div>
              </MDBDropdownItem>
              <MDBDropdownItem divider />
            </span>
            <span v-if="isResendable">
              <MDBDropdownItem href="" @click.prevent="emitResend">
                <div class="d-flex justify-content-between">
                  Re-Invite
                  <RocIcon icon="user" color="primary" size="sm" />
                </div>
              </MDBDropdownItem>
              <MDBDropdownItem divider />
            </span>
            <MDBDropdownItem href="" @click.prevent="emitDelete">
              <div class="d-flex justify-content-between">
                Delete
                <RocIcon
                  icon="trash"
                  color="red"
                  size="sm"
                  style="margin-right: 5px;"
                />
              </div>
            </MDBDropdownItem>
          </RocDropdownMenu>
        </MDBDropdown>
      </div>
      <div v-else>
        <roc-spinner />
      </div>
    </div>
    <base-dialog
      :show="showEnrollmentDetails"
      :title="'Enrollment'"
      @close="hideEnrollment"
      :style="enrollmentDetailsStyle"
    >
      <WatchlistedImageDetails
        :read-only="true"
        :watchlisted-face-id="enrollmentDetailsElement._id"
        :md="enrollmentDetailsElement.md"
        :first-name="enrollmentDetailsElement.firstname"
        :last-name="enrollmentDetailsElement.lastname"
        :notes="enrollmentDetailsElement.notes"
        :image-path="enrollmentDetailsElement.tn"
        :raw-object="enrollmentDetailsElement"
        :show-meta-data="false"
        @close="hideEnrollment"
      >
      </WatchlistedImageDetails>
    </base-dialog>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import BaseCard from "@/components/ui/BaseCard";
import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBBadge,
  MDBCard,
  MDBCardBody
} from "mdb-vue-ui-kit";
import WatchlistedImageDetails from "@/components/watchlists/WatchlistedImageDetails";
import dateHelper from "@/js/dateHelper";
import RocDropdownMenu from "@/components/ui/RocDropdownMenu.vue";
import RocIcon from "@/components/ui/RocIcon.vue";
import moment from "moment";

export default {
  props: {
    entitlement: Object,
    isCruding: Boolean
  },
  emits: ["edit", "delete", "revoke", "resend"],
  components: {
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownItem,
    BaseCard,
    MDBBadge,
    WatchlistedImageDetails,
    MDBCard,
    MDBCardBody,
    RocDropdownMenu,
    RocIcon
  },
  setup(props, context) {
    const store = useStore();
    const isShowingDropdown = ref(false);
    const showEnrollmentDetails = ref(false);
    const enrollmentDetailsElement = ref({});
    const dateTool = new dateHelper();

    const name = computed(() => {
      return props.entitlement.firstname
        ? props.entitlement.firstname + " " + props.entitlement.lastname[0]
        : undefined;
    });

    const startDate = computed(() => {
      return moment(props.entitlement.created).format('MM/DD/YYYY');
    });

    const expirationDate = computed(() => {
      return moment(props.entitlement.expiration).format('MM/DD/YYYY');
    });

    function emitEdit() {
      isShowingDropdown.value = false;
      context.emit("edit");
    }

    function emitDelete() {
      isShowingDropdown.value = false;
      context.emit("delete");
    }

    function emitRevoke() {
      isShowingDropdown.value = false;
      context.emit("revoke");
    }

    function emitResend() {
      isShowingDropdown.value = false;
      context.emit("resend");
    }

    const statusString = computed(() => {
      if (isPending.value) {
        const expiredDate = dateTool.getDateFromDateTimeString(
          props.entitlement.expiration
        );
        const dateString = dateTool.getDisplayDateTime(expiredDate);
        return "Invite Expires: " + dateString;
      } else if (isRevoked.value) {
        const modifiedDate = dateTool.getDateFromDateTimeString(
          props.entitlement.lastModified
        );
        const dateString = dateTool.getDisplayDateTime(modifiedDate);
        return "Revoked on: " + dateString;
      } else if (isComplete.value) {
        const modifiedDate = dateTool.getDateFromDateTimeString(
          props.entitlement.lastModified
        );
        const dateString = dateTool.getDisplayDateTime(modifiedDate);
        return "Enrolled on: " + dateString;
      } else if (isExpired.value) {
        const expiredDate = dateTool.getDateFromDateTimeString(
          props.entitlement.expiration
        );
        const dateString = dateTool.getDisplayDateTime(expiredDate);
        return "Expired: " + dateString;
      }
    });

    async function showEnrollment() {
      if (isComplete.value) {
        showEnrollmentDetails.value = true;
      }
    }

    function hideEnrollment() {
      showEnrollmentDetails.value = false;
    }

    async function getWatchlistedFaceDetails(_id) {
      const responseData = await store.dispatch(
        "watchlists/getWatchlistedFace",
        { id: _id }
      );
      if (responseData && responseData.status == "success") {
        enrollmentDetailsElement.value = responseData.face;
      }
    }

    const enrollmentDetailsStyle = computed(() => {
      if (window.innerWidth <= 480) {
        return {
          width: "90%"
        };
      } else {
        return {
          width: "700px",
          height: "370px"
        };
      }
    });

    const isComplete = computed(() => {
      return props.entitlement.status === "enrolled";
    });
    const isPending = computed(() => {
      return props.entitlement.status === "pending" && !isExpired.value;
    });
    const isExpired = computed(() => {
      if (moment(props.entitlement.expiration).isBefore(moment())) {
        return true;
      } else {
        return props.entitlement.status === "expired";
      }
    });
    const isRevoked = computed(() => {
      return props.entitlement.status === "revoked" && !isExpired.value;
    });

    const isRevokable = computed(() => {
      return !isRevoked.value && !isExpired.value;
    });
    const isResendable = computed(() => {
      return !isRevoked.value && !isComplete.value;
    });
    const isEditable = computed(() => {
      return isPending.value;
    });

    const statusStyle = computed(() => {
      const style = {
        flex: "1"
      };
      if (isComplete.value) {
        style.cursor = "pointer";
      }
      return style;
    });

    onMounted(() => {
      if (isComplete.value) {
        getWatchlistedFaceDetails(props.entitlement._enrollmentId);
      }
    });

    watch(isComplete, () => {
      if (isComplete.value) {
        getWatchlistedFaceDetails(props.entitlement._enrollmentId);
      }
    });

    return {
      isShowingDropdown,
      statusString,
      emitEdit,
      emitDelete,
      emitRevoke,
      emitResend,
      name,
      showEnrollment,
      hideEnrollment,
      enrollmentDetailsStyle,
      showEnrollmentDetails,
      enrollmentDetailsElement,
      isComplete,
      isPending,
      isExpired,
      isRevoked,
      statusStyle,
      isRevokable,
      isResendable,
      isEditable,
      expirationDate,
      startDate
    };
  }
};
</script>

<style scoped lang="scss">
.card {
  width: 95% !important;
  min-width: 15rem;
  display: block;
  padding: 24px 20px 24px !important;
  margin: 0;
  background: var(--overwatch-secondary);
  margin-right: 0rem !important;
  border-bottom: 1px solid var(--overwatch-accent);
  border-radius: 0;
}

.group-badges {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
  height: 100%;
  gap: 0;
}

.statusBadge {
  @include overwatch-body-small;
  width: 100px;
}

.statusBadge.pending {
  background-color: var(--overwatch-button-primary);
  color: var(--overwatch-button-text);
}

.statusBadge.complete {
  background-color: var(--overwatch-success);
  color: var(--overwatch-button-text);
}

.statusBadge.expired {
  background-color: var(--overwatch-error);
  color: var(--overwatch-button-text);
}

.statusBadge.revoked {
  background-color: var(--overwatch-neutral-400);
}

.entitlement-Date {
  @include overwatch-body-med;
  line-height: 18px;
  text-align: center;
}

.user-name {
  @include overwatch-body-med;
  margin-bottom: 4px;
  line-height: 21px;
}

.dropdown-toggle:after {
  display: none;
}

@media (max-width: 480px) {
  .card {
    width: 100%;
  }
}
</style>
