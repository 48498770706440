<template>
<div v-if="isLoading">
  <roc-spinner/>
</div>
<div v-else-if="isCRUDing" class="crud-holder">
  <div class="crud-row">
    <div class="input-section" style="flex: 3;">
      <div class="input-title">Name</div>
      <RocInput
        style="flex: 3;"
        type="text"
        v-model="scheduleName"
        :placeholder="'unique schedule name'"
        :errorMessage="scheduleNameError"
      />
    </div>
    <div class="input-section" style="flex: 1;">
      <div class="input-title">Repeat</div>
      <RocSingleSelect
        class="repeat-select"
        :searchable="false"
        :enableClear="false"
        :availableItems="repeatModes"
        @selection-changed="setRepeatMode"
        :valueProp="'value'"
        :currentlySelected="scheduleRepeat"
      />
    </div>
  </div>
  <div class="crud-row">
    <div v-if="scheduleRepeat === 'Weekly'" class="input-section" style="flex: 3;">
      <div class="input-title">Repeat {{ scheduleRepeat }} On Days</div>
      <div class="days-row">
        <div v-for="day in daysOfWeek" :key="day.value">
          <RocButton @click="day.enabled = !day.enabled" :type="day.enabled ? 'primary' : 'secondary'">{{ day.label }}</RocButton>
        </div>
      </div>
    </div>
    <div v-else-if="scheduleRepeat === 'Monthly'" class="input-section" style="flex: 3;">
      <div class="input-title">Repeat {{ scheduleRepeat }} On Dates</div>
      <div class="dates-row">
        <div v-for="day in 31">
          <RocButton @click="toggleMonthDate(day)" :type="scheduleDatesArray[day] ? 'primary' : 'secondary'">{{ day }}</RocButton>
        </div>
      </div>
    </div>
    <div v-if="scheduleRepeat" class="input-section" style="flex: 1;">
      <div class="input-title"><span v-if="scheduleRepeat === 'Daily'">{{ `Repeat ${scheduleRepeat} ` }} </span>At</div>
      <div class="days-row">
        <RocNumberInput
          :inputType="'time'"
          :numberInput="scheduleTimeHour"
          :min="0"
          :max="23"
          @number-input-change="scheduleTimeHour = $event"
        />
        :
        <RocNumberInput
          :inputType="'time'"
          :numberInput="scheduleTimeMinute"
          :min="0"
          :max="59"
          @number-input-change="scheduleTimeMinute = $event"
        />
      </div>
    </div>
  </div>
  <div class="action-bar">
    <div>Enabled</div>
    <RocSwitch :modelValue="scheduleEnabled" style="margin-right: var(--spacing-xl);"/>
    <RocButton @click="actionClicked">Save</RocButton>
    <RocButton @click="reset(true)" type="secondary">Cancel</RocButton>
  </div>
</div>
<div v-else>
  <div class="action-bar">
    <RocButton @click="actionClicked">Create</RocButton>
  </div>
  <div class="schedule-list">
    <div class="schedule-header">
        <div class="schedule-field name-entry">Name</div>
        <div class="schedule-field schedule-entry">Schedule</div>
        <div class="schedule-field action-entry">Actions</div>
      </div>
    <div v-for="schedule in schedules" :key="schedule._id">
      <div class="schedule-card">
        <div class="schedule-field name-entry">
          {{ schedule.name }}
        </div>
        <div class="schedule-field schedule-entry">
          {{ getScheduleString(schedule) }}
        </div>
        <div class="schedule-field action-entry">
          <RocIcon
            size="md"
            icon="trash"
            color="primary"
            class="action-icon"
            @click="confirmDeleteSchedule(schedule)"
          />
          <RocIcon
            size="md"
            icon="edit"
            color="primary"
            class="action-icon"
            @click="editSchedule(schedule)"
          />
        </div>
      </div>
    </div>
  </div>
</div>
<BaseDialog
  title="Delete Schedule"
  v-if="isShowingDelete"
  show
  @close="isShowingDelete = false"
>
  <DeleteConfirmation
    @close="isShowingDelete = false; deletingSchedule = null;"
    @delete="deleteSchedule(deletingSchedule)"
  >
    Are you sure you want to delete schedule
    <span style="color:var(--overwatch-error)">{{
      deletingSchedule.name
    }}</span
    >? This action cannot be undone.
  </DeleteConfirmation>
</BaseDialog>

</template>

<script setup>
import { useStore } from "vuex";
import RocButton from "@/components/ui/RocButton.vue";
import RocIcon from "@/components/ui/RocIcon.vue";
import RocInput from "@/components/ui/RocInput.vue";
import RocSingleSelect from "@/components/ui/RocSingleSelect.vue";
import RocNumberInput from "@/components/ui/RocNumberInput.vue";
import RocSwitch from "@/components/ui/RocSwitch.vue";
import DeleteConfirmation from "@/components/settings/DeleteConfirmation.vue";
import BaseDialog from "@/components/ui/BaseDialog.vue";
import { onMounted, ref } from "vue";

const store = useStore();
const isLoading = ref(false);
const isCRUDing = ref(false);
const schedules = ref([]);
const deletingSchedule = ref();
const isShowingDelete= ref(false);

const scheduleEnabled = ref(true);
const scheduleName = ref();
const scheduleNameError = ref();
const scheduleRepeat = ref();
const repeatModes = ref(['Daily', 'Weekly', 'Monthly']);
const daysOfWeek = ref([
  { label: "M", value: 1, enabled: false },
  { label: "T", value: 2, enabled: false },
  { label: "W", value: 3, enabled: false },
  { label: "Th", value: 4, enabled: false },
  { label: "F", value: 5, enabled: false },
  { label: "Sa", value: 6, enabled: false },
  { label: "Su", value: 7, enabled: false }
]);
const scheduleDaysError = ref();
const scheduleDatesArray = ref(new Array(31).fill(false));
const scheduleDatesError = ref();
const scheduleTimeHour = ref('00');
const scheduleTimeMinute = ref('00');
const editingScheduleId = ref();

onMounted(async () => {
  await loadSchedules();
});

async function loadSchedules() {
  isLoading.value = true;
  schedules.value = [];
  const response = await store.dispatch('workflows/getWorkflowSchedules');
  if (response?.status === 'success') {
    schedules.value = response.result;
  }

  // auto create if we have none
  isCRUDing.value = !schedules.value.length;
  isLoading.value = false;
}

async function actionClicked() {
  if (isCRUDing.value) {
    await saveSchedule();
  } else {
    createNew();
  }
}

function createNew() {
  reset();
  isCRUDing.value = true;
}

function editSchedule(scheduleToEdit) {
  editingScheduleId.value = scheduleToEdit._id;
  scheduleName.value = scheduleToEdit.name;
  scheduleRepeat.value = scheduleToEdit.repeat;
  for (let day of daysOfWeek.value) {
    day.enabled = scheduleToEdit.daysOfWeek?.includes(day.value) ? true : false;
  }
  for (let date of scheduleToEdit.daysOfMonth) {
    scheduleDatesArray.value[date] = true;
  }
  scheduleEnabled.value = scheduleToEdit.enabled;
  scheduleTimeHour.value = scheduleToEdit.timeOfDay.split(':')[0];
  scheduleTimeMinute.value = scheduleToEdit.timeOfDay.split(':')[1];
  isCRUDing.value = true;
}

function confirmDeleteSchedule(scheduleToDelete) {
  deletingSchedule.value = scheduleToDelete;
  isShowingDelete.value = true;
}

async function deleteSchedule() {
  isShowingDelete.value = false;
  isLoading.value = true;
  await store.dispatch('workflows/deleteWorkflowSchedule', deletingSchedule.value._id);
  deletingSchedule.value = null;
  reset();
  await loadSchedules();
}

async function saveSchedule() {
  if (!scheduleName.value) {
    scheduleNameError.value = 'Name required.';
    setTimeout(() => { scheduleNameError.value = null; }, 2000);
    return;
  }
  const scheduleDaysList = [];
  for (let day of daysOfWeek.value) {
    if (day.enabled) {
      scheduleDaysList.push(day.value);
    }
  }
  let monthDaysArr = [];
  if (scheduleDatesArray.value) {
    for (let i=0; i<scheduleDatesArray.value.length; i++) {
      if (scheduleDatesArray.value[i]) {
        monthDaysArr.push(i);
      }
    }
  }
  const schedule = {
    name: scheduleName.value,
    repeat: scheduleRepeat.value,
    daysOfWeek: scheduleDaysList,
    daysOfMonth: monthDaysArr,
    enabled: true,
    timeOfDay: `${scheduleTimeHour.value ?? '00'}:${scheduleTimeMinute.value ?? '00'}`
  }
  isLoading.value = true;
  if (editingScheduleId.value) {
    schedule._id = editingScheduleId.value;
    await store.dispatch('workflows/updateWorkflowSchedule', schedule);
  } else {
    await store.dispatch('workflows/postWorkflowSchedule', schedule);
  }
  editingScheduleId.value = null;
  isCRUDing.value = false;
  reset();
  await loadSchedules();
}

function getScheduleString(schedule) {
  let scheduleStr = schedule.repeat;
  if (schedule.daysOfWeek?.length) {
    scheduleStr += ` on ${getCsvWeekdays(schedule.daysOfWeek)}`;
  }
  if (schedule.daysOfMonth?.length) {
    scheduleStr += ` on days ${schedule.daysOfMonth.join(", ")}`;
  }
  if (schedule.timeOfDay) {
    scheduleStr += ` at ${schedule.timeOfDay}`;
  }
  return scheduleStr;
}

function getCsvWeekdays(daysArray) {
  const daysCsv = [];
  for (let day of daysArray) {
    const entry = daysOfWeek.value.find((d) => d.value === day);
    if (entry) {
      daysCsv.push(entry.label);
    }
  }
  return daysCsv.join();
}

function setRepeatMode(mode) {
  scheduleRepeat.value = mode.value;
}

function reset(cancelCrud=false) {
  scheduleName.value = null;
  scheduleEnabled.value = true;
  scheduleNameError.value = null;
  scheduleRepeat.value = null;
  for (let day of daysOfWeek.value) {
    day.enabled = false;
  }
  scheduleDaysError.value = null;
  scheduleDatesArray.value = new Array(31).fill(false);
  scheduleDatesError.value = null;
  scheduleTimeHour.value = null;
  scheduleTimeMinute.value = null;
  editingScheduleId.value = null;
  if (cancelCrud) {
    isCRUDing.value = !schedules.value.length;
  }
}

function toggleMonthDate(date) {
  scheduleDatesArray.value[date] = !scheduleDatesArray.value[date];
}
</script>

<style lang="scss" scoped>
.action-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  margin-bottom: var(--spacing-m);
  gap: var(--spacing-m);
}
.schedule-list {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
}
.schedule-header {
  @include overwatch-title-xsmall;
  justify-content: start;
  display: flex;
  flex-direction: row;
  gap: var(--spacing-m);
  box-shadow: var(--overwatch-header-box-shadow);
  background-color: var(--overwatch-background);
  padding: 17px 32px 17px 24px;
}
.schedule-card {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-m);
  border-bottom: solid 1px #abb4bd;
  padding: 17px 32px 17px 24px;
}
.schedule-field {
  display: flex;
  align-items: center;
  flex: 1;
}
.name-entry {
  flex: 1.5;
}
.schedule-entry {
  flex: 2;
}
.action-entry {
  justify-content: end;
  gap: var(--spacing-l);
}
.action-icon {
  cursor: pointer;
}
.repeat-select {
  @include overwatch-body-med;
  color: var(--overwatch-neutral-100);
  background-color: var(--overwatch-neutral-500);
  padding: var(--spacing-s) var(--spacing-s) var(--spacing-s) var(--spacing-s);
  width: 20ch;
}
.crud-holder {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
}
.crud-row {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-xl);
}
.days-row {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-xs);
  align-items: center;
}
.dates-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: var(--spacing-s);
}
.input-title {
  @include overwatch-body-med;
}
.input-section {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);
  justify-content: start;
}
</style>