<template>
  <div class="wrapper">
    <div class="overwatch-title-med">
      Select Image To Search
    </div>
    <MDBCard class="image-card" v-if="probeBase64">
      <MDBCardBody>
        <div style="display: flex; align-items: center;">
          <img :src="probeBase64" style="height: 250px;" />
          <RocIcon icon="file" style="margin: 0 var(--spacing-s)" />
          <div style="color: var(--overwatch-neutral-100)">
            {{ probeFile.name }}
          </div>
        </div>
      </MDBCardBody>
    </MDBCard>
    <MDBCard class="image-card" v-else @click="probeRef.click()">
      <MDBCardBody>
        <div class="inner-card">
          <RocIcon icon="upload" size="md" />
          Choose file to upload
        </div>
      </MDBCardBody>
    </MDBCard>
    <input
      ref="probeRef"
      type="file"
      style="display: none"
      @change="getProbeFile($event)"
    />
  </div>
</template>

<script>
import { ref } from "vue";
import RocIcon from "@/components/ui/RocIcon";
import { MDBCard, MDBCardBody } from "mdb-vue-ui-kit";

export default {
  name: "InvestigativeCaseProbeSelection",
  components: {
    MDBCard,
    MDBCardBody,
    RocIcon
  },
  emits: ["upload"],
  setup(props, context) {
    const probeRef = ref(null);
    const probeFile = ref();
    const probeBase64 = ref();

    function getProbeFile(e) {
      probeFile.value = e.target.files[0];

      const fr = new FileReader();
      fr.readAsDataURL(probeFile.value);
      fr.onloadend = () => {
        probeBase64.value = fr.result;
      };

      context.emit("upload", probeFile.value);
    }

    return {
      probeRef,
      probeFile,
      probeBase64,
      getProbeFile
    };
  }
};
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;

  align-items: center;

  padding: var(--spacing-xl);
}

.card-body {
  border-radius: 5px;
  background-color: var(--overwatch-secondary);
}

.inner-card {
  padding: 24px 58px 24px 69px;
  border-radius: 5px;
  border: solid 1px var(--overwatch-button-primary);
  background-color: var(--overwatch-background);

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-s);
}

.image-card {
  margin-top: var(--spacing-l);
}
</style>
