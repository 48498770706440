<template>
  <div>
    <div v-if="isLoading" style="justify-self: center; align-self: center;">
      <roc-spinner />
    </div>
    <div class="d-flex justify-content-start">
      <div
        style="margin-right: 10px; margin-left: 26px; border: 1px solid black;"
      >
        <auth-img
          class="probe-img disable-select"
          :src="obj.probe_tn"
          :fade="false"
        />
        <div
          v-if="obj.objectType === 'face' || obj.objectType === 'face_match'"
          class="md-attributes"
        >
          <div v-if="obj.age">
            <span>Age:</span> {{ obj.age.avg }}<br />
            <span>Age Range:</span> {{ ageMinRounded }} - {{ ageMaxRounded
            }}<br />
          </div>
          <div v-else-if="obj.md.Age">
            <span>Age:</span> {{ ageRounded }}<br />
          </div>
          <span>Gender:</span> {{ obj.md.Gender }}<br />
          <span>Glasses: </span> {{ obj.md.Glasses }}<br />
          <span v-if="obj.spoofScore">Spoof: </span> {{ obj.spoofScore }}<br />
        </div>
        <div v-if="obj.objectType === 'object_lp_match'" class="md-attributes">
          <span>Plate OCR:</span> {{ obj.ocrPlateRead }}<br />
          <span>Name:</span> {{ obj.fullname }}<br />
          <span>Color:</span> {{ obj.color }}<br />
          <span>Make:</span> {{ obj.make }}<br />
          <span>Model:</span> {{ obj.model }}<br />
          <span>Year: </span> {{ obj.year }}<br />
        </div>
        <div v-if="obj.objectType === 'object'" class="md-attributes">
          <div v-if="obj.md.Text">
            <span>OCR:</span> {{ obj.md.Text }}<br />
            <div
              v-if="
                obj.md.LicensePlateState &&
                  obj.md.LicensePlateState.toLowerCase() != 'unknown'
              "
            >
              <span>State:</span> {{ obj.md.LicensePlateState }}<br />
            </div>
          </div>
        </div>
      </div>
      <div style="font-size: 6pt; border: 1px solid black;">
        <div>
          <auth-img
            class="context-img"
            v-if="contextImage"
            :src="contextImage"
          />
        </div>
        <!--{{ obj }}-->
      </div>
    </div>
    <div
      class="d-flex flex-wrap justify-content-start"
      style="margin-left: 26px;"
    >
      <div class="template" v-for="face in facetemplates" :key="face._id">
        <auth-img
          class="template-img"
          :src="face.tn"
          :style="setImgBorder(face.isBestTemplate)"
        /><br />
        <span>{{ face.timestamp }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { onBeforeUnmount, ref, watchEffect, computed } from "vue";
import { useStore } from "vuex";
import AuthImg from "@/components/ui/AuthImg";

export default {
  name: "trackAnalysis",
  components: { AuthImg },
  props: {
    objectId: String,
    encounterType: {
      type: String,
      required: true,
      default: "face"
    }
  },
  setup(props) {
    const store = useStore();
    const obj = computed(() =>
      store.getters["encounters/encounters"].find(
        (e) => e.id === props.objectId
      )
    );
    const isLoading = ref(false);
    const contextImage = ref();
    const facetemplates = ref([]);
    let socket = null;

    loadTrackAnalysis();

    async function loadTrackAnalysis() {
      isLoading.value = true;
      try {
        facetemplates.value = await store.dispatch("tools/getTrackAnalysis", {
          personId: obj.value.personId,
          encounterType: props.encounterType
        });
      } catch (error) {
        console.log(error);
      }
      isLoading.value = false;
    }

    watchEffect(() => {
      disconnectLiveView();
      loadContextImage();
      loadTrackAnalysis();
    });

    function disconnectLiveView() {
      if (socket) {
        socket.disconnect();
        socket.removeAllListeners();
        socket = null;
      }
    }

    async function loadContextImage() {
      isLoading.value = true;
      try {
        const payload = {
          objectType: obj.value.objectType,
          personId: obj.value.personId,
          contextImageMediaId: obj.value.contextImageMediaId
        };

        contextImage.value = await store.dispatch(
          "tools/getContextImage",
          payload
        );
      } catch (error) {
        console.log(error);
      }
      isLoading.value = false;
    }

    function setImgBorder(isBestTemplate) {
      if (isBestTemplate) {
        return { border: "1px solid var(--overwatch-error)" };
      }
    }

    onBeforeUnmount(() => {
      disconnectLiveView();
    });

    const ageMinRounded = computed(() => {
      return Math.round(Number(obj.value.age.min));
    });
    const ageMaxRounded = computed(() => {
      return Math.round(Number(obj.value.age.max));
    });
    const ageRounded = computed(() => {
      return Math.round(Number(obj.value.md.Age));
    });

    return {
      obj,
      isLoading,
      facetemplates,
      setImgBorder,
      contextImage,
      ageMinRounded,
      ageMaxRounded,
      ageRounded
    };
  }
};
</script>

<style scoped lang="scss">
.template {
  margin-right: 4px;
  font-family: Verdana;
  font-size: 5pt;
  overflow: hidden;
}

.template-img {
  max-height: 72px;
  width: auto;
  margin-top: 4px;
}

.md-attributes {
  color: var(--overwatch-neutral-100);
  @include overwatch-body-med;
  line-height: 18px;
  margin-left: 10px;
  margin-top: 20px;
  margin-right: 10px;
}

.context-img {
  height: 600px;
  width: auto;
  /* max-width: 100%; */
}

/* IPAD PORTRAIT */
@media only screen and (max-width: 810px) and (orientation: portrait) {
  .probe-img {
    width: 25vw;
    height: auto;
  }
  .context-img {
    width: 55vw;
    height: auto;
  }
}

@media (max-width: 480px) {
}
</style>
