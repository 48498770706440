<template>
  <MDBRange v-model="rangeValue" :min="min" :max="max" :step="step" />
</template>

<script>
import { ref, watch } from "vue";
import { MDBRange } from "mdb-vue-ui-kit";

export default {
  name: "RocRange",
  emits: ["change"],
  props: {
    value: Number,
    min: Number,
    max: Number,
    step: Number
  },
  components: {
    MDBRange
  },
  setup(props, context) {
    const rangeValue = ref(props.value);

    watch(rangeValue, () => {
      context.emit("change", rangeValue);
    });

    return {
      rangeValue
    };
  }
};
</script>

<style>
/* Styling for MDBRange in App.vue (needs to be global to work) */
</style>
