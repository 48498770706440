<script setup>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import ClusterEncounterVideo from "@/components/clusters/ClusterEncounterVideo.vue";
import BaseDialog from "@/components/ui/BaseDialog";
import ClusterGrid from "@/components/clusters/ClusterGrid.vue";

const props = defineProps({
  caseId: {
    type: String,
    required: true
  }
});

const store = useStore();
const router = useRouter();

const mission = ref();

onMounted(async () => {
  const response = await store.dispatch("cases/getCaseById", props.caseId);
  if (response.status === "success") {
    mission.value = response.result;
    store.commit("clusters/setMission", mission.value);
  }
});

const globalVideoPlaybackEncounter = computed(
  () => store.getters["clusters/videoPlaybackEncounter"]
);

function showAssociates(cluster) {
  store.commit("clusters/setAssociatesTargetCluster", cluster);
  router.push({
    name: "ClusteringTarget",
    params: { caseId: props.caseId, clusterId: cluster._id }
  });
}

const isVideoPopupVisible = computed(
  () => store.getters["clusters/isVideoPopupVisible"]
);
const isVideoDialogVisible = ref(false);
</script>

<template>
  <div class="body">
    <ClusterGrid :caseId="props.caseId" />
    <div class="video-playback-box" v-show="!isVideoPopupVisible">
      <ClusterEncounterVideo
        v-if="globalVideoPlaybackEncounter"
        :caseId="caseId"
        @find-cluster="showAssociates"
        @show-dialog="isVideoDialogVisible = true"
      />
      <div style="text-align: center;" v-else>
        Select an encounter to view context video.
      </div>
    </div>
  </div>
  <BaseDialog
    :show="isVideoDialogVisible"
    @close="isVideoDialogVisible = false"
  >
    <ClusterEncounterVideo :caseId="caseId" dialogMode />
  </BaseDialog>
</template>

<style scoped lang="scss">
.page {
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
}

nav {
  display: flex;
  align-items: center;
  padding: $--spacing-m;
  background-color: var(--overwatch-secondary);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);

  .case-title {
    color: var(--overwatch-neutral-100);
    margin: 0px var(--spacing-m) 0px var(--spacing-l);
    @include overwatch-title-med;
  }

  .case-datetime {
    @include overwatch-body-med;
  }
}

.body {
  flex: 1;
  display: flex;
  min-height: 0;
}

.video-playback-box {
  width: calc(100% / 3);
  background-color: var(--overwatch-secondary);
  position: relative;
  padding: $--spacing-m;
}

.spinner-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  width: 100%;
}

.cluster-card-wrapper {
  // extra space, 2 pixels extra for each border side, so that cards have room to grow when highlighted.
  height: 324px;
  width: 204px;

  // centralize card
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter-button {
  @include overwatch-body-small;
  height: 48px;
  text-transform: capitalize;
  padding-left: $--spacing-s;
  padding-right: $--spacing-s;
}

.target-cluster-section {
  .title {
    @include overwatch-title-med;
    margin-bottom: var(--spacing-s);
  }

  .body {
    display: flex;
    align-items: flex-start;
    gap: var(--spacing-s);
    margin-bottom: var(--spacing-m);
  }

  img {
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 var(--overwatch-overlay);
  }
}
</style>
