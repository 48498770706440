<template>
  <div class="d-flex flex-column">
    <div style="margin-left: var(--spacing-m);">
      <router-link
        v-if="eventName"
        style="color: var(--overwatch-primary); margin-bottom: var(--spacing-s);"
        :to="editUrl"
      >
        {{ eventName }}
      </router-link>
    </div>
    <div
      class="d-flex flex-row justify-content-end eventDataDates"
      style="flex: 1;"
    >
      <div
        class="d-flex flex-column"
        style="flex: 1; border-right: 1px solid var(--overwatch-accent);"
      >
        <div class="date-string-label">Starts</div>
        <div class="date-string">{{ startDateString }}</div>
      </div>
      <hr />
      <div
        class="d-flex flex-column"
        style="flex: 1; margin-left: var(--spacing-s);"
      >
        <div class="date-string-label">Ends</div>
        <div class="date-string">{{ endDateString }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import moment from "moment";

export default {
  name: "EventView",
  emits: ["edit", "delete", "toggleEnabled"],
  components: {},
  props: {
    startDate: String,
    endDate: String,
    eventId: {
      type: String,
      required: false
    },
    eventName: {
      type: String,
      required: false
    }
  },
  setup(props, context) {
    const eventName = ref(props.eventName);
    const editUrl = ref({
      name: "EventDashboard",
      params: { id: props.eventId }
    });

    watch(
      () => props.eventName,
      (nv) => {
        eventName.value = nv;
      }
    );

    const startDateString = computed(() => {
      return moment(props.startDate).format("dddd MMMM DD, YYYY");
    });

    const endDateString = computed(() => {
      return moment(props.endDate).format("dddd MMMM DD, YYYY");
    });

    return {
      startDateString,
      endDateString,
      editUrl,
      eventName
    };
  }
};
</script>
<style scoped lang="scss">
.eventDataDates {
  position: absolute;
  width: 100%;
  padding: var(--spacing-m);
  @include overwatch-body-med;
  bottom: 0;
}

.date-string {
  @include overwatch-body-med;
}

.date-string-label {
  opacity: 0.5;
  @include overwatch-body-xsmall;
  margin-bottom: var(--spacing-base);
  font-style: normal;
  text-transform: uppercase;
}
</style>
