<template>
  <div
    :class="{
      header: !transparentBackground,
      transparentHeader: transparentBackground
    }"
  >
    <div class="title-slot">
      <slot name="leftnav" />
    </div>
    <div class="container">
      <slot name="filterWidgets" />
      <div class="search-button-container" v-if="!hideSearch">
        <div class="searchRectangle" :style="styleSearchRectangle">
          <div
            v-if="customSearch === false"
            class="align-self-center"
            style="margin-top: 9px;"
          >
            <RocIcon
              v-if="!isImageFilterMode"
              color="black"
              icon="search"
              size="sm"
            />
            <input
              v-if="!isImageFilterMode"
              class="searchInput"
              :placeholder="searchTextPlaceHolder"
              label="Example label"
              type="text"
              @input="updateSearchText"
            />
            <RocIcon
              v-if="allowPhotoUpload && !isLoadingImage && !isImageFilterMode"
              icon="image"
              style="cursor: pointer;"
              @click="uploadPhoto"
            />
            <roc-spinner v-if="isLoadingImage" size="sm" />
            <div
              v-if="isImageFilterMode"
              style="display: flex; flex-direction: column; width: 100%; padding-bottom: 6px;"
            >
              <div class="d-flex flex-row">
                <div class="overwatch-title-small">Image Search</div>
                <div style="flex: 1; text-align: right;">
                  <RocIcon
                    icon="exit"
                    style="cursor: pointer;"
                    @click="clearImageFilter"
                  />
                </div>
              </div>
              <img
                class="filter-image"
                :src="imageFilterData"
                style="padding: 5px;"
              />
            </div>
            <slot name="startExamineCase"></slot>
          </div>
          <div class="align-self-center" style="margin-top: 9px;" v-else>
            <RocIcon
              v-if="!isImageFilterMode"
              color="black"
              class="fa-lg"
              size="sm"
              icon="search"
            />
            <slot name="searchInput"></slot>
          </div>
        </div>
        <div v-if="!isImageFilterMode" style="float:right">
          <div v-if="useDropdown">
            <MDBDropdown v-model="dropdownOptions" align="end">
              <MDBDropdownToggle
                style="color:black;
                  -webkit-appearance: none;
                  -moz-appearance: none;
                  appearance: none;"
                tag="a"
                @click="dropdownOptions = !dropdownOptions"
              >
                <RocButton
                  class="addCamera"
                  :disabled="disableButton"
                  :style="buttonStyleComputed"
                >
                  <RocIcon
                    v-if="buttonTitleComputed.startsWith('Filter')"
                    size="sm"
                    icon="filter2"
                    color="white"
                    style="margin-right: var(--spacing-base); margin-top:-1px;"
                  />
                  <RocIcon
                    v-else-if="buttonTitleComputed === 'Sort'"
                    size="md"
                    icon="sort"
                    color="white"
                    style="margin-right: var(--spacing-base); margin-top:-1px;"
                  />
                  {{ buttonTitleComputed }}
                </RocButton>
              </MDBDropdownToggle>
              <RocDropdownMenu
                @click="dropdownOptions = false"
                aria-labelledby="dropdownMenuButton"
                style="margin-top: 20px; box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);"
              >
                <slot name="dropdown-slot"></slot>
              </RocDropdownMenu>
            </MDBDropdown>
          </div>
          <div v-else>
            <div
              v-if="disableButton"
              class="tip-wrapper tip t-bottom"
              :data-tooltip-text="disableText"
            >
              <RocButton
                class="addCamera"
                v-if="!hideButton"
                @click="clicked"
                :disabled="disableButton"
                :style="buttonStyleComputed"
              >
                {{ buttonTitleComputed }}
              </RocButton>
            </div>
            <RocButton
              class="addCamera"
              v-else-if="!hideButton"
              @click="clicked"
              :disabled="disableButton"
              :style="buttonStyleComputed"
            >
              {{ buttonTitleComputed }}
            </RocButton>
            <slot v-else name="buttonPair" />
          </div>
        </div>
      </div>
      <div v-else class="search-hidden align-self-center">
        <slot name="rightnav" />
        <RocPopper
          hover
          arrow
          placement="left"
          popper-type="tooltip"
          v-if="disableButton"
          v-model="showTooltip"
        >
          <div style="line-height: var(--spacing-l);">
            <RocButton
              class="addCamera"
              v-if="!hideButton"
              @click="clicked"
              :disabled="disableButton"
              :style="buttonStyleComputed"
            >
              {{ buttonTitleComputed }}
            </RocButton>
          </div>
          <template #content>
            {{ disableText }}
          </template>
        </RocPopper>
        <div v-if="useDropdown && !hideButton">
          <MDBDropdown v-model="dropdownOptions" align="end">
            <MDBDropdownToggle
              style="color:black;
                  -webkit-appearance: none;
                  -moz-appearance: none;
                  appearance: none;"
              tag="a"
              @click="dropdownOptions = !dropdownOptions"
            >
              <RocButton
                class="addCamera"
                :disabled="disableButton"
                :style="buttonStyleComputed"
              >
                <RocIcon
                  v-if="buttonTitleComputed === 'Filter'"
                  size="sm"
                  icon="filter2"
                  color="white"
                  style="margin-right: var(--spacing-base); margin-top:-1px;"
                />
                {{ buttonTitleComputed }}
              </RocButton>
            </MDBDropdownToggle>
            <RocDropdownMenu
              @click="dropdownOptions = false"
              aria-labelledby="dropdownMenuButton"
              style="margin-top: 20px; box-shadow: 4px 4px 15px 4px rgba(0, 0, 0, 0.3);"
            >
              <slot name="searchless-dropdown"></slot>
            </RocDropdownMenu>
          </MDBDropdown>
        </div>
        <RocButton
          class="addCamera"
          v-else-if="!hideButton && !disableButton"
          @click="clicked"
          :disabled="disableButton"
          :style="buttonStyleComputed"
        >
          {{ buttonTitleComputed }}
        </RocButton>
      </div>
      <slot name="buttonPair" />
    </div>
  </div>
</template>
<script>
import {
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownToggle,
  MDBInput
} from "mdb-vue-ui-kit";
import { onMounted, ref, computed } from "vue";
import RocIcon from "@/components/ui/RocIcon";
import RocDropdownMenu from "./RocDropdownMenu.vue";
import RocButton from "./RocButton.vue";
import RocPopper from "@/components/ui/RocPopper.vue";

export default {
  name: "SearchHeader.vue",
  emits: [
    "button-clicked",
    "search-filter-change",
    "search-image-change",
    "search-image-cleared"
  ],
  props: {
    buttonTitle: {
      String,
      default: ""
    },
    hideSearch: {
      type: Boolean,
      default: false
    },
    useDropdown: {
      type: Boolean,
      default: false
    },
    allowPhotoUpload: {
      type: Boolean,
      default: false
    },
    keepButtonTitle: {
      // Keeps from changing button title on mobile format.
      type: Boolean
    },
    hideButton: {
      type: Boolean,
      default: false
    },
    disableButton: {
      type: Boolean,
      default: false
    },
    disableText: {
      type: String,
      default: ""
    },
    customSearch: {
      type: Boolean,
      default: false
    },
    searchTextPlaceHolder: {
      type: String,
      default: "Search"
    },
    transparentBackground: {
      type: Boolean,
      default: false
    }
  },
  components: {
    MDBDropdown,
    MDBDropdownItem,
    MDBDropdownToggle,
    MDBInput,
    RocIcon,
    RocDropdownMenu,
    RocButton,
    RocPopper
  },
  setup(props, context) {
    const windowWidth = ref(window.innerWidth);
    const isImageFilterMode = ref(false);
    const isLoadingImage = ref(false);
    const imageFilterData = ref("");
    const showTooltip = ref(false);

    onMounted(() => {
      window.addEventListener("resize", () => {
        windowWidth.value = window.innerWidth;
      });
    });

    const dropdownOptions = ref(false);

    function updateSearchText(event) {
      const filterText = event.target.value;
      context.emit("search-filter-change", filterText);
    }

    function clicked() {
      context.emit("button-clicked");
    }

    function uploadPhoto() {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = "image/*";
      input.hidden = true;
      input.addEventListener("change", (e) => {
        setImageFilter(e.target.files[0]);

        input.remove();
      });
      document.body.appendChild(input);
      input.click();
    }

    function setImageFilter(imageFile) {
      if (imageFile) {
        if (imageFile.type.startsWith("image")) {
          isImageFilterMode.value = true;
          isLoadingImage.value = true;
          let fr = new FileReader();
          fr.onloadend = function() {
            isLoadingImage.value = false;
            imageFilterData.value = fr.result;
            context.emit(
              "search-image-change",
              fr.result,
              imageFile.name,
              imageFile
            );
          };
          fr.onerror = function() {
            isLoadingImage.value = false;
            console.error("Unable to load selected image");
          };
          fr.readAsDataURL(imageFile);
        }
      }
    }

    function clearImageFilter() {
      isImageFilterMode.value = false;
      imageFilterData.value = "";
      context.emit("search-image-cleared");
    }

    const buttonTitleComputed = computed(() => {
      if (windowWidth.value <= 480 && !props.keepButtonTitle) {
        return "+";
      } else {
        return props.buttonTitle;
      }
    });

    const buttonStyleComputed = computed(() => {
      if (windowWidth.value <= 480 && !props.keepButtonTitle) {
        return { "font-size": "24px" };
      } else {
        return {};
      }
    });

    const searchRectangleHeight = computed(() => {
      if (isImageFilterMode.value) {
        return "auto";
      } else {
        return "44px";
      }
    });

    const styleSearchRectangle = computed(() => {
      if (isImageFilterMode.value) {
        return {
          float: "right"
        };
      } else {
        return {
          float: "left"
        };
      }
    });

    return {
      showTooltip,
      updateSearchText,
      clicked,
      dropdownOptions,
      uploadPhoto,
      buttonTitleComputed,
      buttonStyleComputed,
      isImageFilterMode,
      isLoadingImage,
      imageFilterData,
      searchRectangleHeight,
      clearImageFilter,
      styleSearchRectangle
    };
  }
};
</script>

<style scoped lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: auto;
  padding: 1rem;
  background: var(--overwatch-secondary);
  font-size: 24px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.3);
}

.transparentHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: auto;
  padding: 1rem;
  font-size: 24px;
  align-items: center;
}

.title-slot {
  align-self: center;
  float: left;
  margin-left: 2.5rem;
  @include overwatch-title-large;
  display: flex;
  align-items: center;
}

.searchRectangle {
  float: left;
  height: v-bind(searchRectangleHeight);
  width: 300px;
  border: 1px solid var(--overwatch-neutral-300);
  background-color: var(--overwatch-neutral-500);
  @include overwatch-body-small;
  border-radius: 5px;
  padding-left: var(--spacing-s);
  padding-top: 2px;
  padding-bottom: 0;
  padding-right: 6px;
  margin-left: 2rem;
  border-radius: 5px;
  margin-top: 2px;
}

.container {
  float: right;
  width: fit-content;
  margin-right: 2.5rem;
  align-self: center;
  display: flex;
  flex-direction: row;
}

.search-button-container {
  width: fit-content;
}

.addCamera {
  text-transform: unset !important;
  margin-left: var(--spacing-s);
}

.searchInput {
  border: 0px;
  margin-left: var(--spacing-s);
  width: 80%;
}

searchInput:active {
  border: 1px solid var(--overwatch-error);
}

.searchInput:hover {
  border: 0px;
}

input.searchInput:focus {
  outline-width: 0;
}

.dropdown-toggle:after {
  display: none;
}
.header-options-dd {
  @include overwatch-body-med;
  /* color: #000000; */
}

.dot {
  height: 18px;
  width: 18px;
  background-color: var(--overwatch-primary);
  border-radius: 50%;
  display: inline-block;
}

.search-hidden {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 250px;
}

.filter-image {
  height: 115px;
  width: auto;
  object-fit: contain;
}

/* MOBILE */
@media (max-width: 480px) {
  .header {
    flex-direction: column;
    font-size: 20px;
    padding-top: 0.2rem;
    padding-bottom: 0;
  }
  .title-slot {
    all: revert;
  }

  .container {
    all: revert;
    width: 100%;
    margin-top: 10px;
    /* float: none;
    margin: 0;
    padding: 0; */
  }
  .search-button-container {
    display: flex;
    width: 70%;
    gap: 10px;
    margin-bottom: 10px;
    padding-left: 10px;
  }
  .searchRectangle {
    flex: 1;
    margin: 0;
  }

  .addCamera {
    margin: 0;
    padding: 4px 14px;
  }
  .search-hidden {
    width: revert;
  }
  .transparentHeader {
    display: inline;
  }
}

.btn:disabled {
  opacity: 1;
  background-color: var(--overwatch-neutral-400);
  color: var(--overwatch-neutral-300) !important;
}
</style>
