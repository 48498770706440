<template>
  <img :class="classRef" :src="imgData" :style="styleRef" :key="key" />
</template>

<script>
import { ref, watchEffect } from "vue";
import { useStore } from "vuex";

export default {
  name: "AuthImg",
  props: {
    src: String,
    style: Object,
    class: String,
    key: String,
    fade: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const store = useStore();
    const imgData = ref(null);
    const lastPropsUrl = ref(null);
    const styleRef = ref(props.style);
    const classRef = ref(props.class);
    const classRefOrg = ref(props.class);

    watchEffect(async () => {
      if (props.src != lastPropsUrl.value) {
        props.fade ? (classRef.value = classRefOrg.value + " hidden") : 0;

        if (!props.src.includes("data:image")) {
          imgData.value = await loadImage(props.src);
        } else {
          imgData.value = props.src;
        }

        props.fade ? (classRef.value = classRefOrg.value + " visible") : 0;
        lastPropsUrl.value = props.src;
      }
    });

    async function loadImage(imgSrc) {
      const httpPayload = {
        url: imgSrc,
        payload: { method: "GET" }
      };

      return await store.dispatch(
        "auth/fetchBinaryResourceAsBase64",
        httpPayload
      );
    }

    return {
      imgData,
      styleRef,
      classRef,
      lastPropsUrl
    };
  }
};
</script>

<style scoped>
.visible {
  visibility: visible;
  opacity: 1.5;
  transition: opacity 0.75s linear;
}

.hidden {
  visibility: hidden;
  opacity: 0.5;
  transition: visibility 0s 0.5s, opacity 0.5s linear;
}
</style>
