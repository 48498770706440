<template>
  <MyPopperWrapper
    class="encounters-popper"
    :Index="99"
    :show="show"
    ref="popperRef"
    offsetSkid="158"
    :zIndex="99"
  >
    <slot ref="toggleRef" />
    <template #content>
      <div class="wrapper" ref="wrapperRef">
        <div class="header">
          <div :title="cluster.name">
            {{ clusterName ? clusterName : "" }}
          </div>

          <!-- Multiple select menu -->
          <div class="selected-menu" v-if="selectedEncounters.length > 0">
            {{ selectedEncounters.length }} selected
            <MDBDropdown v-model="isMultipleEncountersMenuOpen">
              <RocDropdownToggle
                @click="
                  isMultipleEncountersMenuOpen = !isMultipleEncountersMenuOpen
                "
              >
                <RocIcon color="black" size="xs" icon="kebab" />
              </RocDropdownToggle>
              <RocDropdownMenu>
                <RocDropdownSub>
                  Move to
                  <template #menu>
                    <RocDropdownItem
                      @click.stop="moveToNewCluster(selectedEncounters)"
                    >
                      New Cluster
                    </RocDropdownItem>
                    <RocDropdownItem
                      @click.stop="moveToExistingCluster(selectedEncounters)"
                    >
                      Existing Cluster
                    </RocDropdownItem>
                  </template>
                </RocDropdownSub>
              </RocDropdownMenu>
            </MDBDropdown>
          </div>

          <!-- Sort by -->
          <MDBDropdown
            v-model="isEncounterSortDropdownOpen"
            style="margin-left: auto;"
          >
            <RocDropdownToggle
              @click="
                isEncounterSortDropdownOpen = !isEncounterSortDropdownOpen
              "
            >
              <div
                class="overwatch-body-small"
                style="display: flex; align-items: center; gap: var(--spacing-xs); color: var(--overwatch-neutral-100);"
              >
                <RocIcon class="icon" color="black" icon="sort" size="sm" />
                Sort By
              </div>
            </RocDropdownToggle>
            <RocDropdownMenu @click="isEncounterSortDropdownOpen = false">
              <RocDropdownItem
                @click="isAscending = true"
                :active="isAscending"
              >
                Time Ascending
              </RocDropdownItem>
              <RocDropdownItem
                @click="isAscending = false"
                :active="!isAscending"
              >
                Time Descending
              </RocDropdownItem>
            </RocDropdownMenu>
          </MDBDropdown>

          <RocIcon
            class="icon"
            color="gray"
            icon="exit"
            size="xs"
            @click="$emit('close')"
          />
        </div>
        <div class="divider"></div>
        <ClusterEncountersGrid
          v-if="cluster?.encounters.length > 0 && openedOnce"
          :encounters="encounters"
          @move-new="moveToNewCluster"
          @move-existing="moveToExistingCluster"
          @set-cover="setAsCoverImage"
          @selection-changed="handleSelectionChanged"
          @enroll-face="(e) => $emit('enroll-face', e)"
          @enroll-lpr="(e) => $emit('enroll-lpr', e)"
        />
        <div v-else>
          No encounters.
        </div>
        <BaseDialog
          v-if="isShowingMoveToNewCluster"
          @close="isShowingMoveToNewCluster = false"
          show
          hideCloseBtn
          preventCloseOnClickAway
        >
          <MoveToNewCluster
            @close="isShowingMoveToNewCluster = false"
            @save="handleMovedEncounters"
            :encounters="editingEncounters"
            :cluster="cluster"
          />
        </BaseDialog>
        <BaseDialog
          v-if="isShowingMoveToExistingCluster"
          @close="isShowingMoveToExistingCluster = false"
          show
          hideCloseBtn
          noPadding
          preventCloseOnClickAway
        >
          <MoveToExistingCluster
            @close="isShowingMoveToExistingCluster = false"
            @save="handleMovedEncounters"
            :encounters="editingEncounters"
            :cluster="cluster"
          />
        </BaseDialog>
      </div>
    </template>
  </MyPopperWrapper>
</template>

<script>
import { ref, watch, computed } from "vue";
import { useStore } from "vuex";

import ClusterEncounterCard from "@/components/clusters/ClusterEncounterCard";
import ClusterEncountersGrid from "@/components/clusters/ClusterEncountersGrid";

import MyPopperWrapper from "@/components/ui/MyPopperWrapper";
import RocIcon from "@/components/ui/RocIcon";
import BaseDialog from "@/components/ui/BaseDialog";

import MoveToExistingCluster from "@/components/clusters/MoveToExistingCluster";
import MoveToNewCluster from "@/components/clusters/MoveToNewCluster.vue";

import { MDBDropdown, MDBSpinner } from "mdb-vue-ui-kit";
import RocDropdownMenu from "@/components/ui/RocDropdownMenu";
import RocDropdownItem from "@/components/ui/RocDropdownItem";
import RocDropdownToggle from "@/components/ui/RocDropdownToggle";
import RocDropdownSub from "@/components/ui/RocDropdownSub";

export default {
  name: "ClusterEncountersPopper",
  props: {
    cluster: {
      type: Object,
      required: true
    },
    show: {
      type: Boolean,
      required: true
    }
  },
  emit: ["close", "enroll-face", "enroll-lpr"],
  components: {
    ClusterEncounterCard,
    ClusterEncountersGrid,
    RocIcon,
    MyPopperWrapper,
    BaseDialog,
    MoveToNewCluster,
    MoveToExistingCluster,
    RocDropdownMenu,
    RocDropdownItem,
    RocDropdownToggle,
    RocDropdownSub,
    MDBDropdown,
    MDBSpinner
  },
  setup(props, context) {
    const store = useStore();

    const isAscending = ref(true);
    const encounters = computed(() => {
      const encounters = props.cluster.encounters
        ? props.cluster.encounters
        : [];

      if (isAscending.value) {
        encounters.sort((a, b) => {
          return a.timestamp - b.timestamp;
        });
      } else {
        encounters.sort((a, b) => {
          return b.timestamp - a.timestamp;
        });
      }
      return encounters;
    });

    const selectedEncounters = ref([]);
    const editingEncounters = ref([]);

    /**
     * Sean -
     * Need a selectedEncounters array to represent encounters checked by box
     * but also an editingEncounters array to represent encounters that will be passed to
     * moveTo functions.
     * This difference exists because editingEncounters can be just one encounter
     * that was set from the encounter card dropdown
     */

    function handleSelectionChanged(encounters) {
      selectedEncounters.value = encounters;
    }

    const isShowingMoveToNewCluster = ref(false);
    function moveToNewCluster(encounters) {
      editingEncounters.value = encounters;
      isShowingMoveToNewCluster.value = true;
    }

    const isShowingMoveToExistingCluster = ref(false);
    function moveToExistingCluster(encounters) {
      editingEncounters.value = encounters;
      isShowingMoveToExistingCluster.value = true;
    }

    const isMultipleEncountersMenuOpen = ref(false);

    const wrapperRef = ref(null);

    // Must ignore the first click,
    // as addEventListener still listens to the first click for some reason.
    const eatClick = ref(false);
    function clickOutsideHandler(e) {
      if (!eatClick.value) {
        // Eating first click yum.
        eatClick.value = true;
        return;
      }

      const isInside = wrapperRef.value?.contains(e.target);
      if (!isInside) {
        context.emit("close");
      }
    }

    const openedOnce = ref(false); // Check if popper is opened once and lazy load images.
    watch(
      () => props.show,
      async (nv) => {
        if (nv) {
          openedOnce.value = true;
          document.addEventListener("click", clickOutsideHandler);
        } else {
          document.removeEventListener("click", clickOutsideHandler);
          eatClick.value = false;
        }
      }
    );

    async function setAsCoverImage(encounter) {
      const response = await store.dispatch("clusters/updateCluster", {
        id: props.cluster._id,
        update: {
          referenceEncounter: encounter._id
        }
      });
    }

    const isEncounterSortDropdownOpen = ref(false);

    const clusterName = computed(() => {
      var maxLength = 25;
      if (props.cluster.name.length > maxLength) {
        return props.cluster.name.substring(0, maxLength) + "...";
      }
      return props.cluster.name;
    });

    function handleMovedEncounters() {
      selectedEncounters.value = [];
    }

    return {
      encounters,
      wrapperRef,
      moveToNewCluster,
      moveToExistingCluster,
      isShowingMoveToExistingCluster,
      isShowingMoveToNewCluster,
      selectedEncounters,
      editingEncounters,
      handleSelectionChanged,
      isMultipleEncountersMenuOpen,
      setAsCoverImage,
      isEncounterSortDropdownOpen,
      isAscending,
      isShowingMoveToNewCluster,
      clusterName,
      handleMovedEncounters,
      openedOnce
    };
  }
};
</script>

<style scoped lang="scss">
.divider {
  margin: 0;
  margin-top: var(--spacing-base);
  line-height: 0;
  border-top: 1px solid var(--overwatch-accent);
}

.wrapper {
  background-color: var(--overwatch-secondary);
}
.header {
  display: flex;
  align-items: center;
}

.encounters-popper {
  position: relative;
}

.encounters-popper :deep(.popper) {
  width: 520px;
  padding: $--spacing-s $--spacing-m;
  background-color: var(--overwatch-secondary);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.encounters-popper :deep(.popper):hover {
  background-color: var(--overwatch-secondary);
}

.loading {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: var(--spacing-s) 0;
}
.encounters-grid {
  margin-top: $--spacing-s;
  display: grid;
  grid-template-columns: repeat(5, 94px);
  grid-auto-rows: 144px;
  column-gap: $--spacing-base;
  row-gap: $--spacing-base;

  width: 500px;

  align-items: center;
  justify-items: center;
}

.icon {
  margin-left: $--spacing-s;
  cursor: pointer;
}

.dialog :deep(section) {
  padding: $--spacing-xl $--spacing-xxl;
}

.selected-menu {
  @include overwatch-body-small;
  display: flex;
  align-items: center;

  margin-left: $--spacing-m;
  gap: $--spacing-base;
}
</style>
