<template>
  <li>
    <div>
      <a :href="emailLink">{{ email }}</a>
    </div>
    <p>{{ message }}</p>
  </li>
</template>

<script>
export default {
  props: ["email", "message"],
  computed: {
    emailLink() {
      return "mailto:" + this.email;
    }
  }
};
</script>

<style scoped>
li {
  margin: 1rem 0;
  padding: 1rem;
}

a {
  color: var(--overwatch-primary);
  text-decoration: none;
}

a:hover,
a:active {
  color: var(--overwatch-primary);
}

p {
  margin: 0.5rem 0 0 0;
}
</style>
