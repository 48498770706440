<template>
  <MDBBtn
    :class="{
      normal: size === 'normal',
      mega: size === 'mega',
      tiny: size === 'tiny',
      dialogButton: size === 'dialogButton',
      match: size === 'match', //matches parents size

      primary: type === 'primary',
      secondary: type === 'secondary',
      white: type === 'white',
      binary: type === 'binary',
      menu: type === 'menu',
      ghost: type === 'ghost',

      // only activates in binary mode
      active: isActive === true
    }"
    :ripple="false"
  >
    <slot></slot>
  </MDBBtn>
</template>

<script>
import { MDBBtn } from "mdb-vue-ui-kit";

export default {
  name: "RocButton",
  components: {
    MDBBtn
  },
  props: {
    size: {
      type: String,
      default: "normal"
    },
    type: {
      type: String,
      default: "primary"
    },
    isActive: {
      type: Boolean,
      required: false
    }
  }
};
</script>

<style scoped lang="scss">
button {
  all: unset;

  box-sizing: border-box;

  font-family: var(--overwatch-button-font-family);
  font-stretch: normal;
  text-align: center;
  color: var(--overwatch-button-text);
  text-transform: unset;

  border-radius: var(--overwatch-button-border-radius);
  box-shadow: var(--overwatch-button-box-shadow);

  cursor: pointer;
  user-select: none;
}

button:enabled:active {
  box-shadow: 0 0 0 0;
}

button:disabled,
button:disabled:hover {
  cursor: default;
  background-color: var(--overwatch-neutral-400);
  box-shadow: var(--overwatch-button-box-shadow);
}

button:hover {
  color: var(--overwatch-button-text);
  box-shadow: var(--overwatch-button-box-shadow);
}

button:focus {
  box-shadow: var(--overwatch-button-box-shadow);
}

/* Size Classes */
.normal {
  font-size: 14px;
  padding: var(--spacing-s) var(--spacing-l);
}

.mega {
  font-size: 16px;
  padding: var(--spacing-s) var(--spacing-l);
}

.tiny {
  font-size: 12px;
  padding: var(--spacing-base) var(--spacing-l);
}

.dialogButton {
  font-size: 14px;
  padding: var(--spacing-s) var(--spacing-xl);
}

.match {
  font-size: 0;
  padding: 0;
}

/* Type Classes */
.primary {
  background-color: var(--overwatch-button-primary);
}

.primary:enabled:hover {
  background-color: var(--overwatch-button-hover);
}

.secondary {
  background-color: var(--overwatch-neutral-300);
}

.secondary:enabled:hover {
  background-color: var(--overwatch-neutral-200);
}

.white {
  color: var(--overwatch-neutral-200);
  border: solid 1px var(--overwatch-neutral-400);
  background-color: var(--overwatch-neutral-500);
}

.white:hover {
  color: var(--overwatch-neutral-200);
}

.binary {
  background-color: var(--overwatch-neutral-500);
  border: solid 1px var(--overwatch-neutral-300);
  color: var(--overwatch-neutral-200);
}

.binary:hover {
  color: var(--overwatch-neutral-200);
}

.binary.active {
  background-color: var(--overwatch-neutral-200) !important;
  color: white !important;
}

.menu {
  background-color: var(--overwatch-secondary);
  border: solid 1px var(--overwatch-neutral-400);
  color: var(--overwatch-neutral-100);
}

.menu:hover {
  background-color: var(--overwatch-neutral-500);
  color: var(--overwatch-neutral-100);
}

.ghost {
  background-color: transparent;
  color: var(--overwatch-button-primary);
  box-shadow: none;
}

.ghost:hover {
  background-color: var(--overwatch-neutral-500);
  color: var(--overwatch-button-primary);
}

.ghost:disabled {
  background-color: var(--overwatch-neutral-400);
  color: var(--overwatch-neutral-300);
}

/* MOBILE */
@media (max-width: 480px) {
  .normal {
    font-size: 12px;
    padding: var(--spacing-xs) var(--spacing-m);
  }
  .mega {
    font-size: 14px;
    padding: var(--spacing-xs) var(--spacing-m);
  }
  .tiny {
    font-size: 10px;
    padding: var(--spacing-base) var(--spacing-l);
  }
  .dialogButton {
    font-size: 14px;
    padding: var(--spacing-xs) var(--spacing-l);
  }
}
</style>
