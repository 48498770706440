<template>
  <div style="width: 100%;">
    <div class="container">
      <div>
        <img :src="faceThumbnail" style="max-width: 115px; height: auto" />
      </div>
      <div class="all-input-div">
        <div
          v-if="elementWatchlistType === 'face'"
          class="d-flex"
          style="width:100%;"
        >
          <div v-if="mode == 'move'">
            <div>
              Full Name
              <div>
                {{ firstNameUpdate + " " + lastNameUpdate }}
              </div>
            </div>
          </div>
          <div v-else-if="mode == 'enroll'" style="width: 100%;">
            <div style="flex:1; margin-right: 5px; margin-bottom: 12px;">
              <div class="fieldTitle" style="margin-bottom: 4px">
                First Name
              </div>
              <div class="fieldRectangle">
                <div class="align-self-center" style="margin-top: 4px;">
                  <input
                    class="fieldInput"
                    type="text"
                    v-model="firstNameUpdate"
                    :disabled="mode == 'move'"
                  />
                </div>
              </div>
              <div style="flex:1" :disabled="mode == 'move'">
                <div class="fieldTitle" style="margin-bottom: 4px">
                  Last Name
                </div>
                <div class="fieldRectangle">
                  <div class="align-self-center" style="margin-top: 4px;">
                    <input
                      class="fieldInput"
                      type="text"
                      v-model="lastNameUpdate"
                      :disabled="mode == 'move'"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else-if="elementWatchlistType === 'text'"
          class="d-flex"
          style="width:100%; margin-bottom: 24px;"
        >
          <WatchlistedLicensePlateCRUD
            :p_collapsed="true"
            :p_plate-number="lprText"
            :p_fullname="lprState"
            :mode="'enrollEncounter'"
            @handle-change="updateLpr"
          />
        </div>
        <div v-if="mode == 'enroll'" style="width: 100%; margin-bottom: 16px;">
          <div class="fieldTitle">{{ watchlistSelectorLabel }}</div>
          <filter-multi-select
            class="watchlistSelectMulti"
            mode="tags"
            name=""
            no-results-text=""
            :placeholder-text="watchlistSelectorHint"
            :available-items="fetchEnrollWatchlists()"
            :currently-selected="watchlistEnrollTargets"
            @selection-changed="updatedSelectedEnrollWatchlists"
            :close-on-select="false"
            :watchlistEnrollMode="true"
          />
        </div>
      </div>
    </div>
    <div
      v-if="mode == 'move'"
      style="width: 100%; margin-bottom: 16px; padding-top: 10px;"
    >
      <div class="fieldTitle">{{ watchlistSelectorLabel }}</div>
      <filter-multi-select
        class="watchlistSelectMulti"
        mode="single"
        name=""
        no-results-text=""
        :placeholder-text="watchlistSelectorHint"
        :available-items="fetchMoveWatchlists()"
        :currently-selected="null"
        @selection-changed="updatedSelectedMoveWatchlist"
        :close-on-select="true"
        :watchlistEnrollMode="true"
      />
    </div>
    <div class="d-flex justify-content-end">
      <div
        class="d-flex justify-content-between align-self-center"
        style="margin-right: 10px;"
      >
        <roc-spinner v-if="isWorking" />
        <RocIcon
          v-if="actionSuccess"
          icon="match"
          style="margin-right: 4px;"
          size="lg"
        />
      </div>
      <div
        class="d-flex justify-content-between align-self-center"
        style="margin-right: 10px;"
      >
        <div v-if="actionFailed" class="msgStatus">{{ failedMsg }}</div>
        <RocIcon
          v-if="actionFailed"
          icon="exit"
          color="red"
          style="margin-right: 4px;"
          size="lg"
        />
      </div>
      <div>
        <RocButton @click="performAction" :disabled="btnDisabled">{{
          buttonText
        }}</RocButton>
      </div>
    </div>
  </div>
</template>

<script>
import FilterMultiSelect from "@/components/ui/filterMultiSelect";
import WatchlistedLicensePlateCRUD from "@/components/watchlists/WatchlistedLicensePlateCRUD";
import { useStore } from "vuex";
import { computed, ref } from "vue";
import "@vueform/multiselect/themes/default.css";
import RocIcon from "../ui/RocIcon.vue";
import RocButton from "@/components/ui/RocButton";

export default {
  name: "EnrollEncounter",
  emits: ["elementEnrolled", "elementMoved"],
  components: {
    FilterMultiSelect,
    WatchlistedLicensePlateCRUD,
    RocButton,
    RocIcon
  },
  props: {
    mode: {
      type: String,
      required: true,
      default: "enroll"
    },
    // defaults when enrolling, already set when moving
    firstName: {
      type: String,
      required: false,
      default: "Unknown"
    },
    // defaults when enrolling, already set when moving
    lastName: {
      type: String,
      required: false,
      default: ""
    },
    elementWatchlistType: {
      type: String,
      required: false,
      default: "face"
    },
    // set for enroll and move
    faceThumbnail: {
      type: String,
      required: false,
      default: ""
    },
    // enroll mode - used to enroll face encounter - expects person id
    enrollFacePersonId: {
      type: String,
      required: false,
      default: ""
    },
    // move mode - used to move enrolled face to a different watchlist - expects watchlisted element id and current watchlist
    moveWatchlistedElementId: {
      type: String,
      required: false,
      default: ""
    },
    moveWatchlistedElementCurrentWatchlist: {
      type: String,
      required: false,
      default: ""
    },
    lprText: {
      type: String,
      required: false,
      default: ""
    },
    lprState: {
      type: String,
      required: false,
      default: ""
    }
  },
  setup(props, context) {
    const store = useStore();
    const watchlistEnrollTargets = ref(null);
    const isWorking = ref(false);
    const btnDisabled = ref(true);
    const actionSuccess = ref(false);
    const actionFailed = ref(false);
    const watchlistMoveTarget = ref(null);
    const moveCurrentWatchlist = ref(
      props.moveWatchlistedElementCurrentWatchlist
    );
    const firstNameUpdate = ref(props.firstName);
    const lastNameUpdate = ref(props.lastName);
    const lprUpdate = ref({
      plateNumber: props.lprText,
      fullname: props.lprState
    });

    function fetchEnrollWatchlists() {
      let watchlistsForEnroll = [];
      const watchlistList = store.getters["watchlists/watchlists"];
      watchlistList.forEach((watchlist) => {
        if (watchlist.type === props.elementWatchlistType) {
          watchlistsForEnroll.push(watchlist);
        }
      });
      return watchlistsForEnroll;
    }

    function fetchMoveWatchlists() {
      let watchlistsForMove = [];
      const watchlistList = store.getters["watchlists/watchlists"];
      watchlistList.forEach((watchlist) => {
        if (
          watchlist.name != moveCurrentWatchlist.value &&
          watchlist.type === props.elementWatchlistType
        ) {
          watchlistsForMove.push(watchlist);
        }
      });

      return watchlistsForMove;
    }

    function updatedSelectedEnrollWatchlists(selected) {
      watchlistEnrollTargets.value = selected.value;
      if (
        watchlistEnrollTargets.value &&
        watchlistEnrollTargets.value.length > 0
      ) {
        btnDisabled.value = false;
      } else {
        btnDisabled.value = true;
      }
    }

    function updatedSelectedMoveWatchlist(selected) {
      if (selected.value) {
        watchlistMoveTarget.value = selected.value;
        btnDisabled.value = false;
      } else {
        watchlistMoveTarget.value = null;
        btnDisabled.value = true;
      }
    }

    async function performAction() {
      if (props.mode == "move") {
        return moveWatchlistedElement();
      } /*if (props.mode == 'enroll')*/ else {
        return enroll();
      }
    }

    async function enroll() {
      actionSuccess.value = false;
      actionFailed.value = false;
      isWorking.value = true;
      btnDisabled.value = true;
      let responseData;
      if (props.elementWatchlistType === "face") {
        const enrollmentPayload = {
          personId: props.enrollFacePersonId,
          watchlistTargets: watchlistEnrollTargets.value,
          firstname: firstNameUpdate.value,
          lastname: lastNameUpdate.value
        };
        responseData = await store.dispatch(
          "watchlists/enrollFaceToWatchlists",
          enrollmentPayload
        );
      } else if (props.elementWatchlistType === "text") {
        const enrollmentPayload = {
          watchlistTargets: watchlistEnrollTargets.value,
          watchlistedLicensePlate: lprUpdate.value
        };
        responseData = await store.dispatch(
          "watchlists/enrollLicensePlateToWatchlists",
          enrollmentPayload
        );
      }
      if (responseData && responseData.status == "success") {
        actionSuccess.value = true;
      } else {
        actionFailed.value = true;
        btnDisabled.value = false;
      }
      isWorking.value = false;
    }

    async function moveWatchlistedElement() {
      actionSuccess.value = false;
      actionFailed.value = false;
      isWorking.value = true;
      btnDisabled.value = true;
      const destinationWatchlistId = watchlistMoveTarget.value;
      const destinationWatchlistName = getWatchlistNameFromId(
        destinationWatchlistId
      );
      const movePayload = {
        watchlistedElementId: props.moveWatchlistedElementId,
        watchlistDestination: destinationWatchlistId,
        watchlistType: props.elementWatchlistType
      };
      const responseData = await store.dispatch(
        "watchlists/moveWatchlistedElement",
        movePayload
      );
      if (responseData && responseData.status == "success") {
        actionSuccess.value = true;
        context.emit("elementMoved", props.moveWatchlistedElementId, "moved");
      } else {
        actionFailed.value = true;
        btnDisabled.value = false;
      }
      isWorking.value = false;
    }

    function getWatchlistNameFromId(watchlistId) {
      let watchlistName = "";
      const watchlistList = store.getters["watchlists/watchlists"];
      watchlistList.forEach((watchlist) => {
        if (watchlist._id == watchlistId) {
          watchlistName = watchlist.name;
        }
      });
      return watchlistName;
    }

    const buttonText = computed(() => {
      if (props.mode == "move") {
        return "Save";
      } /*if (props.mode == 'enroll')*/ else {
        return "Enroll";
      }
    });

    const watchlistSelectorLabel = computed(() => {
      if (props.mode == "move") {
        return "Watchlist";
      } /*if (props.mode == 'enroll')*/ else {
        return "Target Watchlist(s)";
      }
    });

    const watchlistSelectorHint = computed(() => {
      if (props.mode == "move") {
        return "watchlist";
      } /*if (props.mode == 'enroll')*/ else {
        return "watchlist(s)";
      }
    });

    const failedMsg = computed(() => {
      if (props.mode == "move") {
        return "Move Failed";
      } /*if (props.mode == 'enroll')*/ else {
        return "Enrollment Failed";
      }
    });

    function updateLpr(lprObject) {
      lprUpdate.value = lprObject;
    }

    return {
      fetchEnrollWatchlists,
      watchlistEnrollTargets,
      updatedSelectedEnrollWatchlists,
      updatedSelectedMoveWatchlist,
      enroll,
      isWorking,
      buttonText,
      btnDisabled,
      actionSuccess,
      actionFailed,
      moveWatchlistedElement,
      performAction,
      watchlistSelectorLabel,
      watchlistSelectorHint,
      watchlistMoveTarget,
      fetchMoveWatchlists,
      failedMsg,
      moveCurrentWatchlist,
      firstNameUpdate,
      lastNameUpdate,
      updateLpr
    };
  }
};
</script>

<style scoped lang="scss">
.container {
  display: flex;
  justify-content: flex-start; //previously was space betwewen
  padding: 0;
}

.fieldInput {
  border: 0px;
  width: 95%;
  @include overwatch-body-small;
}

.fieldTitle {
  float: top;
  @include overwatch-body-med;
}

.fieldRectangle {
  float: left;
  height: 35px;
  width: 100%;
  border: 1px solid var(--overwatch-neutral-300);
  background-color: var(--overwatch-neutral-500);
  border-radius: 4px;
  font-size: 14px;
  padding-left: 2px;
  margin-bottom: 4px;
}

.watchlistSelectMulti {
  /* This position: fixed allows for overflow, but I will remove it for now and try to refactor 
  Base Dialog so that it handles overflow better - Sean */

  /* position: fixed; */
  width: 100% !important;
  max-height: 200px;
  margin-top: 5px !important;
  z-index: 100;
  max-width: 340px !important;
}

.msgStatus {
  @include overwatch-body-small;
  margin-top: 10px;
  margin-right: 10px;
}

.all-input-div {
  margin-left: 1rem;
}

@media (max-width: 480px) {
  .all-input-div {
    margin-left: 0;
  }
  .container {
    flex-direction: column;
    height: auto;
    margin-bottom: 1rem;
  }

  .desktop-margin {
    margin-left: 0;
  }
}
</style>

<style>
@media (max-width: 480px) {
  .watchlistSelectMulti {
    /* This position: fixed allows for overflow, but I will remove it for now and try to refactor 
      Base Dialog so that it handles overflow better - Sean */
    position: relative !important;
  }

  dialog .content {
    overflow: visible !important;
  }
}
</style>
