<template>
  <div class="case-management-page">
    <div class="overwatch-title-large title">
      {{ $store.state.settings.appTerms.Mission }} Dashboard
      <MDBDropdown v-model="isShowingCreateMenu" v-if="isAdminOrPowerUser">
        <RocDropdownToggle
          @click="isShowingCreateMenu = !isShowingCreateMenu"
          style="line-height: unset;"
        >
          <RocButton size="mega">
            Create
          </RocButton>
        </RocDropdownToggle>
        <RocDropdownMenu @click="isShowingCreateMenu = false">
          <RocDropdownItem @click="isShowingCreateLiveMission = true">
            Live
          </RocDropdownItem>
          <RocDropdownItem
            v-if="!isMobile"
            @click="
              showImporter();
              isShowingCreateMenu = false;
            "
          >
            Post-Event
          </RocDropdownItem>
        </RocDropdownMenu>
      </MDBDropdown>
    </div>

    <div class="sorting-filters">
      <div class="active-mission">
        <div style="display: flex; align-items: center; gap: var(--spacing-s);">
          <div>Active {{ $store.state.settings.appTerms.Mission }}</div>
          <RocPopper arrow hover popperType="tooltip" placement="bottom">
            <RocIcon color="primary" size="sm" icon="tooltip" />
            <template #content>
              The Active {{ $store.state.settings.appTerms.Mission }}'s
              associated cameras will be applied to the Encounters and Cameras
              pages.
            </template>
          </RocPopper>
        </div>
        <RocSelect
          class="mission-select"
          id="MissionSelect"
          :currentlySelected="currentActiveMission?._id"
          :availableOptions="activeMissionOptions"
          @selection-changed="setActiveMissionId"
          optionLabel="name"
          optionValue="value"
          :placeholder="`Active ${store.state.settings.appTerms.Mission}`"
        />
      </div>
      <RocDashboardFilters
        @text-filter-changed="textFilterChanged"
        @sort-changed="sortChanged"
        @filter-changed="filterChanged"
        :hide-filter="currentlySelectedTable === 'live'"
      />
    </div>

    <RocTabs
      class="table-tabs"
      :options="tableTabOptions"
      v-model="currentlySelectedTable"
    />

    <RocTable
      templateColumns="repeat(2, 1fr)"
      class="datatable"
      v-if="currentlySelectedTable === 'live'"
    >
      <template #header>
        <div>
          <span>Name</span>
          <span># Cameras</span>
        </div>
      </template>
      <template #entries>
        <LiveMissionCard
          class="centralize"
          v-for="c in liveMissions"
          :key="c._id"
          :case="c"
          @edit-case="handleEditCase(c)"
        />
      </template>
    </RocTable>

    <RocTable
      :templateColumns="isMobile ? 'repeat(2, 1fr)' : 'repeat(6, 1fr)'"
      class="datatable"
      v-if="currentlySelectedTable === 'postevent'"
    >
      <template #header>
        <div>
          <span>Name</span>
          <span v-if="!isMobile"># Cameras</span>
          <span v-if="!isMobile"># Files</span>
          <span v-if="!isMobile">Progress</span>
          <span v-if="!isMobile"># Watchlists</span>
          <span>Clustering</span>
        </div>
      </template>
      <template #entries>
        <PostEventMissionCard
          class="centralize"
          v-for="(c, i) in postEventMissions"
          :key="c._id"
          :case="c"
          @edit-case="handleEditCase(c)"
          :trigger="triggers[i]"
        />
      </template>
    </RocTable>

    <BaseDialog
      v-if="isShowingEditCase"
      show
      @close="isShowingEditCase = false"
      title="Edit Case"
      :style="computedDialogStyle"
      noPadding
    >
      <CaseEditDetails
        :case="editingCase"
        @close="isShowingEditCase = false"
        @delete-camera="
          triggerCaseCardUpdate(
            postEventMissions.findIndex((c) => c._id === editingCase._id)
          )
        "
      />
    </BaseDialog>
    <BaseDialog
      :show="isShowingCreateLiveMission"
      @close="isShowingCreateLiveMission = false"
      :title="`Create Live ${store.state.settings.appTerms.Mission}`"
      :style="computedDialogStyle"
      noPadding
    >
      <CreateLiveCaseDialog
        @close="isShowingCreateLiveMission = false"
        @save="handleLiveCaseCreation"
      />
    </BaseDialog>
    <RocToast
      v-if="isShowingToast"
      @autoClose="isShowingToast = false"
      :message="
        `Live ${store.state.settings.appTerms.Mission} created successfully.`
      "
      :timeout="1000"
    />
  </div>
</template>

<script setup>
import { ref, computed, reactive, watch, onMounted } from "vue";
import { useStore } from "vuex";

import RocButton from "@/components/ui/RocButton";
import BaseDialog from "@/components/ui/BaseDialog";
import CaseEditDetails from "@/components/cases/CaseEditDetails";
import RocTable from "@/components/ui/RocTable";
import LiveMissionCard from "@/components/cases/LiveMissionCard.vue";
import PostEventMissionCard from "@/components/cases/PostEventMissionCard.vue";
import RocSelect from "@/components/ui/RocSelect.vue";
import RocIcon from "@/components/ui/RocIcon.vue";
import RocDashboardFilters from "@/components/ui/RocDashboardFilters.vue";

import { MDBDropdown } from "mdb-vue-ui-kit";
import RocDropdownToggle from "@/components/ui/RocDropdownToggle";
import RocDropdownMenu from "@/components/ui/RocDropdownMenu";
import RocDropdownItem from "@/components/ui/RocDropdownItem";
import CreateLiveCaseDialog from "@/components/cases/CreateLiveCaseDialog.vue";

import RocToast from "@/components/ui/RocToast";
import RocTabs from "@/components/ui/RocTabs";
import RocPopper from "@/components/ui/RocPopper";

import userAccessHelper from "@/js/userAccessHelper";

const store = useStore();

const cases = computed(() => {
  return store.getters["cases/cases"];
});

const isShowingCreateMenu = ref(false);
const isShowingCreateLiveMission = ref(false);

const activeMissionOptions = computed(() => {
  const output = [];

  // Checking for cases with no type here for back compatibility
  const validCases = cases.value.filter(
    (c) => c.type === "live" || c.type === "postevent" || !c.type
  );
  for (let c of validCases) {
    output.push({
      name: c.name,
      value: c._id
    });
  }
  return output;
});

function setActiveMissionId(id) {
  const activeMissionId = store.getters["cases/activeMissionId"];
  if (activeMissionId !== null) {
    store.commit("cases/setPreviousMissionId", activeMissionId);
  }
  store.commit("cases/setActiveMissionId", id);
  store.commit("auth/setUserSettingsChanged", Date.now());
}

const currentActiveMission = computed(
  () => store.getters["cases/activeMission"]
);

function showImporter() {
  store.commit("cases/setEditingCase", null);
  store.commit("cases/setImporterVisible", true);
}

const editingCase = ref();
const isShowingEditCase = ref(false);

function handleEditCase(c) {
  editingCase.value = c;
  isShowingEditCase.value = true;
}

const triggers = ref([]);
watch(cases, (nv) => {
  triggers.value = nv.map(() => 0);
});

function triggerCaseCardUpdate(i) {
  triggers.value[i]++;
}

onMounted(async () => {
  await getCaseStatuses();

  if (store.getters["watchlists/watchlists"].length === 0) {
    await store.dispatch("watchlists/loadWatchlists");
  }
});

/** Filters */

const searchFilterText = ref("");

function textFilterChanged(text) {
  searchFilterText.value = text;
}

const currentlySelectedSort = ref("");

function sortChanged(sort) {
  currentlySelectedSort.value = sort;
}

const currentlySelectedFilter = ref("");

async function filterChanged(filter) {
  currentlySelectedFilter.value = filter;

  // Calling get case statuses here so that
  // we can receive case statuses before
  // filtering on them
  await getCaseStatuses();
}

const tableTabOptions = ref([
  {
    label: `Live ${store.state.settings.appTerms.Mission}s`,
    value: "live"
  },
  {
    label: `Post Event ${store.state.settings.appTerms.Mission}s`,
    value: "postevent"
  }
]);

const liveMissions = computed(() => {
  var currentCases = cases.value.filter((c) => c.type === "live");

  /**
   * Sorting:
   * - newest
   * - oldest
   * - alpha
   * - reverse-alpha
   */
  var sorter;
  switch (currentlySelectedSort.value) {
    case "newest":
      sorter = (a, b) => {
        return a.createdAt > b.createdAt ? -1 : 1;
      };
      break;
    case "oldest":
      sorter = (a, b) => {
        return b.createdAt > a.createdAt ? -1 : 1;
      };
      break;
    case "alpha":
      sorter = (a, b) => {
        return b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 1;
      };
      break;
    case "reverse-alpha":
      sorter = (a, b) => {
        return a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 1;
      };
      break;
    default:
      sorter = null;
  }
  if (sorter) {
    currentCases.sort(sorter);
  } else {
    currentCases.sort();
  }

  // Place imported case at top.
  currentCases.sort((a, b) => (a.imported ? -1 : 1));

  /**
   * Text filtering
   */
  return searchFilterText.value && currentCases
    ? currentCases.filter((c) =>
        c.name.toLowerCase().includes(searchFilterText.value.toLowerCase())
      )
    : currentCases;
});

const postEventMissions = computed(() => {
  // Only show cases that have type 'postevent' or no type (previous versions)
  var currentCases = cases.value.filter(
    (c) => c.type === "postevent" || !c.type
  );

  /**
   * Sorting:
   * - newest
   * - oldest
   * - alpha
   * - reverse-alpha
   */
  var sorter;
  switch (currentlySelectedSort.value) {
    case "newest":
      sorter = (a, b) => {
        return a.createdAt > b.createdAt ? -1 : 1;
      };
      break;
    case "oldest":
      sorter = (a, b) => {
        return b.createdAt > a.createdAt ? -1 : 1;
      };
      break;
    case "alpha":
      sorter = (a, b) => {
        return b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 1;
      };
      break;
    case "reverse-alpha":
      sorter = (a, b) => {
        return a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 1;
      };
      break;
    default:
      sorter = null;
  }
  if (sorter) {
    currentCases.sort(sorter);
  } else {
    currentCases.sort();
  }

  /**
   * Filtering:
   * - all
   * - complete
   * - in progress
   */
  var filterer;
  switch (currentlySelectedFilter.value) {
    case "all":
      break;
    case "complete":
      filterer = (c) => {
        return isCaseFinished(c._id);
      };
      break;
    case "in progress":
      filterer = (c) => {
        return !isCaseFinished(c._id);
      };
  }
  if (filterer) {
    currentCases = currentCases.filter(filterer);
  }

  /**
   * Text filtering
   */
  return searchFilterText.value && currentCases
    ? currentCases.filter((c) =>
        c.name.toLowerCase().includes(searchFilterText.value.toLowerCase())
      )
    : currentCases;
});

const caseStatuses = reactive({});

async function getCaseStatuses() {
  // Get media statuses for all cases.
  // Store in caseStatuses object.
  for (let c of cases.value) {
    const response = await store.dispatch("cases/getCaseStatus", {
      caseId: c._id
    });
    if (response.status === "success") {
      caseStatuses[c._id] = response.result;
    }
  }
}

function isCaseFinished(id) {
  // Count the media statuses of the given case.
  // If media that are completed or errored out equal entire media count,
  // consider it finished.
  const media = caseStatuses[id] ? caseStatuses[id] : [];

  var completeCount = 0;
  var mediaCount = 0;
  for (let m of media) {
    mediaCount++;
    if (m.status === "completed" || m.status === "error") {
      completeCount++;
    }
  }

  return completeCount === mediaCount;
}

const isShowingToast = ref(false);

const currentlySelectedTable = ref(store.getters["cases/selectedTable"]);
watch(currentlySelectedTable, (nv) => {
  store.commit("cases/setSelectedTable", nv);
});

const isAdminOrPowerUser = computed(() => {
  const helper = new userAccessHelper();
  return helper.isAdminOrPowerUser();
});

function handleLiveCaseCreation(c) {
  isShowingToast.value = true;
}

const windowWidth = ref(window.innerWidth);
onMounted(() => {
  window.addEventListener("resize", () => {
    windowWidth.value = window.innerWidth;
  });
});

const isMobile = computed(() => {
  return windowWidth.value <= 480;
});

const computedDialogStyle = computed(() => {
  if (isMobile.value) {
    return {
      width: "90dvw"
    };
  } else {
    return {
      width: "500px"
    };
  }
});
</script>

<style scoped lang="scss">
.case-management-page {
  padding: var(--spacing-xl) var(--spacing-xxl);
  background-color: var(--roc-global-light-background);
}

.title {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-xl);
  align-items: center;
}

.sorting-filters {
  display: flex;
  height: 45px;
  width: 100%;
  justify-content: space-between;
  margin-top: $--spacing-s;
}

.active-mission {
  display: flex;
  align-items: center;
  min-height: 100%;
  height: auto;
  gap: $--spacing-s;
}

.active-mission .mission-select {
  height: 100%;
  width: 350px;
}

.datatable {
  margin-top: $--spacing-s;
}

.dashboard {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(6, 1fr);
  justify-items: stretch;
  margin-top: var(--spacing-s);
}

.dashboard * span:first-child {
  padding-left: var(--spacing-l);
}

.dashboard * span:last-child {
  padding-right: var(--spacing-l);
}

.table-tabs {
  margin-top: var(--spacing-m);
}

@media (max-width: 480px) {
  .case-management-page {
    padding: var(--spacing-xs);
  }

  .title {
    flex-direction: column;
    align-items: flex-start;
    margin-top: var(--spacing-m);
    gap: var(--spacing-s);
  }
  .active-mission {
    flex-direction: column;
    align-items: flex-start;
  }
  .sorting-filters {
    height: fit-content;
    flex-direction: column;
    gap: var(--spacing-l);
  }
}
</style>
