<script setup>
import { ref, watch, onMounted, computed, defineExpose } from "vue";
import { useStore } from "vuex";
import filterMultiSelect from "@/components/ui/filterMultiSelect";
import RocButton from "@/components/ui/RocButton";
import { MDBDropdown } from "mdb-vue-ui-kit";
import RocIcon from "@/components/ui/RocIcon.vue";
import RocDropdownMenu from "@/components/ui/RocDropdownMenu.vue";
import RocDropdownToggle from "@/components/ui/RocDropdownToggle.vue";
import RocDropdownItem from "@/components/ui/RocDropdownItem.vue";
import userAccessHelper from "@/js/userAccessHelper";

const store = useStore();

const props = defineProps({
  watchlistId: { type: String },
  userAccessList: { type: Array, default: [] },
  restricted: { type: Boolean },
  showRestricted: { type: Boolean },
  isEditing: { type: Boolean }
});

const emits = defineEmits([
  "update:userAccessList",
  "update:restricted",
  "close"
]);

const roles = ref([]);
const editPermissions = ref([]);
const selectedEditPermissions = ref(
  props?.userAccessList.reduce((a, c) => {
    a.push(c.id);
    return a;
  }, [])
); // { type: 'user', name: 'Roc User', email: 'roc.user@rankone.io', role: 'admin', id: '', }, { type: 'group', name: 'System', users: [], id: '' }, ...
const userAccessList = ref(props?.userAccessList);
const restricted = ref(props?.restricted ?? false);
const showingRestricted = ref(props?.showRestricted ?? false);

defineExpose({ clear });

function clear() {
  selectedEditPermissions.value = [];
  userAccessList.value = [];
  restricted.value = false;
}

async function loadUserAccess() {
  const response = await store.dispatch("settings/getUserAccess");
  if (response && response.userAccess) {
    roles.value = response?.userAccess ?? [];
    roles.value.map((item) => (item.value = item?._id));
  }
}

async function getUsers() {
  const response = await store.dispatch("settings/getUsers");
  return response?.users ?? [];
}

async function initEditPermissions() {
  const users = (await getUsers()).filter(
    (user) => user?.enabled === true && user?._id !== currentUser?.id
  );
  const userGroups = store.getters["settings/userGroups"].filter(
    (group) => group?.enabled === true
  );

  const usersFmt = users.reduce((accum, curr) => {
    accum.push({
      type: "user",
      id: curr._id,
      value: curr._id,
      userAccessId: curr._userAccessId,
      label: curr.email,
      firstname: curr.firstname,
      lastname: curr.lastname,
      email: curr.email
    });
    return accum;
  }, []);

  const userGroupsFmt = userGroups.reduce((accum, curr) => {
    const users = curr?.users.reduce((a, c) => {
      a.push(c?._id);
      return a;
    }, []);
    accum.push({
      type: "group",
      id: curr._id,
      value: curr._id,
      label: curr.label,
      users: users
    });
    return accum;
  }, []);

  editPermissions.value = [...usersFmt, ...userGroupsFmt];

  if (!props.isEditing) {
    selectedEditPermissions.value.push(currentUser.value.id);
    userAccessList.value.push(currentUser.value);
  }

  if (props.isEditing && userAccessList.value?.length < 1) {
    selectedEditPermissions.value.push(currentUser.value.id);
    userAccessList.value.push(currentUser.value);
  }
}

function removeUserAccess(user, index) {
  userAccessList.value.splice(index, 1);
  emits("update:userAccessList", userAccessList.value);
  selectedEditPermissions.value.splice(
    selectedEditPermissions.value.indexOf(user.id),
    1
  );
}

function lookupUserRoleString(user) {
  const role =
    roles.value.filter((item) => item?._id === user.userAccessId)[0]?.role ??
    "Admin";
  return role.charAt(0).toUpperCase() + role.slice(1);
}

function updateSelectedEditPermissions(permId) {
  const tmp = [];
  for (let i = 0; i < editPermissions.value.length; i++) {
    const elem = editPermissions.value[i];
    if (permId.value.includes(elem.id)) {
      tmp.push(elem);
    }
  }
  if (!permId.value.includes(currentUser.value.id)) {
    permId.value.push(currentUser.value.id);
    tmp.push(currentUser.value);
  }
  userAccessList.value = tmp;
  selectedEditPermissions.value = permId.value;
  emits("update:userAccessList", userAccessList.value);
}

function getCurrentUser() {
  const userAccessCookie = new userAccessHelper().parseUserAccessCookie();
  return {
    type: "user",
    id: store.getters["auth/userId"],
    value: store.getters["auth/userId"],
    label: store.getters["auth/email"],
    firstname: store.getters["auth/firstname"],
    lastname: store.getters["auth/lastname"],
    name: `${store.getters["auth/firstname"]} ${store.getters["auth/lastname"]}`,
    email: store.getters["auth/email"],
    initials: store.getters["auth/userInitials"],
    role: userAccessCookie?.role
  };
}

// const currentUser = computed(() => getCurrentUser());
const currentUser = ref(getCurrentUser());

function handleRestrictedChange(val) {
  currentUser.value = getCurrentUser();
  showingRestricted.value = !showingRestricted.value;
  restricted.value = val;
  emits("update:restricted", val);
}

onMounted(async () => {
  await loadUserAccess();
  await initEditPermissions();
});
</script>

<template>
  <div class="editWatchlistContainer">
    <div>
      <div class="titleGroup" style="justify-content: flex-start">
        <RocButton
          type="tertiary"
          floating
          style="box-shadow: none !important"
          @click="emits('close', currentUser)"
        >
          <RocIcon icon="backArrow" size="sm" />
        </RocButton>
        <div class="inputTitle">Edit Permissions</div>
      </div>

      <p style="text-indent: var(--spacing-xxl)">
        Allow selected users to edit the watchlist
      </p>
    </div>

    <div style="margin: var(--spacing-xl) var(--spacing-m)">
      <span class="inputTitle">General Access</span>
      <template v-if="!restricted">
        <div class="titleGroup" style="justify-content: flex-start">
          <RocIcon
            style="
              margin: var(--spacing-xs) var(--spacing-m);
              color: var(--overwatch-success);
            "
            icon="global"
            size="lg"
          />

          <MDBDropdown class="dropdownContainer" v-model="showingRestricted">
            <RocDropdownToggle @click="showingRestricted = !showingRestricted">
              <span class="inputTitle" style="cursor: pointer;">All Users</span>
              <RocIcon
                :style="{
                  transform: showingRestricted
                    ? 'rotate(180deg)'
                    : 'rotate(0deg)'
                }"
                icon="inputArrow"
                size="sm"
              />
            </RocDropdownToggle>
            <RocDropdownMenu>
              <RocDropdownItem @click="() => handleRestrictedChange(false)">
                All Users
              </RocDropdownItem>
              <RocDropdownItem @click="() => handleRestrictedChange(true)">
                Restricted
              </RocDropdownItem>
            </RocDropdownMenu>
          </MDBDropdown>
        </div>
        <p style="text-indent: var(--spacing-xxl)">
          All users can edit the watchlist
        </p>
      </template>
      <template v-else>
        <div class="titleGroup" style="justify-content: flex-start">
          <RocIcon
            style="margin: var(--spacing-xs) var(--spacing-m)"
            icon="lock"
            size="lg"
          />

          <MDBDropdown class="dropdownContainer" v-model="showingRestricted">
            <RocDropdownToggle @click="showingRestricted = !showingRestricted">
              <span class="inputTitle" style="cursor: pointer;"
                >Restricted</span
              >
              <RocIcon
                :style="{
                  transform: showingRestricted
                    ? 'rotate(180deg)'
                    : 'rotate(0deg)'
                }"
                icon="inputArrow"
                size="sm"
              />
            </RocDropdownToggle>
            <RocDropdownMenu>
              <RocDropdownItem @click="() => handleRestrictedChange(false)">
                All Users
              </RocDropdownItem>
              <RocDropdownItem @click="() => handleRestrictedChange(true)">
                Restricted
              </RocDropdownItem>
            </RocDropdownMenu>
          </MDBDropdown>
        </div>
        <p style="text-indent: var(--spacing-xxl)">
          Only users added can edit the watchlist
        </p>
      </template>
    </div>

    <div style="margin: var(--spacing-xl) var(--spacing-m);">
      <div class="inputTitle">User or User Group</div>
      <filterMultiSelect
        mode="tags"
        :close-on-select="false"
        placeholder-text="Add user or user group to be able to edit"
        :available-items="editPermissions"
        :currently-selected="selectedEditPermissions"
        @selection-changed="updateSelectedEditPermissions"
        :enableClear="false"
        :disabled="!restricted"
      />
    </div>

    <div class="inputTitle" style="margin: var(--spacing-m);">
      Users With Access
    </div>
    <div class="accessUsersContainer">
      <div class="accessUsers" v-for="(usr, index) in userAccessList">
        <template v-if="usr.type === 'user'">
          <div>
            <div class="nameCircle">
              {{
                `${usr?.firstname[0]?.toUpperCase() ??
                  "R"}${usr?.lastname[0]?.toUpperCase() ?? "U"}`
              }}
            </div>
          </div>
          <div class="user">
            {{ `${usr?.firstname ?? ""} ${usr?.lastname ?? ""}` }}
            <br />
            {{ usr?.email }}
          </div>
          <div>
            {{ lookupUserRoleString(usr) }}
          </div>
          <div>
            <div class="pill">
              <span
                class="overwatch-title-xsmall d-flex"
                style="gap: var(--spacing-base)"
              >
                Can Edit
              </span>
            </div>
          </div>
          <div>
            <RocButton
              @click="removeUserAccess(usr, index)"
              size="sm"
              style="background-color: transparent; box-shadow: none;"
              floating
              v-if="usr?.id !== currentUser?.id"
            >
              <RocIcon
                icon="trash"
                size="sm"
                style="color: var(--overwatch-error)"
              />
            </RocButton>
          </div>
        </template>
        <template v-else>
          <div>
            <div class="nameCircle">{{ usr?.label[0] ?? "S" }}</div>
          </div>
          <div class="user">
            {{ `${usr?.label ?? ""}` }}
            <br />
            {{ `Users: ${usr?.users?.length ?? 0}` }}
          </div>
          <div>Admin</div>
          <div>
            <div class="pill">
              <span
                class="overwatch-title-xsmall d-flex"
                style="gap: var(--spacing-base)"
              >
                Can Edit
              </span>
            </div>
          </div>
          <div>
            <RocButton
              @click="removeUserAccess(usr, index)"
              style="background-color: transparent; box-shadow: none;"
              size="sm"
            >
              <RocIcon
                icon="trash"
                size="sm"
                style="color: var(--overwatch-error)"
              />
            </RocButton>
          </div>
        </template>
      </div>
    </div>

    <div
      style="margin: var(--spacing-xl) var(--spacing-m); display: flex; justify-content: flex-end"
    >
      <RocButton @click="emits('close', currentUser)">Done</RocButton>
    </div>
  </div>
</template>

<style scoped lang="scss">
.pill {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 48px;
  border: 1px solid var(--overwatch-success);
  background-color: var(--overwatch-success-20);
  color: var(--overwatch-success);
  height: 26px;
  gap: 4px;
  width: 82px;
}

.nameCircle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  font-size: 14px;
  color: var(--overwatch-button-text);
  background: var(--overwatch-primary);

  padding-left: 0;
  padding-right: 0;
  box-shadow: none;
}

.watchlist-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include overwatch-body-med;
  gap: var(--spacing-l);
}

.fieldCounter {
  display: flex;
  justify-content: end;
  color: var(--overwatch-neutral-300);
  font-size: 12px;
  margin-bottom: 0;
}

.inputTitle {
  @include overwatch-body-large;
  margin-bottom: 4px;
  margin-top: 12px;
  text-align: start;
}

.buttonGroups {
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-m);
}

.titleGroup {
  background: var(--overwatch-secondary);
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: -2%;
}

.watchlistAddEdit {
  overflow: auto;
  position: absolute;
  min-width: 750px;
  max-height: 91vh;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  z-index: 2;
  border: 1px solid var(--overwatch-neutral-300);
  background: var(--overwatch-secondary);
  padding: 12px;
}

.watchlistUpload {
  min-height: 200px;
  display: grid;
}

.accessUsers {
  display: grid;
  grid-template-columns: 7% 50% 20% 13% 10%;
  margin: 1rem;
}

.accessUsers > div:not(.user) {
  margin: auto;
  text-align: center;
}

@media (max-width: 480px) {
  .watchlistAddEdit {
    min-width: 480px;
  }
}

.dropdown-toggle > span {
  margin-right: var(--spacing-xs);
  color: var(--overwatch-neutral-100);
  position: relative;
}

.editPermissions {
  gap: var(--spacing-xs);
  color: var(--overwatch-primary);
  text-decoration: underline;
  align-items: center;
  cursor: pointer;
}

.editWatchlistContainer {
  position: absolute;
  min-width: 750px;
  max-height: 91vh;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  z-index: 2;
  border: 1px solid var(--overwatch-neutral-300);
  background: var(--overwatch-secondary);
  padding: 12px;
  overflow: auto;
}

.accessUsersContainer {
  margin: var(--spacing-m);
  max-height: 300px;
  overflow: scroll;
}
</style>
