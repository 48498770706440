<script setup>
import { ref } from 'vue';
import filterMultiSelect from '@/components/ui/filterMultiSelect';
import FileSelector from '@/components/cases/FileSelector.vue';
import RocTimezoneSingleSelect from '@/components/ui/RocTimezoneSingleSelect';
import RocIcon from '@/components/ui/RocIcon';
import RocPopper from '@/components/ui/RocPopper';

const props = defineProps();
const emit = defineEmits(['change-analytics', 'change-files', 'change-timezone']);

/** Analytics */
const selectedAnalytics = ref([]);
const availableAnalytics = ref({
  'Face': 'face',
  'Pedestrian': 'pedestrian',
  'Gun': 'gun',
  'License Plate': 'lpr',
  'Vehicle': 'vehicle',
  'OCR In-The-Wild': 'ocr',
  'Tattoo': 'tattoo'
});
function handleAnalyticsSelection(newSelections) {
  selectedAnalytics.value = newSelections.value;
  emit('change-analytics', newSelections.value);
}

/** File Selector */
const files = ref([]);
function handleNewFiles(newFiles) {
  files.value = newFiles;
  emit('change-files', newFiles);
}

function handleTimezone(offset) {
  emit('change-timezone', offset);
}

</script>

<template>
  <div class="select-files">
    <div class="input-row">
      <div style="display: flex; align-items: center; gap: var(--spacing-base)">
        Default Timezone
        <RocPopper arrow hover placement="bottom" :popperType="'tooltip'" :locked="true" >
          <RocIcon size="sm" color="primary" icon="tooltip" />
            <template #content>
              Select a default timezone to apply to all files that did not have a timezone parsed.
              No timezone selection will default to local timezone.
            </template>
        </RocPopper>
      </div>
      <RocTimezoneSingleSelect class="timezone-select" @selection-changed="handleTimezone"/>
    </div>
    <div class="input-row">
      <div>Analytics</div>
      <filterMultiSelect
        style="height: 100% !important"
        mode="tags"
        :close-on-select="false"
        no-results-text=""
        placeholder-text="Select Analytics"
        :available-items="Object.keys(availableAnalytics)"
        :currently-selected="selectedAnalytics"
        @selection-changed="handleAnalyticsSelection"
      />
    </div>
    <div style="height: 100%; flex: 1; margin-top: var(--spacing-s);">
      <FileSelector style="height: 100%;" @change="handleNewFiles"/>
    </div>
  </div>
</template>

<style scoped lang="scss">
.select-files {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.input-row {
  width: 100%;
  margin-bottom: var(--spacing-base);
}
.timezone-select {
  padding: var(--spacing-xs);
  flex: 1;
}

</style>