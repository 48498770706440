<template>
  <div class="notes-dialog">
    <span class="overwatch-title-large"> {{ cluster.name }}</span>

    <div>
      <div>Notes</div>
      <textarea
        v-model="notes"
        placeholder="Enter notes about cluster"
        cols="50"
        rows="10"
        class="text-area"
      ></textarea>
    </div>

    <div class="button-div">
      <RocButton type="secondary" @click="$emit('close')">Cancel</RocButton>
      <RocButton @click="saveNotes()">Save</RocButton>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import RocButton from "@/components/ui/RocButton";
import RocInput from "@/components/ui/RocInput";

export default {
  name: "EditClusterNotes",
  props: {
    cluster: {
      type: Object,
      required: true
    }
  },
  emits: ["close"],
  components: {
    RocButton,
    RocInput
  },
  setup(props, context) {
    const store = useStore();

    const notes = ref(props.cluster.notes);

    async function saveNotes() {
      const response = await store.dispatch("clusters/updateCluster", {
        id: props.cluster._id,
        update: {
          notes: notes.value
        }
      });
      context.emit("close");
    }

    return {
      notes,
      saveNotes
    };
  }
};
</script>

<style scoped lang="scss">
.notes-dialog {
  @include overwatch-body-med;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-m);
}

.button-div {
  margin-top: $--spacing-s;

  display: flex;

  gap: $--spacing-base;

  justify-content: flex-end;
}

textarea {
  resize: none;
  border-radius: 5px;
  border: 1px solid black;
  margin-top: var(--spacing-s);

  padding: var(--spacing-s);
}
</style>
