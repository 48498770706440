<template>
  <div class="page">
    <!-- Nav bar -->
    <nav class="stick-to-top">
      <RocIcon
        color="black"
        size="sm"
        icon="backArrow"
        style="cursor: pointer;"
        @click="handleBackClick"
      />
      <span class="case-title">{{ computedMissionName }}</span>
      <span class="case-datetime"> {{ computedDaterangeString }} </span>

      <span class="local-timezone"
        >Current Timezone: {{ computedOffsetString }}</span
      >
      <div
        class="d-flex align-items-center"
        style="margin-left: auto"
        v-if="associatesTargetCluster && isAdminOrPowerUser"
      >
        <MDBDropdown v-model="isExportOptionsMenuOpen">
          <RocDropdownToggle
            @click="isExportOptionsMenuOpen = !isExportOptionsMenuOpen"
          >
            <RocButton
              type="menu"
              class="d-flex align-items-center"
              style="padding: var(--spacing-s)"
            >
              <RocIcon
                icon="upload"
                size="sm"
                color="black"
                style="margin-right: var(--spacing-s)"
              />
              Export Options
            </RocButton>
          </RocDropdownToggle>
          <RocDropdownMenu>
            <RocDropdownItem
              @click.stop="
                isExportOptionsMenuOpen = false;
                isExportPopupOpen = true;
              "
            >
              Export as CSV
            </RocDropdownItem>
          </RocDropdownMenu>
        </MDBDropdown>
      </div>
    </nav>

    <RouterView v-slot="{ Component }">
      <component :is="Component" :caseId="props.caseId" />
    </RouterView>

    <BaseDialog
      hideCloseBtn
      :show="isExportPopupOpen"
      @close="isExportPopupOpen = false"
    >
      <ClusterExport
        :caseId="caseId"
        :targetClusterId="associatesTargetCluster?._id"
        @close="isExportPopupOpen = false"
      />
    </BaseDialog>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import { RouterView, useRouter } from "vue-router";
import RocIcon from "@/components/ui/RocIcon";
import moment from "moment";
import userAccessHelper from "@/js/userAccessHelper";
import { MDBDropdown } from "mdb-vue-ui-kit";
import RocButton from "@/components/ui/RocButton.vue";
import ClusterExport from "@/components/clusters/ClusterExport.vue";
import RocDropdownMenu from "@/components/ui/RocDropdownMenu.vue";
import RocDropdownToggle from "@/components/ui/RocDropdownToggle.vue";
import RocDropdownItem from "@/components/ui/RocDropdownItem.vue";
import BaseDialog from "@/components/ui/BaseDialog.vue";
import DateHelper from "@/js/dateHelper";

const dh = new DateHelper();

const store = useStore();
const router = useRouter();

const props = defineProps({
  caseId: {
    type: String,
    required: true
  }
});

const mission = ref();

const associatesTargetCluster = computed(
  () => store.getters["clusters/associatesTargetCluster"]
);

const isAdminOrPowerUser = computed(() => {
  const helper = new userAccessHelper();
  return helper.isAdminOrPowerUser();
});

const isExportPopupOpen = ref(false);
const isExportOptionsMenuOpen = ref(false);

const computedDaterangeString = computed(() => {
  if (mission.value) {
    const start = moment(new Date(mission.value.startTimestamp));
    const end = moment(new Date(mission.value.endTimestamp));

    const formattedStart = start.format("MMM DD, yyyy HH:mm:ss");
    const formattedEnd = end.format("MMM DD, yyyy HH:mm:ss");

    return `${formattedStart} - ${formattedEnd}`;
  } else {
    return "";
  }
});

const computedMissionName = computed(() => {
  return mission.value ? mission.value.name : "Loading.";
});

onMounted(async () => {
  const response = await store.dispatch("cases/getCaseById", props.caseId);
  if (response.status === "success") {
    mission.value = response.result;
    store.commit("clusters/setMission", mission.value);
  }
});

async function handleBackClick() {
  router.back();
}

const computedOffsetString = computed(() => {
  return `GMT${dh.getUTCOffsetFromTimezoneName(dh.getCurrentTimezone())}`;
});

onBeforeUnmount(() => {
  store.commit("clusters/setVideoPlaybackEncounter", null);
});
</script>

<style scoped lang="scss">
.page {
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
}

nav {
  display: flex;
  align-items: center;
  padding: $--spacing-m;
  background-color: var(--overwatch-secondary);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);

  .case-title {
    color: var(--overwatch-neutral-100);
    margin: 0px var(--spacing-m) 0px var(--spacing-l);
    @include overwatch-title-med;
  }

  .case-datetime {
    @include overwatch-body-med;
  }
}

.local-timezone {
  @include overwatch-body-small;
  margin-left: var(--spacing-m);
  color: var(--overwatch-neutral-200);
}
</style>
