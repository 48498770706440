import mutations from "./mutations.js";
import getters from "./getters.js";
import actions from "./actions.js";

const getDefaultState = () => {
  return {
    cases: [],
    newCase: null,
    selectedMedia: [],
    selectedWatchlists: []
  };
};

const state = getDefaultState();

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  getDefaultState
};
