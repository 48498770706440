<template>
  <div>
    <div class="input-row">
      <div>Name</div>
      <RocInput v-model="caseName" />
    </div>

    <div class="input-row">
      <div>Description</div>
      <RocTextArea v-model="caseDescription" />
    </div>

    <div class="input-row">
      <div>User Groups</div>
      <UserGroupFilterMultiSelect
        @selection-changed="updateSelectedUserGroups"
      />
    </div>

    <div class="buttons">
      <RocButton type="secondary" @click="$emit('close')">Close</RocButton>
      <RocButton @click="createCase">Save</RocButton>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
import RocInput from "@/components/ui/RocInput";
import RocButton from "@/components/ui/RocButton";
import RocTextArea from "@/components/ui/RocTextArea";
import UserGroupFilterMultiSelect from "@/components/ui/UserGroupFilterMultiSelect";

const store = useStore();
const emit = defineEmits(["close", "save"]);

const caseName = ref("");
const caseDescription = ref("");

const userGroups = ref([]);

function updateSelectedUserGroups(g) {
  userGroups.value = g;
}

async function createCase() {
  const payload = {
    name: caseName.value,
    description: caseDescription.value,
    type: "live",
    userGroups: userGroups.value
  };
  var response = await store.dispatch("cases/createCase", payload);

  if (response.status === "success") {
    emit("save", response.result);
    emit("close");
  } else {
  }
}
</script>

<style scoped lang="scss">
.input-row {
  width: 100%;
  margin-bottom: var(--spacing-m);
}

.buttons {
  display: flex;
  gap: var(--spacing-s);
  justify-content: flex-end;
}
</style>
