<template>
  <div>
    <div class="container">
      <div class="d-flex flex-column" style="height: 30%;">
        <auth-img class="face-img" :src="imageThumbnail" />
      </div>
      <div class="d-flex flex-column info-box">
        <div
          class="d-flex justify-content-between"
          style="gap: var(--spacing-s)"
        >
          <div style="width: 100%">
            <span>First Name</span>
            <RocInput
              type="text"
              v-model="firstNameUpdate"
              :disabled="readOnly"
              :placeholder="'First name'"
            />
          </div>
          <div style="width: 100%">
            <span>Last Name</span>
            <RocInput
              type="text"
              v-model="lastNameUpdate"
              :disabled="readOnly"
              :placeholder="'Last name'"
            />
          </div>
        </div>
        <!-- <div class="info-entry">
          <span>
            Internal ID
          </span>
            <div class="d-flex">
              <RocInput class='textInput' type='text' v-model="internalIdUpdate" :disabled="readOnly"/>
            </div>
        </div> -->
        <div class="info-entry" style="margin-bottom: 0px;">
          <div class="info-label-small">
            Identity Notes
          </div>
          <div
            v-if="readOnly"
            style="white-space: pre-wrap;"
            class="overwatch-body-med"
          >
            {{ notesValue }}
          </div>
          <div v-else>
            <RocTextArea
              rows="2"
              class="textInput"
              type="text"
              v-model="notesUpdate"
              :placeholder="'Enter any notes for this face'"
            />
          </div>
          {{ updateSuccessMess }}
        </div>
        <div class="info-entry" style="margin-top: 10px; margin-bottom: 0px;">
          <div v-if="!readOnly" style="width: 100%; text-align: right;">
            <RocButton v-if="!isSaving" @click="saveChanges">Update</RocButton>
            <roc-spinner v-if="isSaving" />
          </div>
          <WatchlistedFaceAdditionalInfo
            v-if="type === 'face'"
            :watchlistedFace="rawObject"
            style="max-width: 100%;"
          />
        </div>
        <roc-spinner
          v-if="isLoading"
          style="justify-self: center; align-self: center;"
        />
      </div>
    </div>
    <div
      v-if="prevEncounters.length && showEncounterMatchesMode"
      class="previous-encounters-div"
    >
      <InfiniteScroll
        style="max-height: 350px"
        :items="prevEncounters"
        @refetch="loadEncounters"
        mode="nopage"
        :is-more-data="prevEncountersHasMore"
        :enabled="scrollEnabled"
      >
        <template v-slot:title>
          <header class="info-label-previous-encounters">
            Previous Encounters
          </header>
        </template>
        <template v-slot:item="{ item }">
          <encounter-card
            :camera-name="item.cameraName"
            :firstname="item.firstname"
            :label="item.label"
            :lastname="item.lastname"
            :object-type="item.objectType"
            :probe-thumbnail="item.probe_tn"
            :similarity="item.similarity"
            :time="item.timestamp"
            :raw-object="item"
            :show-encounter-matches-mode="true"
          >
          </encounter-card>
        </template>
      </InfiniteScroll>
    </div>
  </div>
</template>

<script>
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import AuthImg from "@/components/ui/AuthImg";
import { cloneDeep } from "lodash";
import EncounterCard from "../encounters/EncounterCard.vue";
import InfiniteScroll from "@/components/ui/InfiniteScroll";
import WatchlistedFaceAdditionalInfo from "@/components/watchlists/WatchlistedFaceAdditionalInfo";
import RocButton from "@/components/ui/RocButton";
import RocInput from "@/components/ui/RocInput.vue";
import RocTextArea from "@/components/ui/RocTextArea.vue";

export default {
  name: "WatchlistedImageDetails.vue",
  emits: ["update"],
  props: {
    readOnly: Boolean,
    watchlistedFaceId: String,
    md: Object,
    firstName: String,
    lastName: String,
    notes: String,
    internalId: String,
    imagePath: String,
    rawObject: Object,
    showEncounterMatchesMode: Boolean,
    type: {
      type: String,
      default() {
        return "face";
      }
    }
  },
  components: {
    AuthImg,
    EncounterCard,
    InfiniteScroll,
    WatchlistedFaceAdditionalInfo,
    RocButton,
    RocInput,
    RocTextArea
  },
  setup(props, context) {
    const store = useStore();
    const isSaving = ref(false);
    const firstNameUpdate = ref(props.firstName);
    const lastNameUpdate = ref(props.lastName);
    const notesUpdate = ref(props.notes);
    const internalIdUpdate = ref(props.internalId);
    const isLoading = ref(false);
    const prevEncounters = ref([]);
    const prevEncountersCursor = ref(null);
    const prevEncountersHasMore = ref(false);
    const updateSuccessMess = ref(undefined);

    const nameValue = computed(function() {
      return props.firstName + " " + props.lastName;
    });
    const notesValue = computed(function() {
      return props.notes;
    });

    const internalIdValue = computed(() => {
      return props.internalId;
    });

    onMounted(() => {
      if (props.showEncounterMatchesMode === true) {
        const fetchCameras = store.getters["cameras/cameras"];
        if (!fetchCameras || fetchCameras.length === 0) {
          store.dispatch("cameras/loadCamerasByActiveMission");
        }
        loadEncounters();
      }
    });

    // const metaDataValues = computed(function () {
    //   let metaDataList = [];
    //   if (props.md) {
    //     if (props.md.Age) {
    //       metaDataList.push('~' + props.md.Age + ' years old');
    //     }
    //     if (props.md.Gender) {
    //       metaDataList.push(props.md.Gender);
    //     }
    //     // if (props.md.GeographicOrigin) {
    //     //   metaDataList.push(props.md.GeographicOrigin);
    //     // }
    //     if (props.md.Glasses) {
    //       metaDataList.push('Glasses: ' + props.md.Glasses);
    //     }
    //     if (props.md.MaskDetection) {
    //       if (props.md.MaskDetection === 'NoMask') {
    //         metaDataList.push('No Mask');
    //       } else {
    //         metaDataList.push('Mask');
    //       }

    //     }
    //   }

    //   return metaDataList;
    // });
    const imageThumbnail = computed(function() {
      return props.imagePath;
    });
    const qualityScore = computed(function() {
      if (props.md && props.md.Quality) {
        return (props.md.Quality * 100).toFixed(0);
      } else {
        return "";
      }
    });

    async function loadEncounters(payload) {
      isLoading.value = true;
      let isAppend = false;
      if (payload) {
        isAppend = payload.isAppend ? true : false;
      }
      try {
        const responseData = await store.dispatch(
          "encounters/loadEncountersWatchlistedFace",
          {
            watchlistedFaceIds: [props.watchlistedFaceId],
            watchlistIds: [props.rawObject._watchlistId],
            cursor: prevEncountersCursor.value
          }
        );
        if (isAppend) {
          prevEncounters.value.push(...responseData.encounters);
        } else {
          prevEncounters.value = responseData.encounters;
        }
        prevEncountersCursor.value = responseData.cursor;
        prevEncountersHasMore.value = responseData.moreData;
      } catch (err) {
        console.error(err);
      }
      isLoading.value = false;
      if (payload) {
        payload.status ? (payload.status.value = false) : 0;
      }
    }

    const scrollEnabled = ref(true);

    async function saveChanges() {
      isSaving.value = true;
      // make a temp copy of the original object for the update below
      const updatedObject = cloneDeep(props.rawObject);
      // update the fields we allow to be edited
      updatedObject.firstname = firstNameUpdate.value;
      updatedObject.lastname = lastNameUpdate.value;
      updatedObject.notes = notesUpdate.value;
      updatedObject.internalId = internalIdUpdate.value;
      // update watchlisted element takes full element object
      const payload = {
        type: props.type,
        watchlistedElement: updatedObject
      };
      const responseData = await store.dispatch(
        "watchlists/updateWatchlistedElement",
        payload
      );
      isSaving.value = false;
      if (responseData && responseData.status == "success") {
        updateSuccessMess.value = "Changes have been saved.";
        context.emit("update");
      }
    }

    return {
      nameValue,
      notesValue,
      // metaDataValues,
      imageThumbnail,
      isSaving,
      qualityScore,
      notesUpdate,
      saveChanges,
      firstNameUpdate,
      lastNameUpdate,
      loadEncounters,
      scrollEnabled,
      isLoading,
      internalIdUpdate,
      internalIdValue,
      prevEncounters,
      prevEncountersCursor,
      prevEncountersHasMore,
      updateSuccessMess
    };
  }
};
</script>

<style scoped lang="scss">
img {
  max-height: 100%;
  max-width: 100%;
}

.container {
  display: flex;
}

.face-img {
  width: 225px;
  height: auto;
}

.info-box {
  width: 100%;
  margin-left: 40px;
  min-width: 0;
}

.info-entry {
  margin-bottom: 0.75rem;
  margin-top: 0.75rem;
}

.info-label-small {
  width: 100%;
  color: var(--overwatch-neutral-100);
  @include overwatch-body-large;
  margin-bottom: 4px;
  letter-spacing: 0;
  line-height: 18px;
}

.info-value {
  width: 100%;
  color: var(--overwatch-neutral-100);
  @include overwatch-body-med;
  line-height: 21px;
  background-color: var(--overwatch-secondary);
  padding: 7px;
}

.info-label-meta {
  opacity: 0.5;
  color: var(--overwatch-neutral-100);
  @include overwatch-body-med;
  line-height: 25px;
  margin-bottom: 10px;
}

.info-image {
  color: var(--overwatch-neutral-100);
  @include overwatch-body-med;
  line-height: 27px;
  padding: 7px;
}

.textInput {
  width: 100%;
  @include overwatch-body-med;
}

textarea {
  resize: none;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  text-align: left;
  padding: 8px;
}

tr:nth-child(odd) {
  background-color: var(--overwatch-secondary);
}

.saveBtn {
  text-transform: unset !important;
  background: var(--overwatch-primary);
  color: var(--overwatch-button-text) !important;
  font-size: 16px;
}

.previous-encounters-div {
  width: 100%;
  background: var(--overwatch-neutral-500);
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: 5px;
  margin-top: 15px;
}

.info-label-previous-encounters {
  width: 100%;
  color: var(--overwatch-neutral-200);
  @include overwatch-title-xsmall;
  line-height: 18px;
  margin-left: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 4px;
}

/* MOBILE */
@media (max-width: 480px) {
  .container {
    flex-direction: column;
    padding: 0;
  }

  .face-img {
    max-height: 250px;
    width: auto;
    align-self: center;
  }

  .info-box {
    margin: 0;
  }
}
</style>
