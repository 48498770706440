<template>
  <div class="content">
    <div class="groups">
      <div
        style="padding-left: var(--spacing-s); margin-left: 50px; margin-top: 50px;"
      >
        <RocPill
          leftText="Users"
          rightText="User Groups"
          activeSide="right"
          @pill-toggled="router.push('/settings/users')"
        ></RocPill>
      </div>
      <search-header
        class="stick-to-top"
        @search-filter-change="setSearchFilter"
        searchTextPlaceHolder="Search Groups"
        :transparentBackground="true"
        :hideButton="true"
      >
        <template v-slot:leftnav>
          <div class="title">
            <div style="display: flex; align-items: center;">
              User Groups <span class="group-amount">{{ groups.length }}</span>
            </div>
            <RocButton
              size="dialogButton"
              type="primary"
              @click="isShowingAddGroup = true"
            >
              Create
            </RocButton>
          </div>
        </template>
      </search-header>
      <div v-if="isLoading">
        <roc-spinner />
      </div>
      <div class="groupGrid" v-if="groups.length">
        <div class="gridItem" v-for="group in displayRows" :key="group._id">
          <UserGroupCard
            :group="group"
            @toggle-state="toggleEnabledState(group)"
            @edit="showEditGroup(group)"
            @delete="showDeleteGroup(group)"
          />
        </div>
      </div>
      <RocToast
        v-if="displayToast"
        :message="message"
        @autoClose="
          () => {
            displayToast = !displayToast;
          }
        "
      />
      <base-dialog
        v-if="isShowingAddGroup"
        :show="true"
        title="New Group"
        @close="isShowingAddGroup = false"
        :style="userCRUDStyle"
      >
        <UserGroupCRUD
          @close="isShowingAddGroup = false"
          @save="loadGroups(true)"
        />
      </base-dialog>
      <base-dialog
        v-if="isShowingEditGroup"
        :show="true"
        title="Edit Group"
        @close="isShowingEditGroup = false"
        :style="userCRUDStyle"
      >
        <UserGroupCRUD
          @close="isShowingEditGroup = false"
          @save="loadGroups(true)"
          :group="currentEditedGroup"
        />
      </base-dialog>
      <base-dialog
        v-if="isShowingDeleteGroup"
        :show="true"
        @close="isShowingDeleteGroup = false"
        :style="userDeleteStyle"
      >
        <DeleteConfirmation
          message="Yes"
          cancelMessage="No"
          @close="isShowingDeleteGroup = false"
          @delete="deleteHandler(currentEditedGroup)"
          @disable="disableGroup(currentEditedGroup)"
        >
          <div>
            <RocIcon icon="trash" color="red" @click="() => {}" size="md" />
            Are you sure you want to delete group
            <span style="text-decoration: underline;"
              >{{ currentEditedGroup.label }} </span
            >?
          </div>
        </DeleteConfirmation>
      </base-dialog>
    </div>
    <RocPageSelector
      :currPage="currPage"
      :lastPage="lastPage"
      @goToPage="changePage"
    />
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import SearchHeader from "@/components/ui/SearchHeader";
import DeleteConfirmation from "@/components/settings/DeleteConfirmation";
import BaseDialog from "@/components/ui/BaseDialog.vue";
import UserGroupCard from "@/components/settings/UserGroupCard.vue";
import UserGroupCRUD from "@/components/settings/UserGroupCRUD.vue";
import RocPill from "@/components/ui/RocPill.vue";
import RocButton from "@/components/ui/RocButton.vue";
import RocPageSelector from "@/components/ui/RocPageSelector.vue";
import RocIcon from "@/components/ui/RocIcon.vue";
import RocToast from "@/components/ui/RocToast.vue";
import { useRouter, useRoute } from "vue-router";

export default {
  name: "UserGroupsList",
  components: {
    SearchHeader,
    DeleteConfirmation,
    BaseDialog,
    UserGroupCard,
    UserGroupCRUD,
    RocPill,
    RocButton,
    RocPageSelector,
    RocIcon,
    RocToast
  },
  setup(props, context) {
    const store = useStore();
    const groups = computed(() => {
      return store.getters["settings/userGroups"];
    });
    const isLoading = ref(false);
    const currPage = ref(1);
    const itemsDisplayed = 12;
    const lastPage = ref(Math.ceil(groups.value.length / itemsDisplayed));
    const displayToast = ref(false);
    const message = ref(" has been deleted");
    const router = useRouter();
    const route = useRoute();

    onMounted(async () => {
      await loadGroups(true);
    });

    async function loadGroups(reload) {
      isLoading.value = true;
      if (reload || !groups.value || groups.value.length == 0) {
        await store.dispatch("settings/loadUserGroups");
      }
      isLoading.value = false;
      lastPage.value = Math.ceil(groups.value.length / itemsDisplayed);
    }

    const searchFilterText = ref("");

    function setSearchFilter(filterText) {
      searchFilterText.value = filterText;
    }

    const displayRows = computed(function() {
      let lastRow = currPage.value * itemsDisplayed;
      let firstRow = lastRow - itemsDisplayed;
      let tempGroupArray = [];

      if (searchFilterText.value != "") {
        tempGroupArray = filteredGroups.value;
      } else {
        tempGroupArray = groups.value;
      }
      return tempGroupArray.filter((user, index) => {
        if (index <= lastRow - 1 && index >= firstRow) return true;
      });
    });
    //make regular function
    const filteredGroups = computed(function() {
      const groupsArray = groups.value;
      return searchFilterText.value != ""
        ? groupsArray.filter((group) => {
            const label = group.label.toLowerCase();
            if (label.includes(searchFilterText.value.toLowerCase())) {
              return true;
            }
          })
        : groupsArray;
    });

    const isShowingAddGroup = ref(false);
    const isShowingEditGroup = ref(false);
    const isShowingDeleteGroup = ref(false);
    const currentEditedGroup = ref(null);

    function showEditGroup(group) {
      currentEditedGroup.value = group;
      isShowingEditGroup.value = true;
    }

    function showDeleteGroup(group) {
      currentEditedGroup.value = group;
      isShowingDeleteGroup.value = true;
    }

    function changePage(value) {
      currPage.value = value;
    }

    async function toggleEnabledState(group) {
      try {
        const payload = {
          _id: group._id,
          enabled: !group.enabled
        };

        const responseData = await store.dispatch(
          "settings/updateUserGroup",
          payload
        );
        if (responseData && responseData.status === "success") {
          console.log(responseData);
          Object.assign(group, responseData.userGroup);
        }
      } catch (err) {
        console.log(err);
      }
    }

    async function deleteHandler(group) {
      try {
        const payload = {
          _id: group._id
        };
        await store
          .dispatch("settings/deleteUserGroup", payload)
          .then((res) => {
            if (res.status === "success")
              message.value = group.label + " has been deleted";
          });
        loadGroups(true);
        displayToast.value = true;
      } catch (err) {
        console.log(err);
      }
    }

    // Dialog styles
    const windowWidth = ref(window.innerWidth);
    onMounted(() => {
      window.addEventListener("resize", () => {
        windowWidth.value = window.innerWidth;
      });
    });

    const userDeleteStyle = computed(() => {
      if (windowWidth.value <= 480) {
        return {
          width: "100%"
        };
      } else {
        return {
          width: "500px"
        };
      }
    });

    const userCRUDStyle = computed(() => {
      if (windowWidth.value <= 480) {
        return {
          width: "100%",
          "max-height": "100%"
        };
      } else {
        return {
          width: "700px",
          "max-height": "100%"
        };
      }
    });

    return {
      groups,
      setSearchFilter,
      filteredGroups,
      loadGroups,
      isLoading,
      isShowingAddGroup,
      isShowingDeleteGroup,
      isShowingEditGroup,
      showEditGroup,
      showDeleteGroup,
      deleteHandler,
      currentEditedGroup,
      toggleEnabledState,
      userDeleteStyle,
      userCRUDStyle,
      currPage,
      changePage,
      displayRows,
      lastPage,
      displayToast,
      message,
      currPage,
      route,
      router
    };
  }
};
</script>

<style scoped lang="scss">
.groups {
  min-width: 35rem;
  flex: 1 0 auto;
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 0;
}

.stick-to-bottom {
  flex-shrink: auto;
}

.camera-container {
  max-width: 100%;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 20px;
  min-width: 450px;
  position: relative;
}

.groupGrid {
  width: 100%;
  display: grid;
  justify-items: start;
  grid-gap: var(--spacing-m);
  justify-content: start;
  grid-template-columns: repeat(auto-fill, 316px);
  padding-left: 50px;
}

.gridItem {
  width: 316px;
  height: 246px;
}

.go-to {
  margin-left: 30px;
  @include overwatch-body-med;
  color: var(--overwatch-neutral-100);
}

.stick-to-top {
  position: sticky;
  top: 0;
  z-index: 50;
}

.group-amount {
  color: var(--overwatch-neutral-200);
  padding-left: var(--spacing-s);
  @include overwatch-body-large;
}

.title {
  gap: var(--spacing-l);
  padding-left: var(--spacing-base);
  display: flex;
}

@media (max-width: 480px) {
  .groups {
    min-width: 0;
  }
  .camera-container {
    margin: 0;
    min-width: 0;
  }
  .title {
    gap: 8px;
    padding-left: 10px;
    padding-bottom: 10px;
    display: flow;
  }
}
</style>
