<template>
  <div>
    <div style="height: 100%;">
      <div class="header">
        <RocIcon
          class="search-header-backbutton"
          icon="backArrow"
          size="sm"
          @click="$router.back()"
        />
        <span class="search-header-title">{{ event.eventName }}</span>
        <div class="d-flex align-items-baseline">
          <div
            :class="{
              upcomingBubble: status === 'Upcoming',
              activeBubble: status === 'Active',
              disabledBubble: status === 'Disabled',
              endedBubble: status === 'Ended',
              inActiveBubble: status === 'Inactive'
            }"
          ></div>
          <span class="overwatch-body-small">{{ status }} </span>
        </div>
        <div
          style="flex: 1; margin-left: var(--spacing-l); align-items: center;"
          v-if="!isMobile"
        >
          <RocIcon
            color="black"
            size="sm"
            icon="calendar"
            style="margin-right: var(--spacing-base)"
          />
          <span class="overwatch-body-med">{{ startDateString }} </span>
          <span> - </span>
          <span class="overwatch-body-med">{{ endDateString }} </span>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <span
            class="overwatch-body-med"
            style="margin-left: var(--spacing-l); margin-right: var(--spacing-s); color: var(--overwatch-neutral-200);"
          >
            {{ totalEntitlements }} attendees
          </span>
          <MDBDropdown v-model="dropdownOptions">
            <MDBDropdownToggle
              style="color:black;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;"
              tag="a"
              @click="dropdownOptions = !dropdownOptions"
            >
              <RocButton class="addCamera">
                Invite
              </RocButton>
            </MDBDropdownToggle>
            <RocDropdownMenu
              @click="dropdownOptions = false"
              aria-labelledby="dropdownMenuButton"
              style="margin-top: 20px; box-shadow: 4px 4px 15px 4px rgba(0, 0, 0, 0.3);"
            >
              <MDBDropdownItem
                class="dropdown-button"
                href="#"
                @click.prevent="isShowingCreateDialog = true"
              >
                Create Invite
              </MDBDropdownItem>
              <MDBDropdownItem divider @click.stop />
              <MDBDropdownItem
                class="dropdown-button"
                href=""
                @click.prevent="batchCreate"
              >
                Batch Invite
              </MDBDropdownItem>
            </RocDropdownMenu>
          </MDBDropdown>
        </div>
      </div>
      <div v-if="isMobile" class="datesCalendar">
        <RocIcon color="black" size="sm" icon="calendar" />
        <span class="overwatch-body-small">{{ startDateString }} </span>
        <span> – </span>
        <span class="overwatch-body-small">{{ endDateString }} </span>
      </div>
      <SearchHeader
        button-title="Filter"
        :use-dropdown="true"
        :transparentBackground="true"
        @search-filter-change="searchFilterChange"
        searchTextPlaceHolder="Search Attendees"
        :keepButtonTitle="true"
      >
        <template v-slot:dropdown-slot>
          <MDBDropdownItem href="" @click.prevent="() => {}">
            <div class="d-flex flex-row">
              <RocCheckbox v-model="filterPending" @click.stop="() => {}" />
              <span style="margin-top: 2px; margin-left: var(--spacing-m);"
                >Pending</span
              >
            </div>
          </MDBDropdownItem>
          <MDBDropdownItem href="" @click.prevent="() => {}">
            <div class="d-flex flex-row">
              <RocCheckbox v-model="filterEnrolled" @click.stop="() => {}" />
              <span style="margin-top: 2px; margin-left: var(--spacing-m);"
                >Enrolled</span
              >
            </div>
          </MDBDropdownItem>
          <MDBDropdownItem href="" @click.prevent="() => {}">
            <div class="d-flex flex-row">
              <RocCheckbox v-model="filterExpired" @click.stop="() => {}" />
              <span style="margin-top: 2px; margin-left: var(--spacing-m);"
                >Expired</span
              >
            </div>
          </MDBDropdownItem>
          <MDBDropdownItem href="" @click.prevent="() => {}">
            <div class="d-flex flex-row">
              <RocCheckbox v-model="filterRevoked" @click.stop="() => {}" />
              <span style="margin-top: 2px; margin-left: var(--spacing-m);"
                >Revoked</span
              >
            </div>
          </MDBDropdownItem>
        </template>
      </SearchHeader>
      <div class="grid">
        <div class="headerColumn d-flex flex-column justify-content-center">
          <div
            class="d-flex justify-content-between"
            style="height: 100%; ; min-width: 35rem; gap: 15px;"
          >
            <div class="align-self-center" style="flex: 1; ">
              Name
            </div>
            <div
              class="align-self-center"
              style="flex: 1; text-align: center; margin-right: 15px;"
            >
              Status
            </div>
            <div class="align-self-center" style="flex: 2; text-align: end;">
              Date Added
            </div>
            <div class="align-self-center" style="flex: 4; text-align: center;">
              Expiration Date
            </div>
          </div>
        </div>
        <div class="entitlementsList">
          <div
            v-if="!hasEntitlements && !isLoading"
            style="flex: 1; margin-top: 100px;"
          >
            <RocLogo />
            <div class="no-data-bold">
              No Event Enrollments
            </div>
          </div>
          <div
            v-else
            v-for="item in entitlements"
            :key="item.id"
            class="entitlement-card-holder"
          >
            <EntitlementCard
              :entitlement="item"
              :key="item._id"
              @edit="showEditEntitlementDialog(item)"
              @delete="deleteEntitlement(item)"
              @revoke="revokeEntitlement(item)"
              @resend="resendEntitlement(item)"
              :isCruding="isCrudingEntry(item._id)"
              style="width: 100%;"
            />
          </div>
        </div>
      </div>
      <BaseDialog
        show
        title="Invite New Attendee"
        v-if="isShowingCreateDialog"
        @close="isShowingCreateDialog = false"
      >
        <EntitlementCRUD
          @close="isShowingCreateDialog = false"
          @create="createEntitlement"
          :crudError="crudError"
          :isCruding="isCruding"
        />
      </BaseDialog>

      <BaseDialog
        show
        title="Edit Invite"
        v-if="isShowingEditDialog"
        @close="isShowingEditDialog = false"
      >
        <EntitlementCRUD
          :entitlement="currentEditingEntitlement"
          @close="isShowingEditDialog = false"
          @edit="editEntitlement"
          :crudError="crudError"
          :isCruding="isCruding"
        />
      </BaseDialog>
      <BaseDialog
        v-if="isShowingDelete"
        :show="true"
        @close="isShowingDelete = false"
        :style="deleteConfirmationStyle"
      >
        <DeleteConfirmation
          @close="isShowingDelete = false"
          @delete="deleteConfirmed"
          message="Yes"
        >
          <div
            class="d-flex justify-content-center"
            style="padding-top: var(--spacing-xl);"
          >
            <RocIcon
              icon="trash"
              color="red"
              size="md"
              style="margin-right: 5px;"
            />
            {{ deleteConfirmMsg }}
          </div>
        </DeleteConfirmation>
      </BaseDialog>
      <RocToast
        v-if="displayToast"
        :message="message"
        @autoClose="displayToast = !displayToast"
      />
      <RocPageSelector
        v-if="hasEntitlements"
        :currPage="currentPage"
        :lastPage="totalPages"
        @goToPage="setCurrentPage"
      />
    </div>
  </div>
</template>
<script>
import { ref, computed, onMounted, onBeforeUnmount, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import {
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownToggle
} from "mdb-vue-ui-kit";
import BaseDialog from "@/components/ui/BaseDialog";
import EntitlementCard from "@/components/events/EntitlementCard.vue";
import EntitlementCRUD from "@/components/events/EntitlementCRUD";
import SearchHeader from "@/components/ui/SearchHeader";
import RocLogo from "@/components/ui/RocLogo";
import DeleteConfirmation from "@/components/settings/DeleteConfirmation.vue";
import RocIcon from "@/components/ui/RocIcon";
import RocButton from "../../components/ui/RocButton.vue";
import RocPageSelector from "@/components/ui/RocPageSelector.vue";
import RocCheckbox from "@/components/ui/RocCheckbox.vue";
import RocToast from "@/components/ui/RocToast.vue";
import RocDropdownMenu from "@/components/ui/RocDropdownMenu.vue";
import moment from "moment";

export default {
  components: {
    BaseDialog,
    EntitlementCard,
    EntitlementCRUD,
    SearchHeader,
    MDBDropdownItem,
    MDBDropdownToggle,
    MDBDropdown,
    RocLogo,
    RocIcon,
    DeleteConfirmation,
    RocButton,
    RocPageSelector,
    RocCheckbox,
    RocToast,
    RocDropdownMenu
  },
  props: {
    id: String
  },
  setup(props, context) {
    const store = useStore();
    const router = useRouter();
    const entitlements = ref([]);
    const currentPage = ref(1);
    const totalPages = ref(1);
    const totalEntitlements = ref(0);
    const crudError = ref("");
    const isCruding = ref(false);
    const crudingId = ref(null);
    const event = ref({});
    const isShowingDelete = ref(false);
    const deleteConfirmMsg = ref("Are you sure you want to delete this user");
    const entitlementToDelete = ref(null);
    let socketEnrollments = null;
    const filterPending = ref(true);
    const filterEnrolled = ref(true);
    const filterExpired = ref(true);
    const filterRevoked = ref(true);
    const isLoading = ref(false);
    const displayToast = ref(false);
    const message = ref("");
    const dropdownOptions = ref(false);

    onMounted(async () => {
      window.addEventListener("resize", () => {
        windowWidth.value = window.innerWidth;
      });
      selectedStatuses.value = ["pending", "enrolled", "expired", "revoked"];
      await loadEventDetails();
    });

    async function loadEventDetails() {
      const response = await store.dispatch("events/getEvent", props.id);
      if (response && response.status === "success") {
        event.value = response.event;
        wireSocketIoEnrollments();
      } else {
        // we reloaded or event is deleted, back into events
        router.push("/events");
      }
    }

    const itemsPerPage = computed(() => {
      return store.getters["events/queryPageSize"] ?? 50;
    });

    const status = computed(() => {
      if (!event.value.enabled) {
        return "Disabled";
      } else {
        if (!event.value.startDate || !event.value.endDate) {
          return "Inactive";
        }
        if (moment().isBefore(moment(event.value.startDate), "day")) {
          return "Upcoming";
        } else if (moment(event.value.endDate).isBefore(moment(), "day")) {
          return "Ended";
        } else {
          return "Active";
        }
      }
    });

    const startDateString = computed(() => {
      return moment(event.value.startDate).format("dddd MMMM DD, YYYY");
    });

    const endDateString = computed(() => {
      return moment(event.value.endDate).format("dddd MMMM DD, YYYY");
    });

    const isShowingCreateDialog = ref(false);
    const isShowingEditDialog = ref(false);
    const isShowingImportDialog = ref(false);

    const selectedStatuses = ref(["pending", "enrolled", "expired", "revoked"]);

    //gets called by filter. payload is val seena bove
    async function updateSelectedStatuses(payload) {
      selectedStatuses.value = payload;
      resetEntitlements();
      await loadEntitlements(1);
    }

    watch(
      [filterPending, filterEnrolled, filterExpired, filterRevoked],
      (newArray) => {
        let output = [];
        if (filterPending.value) output.push("pending");
        if (filterEnrolled.value) output.push("enrolled");
        if (filterExpired.value) output.push("expired");
        if (filterRevoked.value) output.push("revoked");
        updateSelectedStatuses(output);
      }
    );

    watch(currentPage, (nv) => {
      currentPage.value = nv;
      totalPages.value = 1;
      totalEntitlements.value = 0;
      entitlements.value = [];
      loadEntitlements(1);
    });

    const searchFilter = ref("");
    var timeoutId;

    function searchFilterChange(text) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        searchFilter.value = text;
        resetEntitlements();
        loadEntitlements(1);
      }, 500);
    }

    loadEntitlements(1);

    async function loadEntitlements(reload) {
      isLoading.value = true;
      const payload = {
        _eventId: props.id,
        currentPage: currentPage.value,
        statusList: selectedStatuses.value,
        pageSize: itemsPerPage.value
      };

      if (searchFilter.value) {
        payload.filter = searchFilter.value;
      }

      const result = await store.dispatch("events/getEntitlements", payload);
      if (result) {
        totalEntitlements.value = result.totalEntitlements;
        totalPages.value = result.totalPages;
        if (result.entitlements) {
          for (let entitlement of result.entitlements) {
            entitlements.value.push(entitlement);
          }
        }
      }
      reload.status ? (reload.status.value = false) : 0;
      isLoading.value = false;
    }

    function resetEntitlements() {
      currentPage.value = 1;
      totalPages.value = 1;
      totalEntitlements.value = 0;
      entitlements.value = [];
    }

    const windowWidth = ref(window.innerWidth);
    const isMobile = computed(() => {
      return windowWidth.value <= 480;
    });

    const hasEntitlements = computed(function() {
      return entitlements.value.length > 0;
    });

    async function createEntitlement(payload) {
      isCruding.value = true;
      crudError.value = "";
      payload._eventId = props.id;
      const response = await store.dispatch(
        "events/createEntitlement",
        payload
      );
      isCruding.value = false;
      if (response && response.status === "success") {
        entitlements.value.unshift(response.entitlement);
        totalEntitlements.value++;
        isShowingCreateDialog.value = false;
        loadEventDetails();
        message.value = "An invite has been Sent!";
        displayToast.value = true;
      } else {
        displayCrudError(
          response ? response.message : "Error Creating Entitlement"
        );
      }
    }

    const currentEditingEntitlement = ref();

    function showEditEntitlementDialog(entitlement) {
      currentEditingEntitlement.value = entitlement;
      isShowingEditDialog.value = true;
    }

    async function editEntitlement(payload) {
      isCruding.value = true;
      const elementId = payload.id;
      crudError.value = "";
      const response = await store.dispatch(
        "events/updateEntitlement",
        payload
      );
      isCruding.value = false;
      if (response && response.status === "success") {
        const index = entitlements.value.findIndex(
          (element) => element._id === elementId
        );
        if (index >= 0) {
          entitlements.value.splice(index, 1);
        }
        entitlements.value.unshift(response.entitlement);
        isShowingEditDialog.value = false;
        loadEventDetails();
      } else {
        displayCrudError(
          response ? response.message : "Error Editing Entitlement"
        );
      }
    }

    function displayCrudError(msg) {
      crudError.value = msg;
      setTimeout(() => {
        crudError.value = "";
      }, 3000);
    }

    async function deleteEntitlement(entitlement) {
      //Todo get name of entitlement, add to delete message
      entitlementToDelete.value = entitlement;
      isShowingDelete.value = true;
    }

    async function deleteConfirmed() {
      isCruding.value = true;
      const elementId = entitlementToDelete.value._id;
      const response = await store.dispatch(
        "events/deleteEntitlementByID",
        entitlementToDelete.value._id
      );
      if (response && response.status === "success") {
        const index = entitlements.value.findIndex(
          (element) => element._id === elementId
        );
        if (index >= 0) {
          entitlements.value.splice(index, 1);
          totalEntitlements.value--;
        }
        loadEventDetails();
        message.value = "Attendee has been deleted";
        displayToast.value = true;
      } else {
        console.error(
          response ?? `Unable to delete entitlement id ${elementId}`
        );
      }
      isCruding.value = false;
      isShowingDelete.value = false;
      entitlementToDelete.value = null;
    }

    async function revokeEntitlement(entitlement) {
      isCruding.value = true;
      const elementId = entitlement._id;
      crudingId.value = elementId;
      const payload = {
        id: elementId,
        update: {
          status: "revoked"
        }
      };
      // const response = await store.dispatch('events/revokeEntitlementByID', entitlement._id);
      const response = await store.dispatch(
        "events/updateEntitlement",
        payload
      );
      if (response && response.status === "success") {
        const index = entitlements.value.findIndex(
          (element) => element._id === elementId
        );
        if (index >= 0) {
          entitlements.value.splice(index, 1, response.entitlement);
        }
        loadEventDetails();
      }
      crudingId.value = null;
      isCruding.value = false;
    }

    async function resendEntitlement(entitlement) {
      isCruding.value = true;
      const elementId = entitlement._id;
      crudingId.value = elementId;
      // delete original entitlement
      let response = await store.dispatch(
        "events/deleteEntitlementByID",
        elementId
      );
      if (response && response.status === "success") {
        // re-create entitlement, this will update timestamps and re-send
        const newEntitlement = _.cloneDeep(entitlement);
        delete newEntitlement._id;
        newEntitlement._eventId = props.id;
        const response = await store.dispatch(
          "events/createEntitlement",
          newEntitlement
        );
        if (response && response.status === "success") {
          const index = entitlements.value.findIndex(
            (element) => element._id === elementId
          );
          if (index >= 0) {
            entitlements.value.splice(index, 1);
          }
          entitlements.value.unshift(response.entitlement);
        }
      }
      crudingId.value = null;
      isCruding.value = false;
    }

    //this is called in app.vue
    function importerClose() {
      isShowingImportDialog.value = false;
      store.dispatch("watchlists/toggleWatchlistImporter", false);
      resetEntitlements();
      loadEntitlements(1);
      loadEventDetails();
    }

    const entitlementDialogStyle = computed(() => {
      if (window.innerWidth <= 480) {
        return {
          width: "90%"
        };
      } else {
        return {
          width: "800px"
        };
      }
    });

    function batchCreate() {
      store.dispatch("watchlists/initWatchlistImporter", {
        type: "entitlements",
        mode: "create",
        eventId: props.id,
        visible: true
      });
    }

    const deleteConfirmationStyle = computed(() => {
      if (window.innerWidth <= 480) {
        // Mobile style
        return {
          width: "90%"
        };
      }
    });

    onBeforeUnmount(() => {
      disconnectAllSocketIO();
    });

    async function disconnectAllSocketIO() {
      if (socketEnrollments) {
        socketEnrollments.disconnect();
        socketEnrollments.removeAllListeners();
        socketEnrollments = null;
      }
    }

    async function wireSocketIoEnrollments() {
      if (!socketEnrollments) {
        const encodedUriComp = encodeURIComponent(
          JSON.stringify([event.value._watchlistId])
        );
        const payload = `feed=enrollments&watchlists=${encodedUriComp}`;
        socketEnrollments = await store.dispatch("auth/getSocketIO", payload);
        socketEnrollments.on("enrollments", async (payload) => {
          upsertEnrollment(payload);
        });
      }
    }

    async function upsertEnrollment(payload) {
      if (payload && payload.entitlement) {
        const result = await store.dispatch("events/getEntitlementInfoByID", {
          _entitlementId: payload.entitlement._id
        });
        if (result && result.status === "success") {
          const index = entitlements.value.findIndex(
            (element) => element._id === result.entitlement._id
          );
          if (index >= 0) {
            entitlements.value.splice(index, 1, result.entitlement);
          }
        }
      }
    }

    function isCrudingEntry(id) {
      return isCruding.value && crudingId.value === id;
    }

    function setCurrentPage(nv) {
      currentPage.value = nv;
    }

    return {
      createEntitlement,
      isShowingCreateDialog,
      isShowingEditDialog,
      entitlements,
      currentEditingEntitlement,
      updateSelectedStatuses,
      showEditEntitlementDialog,
      editEntitlement,
      deleteEntitlement,
      isShowingImportDialog,
      entitlementDialogStyle,
      importerClose,
      searchFilterChange,
      hasEntitlements,
      batchCreate,
      revokeEntitlement,
      currentPage,
      setCurrentPage,
      loadEntitlements,
      totalPages,
      totalEntitlements,
      crudError,
      isCruding,
      event,
      resendEntitlement,
      isShowingDelete,
      deleteConfirmMsg,
      deleteConfirmationStyle,
      deleteConfirmed,
      isCrudingEntry,
      startDateString,
      endDateString,
      filterRevoked,
      filterPending,
      filterEnrolled,
      filterExpired,
      isLoading,
      status,
      displayToast,
      message,
      isMobile,
      dropdownOptions
    };
  }
};
</script>

<style scoped lang="scss">
.dropdown-button {
  padding: 8px 16px 16px 8px;
}

.header {
  background: var(--overwatch-secondary);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: var(--spacing-l);
}

.headerColumn {
  width: 95% !important;
  min-width: 25rem;
  display: block;
  padding: 16px 20px 16px !important;
  background: var(--overwatch-button-primary-20);
  margin-right: 0rem !important;
  border: 1px solid var(--overwatch-button-primary);
  border-radius: var(--spacing-base) var(--spacing-base) 0 0;
  @include overwatch-title-xsmall;
}

.grid {
  max-width: 90%;
  margin: auto;
  min-width: 450px;
  position: relative;
}

.search-header-title {
  @include overwatch-title-large;
  margin-right: var(--spacing-m);
}

.search-header-backbutton {
  cursor: pointer;
  margin-right: var(--spacing-xl);
}

.dropdown-toggle:after {
  display: none;
}

.no-data-bold {
  display: flex;
  justify-content: center;
  font-size: 30px;
  margin-top: 30px;
}

.no-data-description {
  display: flex;
  justify-content: center;
}

.confirmButton {
  background: var(--overwatch-primary);
  color: var(--overwatch-button-text) !important;
  @include overwatch-body-small;
}

.disabledBubble {
  width: var(--spacing-xs);
  margin-top: var(--spacing-xs);
  margin-right: 2px;
  height: var(--spacing-xs);
  border-radius: 50%;
  background-image: radial-gradient(
    circle at 50% 50%,
    #c9c9c9,
    #c5c5c5 0%,
    #b8b8b8 32%,
    var(--overwatch-light-neutral-300) 71%
  );
}

.activeBubble {
  width: var(--spacing-xs);
  height: var(--spacing-xs);
  margin-top: var(--spacing-xs);
  margin-right: 2px;
  border-radius: 50%;
  box-shadow: 0 0 4px 0 var(--overwatch-light-success);
  background-image: linear-gradient(
    to bottom,
    #e7f0e6 0%,
    #7ec778 39%,
    var(--overwatch-light-success) 100%
  );
}

.upcomingBubble {
  width: var(--spacing-xs);
  height: var(--spacing-xs);
  margin-top: var(--spacing-xs);
  margin-right: 2px;
  border-radius: 50%;
  box-shadow: 0 0 4px 0 #3f3fd9;
  background-image: linear-gradient(
    to bottom,
    #eef 0%,
    #8080f0 50%,
    #3f3fd9 100%
  );
}

.endedBubble {
  width: var(--spacing-xs);
  height: var(--spacing-xs);
  margin-top: var(--spacing-xs);
  margin-right: 2px;
  border-radius: 50%;
  border: solid 1px var(--overwatch-light-neutral-300);
  background-image: radial-gradient(
    circle at 50% 50%,
    #c9c9c9,
    #c5c5c5 0%,
    #b8b8b8 32%,
    var(--overwatch-light-neutral-300) 71%
  );
}

.inActiveBubble {
  width: var(--spacing-xs);
  height: var(--spacing-xs);
  margin-top: var(--spacing-xs);
  margin-right: 2px;
  border-radius: 50%;
  box-shadow: 0 0 4px 0 #3f3fd9;
  background-image: linear-gradient(
    to bottom,
    #eef 0%,
    #8080f0 50%,
    #3f3fd9 100%
  );
}

//apply styling to last card in list
.entitlement-card-holder:nth-last-child(1) .card {
  border: none;
  border-radius: 0 0 5px 5px;
}

@media (max-width: 480px) {
  .search-header-title {
    font-size: 18px;
  }
  .search-header-backbutton {
    margin-right: 14px;
  }

  .grid {
    margin-left: 10px;
    min-width: 650px;
    overflow-x: scroll;
  }

  .activeAttendees {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    margin-top: 12px;
    margin-left: 35px;
  }

  .datesCalendar {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-s);
    border-top: 1px solid var(--overwatch-button-primary);
    background: var(--overwatch-secondary);
    padding-top: 10px;
  }
}
</style>
