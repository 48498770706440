<template>
  <!-- This component is a single select dropdown with searchable input for an array of objects -->
  <Multiselect
    :searchable="true"
    :filter-results="true"
    :mode="'single'"
    :placeholder="placeholderText"
    v-model="selectedItem"
    :label="label"
    :valueProp="valueProp"
    :style="dynamicStyle"
    :options="availableItems"
    :close-on-select="closeOnSelect"
    :canClear="enableClear"
    ref="multiselectRef"
    :class="{ multiselect: true }"
  >
    <template v-slot:option="{ option }">
      <div
        style="display: flex; align-items: center; justify-content: center; height: 24px;"
      >
        <RocIcon size="sm" color="primary" icon="description" />
        <span class="multiselect-option">{{ option.name }}</span>
      </div>
    </template>

    <template v-slot:caret>
      <div @click="enableSelf()">
        <RocIcon
          style="margin-right: var(--spacing-s); margin-top: var(--spacing-base);"
          size="sm"
          color="black"
          icon="downArrow"
          :style="rotateCaret"
        />
      </div>
    </template>
  </Multiselect>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import { ref, watch, computed, nextTick } from "vue";
import RocIcon from "@/components/ui/RocIcon.vue";

export default {
  name: "filterSingleSelect",
  emits: ["selection-changed"],
  props: {
    availableItems: Array,
    currentlySelected: String,
    noResultsText: {
      type: String,
      default: "No results found"
    },
    placeholderText: String,
    closeOnSelect: {
      type: Boolean,
      default: true
    },
    enableClear: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: "name"
    },
    valueProp: {
      type: String,
      default: "name"
    }
  },
  components: {
    Multiselect,
    RocIcon
  },
  setup(props, context) {
    const selectedItem = ref(props.currentlySelected);
    const multiselectRef = ref(null);

    watch(selectedItem, () => {
      console.log(selectedItem.value);
      context.emit("selection-changed", selectedItem.value);
    });

    const isEnabled = ref(false);

    function enableSelf() {
      isEnabled.value = !isEnabled.value;
      console.log("isEnabled", isEnabled.value);
      nextTick(() => {
        if (isEnabled.value) {
          multiselectRef.value.open();
          multiselectRef.value.focus();
        } else {
          multiselectRef.value.close();
        }
      });
    }

    const rotateCaret = computed(() => {
      if (isEnabled.value) {
        return {
          transform: "rotate(180deg)",
          transition: "transform 0.2s ease-in-out"
        };
      } else {
        return {
          transform: "rotate(0deg)",
          transition: "transform 0.2s ease-in-out"
        };
      }
    });

    const dynamicStyle = computed(() => {
      const baseStyle = `
          --ms-ring-color: none;
          --ms-caret-color: none;
          --ms-dropdown-bg: var(--overwatch-secondary);
          --ms-dropdown-border-color: var(--overwatch-neutral-300);
          --ms-dropdown-border-width: 1px;
          --ms-dropdown-radius: 0px;
          --ms-option-font-size: 14px;
          --ms-max-height: 200px;
          --ms-radius: 0px;
          --ms-option-color-pointed: var(--overwatch-neutral-100);
          --ms-option-color-selected: var(--overwatch-button-text);
          --ms-option-bg-selected: var(--overwatch-button-primary);
          --ms-option-bg-selected-pointed: var(--overwatch-button-primary);
          --ms-option-bg-pointed: var(--overwatch-button-primary-20);
          --ms-option-font-size: 0.85rem;
          --ms-spinner-color: var(--overwatch-primary);
          background-color: var(--overwatch-neutral-500);
          border-radius: 5px;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: var(--overwatch-neutral-100);
          --ms-option-font-size: 16px;
          padding-left: 0;
          border: 0px;
          --ms-border-width: 0px;
          --ms-border-width-active: 0px;
          `;
      return baseStyle;
    });
    return {
      selectedItem,
      dynamicStyle,
      multiselectRef,
      enableSelf,
      rotateCaret
    };
  }
};
</script>

<style scoped>
.multiselect-option {
  margin-top: 1px;
}

.multiselect :deep(.multiselect-search) {
  background-color: var(--overwatch-neutral-500);
}
</style>
