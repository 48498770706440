<template>
  <div class="wrapper">
    <div class="header">
      <div class="overwatch-title-large">
        <RocIcon
          style="cursor: pointer; margin-right: 24px; transform: rotate(90deg)"
          icon="downArrow"
          size="sm"
          @click="$router.back()"
        />
        {{ caseName }}
      </div>

      <RocButton
        v-if="currentCase?.investigation?.isOpen"
        style="margin-left: auto;"
        @click="newSearch"
      >
        New Search
      </RocButton>
    </div>

    <div v-if="records.length > 0">
      <div class="toggle-buttons">
        <MDBBtnGroup class="shadow-0 options-button-group">
          <MDBBtn
            @click="selectMode('current')"
            :ripple="false"
            outline="secondary"
            :class="{ active: mode === 'current' }"
            style="text-transform: none !important;"
            >Current Search
          </MDBBtn>
          <MDBBtn
            @click="selectMode('history')"
            :ripple="false"
            outline="secondary"
            :class="{ active: mode === 'history' }"
            style="text-transform: none !important;"
            >Search History
          </MDBBtn>
        </MDBBtnGroup>
      </div>
      <div class="body">
        <InvestigativeCaseRecordView
          :record="records[records.length - 1]"
          v-show="mode === 'current'"
        />
        <div class="records" v-show="mode === 'history'">
          <MDBCard v-for="record of records">
            <MDBCardBody class="record-card">
              <div class="record-card-info">
                <img :src="'data:image/jpg;base64,' + record.probe_tn_base64" />
                <div class="probe-info">
                  <div class="overwatch-body-small gray-font">Date</div>
                  <div class="overwatch-body-large">
                    {{
                      new Date(record.createdAt)
                        .toLocaleString("en-US")
                        .split(",")[0]
                    }}
                  </div>
                  <div class="overwatch-body-small gray-font">File Name</div>
                  <div class="overwatch-body-large">
                    {{ record.probe.fileName }}
                  </div>
                  <div class="overwatch-body-small gray-font">Searched By</div>
                  <div class="overwatch-body-large">
                    {{ `${record.user.lastname}, ${record.user.firstname}` }}
                  </div>
                </div>
              </div>
              <RocButton
                @click="showRecord(record)"
                style="
                  height: fit-content;
                  width: fit-content;
                  padding: var(--spacing-xs) var(--spacing-s);
                "
              >
                <RocIcon icon="visible" color="white" size="sm" />
              </RocButton>
            </MDBCardBody>
          </MDBCard>
        </div>
      </div>
    </div>
    <div v-else style="width: 100%; height: 100%; display: flex;">
      <div class="overwatch-title-med error-msg">
        <RocLogo />
        No records found.
      </div>
    </div>
    <BaseDialog
      v-if="isShowingDialog"
      sectionOverflowHidden
      show
      @close="isShowingDialog = false"
      style="z-index:100; height: 90%; width: 1300px; overflow: auto;"
    >
      <template v-slot:header>
        <div style="display: flex; align-items: center; gap: var(--spacing-m)">
          <RocIcon icon="user" />
          {{
            `${selectedRecord.user.lastname}, ${selectedRecord.user.firstname}`
          }}
        </div>
      </template>
      <InvestigativeCaseRecordView :record="selectedRecord" />
    </BaseDialog>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import RocLogo from "@/components/ui/RocLogo";
import RocButton from "@/components/ui/RocButton";
import InvestigativeCaseRecordView from "@/components/investigations/InvestigativeCaseRecordView";
import { MDBBtnGroup, MDBBtn, MDBCard, MDBCardBody } from "mdb-vue-ui-kit";
import BaseDialog from "@/components/ui/BaseDialog";
import RocIcon from "@/components/ui/RocIcon";

export default {
  name: "InvestigativeCaseRecords",
  props: ["caseId"],
  components: {
    InvestigativeCaseRecordView,
    MDBBtnGroup,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    BaseDialog,
    RocButton,
    RocLogo,
    RocIcon
  },
  setup(props, context) {
    const store = useStore();

    const router = useRouter();

    const currentCase = ref();

    const caseName = ref();

    const records = ref([]);

    onMounted(async () => {
      var response = await store.dispatch("cases/getCaseById", props.caseId);
      if (response.status === "success") {
        currentCase.value = response.result;
      }

      caseName.value = currentCase.value.name;

      response = await store.dispatch("investigations/getRecords", {
        caseId: props.caseId
      });

      if (response.status === "success") {
        records.value = response.result;
      }
    });

    const mode = ref("current");

    function selectMode(m) {
      mode.value = m;
    }

    const selectedRecord = ref();
    const isShowingDialog = ref(false);

    function showRecord(record) {
      selectedRecord.value = record;

      isShowingDialog.value = true;
    }

    function newSearch() {
      router.push({
        name: "InvestigativeCaseImportWizard",
        params: {
          id: props.caseId
        }
      });
    }

    return {
      records,
      mode,
      selectMode,
      showRecord,
      selectedRecord,
      isShowingDialog,
      caseName,
      newSearch,
      currentCase
    };
  }
};
</script>

<style>
.options-button-group .btn {
  border: 1px solid var(--overwatch-neutral-300);
  color: var(--overwatch-neutral-100);
}

.options-button-group .btn.active {
  background-color: var(--overwatch-button-primary);
  border-color: var(--overwatch-button-primary);
  color: var(--overwatch-button-text);
}
</style>

<style scoped lang="scss">
.wrapper {
  height: 100%;

  display: flex;
  flex-direction: column;
}

.header {
  @include overwatch-title-large;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: var(--spacing-l);

  background-color: var(--overwatch-secondary);
}

.toggle-buttons {
  margin-top: var(--spacing-l);
  padding: 0 var(--spacing-xl);
}

.body {
  margin-top: var(--spacing-l);
  padding: 0 var(--spacing-xl);

  flex: 1;
  overflow-y: hidden;
}

.records {
  display: grid;
  width: fit-content;
  grid-template-columns: auto auto auto;
  gap: var(--spacing-s);
}

.probe-info {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-base);
}

.record-card {
  display: flex;
  border-radius: 5px;
  background-color: var(--overwatch-secondary);
}

.record-card-info {
  display: flex;
  gap: var(--spacing-s);
}

.record-card img {
  display: block;
  max-width: 130px;
  max-height: 180px;
  width: auto;
  height: auto;
}

.gray-font {
  color: var(--overwatch-neutral-200);
}

.error-msg {
  margin: auto auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-m);
}
</style>
