export default {
  resetState(state, defaultState) {
    // Merge rather than replace so we don't lose observers
    Object.assign(state, defaultState);
  },
  setEvents(state, payload) {
    state.events = payload;
  },
  appendEvent(state, payload) {
    state.events.push(payload);
  },
  clearEvents(state) {
    state.events = [];
  },
  setQueryPageSize(state, payload) {
    state.queryPageSize = payload;
  }
};
