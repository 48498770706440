<script setup>
import { defineProps, ref, computed, watch } from "vue";
import RocIcon from "@/components/ui/RocIcon";
import {
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownToggle,
  MDBBadge
} from "mdb-vue-ui-kit";
import RocDropdownMenu from "@/components/ui/RocDropdownMenu.vue";
import RocInput from "@/components/ui/RocInput.vue";
import filterMultiSelect from "@/components/ui/filterMultiSelect";
import RocButton from "@/components/ui/RocButton.vue";
import { useStore } from "vuex";

const props = defineProps({
  group: {
    type: String,
    required: true
  }
});

const emits = defineEmits(["collapseDiv", "groupEvent"]);
const store = useStore();
const isDivCollapsed = ref(false);
const dropdownOptions = ref(false);
const allCameras = store.getters["cameras/cameras"];
let origionalGroupList = [];

//dialog vars
const showDialog = ref(false);
const groupName = ref(props.group);
const selectedCameras = ref([]);
const disableSave = ref(true);

const isAdmin = computed(function() {
  return store.getters["auth/isAdmin"];
});

function collapseDiv() {
  emits("collapseDiv", props.group);
  isDivCollapsed.value = !isDivCollapsed.value;
}

function deleteGroup() {
  const updateArr = [];
  allCameras.forEach((camera) => {
    let index = camera.cameraGroups.indexOf(props.group);
    if (index >= 0) {
      let newCameraGroup = camera.cameraGroups.slice(0);
      newCameraGroup.splice(index, 1);
      updateArr.push({
        GUID: camera.GUID,
        updates: { cameraGroups: newCameraGroup }
      });
    }
  });
  emits("groupEvent", updateArr, props.group);
}

function editGroup() {
  selectedCameras.value = [];
  allCameras.forEach((camera) => {
    if (camera.cameraGroups?.includes(props.group))
      selectedCameras.value.push(camera.GUID);
  });
  origionalGroupList = selectedCameras.value.slice(0);
  dropdownOptions.value = false;
  showDialog.value = true;
}

function updateSelectedCameras(selectedCams) {
  selectedCameras.value = selectedCams.value;
  disableSave.value = groupName.value.length == 0;
}

function pressSave() {
  let updates = [];
  allCameras.forEach((camera) => {
    if (
      selectedCameras.value.includes(camera.GUID) ||
      camera?.cameraGroups.includes(props.group)
    ) {
      let newCameraGroup = camera.cameraGroups?.slice(0) ?? [];
      let index = camera.cameraGroups.indexOf(props.group);
      if (
        origionalGroupList.includes(camera.GUID) &&
        !selectedCameras.value.includes(camera.GUID)
      ) {
        if (index >= 0) {
          newCameraGroup.splice(index, 1);
          updates.push({
            GUID: camera.GUID,
            updates: { cameraGroups: newCameraGroup }
          });
        }
      } else if (groupName.value != props.group) {
        if (index >= 0) newCameraGroup.splice(index, 1);
        newCameraGroup.push(groupName.value);
        updates.push({
          GUID: camera.GUID,
          updates: { cameraGroups: newCameraGroup }
        });
      } else if (index == -1) {
        newCameraGroup.push(groupName.value);
        updates.push({
          GUID: camera.GUID,
          updates: { cameraGroups: newCameraGroup }
        });
      }
    }
  });
  emits("groupEvent", updates);
  showDialog.value = false;
}

watch(groupName, () => {
  disableSave.value = groupName.value.length == 0;
});
</script>

<template>
  <div class="groupCard">
    <div :title="group">
      <RocIcon
        @click="collapseDiv"
        :rotate270="isDivCollapsed"
        icon="downArrow"
        size="sm"
        style="margin-right: var(--spacing-m); cursor: pointer;"
      />
      {{ group }}
    </div>
    <div v-if="isAdmin">
      <MDBDropdown v-model="dropdownOptions">
        <MDBDropdownToggle
          style="color:black;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
                        padding: 8px;"
          tag="a"
          @click="dropdownOptions = !dropdownOptions"
        >
          <RocIcon
            color="black"
            size="sm"
            icon="kebab"
            :id="`${group}-dropdownMenu`"
          />
        </MDBDropdownToggle>
        <RocDropdownMenu aria-labelledby="dropdownMenuButton">
          <MDBDropdownItem href="" @click.prevent="editGroup">
            <div class="d-flex justify-content-between">
              Edit
              <RocIcon icon="edit" color="black" size="sm" />
            </div>
          </MDBDropdownItem>
          <hr class="dropdown-divider" />
          <MDBDropdownItem href="" @click.prevent="deleteGroup">
            <div class="d-flex justify-content-between">
              Delete
              <RocIcon icon="trash" color="red" size="sm" />
            </div>
          </MDBDropdownItem>
        </RocDropdownMenu>
      </MDBDropdown>
    </div>
    <base-dialog
      :show="showDialog"
      :title="'Edit Group'"
      @close="showDialog = !showDialog"
    >
      <div id="CameraGroupCRUD" class="crudDialog">
        <div>
          Group Name
          <RocInput
            type="text"
            v-model.trim="groupName"
            :maxLength="30"
            :placeholder="'Enter a name for the camera group'"
          />
          <div class="d-flex justify-content-end overwatch-body-xsmall">
            {{ groupName.length + "/30" }}
          </div>
        </div>
        <div>
          <div class="overwatch-title-small">Move Cameras</div>
          <span>Cameras</span>
          <filterMultiSelect
            style="margin:8px 0px 2px;"
            mode="tags"
            :close-on-select="false"
            placeholder-text="Select Cameras"
            :available-items="allCameras"
            :createTags="false"
            :currently-selected="selectedCameras"
            @selection-changed="updateSelectedCameras"
            :enableClear="false"
          />
        </div>
        <div class="buttonGroup">
          <RocButton
            type="secondary"
            id="rocGroupClosebtn"
            @click="showDialog = !showDialog"
            >Cancel</RocButton
          >
          <RocButton
            @click="pressSave"
            id="rocGroupSavebtn"
            :disabled="disableSave"
            >Save</RocButton
          >
        </div>
      </div>
    </base-dialog>
  </div>
</template>

<style scoped lang="scss">
.groupCard {
  display: flex;
  justify-content: space-between;
  height: 80px;
  display: flex;
  min-height: 80px;
  margin: var(--spacing-m) var(--spacing-xl);
  padding: 26px 16px 26px 16px;
  box-shadow: 0 0.5px 2px 0 rgba(0, 0, 0, 0.25);
  background-color: var(--overwatch-secondary);
}

.crudDialog {
  width: 400px;
  min-height: 300px;
  border-radius: 5px;
}

.buttonGroup {
  margin-left: auto;
  gap: var(--spacing-s);
  margin-top: var(--spacing-m);
  display: flex;
  justify-content: end;
}

@media (max-width: 480px) {
  .crudDialog {
    width: 350px;
  }
}
</style>
