<template>
  <div
    v-if="isLoading || isLoadingError"
    style="width: 100%; display: flex; align-items: center; justify-content: center;"
  >
    <RocSpinner v-if="isLoadingSpinner" style="height: auto; width: auto;" />
    <div
      v-if="isLoadingError"
      class="d-flex flex-column align-items-center"
      style="padding: var(--spacing-l);"
    >
      <img
        src="@/assets/ROC_Logo_White.svg"
        style="max-height: 100px; width: auto;"
      />
      <div class="overwatch-title-med">{{ loadingErrorMsg }}</div>
    </div>
  </div>
  <div v-else class="bg-container" :style="bgStyle">
    <!-- <base-dialog v-if="isShowingSettings" :show="true" title="ROC Watch Server Info" @close="isShowingSettings=false;" style="z-index: 1000; width: 90%; overflow-y: auto;">
    <LoginSettings @close="isShowingSettings=false;" :config="serverUrl" @saved="settingsSaved = true;"/>
  </base-dialog> -->
    <base-dialog
      v-if="isShowingSettings"
      :show="true"
      title="ROC Watch Server Info"
      @close="isShowingSettings = false"
      style="z-index: 1000; width: 90%; overflow-y: auto;"
    >
      <ConfigListGroup :presetServerList="presetServers" />
    </base-dialog>
    <div class="top-border">
      <div class="american-made-logo">
        <img :src="americanMadeLogoSrc" />
      </div>
    </div>
    <div class="login-card" :style="cardStyle">
      <div class="login-panel">
        <div class="logo-and-welcome">
          <div class="roc-watch-logo">
            <RocLogo :width="logoWidth" />
          </div>
          <span class="welcome overwatch-title-large">{{ msgTitle }}</span>
        </div>
         <div class="login-content" v-if="!forgotPasswordVisible">
        <div v-if="!defaultSSO || !enableSSO" style="width:100%">
          <div v-if="!inputHideLabels" class="emailPasswordLabel">Email</div>
          <div
            class="d-flex align-items-center rectangle"
            :style="inputStyleUser"
            @mouseover="isHoveredUser = true"
            @mouseleave="isHoveredUser = false"
          >
            <input
              class="inputEmail"
              v-model="user"
              placeholder="Email Address"
              type="email"
              :style="inputValueStyle"
              @keydown.enter="loginClicked"
            />
          </div>
          <div v-if="!inputHideLabels" class="emailPasswordLabel">Password</div>
          <div
            class="d-flex align-items-center rectangle"
            :style="inputStylePassword"
            @mouseover="isHoveredPassword = true"
            @mouseleave="isHoveredPassword = false"
          >
            <input
              class="inputPassword"
              v-model="password"
              placeholder="Password"
              :type="passwordInputType"
              @keydown.enter="loginClicked"
              :style="inputValueStyle"
            />
            <RocIcon
              size="md"
              :icon="passwordIcon"
              @click="togglePasswordVisibility"
              style="float: right; margin-right: 10px; cursor: pointer;"
            />
          </div>
        </div>
        <div
          v-if="!isLoggingIn"
          class="login-rectangle"
          :class="{ disabled: !settingsSaved }"
          @click="loginClicked"
        >
          <span class="log-in">{{ logInButtonText }}</span
          ><br />
        </div>
        <div v-else class="login-spinner">
          <roc-spinner />
        </div>
        <div v-if="isMobile" class="settings-rectangle">
          <RocIcon
            icon="settings"
            style="cursor: pointer;"
            size="md"
            @click="launchSettings"
          />
        </div>
        <div v-if="loginFailed" class="invalid-credentials">
          {{ errorString }}
        </div>
        <div v-else style="height:30px;"></div>
        <div
          v-if="enableSSO"
          class="login-sso"
          :style="styleSSO"
          @click="secondaryLoginClicked"
          @mouseover="isHoveredSSO = true"
          @mouseleave="isHoveredSSO = false"
        >
          {{ logInSecondaryText }}
        </div>
        <div class="d-flex justify-content-end" style="margin-left: auto; cursor: pointer; text-decoration: underline; color: var(--overwatch-primary)">
          <div @click="showForgotPassword">Forgot Password?</div>
        </div>
        </div>
        <ForgotPassword v-else="forgotPasswordVisible" @cancel="hideForgotPassword"/>
      </div>
    </div>
    <div v-if="!hideFooter" class="bottom-border">
      <div class="border-element">
        <a href="mailto:support@roc.ai">
          Help
        </a>
      </div>
      <div class="border-element">
        <a href="https://roc.ai/privacy-policy/" target="_blank">
          Privacy
        </a>
      </div>
      <div class="border-element">
        <a href="https://www.roc.ai" target="_blank">
          Website
        </a>
      </div>
    </div>

    <!-- Dialog for server URL settings to be available on frontends not deployed in full stack like Mobile -->
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import BaseDialog from "@/components/ui/BaseDialog";
import LoginSettings from "@/components/settings/LoginSettings";
import { Capacitor } from "@capacitor/core";
import ConfigListGroup from "@/components/ui/ConfigListGroup";
import userAccessHelper from "@/js/userAccessHelper";
import RocLogo from "@/components/ui/RocLogo.vue";
import RocIcon from "@/components/ui/RocIcon.vue";
import RocSpinner from "@/components/ui/RocSpinner";
import ForgotPassword from "@/components/settings/ForgotPassword.vue";

export default {
  name: "login",
  components: {
    BaseDialog,
    LoginSettings,
    Capacitor,
    ConfigListGroup,
    RocIcon,
    RocLogo,
    RocSpinner,
    ForgotPassword
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const passwordInputType = ref("password");
    const passwordIcon = ref("invisible");
    const showPassword = ref(false);
    const user = ref(null);
    const password = ref(null);
    const loginFailed = ref(false);
    const isShowingSettings = ref(false);
    const errorString = ref("");
    const isLoggingIn = ref(false);
    const queryParams = ref(null);
    const americanMadeLogoSrc = ref("");
    const msgTitle = ref("");
    const msgSubtitle = ref("");
    const hideFooter = ref(true);
    const buttonBackgroundColor = ref("");
    const buttonFontColor = ref("");
    const buttonBorderRadius = ref("");
    const buttonTextTransform = ref("");
    const backgroundSrc = ref("");
    const fontFamily = ref("");
    const inputRectangleStyle = ref("");
    const inputValueStyle = ref("");
    let inputRectangleStyleHover = "";
    let inputRectangleStyleValid = "";
    const isHoveredUser = ref(false);
    const isHoveredPassword = ref(false);
    const isHoveredSSO = ref(false);
    const labelStyleSecondary = ref("");
    let labelStyleSecondaryHover = "";
    const defaultSSO = ref(false);
    const msgLogInSSO = ref("Log in with SSO");
    const msgLogInAdmin = ref("Log in");
    const enableSSO = ref(false);
    const backgroundColor = ref();
    const loginCardBackgroundColor = ref();
    const loginCardFontColor = ref();
    const loginCardAlign = ref();
    const buttonWidth = ref();
    const logoWidth = ref();
    const inputHideLabels = ref(false);
    const isLoading = ref(true);
    const isLoadingSpinner = ref(false);
    const isLoadingError = ref(false);
    const loadingErrorMsg = ref("");
    // if loading takes longer than 1.5 seconds, this will show a spinner
    const showLoadingSpinner = setInterval(() => {
      isLoadingSpinner.value = true;
    }, 1500);

    function togglePasswordVisibility() {
      if (passwordInputType.value === "password") {
        passwordInputType.value = "text";
        passwordIcon.value = "visible";
      } else if (passwordInputType.value === "text") {
        passwordInputType.value = "password";
        passwordIcon.value = "invisible";
      }
    }

    function loginClicked() {
      if (enableSSO.value && defaultSSO.value) {
        loginSSO();
      } else {
        login();
      }
    }

    function secondaryLoginClicked() {
      if (enableSSO.value && defaultSSO.value) {
        defaultSSO.value = false;
      } else {
        loginSSO();
      }
    }

    async function loginSSO() {
      hideError();
      isLoggingIn.value = true;
      try {
        await store.dispatch("auth/loginSSO");
      } catch (err) {
        displayError("SSO Login Failed.");
        isLoggingIn.value = false;
      }
    }

    async function login() {
      hideError();
      hideKeyboard();
      const payload = { email: user.value, password: password.value };
      try {
        isLoggingIn.value = true;
        await store.dispatch("auth/login", payload);
        await loadUserSettings();
        const userAccess = new userAccessHelper();
        isLoggingIn.value = false;
        if (userAccess.isDashboardRole() && userAccess.getStartPage()) {
          window.location.href = userAccess.getStartPage();
        } else {
          await router.push({
            path: userAccess.getStartPage(),
            query: queryParams.value
          });
        }
      } catch (err) {
        displayError("Login Failed.");
        isLoggingIn.value = false;
        return;
      }
    }

    function launchSettings() {
      hideError();
      isShowingSettings.value = true;
    }

    // computed here to pass to settings dialog as a prop
    const serverUrl = computed(function() {
      return {
        protocol: store.getters["auth/serverProtocol"],
        host: store.getters["auth/serverHost"],
        port: store.getters["auth/serverPort"]
      };
    });

    const presetServers = computed(function() {
      return store.getters["auth/presetServers"];
    });

    function displayError(errorMessage) {
      errorString.value = errorMessage;
      loginFailed.value = true;
    }
    function hideError() {
      errorString.value = "";
      loginFailed.value = false;
    }

    const isMobile = computed(function() {
      return Capacitor ? Capacitor.getPlatform() != "web" : false;
    });

    const settingsSaved = computed(() => {
      if (!isMobile.value) {
        return true;
      } else {
        // Check if server is set by checking if server name exists
        if (store.getters["auth/serverName"]) {
          return true;
        } else {
          return false;
        }
      }
    });

    onMounted(async () => {
      await initUI();
      // cache any query params to send through after login
      queryParams.value = route.query;

      // display sso login error if present
      if (queryParams.value && queryParams.value["error"] !== undefined) {
        displayError(queryParams.value["error"]);
        isLoggingIn.value = false;
      }
    });

    // this function hides on screen keyboards, which is useful on mobile when logging in - small change for better UX
    function hideKeyboard() {
      document.activeElement.blur();
    }

    /**
     * Helper function to query user-based data/settings upon login
     */
    async function loadUserSettings() {
      // user groups depend on current user, reload on login
      await store.dispatch("cases/loadCases");
      await store.dispatch("settings/getUXSetting");
      await store.dispatch("settings/loadUserGroups");
    }

    async function initUI() {
      const preAuthConfig = store.getters["auth/preAuthConfig"];
      if (!preAuthConfig) {
        loadingErrorMsg.value = "Initialization failed, check server settings.";
        isLoadingError.value = true;
        isLoading.value = false;
        clearInterval(showLoadingSpinner);
        return;
      }
      enableSSO.value = preAuthConfig.enableSSO ?? false;
      getAmericanMadeLogo(preAuthConfig);
      msgTitle.value = preAuthConfig.msgTitle ?? "Welcome!";
      if (preAuthConfig.msgSubtitle === "") {
        msgSubtitle.value = preAuthConfig.msgSubtitle;
      } else {
        msgSubtitle.value =
          preAuthConfig.msgSubtitle ?? "Please log in to your account";
      }
      hideFooter.value =
        (preAuthConfig.hideFooter ?? false) ||
        (preAuthConfig.isWhiteLabelEnabled ?? false);
      buttonBackgroundColor.value =
        preAuthConfig.buttonColorBackground ?? "var(--overwatch-primary)";
      buttonFontColor.value =
        preAuthConfig.buttonColorText ?? "var(--overwatch-button-text)";
      buttonBorderRadius.value = preAuthConfig.buttonBorderRadius ?? "4px";
      buttonTextTransform.value = preAuthConfig.buttonTextTransform ?? "";
      getBackground(preAuthConfig);
      fontFamily.value =
        preAuthConfig.fontFamily ??
        getComputedStyle(document.documentElement).getPropertyValue(
          "--font-family"
        );
      if (preAuthConfig.documentTitle) {
        document.title = preAuthConfig.documentTitle;
      }
      if (preAuthConfig.favoriteIconBase64) {
        const favicon = document.getElementById("favicon");
        favicon.href = preAuthConfig.favoriteIconBase64;
      }
      inputRectangleStyle.value =
        preAuthConfig.styleLoginRectangle ??
        "border: 1px solid var(--overwatch-neutral-300); background-color: var(--overwatch-neutral-500); border-radius: 5px;";
      inputValueStyle.value =
        preAuthConfig.styleLoginInput ??
        "background-color: var(--overwatch-neutral-500);";
      inputRectangleStyleHover = preAuthConfig.styleLoginInputHover ?? "";
      inputRectangleStyleValid =
        preAuthConfig.styleLoginInputValid ??
        "color: var(--overwatch-neutral-100);";

      labelStyleSecondary.value = preAuthConfig.styleLoginSecondary;
      labelStyleSecondaryHover = preAuthConfig.styleLoginSecondaryHover;
      defaultSSO.value = preAuthConfig.defaultLoginSSO ?? false;
      if (preAuthConfig.msgLogInSSO) {
        msgLogInSSO.value = preAuthConfig.msgLogInSSO;
      }
      if (preAuthConfig.msgLogInAdmin) {
        msgLogInAdmin.value = preAuthConfig.msgLogInAdmin;
      }
      backgroundColor.value = preAuthConfig.loginPageBackgroundColor;
      loginCardBackgroundColor.value =
        preAuthConfig.loginCardBackgroundColor ?? "var(--overwatch-secondary)";
      loginCardFontColor.value =
        preAuthConfig.loginCardFontColor ?? "var(--overwatch-neutral-100)";
      loginCardAlign.value = preAuthConfig.loginCardAlign ?? "center";
      buttonWidth.value = preAuthConfig.buttonWidth ?? "460px";
      logoWidth.value = preAuthConfig.logoWidth ?? "250px";
      inputHideLabels.value = preAuthConfig.inputHideLabels === true;
      isLoading.value = false;
      clearInterval(showLoadingSpinner);
    }

    function getAmericanMadeLogo(preAuthConfig) {
      let configValue;
      try {
        configValue = preAuthConfig.madeInUSAImageBase64;
        if (typeof configValue === "undefined") {
          configValue = "ROC_American_Flag_White.svg";
        }
      } catch (err) {
        console.error(err);
        configValue = "ROC_American_Flag_White.svg";
      }

      if (configValue === "" || preAuthConfig.isWhiteLabelEnabled) {
        americanMadeLogoSrc.value = "";
        return;
      }
      if (require("@/js/base64Helper").isBase64DataUri(configValue)) {
        americanMadeLogoSrc.value = configValue;
      } else {
        const images = require.context("@/assets/");
        americanMadeLogoSrc.value = images("./" + configValue);
      }
    }

    function getBackground(preAuthConfig) {
      let configValue;
      try {
        configValue = preAuthConfig.backgroundImageBase64;
        if (typeof configValue === "undefined") {
          configValue = "Login-Gradient.svg";
        }
      } catch (err) {
        console.error(err);
        configValue = "Login-Gradient.svg";
      }
      if (configValue === "") {
        backgroundSrc.value = "";
        return;
      }
      if (require("@/js/base64Helper").isBase64DataUri(configValue)) {
        backgroundSrc.value = configValue;
      } else {
        const images = require.context("@/assets/login/");
        backgroundSrc.value = images("./" + configValue);
      }
    }

    const bgStyle = computed(() => {
      if (backgroundSrc.value) {
        return {
          "font-family": fontFamily.value,
          "background-image": "url(" + backgroundSrc.value + ")"
        };
      } else {
        return {
          "font-family": fontFamily.value,
          "padding-top": "0px"
        };
      }
    });

    const cardStyle = computed(() => {
      if (backgroundSrc.value) {
        return {
          "box-shadow": " 0 4px 8px 0 var(--overwatch-overlay)",
          "border-radius": "15px"
        };
      } else {
        return {};
      }
    });

    const inputStyleUser = computed(() => {
      let style = inputRectangleStyle.value;
      if (isHoveredUser.value) {
        style += inputRectangleStyleHover;
      }
      if (user.value) {
        style += inputRectangleStyleValid;
      }
      return style;
    });

    const inputStylePassword = computed(() => {
      let style = inputRectangleStyle.value;
      if (isHoveredPassword.value) {
        style += inputRectangleStyleHover;
      }
      if (password.value) {
        style += inputRectangleStyleValid;
      }
      return style;
    });

    const styleSSO = computed(() => {
      let style = labelStyleSecondary.value;
      if (isHoveredSSO.value) {
        style += labelStyleSecondaryHover;
      }
      return style;
    });

    const logInButtonText = computed(() => {
      if (enableSSO.value && defaultSSO.value) {
        return msgLogInSSO.value;
      } else {
        return msgLogInAdmin.value;
      }
    });

    const logInSecondaryText = computed(() => {
      if (enableSSO.value && defaultSSO.value) {
        return msgLogInAdmin.value;
      } else {
        return msgLogInSSO.value;
      }
    });

    function showForgotPassword() {
      msgTitle.value = "Forgot Password";
      forgotPasswordVisible.value = true;
    }

    function hideForgotPassword() {
      msgTitle.value = "Welcome!";
      forgotPasswordVisible.value = false;
    }

    const forgotPasswordVisible = ref(false);

    return {
      showPassword,
      passwordInputType,
      passwordIcon,
      togglePasswordVisibility,
      loginClicked,
      user,
      password,
      loginFailed,
      isShowingSettings,
      launchSettings,
      serverUrl,
      errorString,
      isLoggingIn,
      isMobile,
      settingsSaved,
      presetServers,
      msgTitle,
      msgSubtitle,
      hideFooter,
      buttonBackgroundColor,
      buttonFontColor,
      buttonBorderRadius,
      buttonTextTransform,
      bgStyle,
      cardStyle,
      inputValueStyle,
      inputStyleUser,
      inputStylePassword,
      isHoveredUser,
      isHoveredPassword,
      isHoveredSSO,
      msgLogInSSO,
      styleSSO,
      defaultSSO,
      msgLogInAdmin,
      logInButtonText,
      logInSecondaryText,
      secondaryLoginClicked,
      enableSSO,
      americanMadeLogoSrc,
      fontFamily,
      backgroundColor,
      loginCardBackgroundColor,
      loginCardFontColor,
      loginCardAlign,
      buttonWidth,
      logoWidth,
      inputHideLabels,
      isLoading,
      isLoadingSpinner,
      isLoadingError,
      loadingErrorMsg,
      forgotPasswordVisible,
      showForgotPassword,
      hideForgotPassword
    };
  }
};
</script>

<style scoped lang="scss">
.bg-container {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 100%;
  min-width: 860px;
  min-height: 700px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: v-bind(backgroundColor);
}

.roc-watch-logo {
  width: v-bind(logoWidth);
}

.login-card {
  // float: left;
  background-color: v-bind(loginCardBackgroundColor);
  min-width: 580px;
  width: 581px;
  height: 566px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: v-bind(loginCardFontColor);
}

.login-panel {
  width: 460px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: v-bind(loginCardAlign);
}

.logo-and-welcome{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.emailPasswordLabel {
  margin-top: var(--spacing-l);
}

.top-border {
  position: absolute;
  top: 0;
  width: 100%;
  background-color: transparent;
  color: var(--overwatch-neutral-100);
  display: flex;
  justify-content: start;
  align-items: center;
  gap: var(--spacing-m);
  padding-left: var(--spacing-xl);
  padding-top: var(--spacing-xl);
}

.bottom-border {
  position: absolute;
  bottom: 0;
  height: 64px;
  width: 100%;
  background-color: transparent;
  color: var(--overwatch-neutral-100);
  display: flex;
  justify-content: end;
  align-items: center;
  gap: var(--spacing-m);
  padding-right: var(--spacing-xl);
}

.border-element {
  color: #ffffff;
  cursor: pointer;
}

.border-element a {
  color: #ffffff;
}

.american-made-logo {
  height: 60px;
  width: 80px;
}

.welcome {
  @include overwatch-title-large;
  font-family: v-bind(fontFamily);
  // margin-bottom: 22px;
  letter-spacing: 0;
  line-height: 48px;
}

.rectangle {
  box-sizing: border-box;
  height: 54px;
  width: 460px;
}

.login-rectangle {
  height: 44px;
  width: v-bind(buttonWidth);
  margin-top: 22px;
  color: v-bind(buttonFontColor);
  border-radius: v-bind(buttonBorderRadius);
  background-color: v-bind(buttonBackgroundColor);
  text-transform: v-bind(buttonTextTransform);
  text-align: center;
  cursor: pointer;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.login-rectangle:hover {
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.disabled {
  background-color: var(--overwatch-neutral-200);
  cursor: default;
  pointer-events: none;
}

.settings-rectangle {
  height: 44px;
  width: 100%;
  margin-top: 10px;
  text-align: center;
  cursor: pointer;
}

.login-spinner {
  height: 44px;
  width: 460px;
  margin-top: 10px;
  text-align: center;
}

.log-in {
  height: 23px;
  width: 47px;
  color: v-bind(buttonFontColor);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 45px;
  text-align: center;
  font-family: v-bind(fontFamily);
}

.login-sso {
  position: relative;
  font-size: 20px;
  font-weight: 800;
  text-align: center;
  margin: 0 auto;
  margin-top: 20px;
  cursor: pointer;
  text-decoration: underline;
}

.inputEmail {
  border: 0px;
  margin-left: 10px;
  width: 90%;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 25px;
}

.inputEmail::placeholder {
  color: var(--overwatch-neutral-300);
  font-family: v-bind(fontFamily) !important;
}

.inputPassword {
  border: 0px;
  margin-left: 10px;
  width: 90%;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 25px;
}

.inputPassword::placeholder {
  color: var(--overwatch-neutral-300);
  font-family: v-bind(fontFamily) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  background: var(--overwatch-neutral-500) !important;
  -webkit-text-fill-color: var(
    --overwatch-neutral-100
  ) !important; //color would be overwritten by autofill
}

.invalid-credentials {
  color: var(--overwatch-error);
  font-size: 18px;
  letter-spacing: 0;
  line-height: 30px;
  height: 30px;
  z-index: 999;
}

/* IPAD PORTRAIT */
@media only screen and (max-width: 810px) and (orientation: portrait) {
  .bg-container {
    min-width: 0;
    width: 100vw;
  }
}

@media (max-width: 480px) {
  .welcome {
    @include overwatch-title-med;
    font-family: v-bind(fontFamily);
    margin-bottom: var(--spacing-base);
  }

  .american-made-logo {
    height: 40px;
    width: 60px;
  }

  .roc-watch-logo {
    margin-bottom: 24px;
  }

  .bg-container {
    position: static;
    padding-top: 20vh;
    padding-bottom: 20vh;
    padding-right: var(--spacing-m);
    padding-left: var(--spacing-m);
    min-width: 0;
    min-height: 0;
    overflow: hidden !important;
  }

  .top-border {
    padding-left: var(--spacing-m);
    padding-top: var(--spacing-m);
  }

  .emailPasswordLabel {
    margin-top: var(--spacing-l);
    margin-bottom: var(--spacing-base);
  }

  .login-card {
    float: none;
    min-width: 0;
    width: 100vw;
    padding: var(--spacing-xxl) var(--spacing-xl);
    margin: var(--spacing-l) 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .login-panel {
    width: 100% !important;
  }

  .login-spinner {
    width: 100%;
  }

  .rectangle {
    @include overwatch-body-med;
    font-family: v-bind(fontFamily);
    width: 100%;
  }

  .login-rectangle {
    width: 100%;
  }

  .login-spinner {
    width: 100%;
  }

  .bottom-border {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0 var(--spacing-s) 0;
    height: auto;
  }

  .invalid-credentials {
    font-size: 12px;
  }
}
</style>
