<template>
  <div class="wrapper">
    <div class="input-row">
      <div>Case Name</div>
      <input v-model="caseName" />
    </div>
    <div class="input-row">
      <div>Case Description</div>
      <textarea
        rows="5"
        type="text"
        style="height:100%"
        v-model="caseDescription"
      ></textarea>
    </div>
    <div class="input-row" v-if="caseType === 'postevent'">
      <div>Watchlists</div>
      <filter-multi-select
        mode="tags"
        :close-on-select="false"
        no-results-text=""
        placeholder-text="Select Watchlists"
        :available-items="availableWatchlists"
        :currently-selected="selectedWatchlists"
        @selection-changed="handleWatchlistsSelection"
      />
    </div>
    <div class="input-row">
      <div>User Groups</div>
      <UserGroupFilterMultiSelect
        :currently-selected="userGroups"
        @selection-changed="updateSelectedUserGroups"
        mode="edit"
      />
    </div>

    <div class="input-row" v-if="caseType === 'postevent'">
      <div>Cameras</div>
      <div class="cameras-files">
        <div v-if="loadingCameras" style="display: flex;">
          <roc-spinner style="margin: auto;" />
        </div>
        <div v-else-if="caseCameras.length === 0">
          No case cameras.
        </div>
        <div v-for="camera of caseCameras" :key="camera._id" class="camera">
          <div>
            {{ camera.name }}
          </div>
          <RocIcon
            icon="trash"
            color="red"
            @click="handleDeleteCamera(camera)"
            class="camera-delete"
          />
        </div>
      </div>
    </div>
    <footer v-if="!isSaving">
      <div class="footer-message">{{ footerMessage }}</div>
      <RocButton
        type="secondary"
        size="sm"
        @click="$emit('close')"
        style="padding: var(--spacing-s); margin-right: var(--spacing-s)"
        >Cancel
      </RocButton>
      <RocButton
        type="primary"
        size="sm"
        @click="updateCase"
        style="padding: var(--spacing-s)"
        >Save
      </RocButton>
    </footer>
    <div v-else>
      <RocSpinner />
    </div>
  </div>
  <BaseDialog
    v-if="isShowingDeleteCamera"
    show
    title="Delete Camera"
    @close="isShowingDeleteCamera = false"
  >
    <DeleteConfirmation
      @close="isShowingDeleteCamera = false"
      @delete="deleteCaseCamera(deletingCamera)"
    >
      Are you sure you want to delete camera
      <span style="color:var(--overwatch-error)">{{ deletingCamera.name }}</span
      >? This action cannot be undone.
    </DeleteConfirmation>
  </BaseDialog>
</template>

<script>
import { ref, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";

import BaseDialog from "@/components/ui/BaseDialog";
import filterMultiSelect from "@/components/ui/filterMultiSelect";
import DeleteConfirmation from "@/components/settings/DeleteConfirmation";
import RocButton from "@/components/ui/RocButton.vue";

import UserGroupFilterMultiSelect from "@/components/ui/UserGroupFilterMultiSelect";

import RocIcon from "@/components/ui/RocIcon";
import RocSpinner from "@/components/ui/RocSpinner";

export default {
  name: "CaseEditDetails",
  props: ["case"],
  emits: ["close", "save", `delete-camera`],
  components: {
    filterMultiSelect,
    BaseDialog,
    DeleteConfirmation,
    RocButton,
    RocIcon,
    UserGroupFilterMultiSelect,
    RocSpinner
  },
  setup(props, context) {
    const store = useStore();

    const caseId = ref(props.case._id);
    const caseName = ref(props.case.name);
    const caseDescription = ref(props.case.description);
    const caseType = ref(props.case.type);
    const userGroups = ref(props.case.userGroups);
    const isSaving = ref(false);

    const footerMessage = ref("");

    async function updateCase() {
      isSaving.value = true;
      const payload = {
        id: caseId.value,
        name: caseName.value,
        description: caseDescription.value,
        watchlistIds: selectedWatchlists.value,
        type: caseType.value,
        userGroups: userGroups.value
      };
      var response = await store.dispatch("cases/updateCase", payload);

      if (response.status === "success") {
        // reload cases after making updates
        await store.dispatch("cases/loadCases");
        isSaving.value = false;
        context.emit("save");
        context.emit("close");
      } else {
        isSaving.value = false;
        footerMessage.value = "Error saving changes. Please try again.";
      }
    }

    const availableWatchlists = computed(function() {
      return store.getters["watchlists/watchlists"];
    });
    const selectedWatchlists = ref(props.case.watchlistIds);

    function handleWatchlistsSelection(newSelections) {
      selectedWatchlists.value = newSelections.value;
    }

    const caseCameras = ref([]);
    const loadingCameras = ref(false);
    onMounted(async () => {
      loadingCameras.value = true;
      const camResponse = await store.dispatch("cases/getCamerasByCaseId", {
        caseId: caseId.value
      });

      caseCameras.value = camResponse.result;
      loadingCameras.value = false;
    });

    async function deleteCaseCamera(camera) {
      const payload = {
        caseId: caseId.value,
        cameraId: camera._id
      };

      const result = await store.dispatch("cases/deleteCamera", payload);

      if (result.status === "success") {
        const camIndex = caseCameras.value.findIndex(
          (c) => c._id === camera._id
        );
        caseCameras.value.splice(camIndex, 1);
        context.emit("delete-camera");
      }
    }

    const isShowingDeleteCamera = ref(false);
    const deletingCamera = ref();

    function handleDeleteCamera(camera) {
      isShowingDeleteCamera.value = true;
      deletingCamera.value = camera;
    }

    function updateSelectedUserGroups(g) {
      userGroups.value = g;
    }

    return {
      caseId,
      caseName,
      caseDescription,
      updateCase,
      availableWatchlists,
      selectedWatchlists,
      handleWatchlistsSelection,
      footerMessage,
      caseCameras,
      deleteCaseCamera,
      loadingCameras,
      isShowingDeleteCamera,
      deletingCamera,
      handleDeleteCamera,
      caseType,
      updateSelectedUserGroups,
      userGroups,
      isSaving
    };
  }
};
</script>

<style scoped lang="scss">
.wrapper {
  width: 100%;
}

.input-row {
  width: 100%;
  margin-bottom: 10px;
}

input {
  width: 100%;
  padding: 5px;
  border: 1px solid var(--overwatch-neutral-300);
  border-radius: 4px;
}

textarea {
  width: 100%;
  padding: var(--spacing-base);
  border-radius: 4px;
  resize: none;
}

footer {
  display: flex;
}

.footer-message {
  margin-left: auto;
  color: var(--overwatch-error);
}

.cancel-button {
  background: var(--overwatch-neutral-300);
  color: white;
}

.save-button {
  background: var(--overwatch-button-primary);
  color: white;
}

.cameras-files {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-base);
  padding: var(--spacing-s) var(--spacing-base);

  height: fit-content;
  max-height: 300px;
  overflow-y: auto;
}

.camera {
  display: flex;
  border-radius: 5px;
  border: 1px solid var(--overwatch-neutral-300);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
  padding: var(--spacing-s);
  align-items: center;
}

.camera-delete {
  margin-left: auto;
  cursor: pointer;
}
</style>
