<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";

import filterMultiSelect from "@/components/ui/filterMultiSelect";
import UserGroupFilterMultiSelect from "@/components/ui/UserGroupFilterMultiSelect";

const store = useStore();

const props = defineProps();
const emit = defineEmits([
  "change-name",
  "change-description",
  "change-watchlists",
  "change-usergroups"
]);

/* Case Info */
const caseName = ref("");
const caseDescription = ref("");

/* Watchlists */
const availableWatchlists = computed(function() {
  return store.getters["watchlists/watchlists"];
});
const selectedWatchlists = ref([]);
function handleWatchlistsSelection(newSelections) {
  selectedWatchlists.value = newSelections.value;
  emit("change-watchlists", newSelections.value);
}

const selectedUserGroups = ref([]);
function updateSelectedUserGroups(g) {
  selectedUserGroups.value = g;
  emit("change-usergroups", g);
}
</script>

<template>
  <div>
    <div class="input-row">
      <div>Case Name</div>
      <input v-model="caseName" @input="$emit('change-name', caseName)" />
    </div>
    <div class="input-row">
      <div>Case Description</div>
      <textarea
        rows="5"
        type="text"
        style="height:100%"
        v-model="caseDescription"
        @input="$emit('change-description', caseDescription)"
      ></textarea>
    </div>
    <div class="input-row">
      <div>Watchlists</div>
      <filter-multi-select
        style="margin-top: 4px;"
        class="overwatch-body-med"
        mode="tags"
        :close-on-select="false"
        no-results-text=""
        placeholder-text="Select Watchlists"
        :available-items="availableWatchlists"
        :currently-selected="selectedWatchlists"
        @selection-changed="handleWatchlistsSelection"
      />
    </div>
    <div class="input-row">
      <div>User Groups</div>
      <UserGroupFilterMultiSelect
        @selection-changed="updateSelectedUserGroups"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
input {
  width: 100%;
  padding: 12px;
  margin-top: 4px;
  color: var(--overwatch-neutral-100);
  background-color: var(--overwatch-neutral-500);
  border: 1px solid var(--overwatch-neutral-300);
  border-radius: 4px;
  @include overwatch-body-med;
}

textarea {
  margin-top: var(--spacing-base);
  width: 100%;
  padding: var(--spacing-base);
  border: 1px solid var(--overwatch-neutral-300);
  border-radius: 4px;
  resize: none;
  padding: var(--spacing-s);
  @include overwatch-body-med;
}

.input-row {
  width: 100%;
  margin-bottom: var(--spacing-base);
}
</style>
