<template>
  <div
    class="slider"
    :class="{ disabled: disabled }"
    @click.stop="toggleSwitch"
  >
    <RocIcon v-if="isActive" color="primary" size="lg" icon="switchOn" />
    <RocIcon v-else color="gray" size="lg" icon="switchOff" />
  </div>
</template>

<script>
import { MDBSwitch } from "mdb-vue-ui-kit";
import { ref, watch } from "vue";
import RocIcon from "@/components/ui/RocIcon.vue";

export default {
  name: "RocSwitch",
  components: {
    MDBSwitch,
    RocIcon
  },
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ["switch-toggled", "update:modelValue"],
  setup(props, context) {
    /**
     * User can choose to either use isActive and switch-toggled, or v-model
     */

    const isActive = ref(props.isActive || props.modelValue);

    watch(
      () => props.isActive,
      () => {
        isActive.value = props.isActive;
      }
    );

    watch(
      () => props.modelValue,
      () => {
        isActive.value = props.modelValue;
      }
    );

    const toggleSwitch = () => {
      if (props.disabled) return;
      isActive.value = !isActive.value;
      context.emit("switch-toggled", isActive.value);
      context.emit("update:modelValue", isActive.value);
    };

    return {
      isActive,
      toggleSwitch
    };
  }
};
</script>

<style scoped lang="scss">
.slider {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.disabled {
  cursor: unset;
  opacity: 0.5;
}
</style>
