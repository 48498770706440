<template>
  <ClusterEncountersPopper
    :cluster="cluster"
    :show="isPopperOpen"
    @enroll-face="(e) => $emit('enroll-face', e)"
    @enroll-lpr="(e) => $emit('enroll-lpr', e)"
    @close="isPopperOpen = false"
  >
    <div
      class="cluster-card"
      :class="{
        'popper-open': isPopperOpen
      }"
      @click="isPopperOpen = !isPopperOpen"
      @close="isPopperOpen = false"
      @mouseover="isHovering = true"
      @mouseleave="isHovering = false"
    >
      <auth-img
        v-if="cluster.referenceEncounter"
        :src="computedReferenceEncounterThumbnail"
        class="best-thumbnail"
        draggable="false"
      />

      <MDBDropdown
        class="menu"
        v-show="isHovering || isMenuOpen"
        v-model="isMenuOpen"
      >
        <RocDropdownToggle @click.stop="isMenuOpen = !isMenuOpen">
          <RocIcon size="md" icon="kebab" style="color: white;" />
        </RocDropdownToggle>
        <RocDropdownMenu @click.stop="isMenuOpen = false">
          <RocDropdownItem @click="launchAssociatesMode()">
            View Target Details
          </RocDropdownItem>
          <RocDropdownItem
            v-if="isAdminOrPowerUser"
            @click="isShowingMergeClusters = true"
          >
            Merge With...
          </RocDropdownItem>
          <RocDropdownItem
            v-if="isAdminOrPowerUser"
            @click="enrollReferenceEncounter"
          >
            Add Cover Photo to Watchlist
          </RocDropdownItem>
          <RocDropdownItem
            v-if="isAdminOrPowerUser"
            @click="isShowingNotes = true"
          >
            Edit Notes
          </RocDropdownItem>
        </RocDropdownMenu>
      </MDBDropdown>

      <div class="cluster-info">
        <div
          class="cluster-name"
          style="margin-right: var(--spacing-base);"
          :title="cluster.name"
        >
          {{ clusterName }}
          <RocIcon
            color="primary"
            size="sm"
            icon="edit"
            @click.stop="isShowingEditName = true"
          />
          <!-- John Smith and Edit icon-->
        </div>
        <div class="cluster-encounter-count">
          {{ cluster.encounters.length }}
          {{ cluster.encounters.length === 1 ? "Encounter" : "Encounters" }}
        </div>
        <div class="cluster-datetime-tags">
          <!-- Hover Datetime and Tags -->
          <MyPopperWrapper arrow hover class="icon-popper">
            <RocIcon color="buttonPrimary" size="sm" icon="calendar" />
            <template #content>
              <div>
                <div>
                  {{ formattedDatetimeString(cluster.startTimestamp) + " - " }}
                </div>
                <div>
                  {{ formattedDatetimeString(cluster.endTimestamp) }}
                </div>
              </div>
            </template>
          </MyPopperWrapper>
          <MyPopperWrapper
            arrow
            hover
            class="icon-popper"
            style="margin-left: 0;"
          >
            <RocIcon color="buttonPrimary" size="sm" icon="tag" />
            <template #content>
              <div>
                {{ computedTagsString }}
              </div>
            </template>
          </MyPopperWrapper>
        </div>
      </div>

      <!-- Right justified Favorite Star -->
      <RocIcon
        class="cluster-favorite"
        :color="cluster.isFavorite ? 'primary' : 'gray'"
        size="md"
        :icon="cluster.isFavorite ? 'starFilled' : 'starEmpty'"
        @click.stop="toggleFavorite()"
      />
      <BaseDialog
        v-if="isShowingEditName"
        show
        hideCloseBtn
        @close="isShowingEditName = false"
      >
        <ClusterCardEditName
          :name="cluster.name"
          @save="saveEditName"
          @close="isShowingEditName = false"
        />
      </BaseDialog>
      <BaseDialog
        v-if="isShowingMergeClusters"
        @close="isShowingMergeClusters = false"
        show
        hideCloseBtn
        noPadding
        preventCloseOnClickAway
      >
        <MoveToExistingCluster
          mergeClusterMode
          @close="isShowingMergeClusters = false"
          :encounters="cluster.encounters"
          :cluster="cluster"
        />
      </BaseDialog>
      <BaseDialog v-if="isShowingNotes" show hideCloseBtn noPadding>
        <EditClusterNotes @close="isShowingNotes = false" :cluster="cluster" />
      </BaseDialog>
    </div>
  </ClusterEncountersPopper>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import BaseDialog from "@/components/ui/BaseDialog";
import RocIcon from "@/components/ui/RocIcon";
import ClusterCardEditName from "@/components/clusters/ClusterCardEditName";
import ClusterEncountersPopper from "@/components/clusters/ClusterEncountersPopper";
import MyPopperWrapper from "@/components/ui/MyPopperWrapper";
import { MDBDropdown } from "mdb-vue-ui-kit";
import RocDropdownMenu from "@/components/ui/RocDropdownMenu.vue";
import RocDropdownToggle from "@/components/ui/RocDropdownToggle.vue";
import RocDropdownItem from "@/components/ui/RocDropdownItem";
import MoveToExistingCluster from "@/components/clusters/MoveToExistingCluster";
import EditClusterNotes from "@/components/clusters/EditClusterNotes.vue";

import userAccessHelper from "@/js/userAccessHelper";

import moment from "moment";

const store = useStore();

const props = defineProps({
  cluster: {
    type: Object,
    required: true
  }
});

const emit = defineEmits(["enroll-face", "enroll-lpr", "show-associates"]);

const computedReferenceEncounterThumbnail = computed(() => {
  // If props.cluster.modality doesn't exist or it is equal to face, then use the face template id, otherwise object
  if (props.cluster?.modality === "face") {
    const faceTemplateId = props.cluster.referenceEncounter.facetemplateId;
    return `/rest/v1/image/face/${faceTemplateId}/tn`;
  } else {
    const objectTemplateId = props.cluster.referenceEncounter.objtemplateId;
    return `/rest/v1/image/object/${objectTemplateId}/tn`;
  }
});

const isShowingEditName = ref(false);

async function saveEditName(name) {
  const response = await store.dispatch("clusters/updateCluster", {
    id: props.cluster._id,
    update: {
      name: name
    }
  });
}

async function toggleFavorite() {
  const response = await store.dispatch("clusters/updateCluster", {
    id: props.cluster._id,
    update: {
      isFavorite: !props.cluster.isFavorite
    }
  });
}

function formattedDatetimeString(datetime) {
  const date = moment(new Date(datetime));
  const formatted = date.format("MMM DD, yyyy HH:mm:ss");
  return formatted;
}

const isPopperOpen = ref(false);
watch(isPopperOpen, (nv) => {
  emit("popper-open", nv);
});

const computedTagsString = computed(() => {
  if (props.cluster.attributes.length > 0) {
    var output = "";
    let i = 0;
    for (let attribute of props.cluster.attributes) {
      output += attribute;
      if (i < props.cluster.attributes.length - 1) {
        output += ", ";
      }
      i++;
    }
    return output;
  } else {
    return "No tags";
  }
});

const clusterName = computed(() => {
  var maxLength = 14;
  if (props.cluster.name.length > maxLength) {
    return props.cluster.name.substring(0, maxLength) + "...";
  }
  return props.cluster.name;
});

const isHovering = ref(false);
const isMenuOpen = ref(false);
const isShowingMergeClusters = ref(false);

const isShowingNotes = ref(false);

function launchAssociatesMode() {
  emit("show-associates");
}

const isAdminOrPowerUser = computed(() => {
  const helper = new userAccessHelper();
  return helper.isAdminOrPowerUser();
});

function enrollReferenceEncounter() {
  const referenceEncounter = props.cluster.referenceEncounter;
  if (props.cluster?.modality && props.cluster.modality === "license_plate") {
    emit("enroll-lpr", {
      templateId: referenceEncounter.objtemplateId,
      thumbnail: computedReferenceEncounterThumbnail.value,
      personId: referenceEncounter.personId,
      text: referenceEncounter.text,
      watchlistType: "text" //used in EnrollEncounter to determine mode of popup
    });
  } else {
    emit("enroll-face", {
      templateId: referenceEncounter.faceTemplateId,
      thumbnail: computedReferenceEncounterThumbnail.value,
      personId: referenceEncounter.personId
    });
  }
}
</script>

<style scoped lang="scss">
.cluster-card {
  // Card should not change size based on border being set in active mode
  box-sizing: content-box;

  display: flex;
  flex-direction: column;

  height: 320px;
  width: 200px;

  border-radius: 5px;

  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);

  background-color: var(--overwatch-secondary);

  position: relative;
  svg {
    cursor: pointer;
  }

  .best-thumbnail {
    height: 220px;
    width: 100%;
    border-radius: 5px 5px 0 0;
  }
  .cluster-info {
    display: flex;
    flex-direction: column;
    padding: $--spacing-s;

    .cluster-name {
      @include overwatch-body-med;
      color: var(--overwatch-neutral-100);
    }

    .cluster-encounter-count {
      @include overwatch-body-small;
      margin-top: var(--spacing-base);
      color: var(--overwatch-neutral-200);
    }

    .cluster-datetime-tags {
      display: flex;
      gap: $--spacing-base;
    }
  }

  .icon-popper :deep(.popper) {
    background-color: var(--overwatch-primary);
    color: var(--overwatch-button-text);
    border-radius: 5px;
    padding: $--spacing-s $--spacing-m;
    width: fit-content;
    min-width: 240px;
  }

  .icon-popper :deep(.popper #arrow::before) {
    background-color: var(--overwatch-primary);
  }
  .cluster-favorite {
    position: absolute;
    right: $--spacing-s;
    bottom: $--spacing-s;
  }
}

.cluster-card.popper-open {
  // TODO: Change to new colors.
  background-color: var(--overwatch-button-primary-20);
}

.menu {
  position: absolute;
  top: $--spacing-s;
  right: $--spacing-base;
}
</style>
