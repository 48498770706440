import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

const getDefaultState = () => {
  return {
    baseUrl: "",
    trackAnalysisFacetemplates: [],
    currentCase: "",
    examId: null,
    readOnly: false,
    activities: [],
    settings: {
      interpolation: "browser" //browser, nearest, bilinear, bicubic
    }
  };
};

const state = getDefaultState();

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  getDefaultState
};
