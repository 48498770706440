import moment from "moment";

//this function takes a date range, whether with just one date range or multiple date ranges,
//and returns the days of week that were selcted from the calendar UI
//this is to prevent handling the days selected in the date range from the UI, and instead can infer from the data
function determineDaysOfWeekSelected(dateValue) {
  let range = {
    start: dateValue?.[0]?.start ? new Date(dateValue[0].start) : null,
    end: dateValue?.[dateValue.length - 1]?.end
      ? new Date(dateValue[dateValue.length - 1].end)
      : null
  };

  let daysOfWeekInRange = findDaysOfWeekInRange(range);

  /*Update days selected array*/
  //create array of all start days of the week from dateValue
  let allStartDatesArray = [];
  //using dateValue array instead of range to get each individual date selected, rather than just start/end
  for (let i = 0; i < dateValue.length; i++) {
    //Note - because of the way this operates, if a single date range is recieved
    //then the only "allStartDatesArray" value that will be available is the first day of the week
    let startDay = moment(dateValue[i].start).day();
    if (!allStartDatesArray.includes(startDay)) {
      allStartDatesArray.push(startDay);
    }
  }

  let daysOfWeekSelected = [];
  //Single Date Range - If dateValue.length is 1
  if (dateValue.length === 1) {
    //if all days of the week are selected, set daysOfWeekSelected to all days of the week
    //allStartDatesArray.length === 1 is a check for if all dates are selected
    if (allStartDatesArray.length === 1 && daysOfWeekInRange.length === 7) {
      daysOfWeekSelected = [0, 1, 2, 3, 4, 5, 6];
    }
    //if less than one week is selected, set daysOfWeekSelected to all dates within the date range
    else if (allStartDatesArray.length < 7 && daysOfWeekInRange.length < 7) {
      daysOfWeekSelected = [...daysOfWeekInRange];
    }
  }
  //Multiple Date Range - If dateValue.length is > 1
  else if (dateValue.length > 1) {
    daysOfWeekSelected = [...allStartDatesArray];
  }
  //if range is null, set to all days of week {
  else {
    daysOfWeekSelected = [0, 1, 2, 3, 4, 5, 6];
  }

  return daysOfWeekSelected;
}

//finds all days of the week within a date range
function findDaysOfWeekInRange(range) {
  let dateArray = [];
  //create array of all the days of the week from date range
  let weekdays = [];
  const startDate = new Date(range.start);
  const endDate = new Date(range.end);

  while (startDate <= endDate) {
    dateArray.push(new Date(startDate));
    startDate.setDate(startDate.getDate() + 1);
  }

  //create array of weekdays from dateArray
  for (let i = 0; i < dateArray.length; i++) {
    //push day of week number to weekdays array
    weekdays.push(moment(dateArray[i]).day());
  }

  //remove duplicates from weekdays array
  weekdays = [...new Set(weekdays)];

  return weekdays;
}

//determines the display string for the date range
//daysOfWeekSelected is optional, and if not provided, will be determined from the range
function dateRangeDisplayCalcHelper(range, daysOfWeekSelected) {
  let dateRange = "";
  let weekdays = "";
  let weekdayMap = {
    0: "S",
    1: "M",
    2: "T",
    3: "W",
    4: "T",
    5: "F",
    6: "S"
  };

  if (!daysOfWeekSelected) {
    daysOfWeekSelected = determineDaysOfWeekSelected(range);
    range = {
      start: range?.[0]?.start ? new Date(range[0].start) : null,
      end: range?.[range.length - 1]?.end
        ? new Date(range[range.length - 1].end)
        : null
    };
  }

  if (range.start && range.end && daysOfWeekSelected) {
    let options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hourCycle: "h23"
    };
    dateRange = `${range.start.toLocaleString(
      "en-US",
      options
    )} - ${range.end.toLocaleString("en-US", options)}`;

    //weekdays selected sort and formatting
    if (daysOfWeekSelected.length > 0 && daysOfWeekSelected.length < 7) {
      daysOfWeekSelected.sort((a, b) => a - b);
      weekdays = daysOfWeekSelected.map((day) => weekdayMap[day]).join(", ");
    }
    //if daysOfWeekSelected.length = 7, keep weekdays = ""
  }

  //combine dateRange and weekdays for display output
  let dateRangeDisplayString = `${dateRange} ${weekdays}`;

  //if dateRangeDisplayString is empty, set to undefined
  if (dateRangeDisplayString === " ") {
    dateRangeDisplayString = undefined;
  }

  return dateRangeDisplayString;
}

export {
  determineDaysOfWeekSelected,
  findDaysOfWeekInRange,
  dateRangeDisplayCalcHelper
};
