<!--
Sean Choi 2023
Stripped down version of WatchlistFileSelector for
importing video / image for Post-Event cases
-->

<template>
  <div class="fileSelector">
    <div
      v-if="noFiles"
      class="rectangle-copy"
      @drop.prevent="onDrop"
      @dragover.prevent="onDragOver"
      @dragleave.prevent="offDragOver"
    >
      <div v-if="!isDragging" class="choose-files-or-drag" style="">
        <input
          ref="filesDirectory"
          webkitdirectory
          mozdirectory
          msdirectory
          odirectory
          directory
          multiple
          type="file"
          style="display: none"
          @change="selectFile($event)"
        />
        <input
          ref="filesIndividual"
          type="file"
          style="display: none"
          @change="selectFile($event)"
          multiple
        />
        Choose
        <a
          @click.prevent="selectDirectory"
          style="color: var(--overwatch-primary); cursor: pointer;"
          >Directory</a
        >,
        <a
          @click.prevent="selectIndividual"
          style="color: var(--overwatch-primary); cursor: pointer;"
          >Files</a
        >
        or drag them here
      </div>
      <div v-else class="choose-files-or-drag" style="">
        Drop Here
      </div>
    </div>

    <div v-else class="files-container">
      <div class="filePanelHeader">
        <div
          class="d-flex justify-content-between align-self-center"
          style="width:100%"
        >
          <div
            class="flex-child"
            style="display: inline-block; text-align: left; flex: 1"
          >
            Filename
          </div>
          <div>
            <div v-if="showDelete" style="">
              <RocIcon
                icon="trash"
                size="sm"
                color="red"
                style="cursor: pointer;"
                @click="clearFiles"
              />
            </div>
            <div v-else>
              Status
            </div>
          </div>
        </div>
      </div>
      <div v-for="(file, index) in filterFiles" :key="file">
        <div class="filePanel">
          <div
            class="d-flex justify-content-between align-self-center"
            style="height: 100%;"
          >
            <div class="align-self-center flex-child file-path" style="flex: 1">
              {{ file.path }}
            </div>
            <div class="align-self-center">
              <RocIcon
                v-if="showDelete"
                icon="trash"
                size="sm"
                color="red"
                @click="deleteFile(file)"
                style="cursor: pointer;"
              />
            </div>
          </div>
        </div>
      </div>
      <div style="margin-top: auto; margin-bottom: 1rem">
        <div
          class="d-flex justify-content-between"
          style="margin-top: 2px; margin-bottom: 2px; width: 95%; border: 0px solid black; margin-left: 16px;"
        >
          <div style="min-width: 33.33%; text-align: left; padding-left: 6px;">
            {{ filePackages.length }}
            <RocIcon size="sm" icon="file" />
          </div>
          <div
            style="min-width: 33.33%; text-align: center; border: 0px solid black;"
          >
            {{ page }} / {{ totalPages }}
          </div>
          <div
            style="min-width: 33.33%; text-align: right; padding-right: 4px;"
          ></div>
        </div>
        <div
          class="d-flex justify-content-between"
          style="width: 95%; border: 0px solid black; margin-left: 16px; margin-top: 1rem;"
        >
          <div style="margin: 0 auto 0 auto;">
            <RocButton
              size="sm"
              @click="decrementPage"
              style="padding: var(--spacing-s); margin-right: var(--spacing-s)"
            >
              <RocIcon
                icon="downArrow"
                size="sm"
                color="white"
                style="transform: rotate(90deg)"
              />
            </RocButton>
            <RocButton
              size="sm"
              @click="incrementPage"
              style="padding: var(--spacing-s)"
            >
              <RocIcon
                icon="downArrow"
                size="sm"
                color="white"
                style="transform: rotate(-90deg)"
              />
            </RocButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from "vue";
import RocButton from "../ui/RocButton.vue";
import RocIcon from "../ui/RocIcon.vue";

export default {
  name: "FileSelector",
  components: {
    RocButton,
    RocIcon
  },
  emits: ["change"],
  setup(props, context) {
    const windowWidth = ref(window.innerWidth);

    const showDelete = ref(true);

    onMounted(() => {
      window.addEventListener("resize", () => {
        windowWidth.value = window.innerWidth;
      });
    });

    let lastId = -1;
    const files = ref(null);
    const filePackages = ref([]);
    const noFiles = computed(() => {
      return !filePackages.value.length;
    });

    watch(
      filePackages,
      (newValue) => {
        context.emit("change", newValue);
      },
      { deep: true }
    );

    /* Drop / Drag */
    const isDragging = ref(false);

    function onDragOver(ev) {
      ev.preventDefault();
      isDragging.value = true;
      ev.currentTarget.classList.add("rectangle-drag-active");
    }

    function offDragOver(ev) {
      ev.preventDefault();
      isDragging.value = false;
      ev.currentTarget.classList.remove("rectangle-drag-active");
    }

    async function onDrop(ev) {
      ev.preventDefault();

      isDragging.value = false;
      ev.currentTarget.classList.remove("rectangle-drag-active");

      let fileArray = [];
      if (ev.dataTransfer.items) {
        // Use DataTransferItemList interface to access the file(s)
        for (let item of ev.dataTransfer.items) {
          // Sean: Try drag and drop of directory containing files.
          // Seems to only be supported by Chrome at the moment due to a bug with getFile on Firefox.
          var itemEntryObject = item.webkitGetAsEntry();

          if (itemEntryObject.isDirectory) {
            let reader = itemEntryObject.createReader();

            var entriesList = await new Promise((resolve, reject) =>
              reader.readEntries(resolve, reject)
            );

            for (let entry of entriesList) {
              if (entry.isFile) {
                let file = await getFile(entry);
                fileArray.push(file);
              }
            }
          }
          /////
          else if (itemEntryObject.isFile) {
            fileArray.push(item.getAsFile());
          }
        }
      } else {
        // Use DataTransfer interface to access the file(s)
        [...ev.dataTransfer.files].forEach(async (file, i) => {
          fileArray.push(file);
        });
      }
      processFileArray(fileArray);
    }

    async function getFile(fileEntry) {
      try {
        return new Promise((resolve, reject) =>
          fileEntry.file(resolve, reject)
        );
      } catch (err) {
        console.log(err);
      }
    }

    const filesDirectory = ref();
    const filesIndividual = ref();

    function selectDirectory() {
      filesDirectory.value.click();
    }

    function selectIndividual() {
      filesIndividual.value.click();
    }

    const page = ref(1);
    const totalPages = ref(1);
    const itemsPerPage = computed(() => {
      // if (windowWidth.value <= 480) {
      //   // TODO: Fit as many as possible on screen. - Sean
      //   return 5;
      // } else {
      //   return 10;
      // }
      return 5;
    });

    async function selectFile(e) {
      files.value = e.target.files;
      let fileArray = [];
      try {
        fileArray = Array.from(files.value);
      } catch (err) {
        console.log(err);
      }
      // Usually processFileArray is run and the package is pushed to filePackages,
      await processFileArray(fileArray);
    }

    async function processFileArray(fileArray) {
      let totalFiles = 0;
      fileArray.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      });

      const supportedExtensions = new Set([
        "mkv",
        "mp4",
        "avi",
        "asf",
        "3g2",
        "3gp",
        "h264",
        "mkv",
        "mov",
        "mpeg",
        "mpg",
        "mxf",
        "webm",
        "wmv",
        "h265"
      ]);

      for (let i = 0; i < fileArray.length; i++) {
        let file = fileArray[i];
        if (
          file &&
          (file["type"].split("/")[0] === "image" ||
            file.type.split("/")[0] === "video" ||
            supportedExtensions.has(file.name.split(".").pop()))
        ) {
          totalFiles++;

          const nameNoEx = file.name.split(".")[0];
          if (nameNoEx) {
            try {
              var filePath = file.webkitRelativePath
                ? file.webkitRelativePath
                : file.name;

              // Get rid of the uploaded directory from the path
              if (filePath.includes("/")) {
                filePath = filePath.split("/");
                filePath = filePath[filePath.length - 1];
              }

              const pkg = {
                id: lastId++,
                fileHandle: file,
                path: filePath
              };
              filePackages.value.push(pkg);
            } catch (err) {
              console.log(err);
            }
          }
        }
      }
      totalPages.value = Math.ceil(totalFiles / itemsPerPage.value);
    }

    const filterFiles = computed(function() {
      const startPos = (page.value - 1) * itemsPerPage.value;
      const endPos = startPos + itemsPerPage.value;
      return filePackages.value.slice(startPos, endPos);
    });

    function deleteFile(file) {
      let index = filePackages.value.findIndex((f) => f == file);

      filePackages.value.splice(index, 1);
      const newMaxPages = Math.ceil(
        filePackages.value.length / itemsPerPage.value
      );
      if (newMaxPages != totalPages.value) {
        totalPages.value = newMaxPages;
        decrementPage();
      }
    }

    function decrementPage() {
      if (page.value > 1) {
        page.value--;
      }
    }

    function clearFiles() {
      filePackages.value = [];
      page.value = 1;
      totalPages.value = 1;
      files.value = [];
      showDelete.value = true;
    }

    function decrementPage() {
      if (page.value > 1) {
        page.value--;
      }
    }

    function incrementPage() {
      if (page.value < totalPages.value) {
        page.value++;
      }
    }

    return {
      isDragging,
      filesDirectory,
      filesIndividual,
      filePackages,
      onDrop,
      onDragOver,
      offDragOver,
      selectDirectory,
      selectIndividual,
      selectFile,
      noFiles,
      filterFiles,
      showDelete,
      deleteFile,
      decrementPage,
      clearFiles,
      decrementPage,
      incrementPage,
      page,
      totalPages
    };
  }
};
</script>

<style scoped lang="scss">
.fileSelector {
  box-sizing: border-box;
  @include overwatch-body-xsmall;
  height: 100%;
  width: 100%;
}

.rectangle-copy {
  box-sizing: border-box;
  /* height: 475px; */
  width: 100%;
  height: 100%;
  border: 3px dashed var(--overwatch-neutral-300);

  display: flex;
  align-items: center;
  justify-content: center;
}

.choose-files-or-drag {
  @include overwatch-body-large;
  letter-spacing: 0;
  line-height: 35px;
  text-align: center;
}

.files-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.filePanelHeader {
  width: 100%;
  height: fit-content;
  margin-left: 0px;
  margin-bottom: var(--spacing-base);
  padding: var(--spacing-base);
  padding-top: 0;
  border-bottom: dotted 1px var(--overwatch-neutral-300);
}

.filePanel {
  background: var(--overwatch-secondary);
  width: 100%;
  height: auto;
  margin-bottom: 2px;
  padding: 4px !important;
}

.flex-child {
  margin-left: 6px;
  padding: 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  display: block;
  text-align: left;
}

.nav-button {
  background: var(--overwatch-primary) !important;
  color: white !important;
}
</style>
