export default {
  trackAnalysisFacetemplates(state) {
    return state.trackAnalysisFacetemplates;
  },
  formattedTime: () => (time) => {
    const theTime = new Date(time);
    return (
      theTime.toLocaleTimeString([], { hour12: true }).split(" ")[0] +
      "." +
      ("0" + theTime.getMilliseconds()).slice(-3) +
      " " +
      (theTime.getHours() >= 12 ? "PM" : "AM")
    ); // .toString() + ':' + ('0' + eventDate.getMilliseconds()).slice(-3) + ' ' + (eventDate.getHours() >= 12) ? 'PM' : 'AM'; //+ ':' + eventDate.getMilliseconds();
  },
  getBaseUrl(state) {
    return state.baseUrl;
  },
  getSettings(state) {
    return state.settings;
  },
  getInterpolation(state) {
    return state.settings.interpolation;
  },
  getCase(state) {
    return state.currentCase;
  },
  getExamId(state) {
    return state.examId;
  },
  getReadOnly(state) {
    return state.readOnly;
  }
};
