<template>
  <div>
    <div class="portrait">
      <div style="margin:auto;">
        Please flip device to landscape mode.
      </div>
    </div>
    <div class="landscape">
      <div class="navigation" @click="navigateBack">
        <RocIcon icon="downArrow" size="xl" style="transform: rotate(90deg)" />
      </div>
      <div class="center">
        <LiveCameraContainer
          class="live-camera"
          v-if="currentCamera"
          :key="currentCamera.GUID"
          :camera-guid="currentCamera.GUID"
          :camera-name="currentCamera.name"
          :camera-online="currentCamera.online"
          :preview="currentCamera.previewImage"
          :camera-url="currentCamera.url"
          :width="498"
          :height="279"
          alwaysShowName
        />
      </div>
      <div class="navigation" @click="navigateForward">
        <div class="settings" @click.stop>
          <MDBDropdown v-model="dropOptions" dropstart>
            <MDBDropdownToggle
              style="color:black;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                position: relative"
              tag="div"
              @click="dropOptions = !dropOptions"
            >
              <RocIcon
                color="black"
                size="med"
                icon="settings"
                style="position: absolute; top: 0; left: 0;"
              />
              <!-- Absolute positioning needed here due to dropstart option (opening menu on left) misaligning icon -->
            </MDBDropdownToggle>
            <RocDropdownMenu aria-labelledby="dropdownMenuButton">
              <MDBDropdownItem style="color:black;" tag="button" @click.prevent>
                <div style="width: 300px; height: 150px;">
                  <filter-multi-select
                    name="cameras"
                    @click.stop="dropOptions = true"
                    no-results-text=""
                    placeholder-text="select cameras"
                    :available-items="cameras"
                    :currently-selected="selectedCameraGUIDs"
                    @selection-changed="updateSelectedLiveCameras"
                  ></filter-multi-select>
                </div>
              </MDBDropdownItem>
            </RocDropdownMenu>
          </MDBDropdown>
        </div>
        <RocIcon icon="downArrow" size="lg" style="transform: rotate(-90deg)" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, computed, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import {
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownToggle
} from "mdb-vue-ui-kit";
import LiveCameraContainer from "@/components/cameras/LiveCameraContainer.vue";
import filterMultiSelect from "@/components/ui/filterMultiSelect.vue";
import RocIcon from "@/components/ui/RocIcon";
import RocDropdownMenu from "../../components/ui/RocDropdownMenu.vue";

export default {
  name: "CameraLiveFeedPage",
  components: {
    LiveCameraContainer,
    MDBDropdown,
    MDBDropdownItem,
    MDBDropdownToggle,
    filterMultiSelect,
    RocIcon,
    RocDropdownMenu
  },
  setup() {
    const store = useStore();

    onMounted(() => {
      if (Object.keys(store.getters["settings/getDeviceInfo"]).length != 0) {
        window.screen.orientation.lock("landscape");
      }
    });

    onUnmounted(() => {
      if (Object.keys(store.getters["settings/getDeviceInfo"]).length != 0) {
        window.screen.orientation.lock("portrait");
      }
    });

    const cameras = computed(function() {
      let cameraList = store.getters["cameras/cameras"];
      cameraList.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        if (nameA < nameB) {
          return -1;
        } else if (nameA > nameB) {
          return 1;
        }

        return 0;
      });

      if (cameraList.length) {
        return cameraList.filter((camera) => {
          if (camera.online === true) {
            return true;
          }
        });
      } else return cameraList;
    });

    const selectedCameraGUIDs = ref(
      store.getters["encounters/liveFeedCameras"]
    ); // List of camera GUIDs that were selected.

    function updateSelectedLiveCameras(selected) {
      selectedCameraGUIDs.value = selected.value;
      store.commit("encounters/setLiveFeedCameras", selected.value);
    }

    const selectedCamObjects = computed(function() {
      return getActiveLiveFeeds();
    });

    const currentCameraIndex = ref(0);

    const currentCamera = computed(() => {
      return selectedCamObjects.value[currentCameraIndex.value];
    });

    const dropOptions = ref(false);

    function getActiveLiveFeeds() {
      /**
       * Return list of camera objects that are selected to be shown in live feed
       */
      let cameraList = store.getters["cameras/cameras"]; // Entire list of camera objects1
      let activeCameraFeedList = [];
      selectedCameraGUIDs.value.forEach((selectedCam) => {
        const index = cameraList.findIndex((cam) => cam.GUID === selectedCam);
        if (index >= 0) {
          if (cameraList[index].online === true) {
            activeCameraFeedList.push(cameraList[index]);
          }
        }
      });

      return activeCameraFeedList;
    }

    function navigateBack() {
      if (currentCameraIndex.value <= 0) {
        currentCameraIndex.value = selectedCamObjects.value.length - 1;
      } else {
        currentCameraIndex.value -= 1;
      }
    }

    function navigateForward() {
      if (currentCameraIndex.value >= selectedCamObjects.value.length - 1) {
        currentCameraIndex.value = 0;
      } else {
        currentCameraIndex.value += 1;
      }
    }

    return {
      currentCamera,
      navigateBack,
      navigateForward,
      dropOptions,
      cameras,
      updateSelectedLiveCameras,
      selectedCameraGUIDs
    };
  }
};
</script>

<style scoped>
@media (orientation: portrait) {
  .portrait {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .landscape {
    display: none;
  }
}

@media (orientation: landscape) {
  .portrait {
    display: none;
  }

  .landscape {
    display: flex;
    height: 100%;
    width: 100%;
    min-height: 279px;
  }

  .navigation {
    display: flex;
    position: relative;
    flex-direction: column;
    background: black;
    align-items: center;
    justify-content: center;
    width: 10%;
  }

  .settings {
    position: absolute;
    top: 20px;

    /* Limit the div to the size of the settings cog icon */
    height: 48px;
    width: 48px;
  }

  .center {
    display: flex;
    flex: 1;
    background: black;
    justify-content: center;
  }

  .live-camera {
    margin: auto;
    border-color: black;
  }

  .icon {
    display: block;
    margin: auto;
    color: white;
    height: 80px;
    width: 40px;
  }
}
</style>
