<script setup>
import {
  ref,
  reactive,
  computed,
  watch,
  onMounted,
  onBeforeUnmount,
  defineProps,
  defineEmits,
  defineExpose
} from "vue";
import { useStore } from "vuex";

const emit = defineEmits(["captureZonesEmit", "enableSave"]);
const props = defineProps({
  image: {
    type: String,
    required: false
  },
  zones: {
    type: Object,
    required: false
  },
  zoneColor: {
    type: String,
    required: false
  },
  zoneOpacity: {
    type: Number,
    required: false
  },
  zoneInclude: {
    type: Boolean,
    required: false
  }
});

//expose allows function to be used directly by parent component
defineExpose({ saveCaptureZones, addCaptureZone });

const store = useStore();

const konvaContainerRef = ref(null);
const konvaStageRef = ref(null);
const imageLayerRef = ref(null);
const drawingLayerRef = ref(null);
const cameraPreview = ref(null);
const captureZoneGroups = reactive([]); //this stores individual capture zones

//margins that help space button groups outside the rectangles, used commonly throughout this file
const addButtonMargin = ref(30);
const editDeleteGroupMarginX = ref(58);
const editDeleteGroupMarginY = ref(30);
const includeExcludeMarginX = ref(130);
const includeExcludeMarginY = ref(10);

const konvaStageConfig = reactive({
  height: 0,
  width: 0,
  imageSmoothingEnabled: true,
  imageSmoothingQuality: "high"
});

const imageData = reactive({
  id: "background-image",
  name: "background-image",
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  initialStageHeight: 0, //this helps calculate scale later on
  initialStageWidth: 0
});

const captureZonesData = computed(() => {
  return {
    imageData: imageData,
    captureZones: captureZoneGroups
  };
});

onMounted(() => {
  //fit konva stage to container
  fitStage();
  window.addEventListener("resize", fitStage);

  //create HTML image bc Konva wants object like image.src and not just string
  cameraPreview.value = createHTMLImageElement(props.image);

  //keeps token refreshed so logout doesn't occur too quickly
  //TODO: update token later/remove
  store.dispatch("auth/setAutoRefreshToken", { refresh_delay: 500 * 60000 });

  //if capture zones exist, draw them
  if (props.zones?.coordinates?.length > 0) {
    addCaptureZone(true);
  }

  //assign hotkeys
  assignHotkeys();

  //add click event to image
  const stage = konvaStageRef.value.getStage();
  const backgroundImage = stage.findOne("#background-image");
  backgroundImage.on("click", handleImageClick);
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", fitStage);
  //remove add event listener for container hotkeys
  const stage = konvaStageRef.value.getStage();
  const container = stage.container();
  container.removeEventListener("keydown", handleKeyDown);
});

function assignHotkeys() {
  if (konvaStageRef.value !== null) {
    const stage = konvaStageRef.value.getStage();
    const container = stage.container();

    // make container focusable
    container.tabIndex = 1;
    // focus it, also stage will be in focus on its click
    container.focus();

    container.addEventListener("keydown", handleKeyDown); ////
  }
}

function handleKeyDown(e) {
  const stage = konvaStageRef.value.getStage();
  if (e.keyCode === 13) {
    //make sure done-editing button exists before calling functioin
    const doneEditingButton = stage.findOne(`#done-editing-button`);
    if (doneEditingButton) {
      doneEditingClick();
    }
  }
  //if key is delete key or backspace delete the capture zone
  if (e.keyCode === 46 || e.keyCode === 8) {
    //find the rect by finding the one that has a transformer
    const transformer = stage.findOne("Transformer");
    if (transformer) {
      const objectIdNumber = transformer
        .nodes()[0]
        .id()
        .split("-")
        .pop();
      //find the rect that has the same id as the transformer
      const rect = stage.findOne(`#rect-${objectIdNumber}`);
      const doneEditingButton = stage.findOne(`#done-editing-button`);
      if (doneEditingButton) {
        deleteCaptureZone(rect);
      }
    }
  } else {
    return;
  }
  e.preventDefault();
}

watch(
  () => props.image,
  (newVal) => {
    cameraPreview.value = createHTMLImageElement(newVal);
  }
);

function createHTMLImageElement(src) {
  const image = new Image();
  image.src = src;
  return image;
}

function fitStage() {
  if (konvaStageRef.value !== null) {
    const stage = konvaStageRef.value.getStage();

    //this is size of container, comparable to doing document.querySelector('.container').offsetWidth;
    const width = konvaContainerRef.value.offsetWidth;
    const height = konvaContainerRef.value.offsetHeight;

    //set stage and image height to fit container
    konvaStageConfig.width = width;
    konvaStageConfig.height = height;

    //set initial values
    if (
      imageData.initialStageHeight === 0 &&
      imageData.initialStageWidth === 0
    ) {
      //update image data
      imageData.width = width;
      imageData.height = height;
      imageData.initialStageHeight = height;
      imageData.initialStageWidth = width;
    }

    if (
      imageData.initialStageHeight !== 0 &&
      imageData.initialStageWidth !== 0
    ) {
      //calculate scale factor
      const scaleX = width / imageData.initialStageWidth;
      const scaleY = height / imageData.initialStageHeight;

      //apply the scaling to the stage
      stage.scale({ x: scaleX, y: scaleY });

      //easier to undo scaling of image than to scale each piece individually
      const { img } = getObjects();
      img?.scale({ x: 1 / scaleX, y: 1 / scaleY });

      //update image data to match container instead of scale
      //helps with maintaining aspect ratio later on
      imageData.width = width;
      imageData.height = height;
    }
  }
}

// add shapes and groups to the drawing layer
function addCaptureZone(zonesExist) {
  if (zonesExist === true) {
    //loop through props.zones.coordinates and draw them
    drawExisting();
  } else {
    drawNew();
  }
}

function drawNew(e) {
  const {
    drawingLayer,
    includeExcludeDoneEditingButtonsGroup,
    doneEditingButton,
    allRects,
    stage
  } = getObjects(e);

  const group = new Konva.Group({
    id: `group-${captureZoneGroups.length + 1}`,
    draggable: true
  });

  const { rectHeight, rectWidth, rectX, rectY } = setNewRectDimensions(e);

  //make rgba color
  const rgbaColor = makeRgba(props.zoneColor, props.zoneOpacity);

  //make all rects except this one not draggable
  allRects?.forEach((r) => {
    r.draggable(false);
  });

  const rect = new Konva.Rect({
    id: `rect-${highestRectNumber.value + 1}`,
    x: rectX,
    y: rectY,
    width: rectWidth,
    height: rectHeight,
    fill: rgbaColor,
    draggable: true
  });

  rect.on("transform", resizeCaptureZone);
  rect.on("click", handleRectClick);
  rect.on("dragmove", dragCaptureZone);
  rect.on("mouseup", snapRectOnOverlap);

  group.add(rect);

  group.on("click", getObjects);
  group.on("dragmove", dragCaptureZone);

  drawingLayer.add(group);

  //draw buttons around rect
  drawAddButtons(rect); //draw add buttons
  drawEditDeleteButtons(rect); //these are drawn but hide at first

  //if buttons don't exist, draw them, otherwise, show and update the coordinates
  if (!includeExcludeDoneEditingButtonsGroup) {
    drawIncludeExcludeAndDoneEditingButton(rect);
  } else {
    includeExcludeDoneEditingButtonsGroup.show();
    doneEditingButton.show();
    includeExcludeDoneEditingButtonsGroup.moveToTop(); //add it to the top of the drawing layer
    updateShapeCoordinates(rect);
  }

  //find all transformers and hide them
  const transformers = stage.find("Transformer");
  transformers.forEach((transformer) => {
    transformer.hide();
  });

  const tr = new Konva.Transformer({
    id: `transformer-${highestRectNumber.value + 1}`,
    rotateEnabled: false,
    nodes: [rect],
    boundBoxFunc: (oldBox, newBox) => {
      const box = newBox;
      const isOut =
        box.x < 0 ||
        box.y < 0 ||
        box.x + box.width > stage.width() ||
        box.y + box.height > stage.height();

      // if new bounding box is out of visible viewport, let's just skip transforming
      // this logic can be improved by still allow some transforming if we have small available space
      if (isOut) {
        return oldBox;
      }
      return newBox;
    }
  });

  drawingLayer.add(tr);

  const captureZone = {
    id: group.id(),
    rects: [
      {
        id: rect.id(),
        x: rect.x(),
        y: rect.y(),
        width: rect.width(),
        height: rect.height()
      }
    ]
  };

  captureZoneGroups.push(captureZone);
  emit("captureZonesEmit", captureZonesData.value);
}

function drawAddButtons(rect) {
  //get number of rect id
  const rectId = rect
    .id()
    .split("-")
    .pop();

  //attribute values
  const boundingBoxHeight = 100;
  const boundingBoxWidth = 100;
  const addButtonCircleRadius = 15;
  const addIconX = -8.5;
  const addIconY = -9;
  const addIconScaleX = 0.7;
  const addIconScaleY = 0.7;
  const addButtonCircleStroke = 2;

  let buttons = ["top", "right", "bottom", "left"];
  buttons.forEach((value) => {
    let addButtonX, addButtonY;

    if (value === "right") {
      addButtonX = rect.x() + rect.width() + addButtonMargin.value;
      addButtonY = rect.y() + rect.height() / 2;
    }
    if (value === "left") {
      addButtonX = rect.x() - addButtonMargin.value;
      addButtonY = rect.y() + rect.height() / 2;
    }
    if (value === "top") {
      addButtonX = rect.x() + rect.width() / 2;
      addButtonY = rect.y() - addButtonMargin.value;
    }
    if (value === "bottom") {
      addButtonX = rect.x() + rect.width() / 2;
      addButtonY = rect.y() + rect.height() + addButtonMargin.value;
    }

    const addButton = new Konva.Group({
      id: `add-button-${value}-${rectId}`,
      x: addButtonX,
      y: addButtonY
    });

    //invisible bounding box for the add button for collision detection
    const addButtonBoundingBox = new Konva.Rect({
      id: `add-button-bounding-box-${value}-${rectId}`,
      x: -(boundingBoxHeight / 2), //center box
      y: -(boundingBoxWidth / 2),
      width: boundingBoxHeight,
      height: boundingBoxWidth,
      fill: "transparent"
    });

    const addButtonCircle = new Konva.Circle({
      id: `add-button-circle-${value}-${rectId}`,
      x: 0,
      y: 0,
      radius: addButtonCircleRadius,
      fill: "transparent",
      stroke: props.zoneColor,
      strokeWidth: addButtonCircleStroke
    });

    //update this path to call the svg file
    const addSvgPath = require("@/assets/img/icons/add.svg");
    let addSvgPathData = "";
    //api call to fetch svg file path data
    fetch(addSvgPath)
      .then((response) => response.text())
      .then((svgText) => {
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(svgText, "image/svg+xml");
        const pathElement = svgDoc.querySelector("path");

        if (pathElement) {
          addSvgPathData = pathElement.getAttribute("d");
          const addIcon = new Konva.Path({
            id: `add-button-icon-${value}-${rectId}`,
            x: addIconX,
            y: addIconY,
            scale: { x: addIconScaleX, y: addIconScaleY },
            data: addSvgPathData, // SVG path data
            fill: props.zoneColor //update to be hex from global stylesheet
          });
          addButton.add(addIcon);
        }
      })
      .catch((error) => console.error("Error loading SVG file:", error));

    //prevent click event from bubbling up to the add button group
    addButtonBoundingBox.on("click", function(e) {
      e.cancelBubble = true;
    });

    addButton.add(addButtonBoundingBox);
    addButton.add(addButtonCircle);
    addButton.on("click", drawNew);

    const drawingLayer = drawingLayerRef.value.getStage();
    drawingLayer.add(addButton);
  });

  //hide/show add buttons based on relation to other shapes
  checkButtonsBoundaries(rect);
}

//used to number rects, easier than renaming them all the time and ordering them all the time
const highestRectNumber = computed(() => {
  let highestNumber = 0;

  //get all capture zone group rects id numbers
  const rectNumbers = captureZoneGroups
    .map((group) => {
      return group.rects.map((rect) => {
        return parseInt(rect.id.split("-").pop());
      });
    })
    .flat();

  //find the highest number in rectNumbers
  if (rectNumbers.length > 0) {
    highestNumber = Math.max(...rectNumbers);
  }

  return highestNumber;
});

function drawIncludeExcludeAndDoneEditingButton(r) {
  const { rect, drawingLayer, stage } = getObjects(r);
  const stageScaleX = stage.scaleX();
  const stageScaleY = stage.scaleY();

  //attribute values
  const doneEditingCircleX = 110;
  const doneEditingCircleY = 20;
  const doneEditingCircleRadius = 20;
  const includeExcludeButtonX = -16;
  const includeExcludeButtonY = 2;
  const includeExcludeRectWidth = 100;
  const includeExcludeRectHeight = 38;
  const cornerRadius = 5;
  const includeExcludeTextX = 30;
  const includeExcludeTextY = 13;
  const includeExcludeFontSize = 14;
  const includeExcludeFont = "FK Grotesk";
  const noMatchIconX = 8;
  const noMatchIconY = 11;
  const noMatchIconScaleX = 0.65;
  const noMatchIconScaleY = 0.65;
  const checkmarkIconX = -12;
  const checkmarkIconY = -12;
  const includeExcludeCheckmarkIconX = 8;
  const includeExcludeCheckmarkIconY = 11;
  const includeExcludeCheckmarkIconScaleX = 0.65;
  const includeExcludeCheckmarkIconScaleY = 0.65;
  const boundingBoxX = 10;
  const boundingBoxY = 0;
  const boundingBoxWidth = 150;
  const boundingBoxHeight = 40;

  const absoluteRect = rect.getClientRect();
  const buttonGroupX =
    absoluteRect.x / stageScaleX +
    absoluteRect.width / stageScaleX -
    includeExcludeMarginX.value;
  const buttonGroupY =
    absoluteRect.y / stageScaleY +
    absoluteRect.height / stageScaleY +
    includeExcludeMarginY.value;

  const buttonGroup = new Konva.Group({
    id: `include-exclude-done-editing-button-group`,
    x: buttonGroupX,
    y: buttonGroupY
  });

  const doneEditingButton = new Konva.Group({
    id: `done-editing-button`,
    x: doneEditingCircleX,
    y: doneEditingCircleY
  });

  const doneEditingCircle = new Konva.Circle({
    id: `done-editing-circle`,
    x: 0,
    y: 0,
    radius: doneEditingCircleRadius,
    fill: getCssVariableValue("--overwatch-button-primary")
  });
  doneEditingButton.add(doneEditingCircle);

  doneEditingButton.on("click", doneEditingClick);

  const includeExcludeRect = new Konva.Rect({
    id: `include-exclude-rect`,
    x: 0,
    y: 0,
    width: includeExcludeRectWidth,
    height: includeExcludeRectHeight,
    fill: getCssVariableValue("--overwatch-button-primary"),
    cornerRadius: cornerRadius
  });

  //add text to the includeExcludeButton
  const includeExcludeText = new Konva.Text({
    id: `include-exclude-text`,
    x: includeExcludeTextX,
    y: includeExcludeTextY,
    text: props.zoneInclude ? "Included" : "Excluded",
    fontSize: includeExcludeFontSize,
    fontFamily: includeExcludeFont,
    fill: getCssVariableValue("--overwatch-button-text") //update to be hex from global stylesheet
  });

  //update this path to call the svg file
  const noMatchSvgPath = require("@/assets/img/icons/nomatch.svg");
  let noMatchSvgPathData = "";
  //api call to fetch svg file path data
  fetch(noMatchSvgPath)
    .then((response) => response.text())
    .then((svgText) => {
      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(svgText, "image/svg+xml");
      const pathElement = svgDoc.querySelector("path");

      if (pathElement) {
        noMatchSvgPathData = pathElement.getAttribute("d");
        const noMatchIcon = new Konva.Path({
          id: `no-match-icon`,
          x: noMatchIconX,
          y: noMatchIconY,
          scale: { x: noMatchIconScaleX, y: noMatchIconScaleY },
          data: noMatchSvgPathData, // SVG path data
          fill: getCssVariableValue("--overwatch-button-text") //update to be hex from global stylesheet
        });
        includeExcludeButton.add(noMatchIcon);
        if (props.zoneInclude) {
          noMatchIcon.hide();
        } else {
          noMatchIcon.show();
        }
      }
    })
    .catch((error) => console.error("Error loading SVG file:", error));

  //add both the rect and text to the includeExcludeButton group
  const includeExcludeButton = new Konva.Group({
    id: `include-exclude-button`,
    x: includeExcludeButtonX,
    y: includeExcludeButtonY
  });
  includeExcludeButton.add(includeExcludeRect);
  includeExcludeButton.add(includeExcludeText);

  //update this path to call the svg file
  const checkmarkSvgPath = require("@/assets/img/icons/check.svg");
  let checkmarkPathData = "";
  //api call to fetch svg file path data
  fetch(checkmarkSvgPath)
    .then((response) => response.text())
    .then((svgText) => {
      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(svgText, "image/svg+xml");
      const pathElement = svgDoc.querySelector("path");

      if (pathElement) {
        checkmarkPathData = pathElement.getAttribute("d");
        const checkmarkIcon = new Konva.Path({
          id: "done-editing-icon",
          data: checkmarkPathData, // Use the extracted path data here
          x: checkmarkIconX,
          y: checkmarkIconY,
          fill: getCssVariableValue("--overwatch-button-text") //update to be hex from global stylesheet
        });
        doneEditingButton.add(checkmarkIcon);

        const includeExludeCheckmarkIcon = new Konva.Path({
          id: `include-exclude-checkmark-icon`,
          x: includeExcludeCheckmarkIconX,
          y: includeExcludeCheckmarkIconY,
          scale: {
            x: includeExcludeCheckmarkIconScaleX,
            y: includeExcludeCheckmarkIconScaleY
          },
          data: checkmarkPathData, // SVG path data
          fill: getCssVariableValue("--overwatch-button-text") //update to be hex from global stylesheet
        });
        includeExcludeButton.add(includeExludeCheckmarkIcon);
        if (props.zoneInclude) {
          includeExludeCheckmarkIcon.show();
        } else {
          includeExludeCheckmarkIcon.hide();
        }
      }
    })
    .catch((error) => console.error("Error loading SVG file:", error));

  const boundingBox = new Konva.Rect({
    id: `include-exclude-done-editing-bounding-box`,
    x: boundingBoxX,
    y: boundingBoxY,
    width: boundingBoxWidth,
    height: boundingBoxHeight,
    fill: "transparent"
  });

  buttonGroup.add(boundingBox);
  buttonGroup.add(doneEditingButton);
  buttonGroup.add(includeExcludeButton);

  drawingLayer.add(buttonGroup);
}

function drawExisting() {
  const drawingLayer = drawingLayerRef.value.getStage();

  //calculate scaling factor
  const scalingFactorWidth =
    imageData.initialStageWidth / props.zones.imageData.width;
  const scalingFactorHeight =
    imageData.initialStageHeight / props.zones.imageData.height;

  //draw capture zones from props.zones
  props.zones.coordinates.forEach((zone) => {
    //make rgba color
    const rgbaColor = makeRgba(props.zoneColor, props.zoneOpacity);
    const groupId = `group-${captureZoneGroups.length + 1}`;
    const group = new Konva.Group({ id: `${groupId}`, draggable: true });

    const captureZone = {
      id: group.id(),
      rects: []
    };

    captureZoneGroups.push(captureZone);

    //loop through each zone and create a rect for each one in the array
    zone.rects.forEach((zoneRect) => {
      const rect = new Konva.Rect({
        id: zoneRect.id,
        x: zoneRect.x * scalingFactorWidth,
        y: zoneRect.y * scalingFactorHeight,
        width: zoneRect.width * scalingFactorWidth,
        height: zoneRect.height * scalingFactorHeight,
        fill: rgbaColor
      });

      rect.on("dragmove", dragCaptureZone);
      rect.on("mouseup", snapRectOnOverlap);
      rect.on("transform", resizeCaptureZone);
      rect.on("click", handleRectClick);

      group.add(rect);
      group.on("click", getObjects);
      group.on("dragmove", dragCaptureZone);

      const rectObj = {
        id: rect.id(),
        x: rect.x(),
        y: rect.y(),
        width: rect.width(),
        height: rect.height()
      };

      //find the capture zone group and add the rect to it
      captureZoneGroups.forEach((group) => {
        if (group.id === groupId) {
          group.rects.push(rectObj);
        }
      });
    });

    drawingLayer.add(group);
  });

  const stage = konvaStageRef.value.getStage();
  const texts = stage.find((node) => node.id().startsWith("text-"));
  texts.forEach((text) => {
    text.moveToTop();
  });

  //draw add buttons for each rect, doing this after all rects are drawn to check buttons boundaries to hide/show
  let drawIncludeExcludeCalled = false;
  captureZoneGroups.forEach((group) => {
    group.rects.forEach((rect, index) => {
      const stage = konvaStageRef.value.getStage();
      const rectNumber = rect.id.split("-").pop();
      const rectObj = stage.findOne(`#rect-${rectNumber}`);

      //draw all add buttons
      drawAddButtons(rectObj);
      drawEditDeleteButtons(rectObj); //these hide by default

      //call drawIncludeExcludeAndDoneEditingButton once and hide include-exclude and done editing buttons
      if (index === 0 && !drawIncludeExcludeCalled) {
        drawIncludeExcludeAndDoneEditingButton(rectObj);
        const { includeExcludeDoneEditingButtonsGroup } = getObjects(rectObj);
        includeExcludeDoneEditingButtonsGroup.hide();
        drawIncludeExcludeCalled = true;
      }
    });
  });
}

function updateGroupNames() {
  //go through all groups on konva stage and give the groups new numbers, if they have the same number, add them to same group
  //start from 1 and work up by 1, so there's not a scenario where it's like group 1,2,4, but instead 1,2,3
  const { allGroups } = getObjects();

  allGroups.forEach((group, index) => {
    const newId = `group-${index + 1}`;
    //update the capture zone groups to match the id
    captureZoneGroups.forEach((captureZone) => {
      if (captureZone.id === group.id()) {
        captureZone.id = newId;
      }
    });

    //update konva group id
    group.id(newId);
  });
}

function doneEditingClick(e) {
  //destroy done editing button
  const {
    stage,
    doneEditingButton,
    allEditDeleteButtonGroups,
    allRects
  } = getObjects(e);
  doneEditingButton.hide();

  //destroy all transformers
  const transformers = stage.find("Transformer");
  transformers.forEach((transformer) => {
    transformer.hide();
  });

  //show edit and delete buttons
  allEditDeleteButtonGroups.forEach((group) => {
    group.show();
  });

  //make all rects not draggable and check boundaries
  allRects.forEach((rect) => {
    rect.draggable(false);
    checkButtonsBoundaries(rect);
  });

  //emit that capture zones are done editing
  emit("enableSave", true);
}

function editButtonClick(e) {
  const {
    doneEditingButton,
    allEditDeleteButtonGroups,
    includeExcludeDoneEditingButtonsGroup
  } = getObjects(e);

  allEditDeleteButtonGroups.forEach((group) => {
    group.hide();
  });

  //re-draw the done editing button
  if (doneEditingButton) {
    doneEditingButton.show();
    if (includeExcludeDoneEditingButtonsGroup) {
      includeExcludeDoneEditingButtonsGroup.show();
    }
  }

  checkButtonsBoundaries(e);

  //emit to disable save
  emit("enableSave", false);
}

function drawEditDeleteButtons(r) {
  const { rect, objectIdNumber, drawingLayer, stage } = getObjects(r);
  const absoluteRect = rect.getClientRect();
  const stageScaleX = stage.scaleX();
  const stageScaleY = stage.scaleY();

  //attribute values
  const editCircleRadius = 15;
  const editIconX = -8.5;
  const editIconY = -9;
  const editScaleX = 0.7;
  const editScaleY = 0.7;
  const editButtonX = 0;
  const editButtonY = 0;
  const deleteIconX = -8.5;
  const deleteIconY = -9;
  const deleteScaleX = 0.7;
  const deleteScaleY = 0.7;
  const deleteButtonX = 40;
  const deleteButtonY = 0;
  const boundingBoxX = -20;
  const boundingBoxY = -20;
  const boundingBoxWidth = 80;
  const boundingBoxHeight = 40;

  const buttonGroupX =
    absoluteRect.x / stageScaleX +
    absoluteRect.width / stageScaleX -
    editDeleteGroupMarginX.value;
  const buttonGroupY =
    absoluteRect.y / stageScaleY - editDeleteGroupMarginY.value;

  const buttonGroup = new Konva.Group({
    id: `edit-delete-buttons-group-${objectIdNumber}`,
    x: buttonGroupX,
    y: buttonGroupY
  });

  const editCircle = new Konva.Circle({
    id: `edit-button-circle-${objectIdNumber}`,
    x: 0,
    y: 0,
    radius: editCircleRadius,
    fill: getCssVariableValue("--overwatch-button-primary")
  });

  //update this path to call the svg file
  const editSvgPath = require("@/assets/img/icons/edit.svg");
  let editSvgPathData = "";
  //api call to fetch svg file path data
  fetch(editSvgPath)
    .then((response) => response.text())
    .then((svgText) => {
      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(svgText, "image/svg+xml");
      const pathElement = svgDoc.querySelector("path");

      if (pathElement) {
        editSvgPathData = pathElement.getAttribute("d");
        const editIcon = new Konva.Path({
          id: `edit-button-icon-${objectIdNumber}`,
          x: editIconX,
          y: editIconY,
          scale: { x: editScaleX, y: editScaleY },
          data: editSvgPathData, // SVG path data
          fill: getCssVariableValue("--overwatch-button-text")
        });
        editButton.add(editIcon);
      }
    })
    .catch((error) => console.error("Error loading SVG file:", error));

  const editButton = new Konva.Group({
    id: `edit-button-${objectIdNumber}`,
    x: editButtonX,
    y: editButtonY
  });
  editButton.add(editCircle);
  editButton.on("click", editButtonClick);

  let deleteCircleRadius = 15;
  const deleteCircle = new Konva.Circle({
    id: `delete-button-circle-${objectIdNumber}`,
    x: 0,
    y: 0,
    radius: deleteCircleRadius,
    fill: getCssVariableValue("--overwatch-error")
  });

  //update this path to call the svg file
  const deleteSvgPath = require("@/assets/img/icons/trash.svg");
  let deleteSvgPathData = "";
  //api call to fetch svg file path data
  fetch(deleteSvgPath)
    .then((response) => response.text())
    .then((svgText) => {
      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(svgText, "image/svg+xml");
      const pathElement = svgDoc.querySelector("path");

      if (pathElement) {
        deleteSvgPathData = pathElement.getAttribute("d");
        const deleteIcon = new Konva.Path({
          id: `delete-button-icon-${objectIdNumber}`,
          x: deleteIconX,
          y: deleteIconY,
          scale: { x: deleteScaleX, y: deleteScaleY },
          data: deleteSvgPathData, // SVG path data
          fill: getCssVariableValue("--overwatch-button-text")
        });
        deleteButton.add(deleteIcon);
      }
    })
    .catch((error) => console.error("Error loading SVG file:", error));

  const deleteButton = new Konva.Group({
    id: `delete-button-${objectIdNumber}`,
    x: deleteButtonX,
    y: deleteButtonY
  });
  deleteButton.add(deleteCircle);
  deleteButton.on("click", deleteCaptureZone);

  //add bounding box to gorup
  const boundingBox = new Konva.Rect({
    id: `edit-delete-bounding-box-${objectIdNumber}`,
    x: boundingBoxX,
    y: boundingBoxY,
    width: boundingBoxWidth,
    height: boundingBoxHeight,
    fill: "transparent"
  });

  buttonGroup.add(boundingBox);
  buttonGroup.add(editButton);
  buttonGroup.add(deleteButton);

  //default hide
  buttonGroup.hide();

  drawingLayer.add(buttonGroup);
}

//used to get hex value from global stylesheet since konva can't use css vars
function getCssVariableValue(varName) {
  return getComputedStyle(document.documentElement)
    .getPropertyValue(varName)
    .trim();
}

function deleteCaptureZone(e) {
  //delete the capture zone, transformer, and all buttons surrounding it
  const {
    objectIdNumber,
    objectId,
    rect,
    tr,
    group,
    allRects,
    includeExcludeDoneEditingButtonsGroup,
    editDeleteButtonsGroup,
    stage
  } = getObjects(e);

  if (tr) {
    tr.destroy();
  }
  if (rect) {
    rect.destroy();
  }

  //destroy all buttons
  if (editDeleteButtonsGroup) {
    editDeleteButtonsGroup.destroy();
  }

  //hide include/exclude, not destroy since there is only one
  if (includeExcludeDoneEditingButtonsGroup) {
    includeExcludeDoneEditingButtonsGroup.hide();
  }

  const addButtons = ["top", "right", "bottom", "left"];
  addButtons.forEach((addButton) => {
    const addBtn = stage.findOne(`#add-button-${addButton}-${objectIdNumber}`);
    if (addBtn) {
      addBtn.destroy();
    }
  });

  if (allRects) {
    allRects.forEach((r) => {
      if (r.id() !== objectId) {
        checkButtonsBoundaries(r);
      }
    });
  }

  //delete the rect from the capture zone group
  captureZoneGroups.forEach((grp, index) => {
    grp.rects.forEach((rect, rectIndex) => {
      if (rect.id === `rect-${objectIdNumber}`) {
        grp.rects.splice(rectIndex, 1);
      }
    });
    //if group has no rects any more destroy group
    if (grp.rects.length === 0) {
      group.destroy();
      captureZoneGroups.splice(index, 1);
    }
  });
  updateGroupNames();

  //delete the rect from the capture zone group
  if (captureZoneGroups.length === 0) {
    if (includeExcludeDoneEditingButtonsGroup) {
      includeExcludeDoneEditingButtonsGroup.destroy();
    }
    emit("captureZonesEmit", captureZonesData.value);
  }
}

function setNewRectDimensions(e) {
  const { stage } = getObjects(e);

  let rectHeight, rectWidth, rectX, rectY;

  //get page scale to handle page re-size
  const stageScaleX = stage.scaleX();
  const stageScaleY = stage.scaleY();
  const scaledStage = {
    width: stage.width() / stageScaleX,
    height: stage.height() / stageScaleY
  };

  //adjust how big each rect is based on design preferences
  const rectScalingFactorWidth = 0.25;
  const rectScalingFactorHeight = 0.75;

  if (captureZoneGroups.length === 0 || !e) {
    //put a capture zone in the middle
    rectHeight = scaledStage.height * rectScalingFactorHeight;
    rectWidth = scaledStage.width * rectScalingFactorWidth;
    rectX = (scaledStage.width - rectWidth) / 2;
    rectY = (scaledStage.height - rectHeight) / 2;
  }
  if (captureZoneGroups.length >= 1 && e) {
    const {
      objectIdNumber,
      rect,
      addButtonRight,
      addButtonLeft,
      addButtonTop,
      addButtonBottom
    } = getObjects(e);
    let id = e.target.id();
    const absoluteRect = rect.getClientRect();
    const absoluteRectWidth = absoluteRect.width / stageScaleX;
    const absoluteRectHeight = absoluteRect.height / stageScaleY;
    const absoluteRectX = absoluteRect.x / stageScaleX;
    const absoluteRectY = absoluteRect.y / stageScaleY;

    //based on design preferences, adjust how big each rect is
    const rectScalingFactor = 1.5;

    if (id.includes("left") && addButtonLeft) {
      rectHeight = absoluteRectHeight / rectScalingFactor;
      rectWidth = absoluteRectWidth / rectScalingFactor;
      rectX = absoluteRectX - rectWidth;
      rectY = absoluteRectY + absoluteRectHeight / (2 * rectScalingFactor);
    }
    if (id.includes("right") && addButtonRight) {
      // put a capture zone on the right edge of the capture zone the click originated from
      rectHeight = absoluteRectHeight / rectScalingFactor;
      rectWidth = absoluteRectWidth / rectScalingFactor;
      rectX = absoluteRectX + absoluteRectWidth;
      rectY = absoluteRectY + absoluteRectHeight / (2 * rectScalingFactor);
    }
    if (id.includes("top") && addButtonTop) {
      // put a capture zone on the top edge of the capture zone the click originated from
      rectHeight = absoluteRectHeight / rectScalingFactor;
      rectWidth = absoluteRectWidth / rectScalingFactor;
      rectX = absoluteRectX + absoluteRectWidth / (2 * rectScalingFactor);
      rectY = absoluteRectY - rectHeight;
    }
    if (id.includes("bottom") && addButtonBottom) {
      // put a capture zone on the bottom edge of the capture zone the click originated from
      rectHeight = absoluteRectHeight / rectScalingFactor;
      rectWidth = absoluteRectWidth / rectScalingFactor;
      rectX = absoluteRectX + absoluteRectWidth / (2 * rectScalingFactor);
      rectY = absoluteRectY + absoluteRectHeight;
    }
  }

  return { rectHeight, rectWidth, rectX, rectY };
}

function snapRectOnOverlap(e) {
  //compare target rectangle with all other rectangles
  const stage = konvaStageRef.value.getStage();
  const drawingLayer = drawingLayerRef.value.getStage();
  const allRectangles = stage.find(
    (node) => node.id() && node.id().startsWith("rect-")
  ); //Get all capture zone rects
  const clickedGroup = stage.findOne(
    `#group-${e.target.parent
      ?.id()
      .split("-")
      .pop()}`
  ); //group of the rect being compared against
  const allRectanglesInClickedGroup = clickedGroup.find(
    (node) => node.id() && node.id().startsWith("rect-")
  ); //Get all rects in the clicked group
  const clickedRect = e.target; //rect that was clicked
  const isRectInEditMode = e.target.draggable(); //rect is draggable only when in edit mode
  const stageScaleX = stage.scaleX();
  const stageScaleY = stage.scaleY();

  //loop through all rects on the stage
  for (const compareRect of allRectangles) {
    const compareGroup = stage.findOne(
      `#group-${compareRect.parent
        ?.id()
        .split("-")
        .pop()}`
    );
    //don't compare a rect to itself or if it's undefined
    if (compareRect.id() === clickedRect.id() || !clickedRect.id()) {
      continue;
    }
    //if the click event is not on a group, return
    if (!e.target?.parent?.id() || !compareRect.parent?.id()) {
      continue;
    }
    //if the rectangle is not in edit mode, handle the drag of the group
    if (!isRectInEditMode) {
      for (const rectWithinClickedGroup of allRectanglesInClickedGroup) {
        //don't compare a rect to itself or if it's undefined
        if (
          compareRect.id() === rectWithinClickedGroup.id() ||
          !rectWithinClickedGroup.id()
        ) {
          continue;
        }
        //if the groups are the same, return. If in edit mode, ignore this check
        if (
          !clickedGroup?.id() ||
          !compareGroup?.id() ||
          clickedGroup.id() === compareGroup.id()
        ) {
          continue;
        }

        //check if rects overlap
        const overlap = checkRectOverlap(compareRect, rectWithinClickedGroup);

        //condition 1 - merge groups if there's an overlap
        if (overlap) {
          //get the nearest edge of the other rect
          const newGroup = mergeGroupsAndUpdatePositions(
            clickedRect,
            clickedGroup,
            rectWithinClickedGroup,
            compareGroup,
            compareRect
          );

          const captureZone = {
            id: newGroup.id(),
            rects: []
          };

          //get all rects from newGroup
          const newGroupRects = newGroup.find(
            (node) => node.id() && node.id().startsWith("rect-")
          );
          newGroupRects.forEach((child) => {
            if (
              child.getClassName() === "Rect" &&
              !child.id().includes("include-exclude")
            ) {
              let rect = child.getClientRect();
              captureZone.rects.push({
                id: child.id(),
                x: rect.x,
                y: rect.y,
                width: rect.width,
                height: rect.height
              });
            }
          });
          captureZoneGroups.push(captureZone);

          //rename all groups to be in order
          updateGroupNames();

          return; //break out of snapRectOnOverlap()
        }
      }
    }
    //if the rectangle is in edit mode, handle the drag of the clicked rect
    if (isRectInEditMode) {
      const overlap = checkRectOverlap(compareRect, clickedRect);

      //condition 2 - update rect position if it's in the same group
      if (overlap && clickedGroup.id() === compareGroup.id()) {
        //get the nearest edge of the other rect
        const { x, y } = getNearestEdge(clickedRect, compareRect);
        const newX = (x - clickedGroup.x()) / stageScaleX;
        const newY = (y - clickedGroup.y()) / stageScaleY;

        //move the rects relative the edge being snapped to
        clickedRect.position({ x: newX, y: newY });

        updateShapeCoordinates(clickedRect);

        // Redraw the layer to reflect changes
        drawingLayer.draw();

        updateGroupNames();

        return; //break out of snapRectOnOverlap()
      }

      //conditon 3 - move rect to new group
      if (overlap && clickedGroup.id() !== compareGroup.id()) {
        //get the nearest edge of the other rect
        const { x, y } = getNearestEdge(clickedRect, compareRect);

        const newX = (x - compareGroup.x()) / stageScaleX;
        const newY = (y - compareGroup.y()) / stageScaleY;

        //move the rects relative the edge being snapped to
        clickedRect.position({ x: newX, y: newY });

        //move the rect to this compareGroup
        clickedRect.remove();
        compareGroup.add(clickedRect);

        //update the shapes around the rect
        updateShapeCoordinates(clickedRect);

        // Redraw the layer to reflect changes
        drawingLayer.draw();

        //update the captureZoneGroups array to move this rect to a new group
        captureZoneGroups.forEach((group) => {
          //find the new capture zone group and add the rect to it
          if (group.id === compareGroup.id()) {
            //move rect to new group
            group.rects.push({
              id: clickedRect.id(),
              x: clickedRect.x(),
              y: clickedRect.y(),
              width: clickedRect.width(),
              height: clickedRect.height()
            });
          }
          //remove rect from old group, delete konva group, and if group has no rects, remove it from capture zones array
          if (group.id === clickedGroup.id()) {
            //remove rect from old group
            const rectIndex = group.rects.findIndex(
              (rect) => rect.id === clickedRect.id()
            );
            if (rectIndex !== -1) {
              group.rects.splice(rectIndex, 1);
              if (group.rects.length === 0) {
                clickedGroup.destroy();
                const groupIndex = captureZoneGroups.findIndex(
                  (grp) => grp.id === clickedGroup.id()
                );
                if (groupIndex !== -1) {
                  captureZoneGroups.splice(groupIndex, 1);
                }
              }
            }
          }
        });

        updateGroupNames();

        return; //break out of snapRectOnOverlap()
      }

      //condition 4 - break rect out of group and put it in new group
      //the check for compareGroup.id() === clickedGroup.id() is to make sure this only gets called once
      if (
        !overlap &&
        allRectanglesInClickedGroup.length > 1 &&
        compareGroup.id() === clickedGroup.id()
      ) {
        //loop through all rects to see if there's an overlap if it got to this point
        let continueLoop = true;
        allRectangles.forEach((rect) => {
          if (rect.id() === clickedRect.id()) {
            return;
          }
          const overlap = checkRectOverlap(rect, clickedRect);
          if (overlap) {
            continueLoop = false;
            return;
          }
        });
        if (!continueLoop) {
          continue;
        }

        //if there is no overlap, remove rect from group and add it to a new one of its own
        const newGroup = new Konva.Group({
          id: `group-${captureZoneGroups.length + 1}`, // Adjusted to count groups in the layer
          draggable: true
        });

        newGroup.on("click", getObjects);
        newGroup.on("dragmove", dragCaptureZone);

        clickedRect.x(clickedRect.x() + clickedGroup.x());
        clickedRect.y(clickedRect.y() + clickedGroup.y());

        // Remove the rectangle from the old group
        clickedRect.remove();

        // Add the rectangle to the new group
        newGroup.add(clickedRect);

        // Add the new group to the layer
        drawingLayer.add(newGroup);

        updateShapeCoordinates(clickedRect);

        // Redraw the layer to reflect changes
        drawingLayer.draw();

        const newGroupRects = newGroup.find(
          (node) => node.id() && node.id().startsWith("rect-")
        );
        //remove the rect from captureZoneGroups
        captureZoneGroups.forEach((grp) => {
          grp.rects.forEach((rect, index) => {
            if (rect.id === clickedRect.id()) {
              grp.rects.splice(index, 1);
            }
          });
          //if groups have no rects, remove them
          if (grp.rects.length === 0) {
            captureZoneGroups.splice(grp, 1);
          }
        });

        const captureZone = {
          id: newGroup.id(),
          rects: []
        };

        //get all rects from newGroup
        //const newGroupRects = newGroup.find((node) => node.id() && node.id().startsWith('rect-'));
        newGroupRects.forEach((child) => {
          if (
            child.getClassName() === "Rect" &&
            !child.id().includes("include-exclude")
          ) {
            let rect = child.getClientRect();
            captureZone.rects.push({
              id: child.id(),
              x: rect.x,
              y: rect.y,
              width: rect.width,
              height: rect.height
            });
          }
        });

        captureZoneGroups.push(captureZone);

        updateGroupNames();
        return; //break out of snapRectOnOverlap()
      }
    }
  }
}

// Function to merge two groups into a new group
function mergeGroupsAndUpdatePositions(
  clickedRect,
  clickedGroup,
  rectWithinClickedGroup,
  compareGroup,
  compareRect
) {
  const { stage, drawingLayer } = getObjects();
  const stageScaleX = stage.scaleX();
  const stageScaleY = stage.scaleY();

  // Create a new group
  const newGroup = new Konva.Group({
    id: `group-${captureZoneGroups.length + 1}`, // Adjusted to count groups in the layer
    draggable: true
  });

  //get all rects in the groups
  const clickedGroupRects = clickedGroup.find(
    (node) => node.id() && node.id().startsWith("rect-")
  );
  const compareGroupRects = compareGroup.find(
    (node) => node.id() && node.id().startsWith("rect-")
  );

  function updatePosition() {
    //update position of the overlap rect first and the delta to update the rest of the rects in the group
    const absoluteRect = rectWithinClickedGroup.getClientRect();
    const { x, y } = getNearestEdge(rectWithinClickedGroup, compareRect);
    const deltaX = x - absoluteRect.x;
    const deltaY = y - absoluteRect.y;
    rectWithinClickedGroup.position({ x: x / stageScaleX, y: y / stageScaleY });

    // Log the nearest edge information
    //move the rects relative the edge being snapped to
    for (const child of clickedGroupRects) {
      const absoluteChild = child.getClientRect();
      if (child.id() !== rectWithinClickedGroup.id()) {
        let targetX = (absoluteChild.x + deltaX) / stageScaleX;
        let targetY = (absoluteChild.y + deltaY) / stageScaleY;
        child.position({ x: targetX, y: targetY });
      }
    }
  }

  function moveChildrenToNewGroup(rects, updateCompareRects) {
    //find all groups for buttons and move them to the new group for the rect
    for (const rect of rects) {
      //make rect not draggable
      rect.draggable(false);

      //keep compare rects in same place, make their position relative to their old groups
      if (updateCompareRects) {
        rect.x(rect.x() + compareGroup.x());
        rect.y(rect.y() + compareGroup.y());
      }

      //move the rect to the group
      rect.remove();
      newGroup.add(rect);
    }
  }

  updatePosition();
  moveChildrenToNewGroup(clickedGroupRects);
  moveChildrenToNewGroup(compareGroupRects, true);

  //remove the groups that were moved into the new group
  const clickedGroupIndex = captureZoneGroups.findIndex(
    (group) => group.id === clickedGroup.id()
  );
  captureZoneGroups.splice(clickedGroupIndex, 1);
  const compareGroupIndex = captureZoneGroups.findIndex(
    (group) => group.id === compareGroup.id()
  );
  captureZoneGroups.splice(compareGroupIndex, 1);

  clickedGroup.destroy();
  compareGroup.destroy();

  newGroup.on("click", getObjects);
  newGroup.on("dragmove", dragCaptureZone);

  // Add the new group to the layer
  drawingLayer.add(newGroup);

  //get all rects in new group
  const newGroupRects = newGroup.find(
    (node) => node.id() && node.id().startsWith("rect-")
  );

  //update each coordinate
  newGroupRects.forEach((child) => {
    updateShapeCoordinates(child);
  });

  // Redraw the layer to reflect changes
  drawingLayer.draw();

  return newGroup;
}

function checkRectOverlap(RectA, RectB) {
  const absoluteRectA = RectA.getClientRect();
  const absoluteRectB = RectB.getClientRect();

  // Add buffer to the rectangle coordinates to not snap too early
  const buffer = -3;
  absoluteRectA.x -= buffer;
  absoluteRectA.y -= buffer;
  absoluteRectA.width += buffer * 2;
  absoluteRectA.height += buffer * 2;

  // Check if rectangles overlap
  // Get rect A corner coordinates
  const rectACorners = {
    X1: absoluteRectA.x,
    X2: absoluteRectA.x + absoluteRectA.width,
    Y1: absoluteRectA.y,
    Y2: absoluteRectA.y + absoluteRectA.height
  };

  // Get rect B corner coordinates
  const rectBCorners = {
    X1: absoluteRectB.x,
    X2: absoluteRectB.x + absoluteRectB.width,
    Y1: absoluteRectB.y,
    Y2: absoluteRectB.y + absoluteRectB.height
  };

  // True = overlap
  return !(
    rectACorners.X1 > rectBCorners.X2 ||
    rectACorners.X2 < rectBCorners.X1 ||
    rectACorners.Y1 > rectBCorners.Y2 ||
    rectACorners.Y2 < rectBCorners.Y1
  );
}

function getNearestEdge(rect1, rect2) {
  const absoluteRect1 = rect1.getClientRect();
  const absoluteRect2 = rect2.getClientRect();

  const rect1CenterX = absoluteRect1.x + absoluteRect1.width / 2;
  const rect1CenterY = absoluteRect1.y + absoluteRect1.height / 2;

  const rect2CenterX = absoluteRect2.x + absoluteRect2.width / 2;
  const rect2CenterY = absoluteRect2.y + absoluteRect2.height / 2;

  let x, y;
  let edge;

  if (
    Math.abs(rect1CenterX - rect2CenterX) >
    Math.abs(rect1CenterY - rect2CenterY)
  ) {
    // Move rect1 to the center of the nearest side of rect2 horizontally
    if (rect1CenterX < rect2CenterX) {
      x = absoluteRect2.x - absoluteRect1.width;
      edge = "right";
    } else {
      x = absoluteRect2.x + absoluteRect2.width;
      edge = "left";
    }
    y = rect1CenterY - absoluteRect1.height / 2;
  } else {
    // Move rect1 to the center of the nearest side of rect2 vertically
    if (rect1CenterY < rect2CenterY) {
      y = absoluteRect2.y - absoluteRect1.height;
      edge = "bottom";
    } else {
      y = absoluteRect2.y + absoluteRect2.height;
      edge = "top";
    }
    x = rect1CenterX - absoluteRect1.width / 2;
  }

  return { x, y, edge };
}

function dragCaptureZone(e) {
  //if dragMove is executed on a rect
  if (e.target.getClassName() === "Rect") {
    //prevent rect from going outside stage
    preventRectFromGoingOutside(e);
    //update the position of all buttons with the same id relative to the rect
    updateShapeCoordinates(e);
    //check if buttons overlap with other rects or are outside of the stage
    checkButtonsBoundaries(e);
  }
  //if dragMove is executed on a group
  else {
    //get all rects within the group and run the same functions as above
    const group = e.target;
    preventGroupFromGoingOutside(group);
    const rects = group.find(
      (node) => node.id() && node.id().startsWith("rect-")
    );
    rects.forEach((rect) => {
      updateShapeCoordinates(rect);
      checkButtonsBoundaries(rect);
    });
  }

  //enable save button
  emit("enableSave", true);
}

function resizeCaptureZone(e) {
  //update coordinates of buttons outside of the rect
  updateShapeCoordinates(e);
  //check if buttons overlap with other rects or are outside of the stage
  checkButtonsBoundaries(e);
}

function preventRectFromGoingOutside(e) {
  const { stage, objectIdNumber } = getObjects(e);
  const stageScaleX = stage.scaleX();
  const stageScaleY = stage.scaleY();

  const rect = stage.findOne(`#rect-${objectIdNumber}`);
  const rectAbsolute = rect.getClientRect();
  const rectX = rectAbsolute.x;
  const rectY = rectAbsolute.y;
  const rectWidth = rectAbsolute.width;
  const rectHeight = rectAbsolute.height;

  let newRectX = rect.x();
  let newRectY = rect.y();

  // Check if the rect is outside of the stage and adjust position
  if (rectX < 0) {
    newRectX = newRectX - rectX / stageScaleX;
  }
  if (rectY < 0) {
    newRectY = newRectY - rectY / stageScaleY;
  }
  if (rectX + rectWidth > stage.width()) {
    newRectX = newRectX - (rectX + rectWidth - stage.width()) / stageScaleX;
  }
  if (rectY + rectHeight > stage.height()) {
    newRectY = newRectY - (rectY + rectHeight - stage.height()) / stageScaleY;
  }

  // Apply new position if there are any changes
  if (newRectX !== rectX || newRectY !== rectY) {
    rect.x(newRectX);
    rect.y(newRectY);
  }
}

function preventGroupFromGoingOutside(group) {
  const { stage } = getObjects();
  const stageScaleX = stage.scaleX();
  const stageScaleY = stage.scaleY();

  // Get the group's absolute position
  const groupX = group.x();
  const groupY = group.y();
  const groupRect = group.getClientRect();

  let newGroupX = groupX;
  let newGroupY = groupY;

  // Check if the group is outside of the stage and adjust position
  if (groupRect.x < 0) {
    newGroupX = groupX - groupRect.x / stageScaleX;
  }
  if (groupRect.y < 0) {
    newGroupY = groupY - groupRect.y / stageScaleY;
  }
  if (groupRect.x + groupRect.width > stage.width()) {
    newGroupX =
      groupX - (groupRect.x + groupRect.width - stage.width()) / stageScaleX;
  }
  if (groupRect.y + groupRect.height > stage.height()) {
    newGroupY =
      groupY - (groupRect.y + groupRect.height - stage.height()) / stageScaleY;
  }

  // Apply new position if there are any changes
  if (newGroupX !== groupX || newGroupY !== groupY) {
    group.x(newGroupX);
    group.y(newGroupY);

    //get all rects from group and update shape coordinates
    const rects = group.find(
      (node) => node.id() && node.id().startsWith("rect-")
    );
    rects.forEach((rect) => {
      updateShapeCoordinates(rect);
    });
  }
}

// Function to check for collision between two rectangles
function isColliding(rect1, rect2) {
  return !(
    rect1.getClientRect().x >
      rect2.getClientRect().x + rect2.getClientRect().width ||
    rect1.getClientRect().x + rect1.getClientRect().width <
      rect2.getClientRect().x ||
    rect1.getClientRect().y >
      rect2.getClientRect().y + rect2.getClientRect().height ||
    rect1.getClientRect().y + rect1.getClientRect().height <
      rect2.getClientRect().y
  );
}

// Function to check if a rectangle is within the bounds of the stage
function isWithinStageBounds(rect, stage) {
  return (
    rect.getClientRect().x >= 0 &&
    rect.getClientRect().y >= 0 &&
    rect.getClientRect().x + rect.getClientRect().width <= stage.width() &&
    rect.getClientRect().y + rect.getClientRect().height <= stage.height()
  );
}

//check if add buttons overlap with other rects or are outside of the stage
function checkButtonsBoundaries(e) {
  const {
    stage,
    rect,
    includeExcludeDoneEditingButtonsGroup,
    allAddButtonBoundingBoxes,
    editDeleteButtonsGroup,
    includeExcludeDoneEditingBoundingBox,
    editDeleteButtonsGroupBoundingBox
  } = getObjects(e);

  const stageScaleX = stage.scaleX();
  const stageScaleY = stage.scaleY();

  //attribute values
  //for moving the buttons inside the stage, only change the y value
  const includeExcludeGroupMarginY = 50;
  const editDeleteGroupMarginY = 20;

  // Add collision detection for each bounding box and hide/show based on collision
  const otherRectangles = stage.find(
    (node) => node.id() && node.id().startsWith("rect-")
  );
  allAddButtonBoundingBoxes?.forEach((addButtonBoundingBox) => {
    let isVisible = true;

    if (!addButtonBoundingBox) {
      return;
    }

    const boundingBoxIdNumber = addButtonBoundingBox
      .id()
      .split("-")
      .pop();
    let boundingBoxDirection;
    if (addButtonBoundingBox.id().includes("top")) {
      boundingBoxDirection = "top";
    }
    if (addButtonBoundingBox.id().includes("right")) {
      boundingBoxDirection = "right";
    }
    if (addButtonBoundingBox.id().includes("bottom")) {
      boundingBoxDirection = "bottom";
    }
    if (addButtonBoundingBox.id().includes("left")) {
      boundingBoxDirection = "left";
    }

    //Check for collisions with other rectangles
    otherRectangles.forEach((otherRectangle) => {
      if (otherRectangle.id() === `rect-${boundingBoxIdNumber}`) {
        return;
      }
      if (isColliding(addButtonBoundingBox, otherRectangle)) {
        isVisible = false;
      }
    });

    // // Check if within stage bounds
    if (!isWithinStageBounds(addButtonBoundingBox, stage)) {
      isVisible = false;
    }

    //check if top add button isColliding with editDeleteButtonsGroupBoundingBox
    //check all, because the current loop we're in gets run multiple times
    if (boundingBoxDirection === "top" && editDeleteButtonsGroup?.visible()) {
      const groups = stage.find(
        (node) => node.id() && node.id().startsWith("edit-delete-bounding-box-")
      );
      groups.forEach((group) => {
        if (isColliding(addButtonBoundingBox, group)) {
          isVisible = false;
        }
      });
    }

    // //check if bottom add button isColliding with includeExcludeDoneEditingBoundingBox
    if (
      boundingBoxDirection === "bottom" &&
      includeExcludeDoneEditingButtonsGroup?.visible()
    ) {
      if (
        isColliding(addButtonBoundingBox, includeExcludeDoneEditingBoundingBox)
      ) {
        isVisible = false;
      }
    }

    //Get the corresponding addButton
    const addButton = stage.findOne(
      `#add-button-${boundingBoxDirection}-${boundingBoxIdNumber}`
    );
    if (addButton) {
      if (boundingBoxDirection === "top") {
        if (isVisible) {
          addButton.show();
        } else {
          addButton.hide();
        }
      } else {
        if (isVisible) {
          addButton.show();
        } else {
          addButton.hide();
        }
      }
    }
  });

  //add check for include-exclude-done-editing-buttons-group
  if (includeExcludeDoneEditingBoundingBox) {
    let isVisible = true;
    // Check if within stage bounds
    if (!isWithinStageBounds(includeExcludeDoneEditingBoundingBox, stage)) {
      isVisible = false;
    }

    // Get the corresponding includeExcludeButton
    if (isVisible) {
      //put it back where it started, handled by updateShapeCoordinates()
    } else {
      //add it to inside the rect
      const rectAbsolute = rect.getClientRect();
      const buttonGroupX =
        rectAbsolute.x / stageScaleX +
        rectAbsolute.width / stageScaleX -
        includeExcludeMarginX.value;
      const buttonGroupY =
        rectAbsolute.y / stageScaleY +
        rectAbsolute.height / stageScaleY -
        includeExcludeGroupMarginY;

      includeExcludeDoneEditingButtonsGroup.position({
        x: buttonGroupX,
        y: buttonGroupY
      });
      includeExcludeDoneEditingButtonsGroup.moveToTop();
    }
  }

  //add check for include-exclude-done-editing-buttons-group
  if (editDeleteButtonsGroupBoundingBox) {
    let isVisible = true;
    // Check if within stage bounds
    if (!isWithinStageBounds(editDeleteButtonsGroupBoundingBox, stage)) {
      isVisible = false;
    }

    if (isVisible) {
      //put it back where it started, handled by updateShapeCoordinates()
    } else {
      //add it to inside the rect
      const rectAbsolute = rect.getClientRect();
      const buttonGroupX =
        rectAbsolute.x / stageScaleX +
        rectAbsolute.width / stageScaleX -
        editDeleteGroupMarginX.value;
      const buttonGroupY =
        rectAbsolute.y / stageScaleY + editDeleteGroupMarginY;

      editDeleteButtonsGroup.position({ x: buttonGroupX, y: buttonGroupY });
      editDeleteButtonsGroup.moveToTop();
    }
  }
}

function updateShapeCoordinates(e) {
  const shape = e.target ?? e;
  const {
    stage,
    addButtonRight,
    addButtonLeft,
    addButtonTop,
    addButtonBottom,
    includeExcludeDoneEditingButtonsGroup,
    editDeleteButtonsGroup
  } = getObjects(e);

  const stageScaleX = stage.scaleX();
  const stageScaleY = stage.scaleY();

  const absoluteShape = {
    x: shape.getClientRect().x / stageScaleX,
    y: shape.getClientRect().y / stageScaleY,
    width: shape.getClientRect().width / stageScaleX,
    height: shape.getClientRect().height / stageScaleY
  };

  //update position of add button relative to the rectangle
  if (addButtonRight) {
    addButtonRight.x(
      absoluteShape.x + absoluteShape.width + addButtonMargin.value
    );
    addButtonRight.y(absoluteShape.y + absoluteShape.height / 2);
  }
  if (addButtonLeft) {
    addButtonLeft.x(absoluteShape.x - addButtonMargin.value);
    addButtonLeft.y(absoluteShape.y + absoluteShape.height / 2);
  }
  if (addButtonTop) {
    addButtonTop.x(absoluteShape.x + absoluteShape.width / 2);
    addButtonTop.y(absoluteShape.y - addButtonMargin.value);
  }
  if (addButtonBottom) {
    addButtonBottom.x(absoluteShape.x + absoluteShape.width / 2);
    addButtonBottom.y(
      absoluteShape.y + absoluteShape.height + addButtonMargin.value
    );
  }
  if (includeExcludeDoneEditingButtonsGroup) {
    includeExcludeDoneEditingButtonsGroup.x(
      absoluteShape.x + absoluteShape.width - includeExcludeMarginX.value
    );
    includeExcludeDoneEditingButtonsGroup.y(
      absoluteShape.y + absoluteShape.height + includeExcludeMarginY.value
    );
  }
  if (editDeleteButtonsGroup) {
    editDeleteButtonsGroup.x(
      absoluteShape.x + absoluteShape.width - editDeleteGroupMarginX.value
    );
    editDeleteButtonsGroup.y(absoluteShape.y - editDeleteGroupMarginY.value);
  }
}

//update the rectangles color/opacity when it changes
watch(
  () => [props.zoneColor, props.zoneOpacity],
  () => {
    const stage = konvaStageRef.value.getStage();

    const rgbaColor = makeRgba(props.zoneColor, props.zoneOpacity); //rgba is color with opacity

    //update the color of the rects
    const rects = stage.find((node) => node.id().startsWith(`rect-`));
    rects.forEach((rect) => {
      rect.fill(rgbaColor);

      let rectId = rect
        .id()
        .split("-")
        .pop();
      //loop through all rects and find their add buttons
      const addButtonRight = stage.findOne(`#add-button-right-${rectId}`);
      const addButtonLeft = stage.findOne(`#add-button-left-${rectId}`);
      const addButtonTop = stage.findOne(`#add-button-top-${rectId}`);
      const addButtonBottom = stage.findOne(`#add-button-bottom-${rectId}`);

      if (addButtonRight) {
        const circle = addButtonRight.findOne("Circle");
        const path = addButtonRight.findOne("Path");
        circle.stroke(props.zoneColor);
        path?.fill(props.zoneColor);
      }
      if (addButtonLeft) {
        const circle = addButtonLeft.findOne("Circle");
        const path = addButtonLeft.findOne("Path");
        circle.stroke(props.zoneColor);
        path?.fill(props.zoneColor);
      }
      if (addButtonTop) {
        const circle = addButtonTop.findOne("Circle");
        const path = addButtonTop.findOne("Path");
        circle.stroke(props.zoneColor);
        path?.fill(props.zoneColor);
      }
      if (addButtonBottom) {
        const circle = addButtonBottom.findOne("Circle");
        const path = addButtonBottom.findOne("Path");
        circle.stroke(props.zoneColor);
        path?.fill(props.zoneColor);
      }
    });
  }
);

function makeRgba(color, opacity) {
  //Extracting individual RGB values, example input: rgb(0,255,240)
  let rgbValues = color.match(/\d+/g);

  //Formatting into the rgb string, example output: "0, 255, 240"
  let rgb = rgbValues.join(", ");

  //make rbga with combined props, this allows for opacity to change in the rect without affecting stroke color
  return `rgba(${rgb}, ${opacity})`;
}

//watch includeExclude prop and update the text on the button
watch(
  () => props.zoneInclude,
  () => {
    const stage = konvaStageRef.value.getStage();
    const text = stage.find("Text");

    text?.forEach((text) => {
      text.text(props.zoneInclude ? "Included" : "Excluded");
    });

    //show hide the check mark or the no match icon
    const includeExcludeCheckmark = stage.findOne(
      "#include-exclude-checkmark-icon"
    );
    const noMatchIcon = stage.findOne("#no-match-icon");

    if (includeExcludeCheckmark && noMatchIcon) {
      if (props.zoneInclude) {
        includeExcludeCheckmark.show();
        noMatchIcon.hide();
      } else {
        includeExcludeCheckmark.hide();
        noMatchIcon.show();
      }
    }
  }
);

function getObjects(e) {
  const objectId = e?.target?.id() || e?.id();
  const objectIdNumber =
    e?.target
      ?.id()
      ?.split("-")
      .pop() ||
    e
      ?.id()
      ?.split("-")
      .pop();
  const stage = konvaStageRef.value.getStage();
  const drawingLayer = drawingLayerRef.value.getStage();
  const img = stage.findOne(`#background-image`);
  const rect = stage.findOne(`#rect-${objectIdNumber}`);
  const addButtonRight = stage.findOne(`#add-button-right-${objectIdNumber}`);
  const addButtonLeft = stage.findOne(`#add-button-left-${objectIdNumber}`);
  const addButtonTop = stage.findOne(`#add-button-top-${objectIdNumber}`);
  const addButtonBottom = stage.findOne(`#add-button-bottom-${objectIdNumber}`);
  const addButtonBoundingBoxRight = stage.findOne(
    `#add-button-bounding-box-right-${objectIdNumber}`
  );
  const addButtonBoundingBoxLeft = stage.findOne(
    `#add-button-bounding-box-left-${objectIdNumber}`
  );
  const addButtonBoundingBoxTop = stage.findOne(
    `#add-button-bounding-box-top-${objectIdNumber}`
  );
  const addButtonBoundingBoxBottom = stage.findOne(
    `#add-button-bounding-box-bottom-${objectIdNumber}`
  );
  const addButtonBoundingBoxes = [
    addButtonBoundingBoxRight,
    addButtonBoundingBoxLeft,
    addButtonBoundingBoxTop,
    addButtonBoundingBoxBottom
  ];
  const includeExcludeButton = stage.findOne(`#include-exclude-button`);
  const doneEditingButton = stage.findOne(`#done-editing-button`);
  const includeExcludeDoneEditingButtonsGroup = stage.findOne(
    `#include-exclude-done-editing-button-group`
  );
  const includeExcludeDoneEditingBoundingBox = stage.findOne(
    `#include-exclude-done-editing-bounding-box`
  );
  const editButton = stage.findOne(`#edit-button-${objectIdNumber}`);
  const deleteButton = stage.findOne(`#delete-button-${objectIdNumber}`);
  const editDeleteButtonsGroup = stage.findOne(
    `#edit-delete-buttons-group-${objectIdNumber}`
  );
  const editDeleteButtonsGroupBoundingBox = stage.findOne(
    `#edit-delete-bounding-box-${objectIdNumber}`
  );
  const tr = stage.findOne(`#transformer-${objectIdNumber}`);

  const allGroups = stage.find(
    (node) => node.id() && node.id().startsWith("group-")
  );
  const allRects = stage.find(
    (node) => node.id() && node.id().startsWith("rect-")
  );
  const allEditDeleteButtonGroups = stage.find(
    (node) => node.id() && node.id().startsWith("edit-delete-buttons-group-")
  );
  const allAddButtonBoundingBoxes = stage.find(
    (node) => node.id() && node.id().startsWith("add-button-bounding-box")
  );

  //loop through allGroups and get the group that contains rect.id()
  let group;
  if (allGroups) {
    allGroups.forEach((grp) => {
      const groupRects = grp.find(
        (node) => node.id() && node.id().startsWith("rect-")
      );
      if (!groupRects) {
        return;
      }
      groupRects.forEach((rect) => {
        const rectId = rect
          .id()
          .split("-")
          .pop();
        if (rectId === objectIdNumber) {
          group = grp;
        }
      });
    });
  }

  //use allRects and get allAddButtons
  let allAddButtons = [];
  allRects?.forEach((rect) => {
    const rectNumber = rect
      .id()
      .split("-")
      .pop();
    const addButtons = ["top", "right", "bottom", "left"];
    addButtons.forEach((addButton) => {
      const addBtn = stage.findOne(`#add-button-${addButton}-${rectNumber}`);
      allAddButtons.push(addBtn);
    });
  });

  return {
    objectId,
    objectIdNumber,
    stage,
    drawingLayer,
    img,
    rect,
    allRects,
    addButtonRight,
    addButtonLeft,
    addButtonTop,
    addButtonBottom,
    addButtonBoundingBoxes,
    allAddButtonBoundingBoxes,
    allAddButtons,
    includeExcludeButton,
    doneEditingButton,
    includeExcludeDoneEditingButtonsGroup,
    includeExcludeDoneEditingBoundingBox,
    editButton,
    deleteButton,
    editDeleteButtonsGroup,
    editDeleteButtonsGroupBoundingBox,
    group,
    tr,
    allEditDeleteButtonGroups,
    allGroups,
    addButtonBoundingBoxRight,
    addButtonBoundingBoxLeft,
    addButtonBoundingBoxTop,
    addButtonBoundingBoxBottom
  };
}

function handleImageClick(e) {
  const { allEditDeleteButtonGroups, allRects, doneEditingButton } = getObjects(
    e
  );

  //remove all transformers
  const destroyObj = {
    transformer: true
  };
  destroyAll(destroyObj);

  if (doneEditingButton && doneEditingButton.visible()) {
    doneEditingButton.hide();
  }

  allEditDeleteButtonGroups.forEach((group) => {
    group.show();
  });

  //make all rects not draggable
  allRects.forEach((rect) => {
    rect.draggable(false);
    checkButtonsBoundaries(rect);
  });
}

// Function to add a transformer to a rectangle
function addRemoveTransformer(e) {
  const { objectIdNumber, tr, rect, stage } = getObjects(e);

  //add transformer on click
  if (!tr) {
    //get all transformers and hide them all
    const allTransformers = stage.find("Transformer");
    allTransformers.forEach((transformer) => {
      transformer.hide();
    });

    const drawingLayer = drawingLayerRef.value.getStage();
    const transformer = new Konva.Transformer({
      id: `transformer-${objectIdNumber}`,
      rotateEnabled: false,
      nodes: [rect],
      boundBoxFunc: (oldBox, newBox) => {
        const box = newBox;
        const isOut =
          box.x < 0 ||
          box.y < 0 ||
          box.x + box.width > stage.width() ||
          box.y + box.height > stage.height();

        // if new bounding box is out of visible viewport, let's just skip transforming
        // this logic can be improved by still allow some transforming if we have small available space
        if (isOut) {
          return oldBox;
        }
        return newBox;
      }
    });

    drawingLayer.add(transformer);
  }
  //remove transformer on click if transformer is already present (like toggle)
  if (tr?.visible()) {
    tr.hide();
  } else {
    tr?.show();
  }
}

function handleRectClick(e) {
  //make this rect draggable = true
  e.target.draggable(true);

  const { allRects, includeExcludeDoneEditingButtonsGroup, tr } = getObjects(e);

  //make all other rects not draggable
  allRects.forEach((rect) => {
    if (rect.id() !== e.target.id()) {
      rect.draggable(false);
    }
  });

  //remove all transformers
  addRemoveTransformer(e);

  //hide all include/exclude buttons
  if (includeExcludeDoneEditingButtonsGroup) {
    if (includeExcludeDoneEditingButtonsGroup.visible()) {
      if (tr) {
        includeExcludeDoneEditingButtonsGroup.hide();
      }
    } else {
      if (!tr) {
        includeExcludeDoneEditingButtonsGroup.show();
        includeExcludeDoneEditingButtonsGroup.moveToTop(); //add it to the top of the drawing layer
      }
    }
  }

  //enable save button
  emit("enableSave", true);

  updateShapeCoordinates(e);
  checkButtonsBoundaries(e);
}

function destroyAll(obj) {
  const {
    stage,
    drawingLayer,
    allRects,
    includeExcludeDoneEditingButtonsGroup,
    includeExcludeDoneEditingBoundingBox,
    editDeleteButtonsGroup,
    editDeleteButtonsGroupBoundingBox
  } = getObjects();

  if (obj.transformer) {
    const tr = stage.find("Transformer");
    tr.forEach((transformer) => {
      transformer.destroy();
    });
  }

  if (obj.circle) {
    const circles = stage.find("Circle");
    circles.forEach((circle) => {
      circle.destroy();
    });
  }

  if (obj.rect) {
    const rects = stage.find("Rect");
    rects.forEach((rect) => {
      rect.destroy();
    });
  }

  if (obj.group) {
    const groups = stage.find("Group");
    groups.forEach((group) => {
      group.destroy();
    });
  }

  //obj includeExclude
  //find the include-exclude button and delete it
  if (obj.includeExclude) {
    const includeExcludeButton = stage.findOne(`#include-exclude-button`);
    if (includeExcludeButton) {
      includeExcludeButton.destroy();
    }
  }

  //objDoneEditing
  //destroy the done editing button
  if (obj.doneEditing) {
    const doneEditingButton = stage.findOne(`#done-editing-button`);
    if (doneEditingButton) {
      doneEditingButton.destroy();
    }
  }

  //whole button group for include/exclude/done editing
  if (obj.includeExcludeDoneEditingGroup) {
    if (includeExcludeDoneEditingButtonsGroup) {
      includeExcludeDoneEditingButtonsGroup.destroy();
      includeExcludeDoneEditingBoundingBox.destroy();
    }
  }

  //whole button group for edit/delete
  if (obj.editDeleteGroup) {
    if (editDeleteButtonsGroup) {
      editDeleteButtonsGroup.destroy();
      editDeleteButtonsGroupBoundingBox.destroy();
    }
  }

  //objEditDelete
  //destroy all edit and delete buttons by rect
  if (obj.editDelete) {
    //get all rects and loop through them
    allRects.forEach((rect) => {
      const rectNumber = rect
        .id()
        .split("-")
        .pop();
      const editButton = stage.findOne(`#edit-button-${rectNumber}`);
      if (editButton) {
        editButton.destroy();
      }
      const deleteButton = stage.findOne(`#delete-button-${rectNumber}`);
      if (deleteButton) {
        deleteButton.destroy();
      }
    });
  }

  //objAddButtons
  if (obj.addButtons) {
    //get all rects and loop through them
    allRects.forEach((rect) => {
      const rectNumber = rect
        .id()
        .split("-")
        .pop();
      const addButtons = ["top", "right", "bottom", "left"];
      addButtons.forEach((addButton) => {
        const addBtn = stage.findOne(`#add-button-${addButton}-${rectNumber}`);
        if (addBtn) {
          addBtn.destroy();
        }
      });
    });
  }

  drawingLayer.draw();
}

function saveCaptureZones() {
  const stage = konvaStageRef.value.getStage();
  //update all rects in captureZonesData.value to use x/y/height/width from rect.getClientRect()
  const rects = stage.find(
    (node) => node.id() && node.id().startsWith("rect-")
  );
  //find the rect that matches the id in captureZonesData and update the x/y/height/width with getClientRect()
  rects.forEach((rect) => {
    const rectNumber = rect
      .id()
      .split("-")
      .pop();
    const rectCoordinates = rect.getClientRect();

    //update the captureZonesGroup with the new coordinatesfor the rect
    captureZoneGroups.forEach((group) => {
      group.rects.forEach((rect) => {
        if (rect.id === `rect-${rectNumber}`) {
          rect.x = rectCoordinates.x;
          rect.y = rectCoordinates.y;
          rect.width = rectCoordinates.width;
          rect.height = rectCoordinates.height;
        }
      });
    });
  });

  //get height and width of stage and set it to imageData
  const width = konvaContainerRef.value.offsetWidth;
  const height = konvaContainerRef.value.offsetHeight;
  imageData.width = width;
  imageData.height = height;

  updateGroupNames();

  emit("captureZonesEmit", captureZonesData.value);
}
</script>

<template>
  <div class="konva-container" ref="konvaContainerRef">
    <v-stage ref="konvaStageRef" :config="konvaStageConfig">
      <v-layer ref="imageLayerRef">
        <v-image
          :config="{
            image: cameraPreview,
            x: imageData.x,
            y: imageData.y,
            width: imageData.width,
            height: imageData.height,
            name: imageData.name,
            id: imageData.id
          }"
        />
      </v-layer>
      <v-layer ref="drawingLayerRef">
        <!-- populated and managed by <script>, contains all shapes and groups -->
      </v-layer>
    </v-stage>
  </div>
</template>

<style scoped>
.konva-container {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: calc(100vh - 140px);
}
</style>
