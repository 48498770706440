<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { Capacitor } from "@capacitor/core";
import csvHelper from "@/js/csvHelper";
import RocDragAndDrop from "@/components/ui/RocDragAndDrop.vue";
import RocGrid from "@/components/ui/RocGrid.vue";
import RocPageSelector from "@/components/ui/RocPageSelector.vue";
import RocIcon from "../ui/RocIcon.vue";
import Popper from "vue3-popper";

const emits = defineEmits(["enroll"]);
const store = useStore();
const files = ref(null);
const fileNames = ref([]);
const page = ref(1);
const totalPages = ref(1);
const previewImageCache = ref([]);
const showDelete = ref(true);
const globalAllowEdit = ref(true);
const rowDefs = ref([]);
const isEnrolling = ref(false);
const gridEditMode = ref(true);
const gridDeleteMode = ref(true);
let lastId = -1;
const itemsPerPage = 10;
let isRunning = false;

const colDefs = ref([
  { label: "", key: "img", editable: false },
  { label: "First Name", key: "f", editable: true },
  { label: "Last Name", key: "l", editable: true },
  { label: "path", key: "path", editable: false, hide: true }
]);

watch(page, () => {
  previewImageCache.value = [];
});

function deleteFile(index) {
  let deleteIndex = (page.value - 1) * itemsPerPage + index;
  fileNames.value.splice(deleteIndex, 1);
  previewImageCache.value.splice(index, 1);
  const newMaxPages = Math.ceil(fileNames.value.length / itemsPerPage);
  if (newMaxPages != totalPages.value) {
    totalPages.value = newMaxPages;
  }
}

function clearFiles() {
  fileNames.value.splice(0, fileNames.value.length);
  page.value = 1;
  totalPages.value = 1;
  previewImageCache.value = [];
  files.value = [];
  showDelete.value = true;
  globalAllowEdit.value = true;
}

function handleContentChange(e, index) {
  let editIndex = (page.value - 1) * itemsPerPage + index;
  if (!e.target?.id) {
    fileNames.value[editIndex].notes = e;
    return;
  }
  switch (e.target.id) {
    case "f":
      fileNames.value[editIndex].f = e.target.innerText;
      break;
    case "l":
      fileNames.value[editIndex].l = e.target.innerText;
      break;
    default:
      console.warn("Editing of this field is not supported");
  }
}

//so with how fast the ui is moving this is to slow, and causes the grid to have more than what was displayed.
const storeReset = computed(function() {
  if (store.getters["watchlists/noFiles"]) {
    fileNames.value = [];
    rowDefs.value = [];
    isEnrolling.value = false;
    colDefs.value = colDefs.value.filter((col) => col.label !== "Status"); //remove this from table since we arent enrolling.
    gridEditMode.value = true;
    gridDeleteMode.value = true;
    return [];
  } else {
    return rowDefs.value;
  }
});

const windowWidth = ref(window.innerWidth);
onMounted(() => {
  window.addEventListener("resize", () => {
    windowWidth.value = window.innerWidth;
  });
});

const filterFiles = computed(function() {
  const startPos = (page.value - 1) * itemsPerPage;
  const endPos = startPos + itemsPerPage;
  return fileNames.value.slice(startPos, endPos);
});

watch(
  filterFiles,
  async () => {
    if (!isRunning) await loadPreviewImageCache(filterFiles.value);
  },
  { deep: true }
);

async function loadPreviewImageCache(filteredResultSet) {
  isRunning = true;
  previewImageCache.value = [];
  let tempRows = [];
  for (let i = 0; i < filteredResultSet.length; i++) {
    const file = filteredResultSet[i];
    if (file) {
      if (!previewImageCache.value[i]) {
        const bytes = await loadPreview(file);
        previewImageCache.value.push(bytes);
        tempRows.push({
          img: bytes ?? file.url,
          f: file.f,
          l: file.l,
          path: file.path,
          status: file.enrollStatus,
          errorMsg: file.enrollStatusMsg,
          notes: file.notes
        });
        if (file.enrollStatus != undefined && !isEnrolling.value) {
          isEnrolling.value = true;
          colDefs.value.push({ label: "Status", key: "status" });
          gridEditMode.value = false;
          gridDeleteMode.value = false;
        }
      }
    }
  }
  rowDefs.value = tempRows;
  store.commit("watchlists/setNoFiles", false);
  isRunning = false;
}

function getNameParts(fullName) {
  let first, last;
  let regexFirst, regexLast;

  var platform = Capacitor.getPlatform();
  if (
    /^((?!chrome|android).)*safari/i.test(navigator.userAgent) ||
    platform === "ios"
  ) {
    // Special case for Safari/iOS as it doesn't support the 'positive lookbehind' RegEx in the general case
    const isCamelOrPascal = new RegExp(
      "(([A-Z][a-z0-9]+)+)|(^[a-z]+(([A-Z][a-z0-9]+)+))",
      "g"
    );
    if (isCamelOrPascal.test(fullName)) {
      try {
        var splitByCapitals = fullName.match(/^([a-z]+(?=[A-Z]))|[A-z][a-z]+/g);
        first =
          splitByCapitals[0].charAt(0).toUpperCase() +
          splitByCapitals[0].slice(1);
        last = splitByCapitals[splitByCapitals.length - 1];
      } catch {
        first = "";
        last = "";
      }
    } else {
      var splitBySpaces = fullName.split(/-_ /g);
      first = splitBySpaces[0];
      last = splitBySpaces[splitBySpaces.length - 1];
    }
  } else {
    const regexCaseSeparation = new RegExp(
      "^[A-Z0-9][a-z0-9]+(?<=[a-z0-9])[A-Z0-9][a-zA-Z0-9]+",
      "g"
    );
    if (regexCaseSeparation.test(fullName)) {
      regexFirst = new RegExp("^[A-Z0-9][a-z0-9]+", "g");
      regexLast = new RegExp("(?<=[a-z0-9])[A-Z0-9][a-zA-Z0-9]+", "g");
    } else {
      regexFirst = new RegExp("^[a-zA-Z0-9]+", "g");
      regexLast = new RegExp("(?<=[- _])[a-zA-Z0-9_ -]+", "g");
    }

    first = fullName.match(regexFirst)
      ? fullName.match(regexFirst)[0].trim()
      : "";
    last = fullName.match(regexLast) ? fullName.match(regexLast)[0].trim() : "";
  }

  return {
    firstname: first,
    lastname: last
  };
}

async function processFileArray(fileArray) {
  let totalImageFiles = 0;
  let manifest;
  try {
    fileArray.sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    });
    const manifestFile = fileArray.find((file) => file.name === "manifest.csv");
    if (manifestFile) {
      console.log("manifest.csv found.  Processing.");
      const manifestData = await readAsText(manifestFile);
      manifest = CSVToManifestObjArray(manifestData);
    }
  } catch (err) {
    console.log(err);
  }

  for (let i = 0; i < fileArray.length; i++) {
    let file = fileArray[i];
    const fileExt = file.name
      .substring(file.name.lastIndexOf(".") + 1, file.name.length)
      .toLowerCase();
    if (
      file &&
      (file["type"].split("/")[0] === "image" ||
        fileExt === "eft" ||
        fileExt === "ebts")
    ) {
      totalImageFiles++;
      let fileDetails;
      try {
        if (manifest && manifest.rows) {
          fileDetails = manifest.rows.find((row) => {
            if (row.file_name.toLowerCase() === file.name.toLowerCase()) {
              return true;
            }
          });
          if (!fileDetails) {
            console.log(
              `details for this file (${file.name}) were not found in the manifest.  Check your CSV data and formatting.`
            );
          }
        }
      } catch (err) {
        console.log("failed to process mainfest.csv, check formatting. ", err);
      }

      let details = fileDetails;
      const nameNoEx = file.name.split(".")[0];
      if (!details) {
        if (nameNoEx && fileExt !== "eft") {
          details = getNameParts(nameNoEx);
        } else {
          details = {};
        }
      }
      try {
        const filePath = file.webkitRelativePath
          ? file.webkitRelativePath
          : file.name;
        const enrollmentPackage = {
          id: ++lastId,
          f: details.firstname,
          l: details.lastname,
          notes: details.notes,
          internalId: details.internalId,
          code: details.code,
          fileHandle: file,
          path: filePath
        };
        fileNames.value.push(enrollmentPackage);
      } catch (err) {
        console.log(err);
      }
    }
  }
  totalPages.value = Math.ceil(totalImageFiles / itemsPerPage);
  emits("enroll", { files: fileNames.value, page: page.value });
}

function CSVToManifestObjArray(CSV_string) {
  const delimiter = ",";
  const firstRowHeader = true;
  //construct validator
  // = await validator.validate .....
  const resultObj = new csvHelper().csvToObject(
    CSV_string,
    delimiter,
    firstRowHeader
  );
  if (resultObj && resultObj.rows.length) {
    try {
      const arrayOfObjs = resultObj.rows.map((enrollment) => ({
        firstname: enrollment[0],
        lastname: enrollment[1],
        code: enrollment[2],
        notes: enrollment[3],
        file_name: enrollment[4],
        internalId: enrollment[5]
      }));
      resultObj.rows = arrayOfObjs;
      return resultObj;
    } catch (err) {
      console.log(err);
    }
    return {};
  }
}

async function loadPreview(file) {
  return await loadImage(file.fileHandle);
}

async function loadImage(file) {
  if (file) {
    if (file.type.startsWith("image") === true) {
      return await readAsDataURL(file);
    } else {
      return null;
    }
  }
}

function readAsDataURL(file) {
  return new Promise(function(resolve, reject) {
    let fr = new FileReader();
    fr.onload = function() {
      resolve(fr.result);
    };
    fr.onerror = function() {
      reject(fr);
    };
    fr.readAsDataURL(file);
  });
}

function readAsText(file) {
  return new Promise(function(resolve, reject) {
    let fr = new FileReader();
    fr.onload = function() {
      resolve(fr.result);
    };
    fr.onerror = function() {
      reject(fr);
    };
    fr.readAsText(file, "UTF-8");
  });
}

function changePage(value) {
  page.value = value;
}
</script>

<template>
  <div class="fileSelector">
    <div v-if="storeReset?.length == 0" style="height: 100%">
      <RocDragAndDrop
        @onDrop="processFileArray"
        :folders="true"
        :files="true"
        :accepts="'image/*,.svg,.eft,.ebts,.csv'"
      />
    </div>
    <div v-else>
      <RocGrid
        :columnDefs="colDefs"
        :rows="rowDefs"
        :allowDelete="gridDeleteMode"
        :allowEdit="gridEditMode"
        @deleteAll="clearFiles"
        @deleteItem="deleteFile"
        @contentChanged="handleContentChange"
      >
        <template #status="{value,error}">
          <Popper
            v-if="value === 'success'"
            class="rocPopper"
            arrow
            content="Enrollment successful."
          >
            <RocIcon icon="check" size="lg" color="primary" />
          </Popper>
          <Popper
            v-else-if="value === 'failed'"
            class="rocPopper"
            arrow
            :content="error"
          >
            <RocIcon icon="error" color="red" size="md" />
          </Popper>
          <roc-spinner v-else :size="'sm'" />
        </template>
      </RocGrid>
      <RocPageSelector
        class="stick-to-bottom"
        :currPage="page"
        :lastPage="totalPages"
        @goToPage="changePage"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.fileSelector {
  @include overwatch-body-xsmall;
  width: 100%;
  max-width: 100%;
}

.rocPopper :deep(.popper) {
  background: var(--overwatch-primary);
  padding: 20px;
  border-radius: 20px;
  color: var(--overwatch-button-text);
  text-transform: uppercase;
}

.rocPopper :deep(.popper #arrow::before) {
  background: var(--overwatch-primary);
}

.rocPopper :deep(.popper:hover),
.rocPopper :deep(.popper:hover > #arrow::before) {
  background: var(--overwatch-primary);
}

#img {
  display: flex;
}

@media (max-width: 480px) {
  .files-container {
    /* display:flex; 
    flex-direction: column; */
    height: auto;
  }

  .file-path {
    min-width: 30%;
  }
  .choose-files-or-drag {
    font-size: 18px;
  }
}
</style>
