export default {
  /*
    payload must be 
    { 
      _watchlistId: [watchlistId selected in ui],
      firstname:
      lastname:
      email: [user enters email],
      phoneNumber: [user enters phoennumber],
      notes: [optional free text notes]
    }
    must contain either email or phone
  */
  async createEntitlement(context, payload) {
    const url = `/rest/v1/enroll/entitlement`;
    const httpPayload = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    };
    return await context.dispatch(
      "auth/fetchJSON",
      { url: url, payload: httpPayload },
      { root: true }
    );
  },
  /*
    payload must be 
    { 
      id: entitlementId,
      update: [entitlement object filled out with fields to update]
    }
    Since entitlements are exported, 'update' will delete previous and create a new one

  */
  async updateEntitlement(context, payload) {
    const url = `/rest/v1/enroll/entitlement/${payload.id}`;
    const httpPayload = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload.update)
    };
    return await context.dispatch(
      "auth/fetchJSON",
      { url: url, payload: httpPayload },
      { root: true }
    );
  },
  /*
    id: entitlementId
  */
  async deleteEntitlementByID(context, id) {
    const url = `/rest/v1/enroll/entitlement/${id}`;
    const httpPayload = {
      method: "DELETE",
      headers: {
        Accept: "application/json"
      }
    };
    return await context.dispatch(
      "auth/fetchJSON",
      { url: url, payload: httpPayload },
      { root: true }
    );
  },
  /*
    payload must be 
    { 
      watchlistId: [watchlistId selected in ui],
      currentPage: [page the ui is on],
      statusList: [optional array of statuses filter],
      filter: [optional free text filter]
      pageSize: [Optional, # of items per page defaults to 50 otherwise]
    }
  */
  async getEntitlements(context, payload) {
    let url = `/rest/v1/enroll/entitlement/${payload._eventId}/${payload.currentPage}`;
    const bodyJson = {};
    if (payload.filter) {
      bodyJson.filterText = payload.filter;
    }
    if (payload.statusList) {
      bodyJson.filterStatus = payload.statusList;
    }
    if (payload.pageSize) {
      bodyJson.pageSize = payload.pageSize;
    }
    const httpPayload = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(bodyJson)
    };
    // caller should parse response and set entitlements array in vuex
    return await context.dispatch(
      "auth/fetchJSON",
      { url: url, payload: httpPayload },
      { root: true }
    );
  },
  /**
   * This queries the entitlement info, not the full object.
   * Info could include: First Name, Last Name, Event Name
   * @param {} context
   * @param {Object} payload
   * @returns
   */
  async getEntitlementInfoByID(context, payload) {
    const url = `/rest/v1/enroll/entitlement/${payload._eventId}/${payload._entitlementId}`;
    const httpPayload = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    };
    return await context.dispatch(
      "auth/fetchJSON",
      { url: url, payload: httpPayload },
      { root: true }
    );
  },
  async getEvents(context) {
    const url = `/rest/v1/enroll/event`;
    const httpPayload = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    };
    return await context.dispatch(
      "auth/fetchJSON",
      { url: url, payload: httpPayload },
      { root: true }
    );
  },
  async getEventsPaged(context, payload) {
    const url = `/rest/v1/enroll/event/${payload.currentPage}`;
    const bodyJson = {};
    if (payload.filter) {
      bodyJson.filterText = payload.filter;
    }
    if (payload.filterEnabled) {
      bodyJson.filterEnabled = payload.filterEnabled;
    }
    const httpPayload = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(bodyJson)
    };
    return await context.dispatch(
      "auth/fetchJSON",
      { url: url, payload: httpPayload },
      { root: true }
    );
  },
  async createEvent(context, payload) {
    const url = `/rest/v1/enroll/event`;
    const httpPayload = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    };
    return await context.dispatch(
      "auth/fetchJSON",
      { url: url, payload: httpPayload },
      { root: true }
    );
  },
  async updateEvent(context, payload) {
    const url = `/rest/v1/enroll/event/${payload._id}`;
    const httpPayload = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    };
    return await context.dispatch(
      "auth/fetchJSON",
      { url: url, payload: httpPayload },
      { root: true }
    );
  },
  async deleteEvent(context, id) {
    const url = `/rest/v1/enroll/event/${id}`;
    const httpPayload = {
      method: "DELETE",
      headers: {
        Accept: "application/json"
      }
    };
    return await context.dispatch(
      "auth/fetchJSON",
      { url: url, payload: httpPayload },
      { root: true }
    );
  },
  async getEvents(context) {
    const url = `/rest/v1/enroll/event`;
    const httpPayload = {
      method: "GET",
      headers: {
        Accept: "application/json"
      }
    };
    return await context.dispatch(
      "auth/fetchJSON",
      { url: url, payload: httpPayload },
      { root: true }
    );
  },
  async getEvent(context, id) {
    const url = `/rest/v1/enroll/event/${id}`;
    const httpPayload = {
      method: "GET",
      headers: {
        Accept: "application/json"
      }
    };
    return await context.dispatch(
      "auth/fetchJSON",
      { url: url, payload: httpPayload },
      { root: true }
    );
  }
};
