export default {
  resetState(state, defaultState) {
    // Merge rather than replace so we don't lose observers
    Object.assign(state, defaultState);
  },
  setCameras(state, payload) {
    state.cameras = payload;
  },
  setImporterVisible(state, payload) {
    state.importerVisible = payload;
  },
  setCases(state, payload) {
    state.cases = payload;
  },
  addNewCase(state, payload) {
    state.cases.push(payload);
  },
  deleteCase(state, payload) {
    const index = state.cases.findIndex((c) => {
      return c._id === payload.id;
    });
    if (index >= 0) {
      state.cases.splice(index, 1);
    }
  },
  replaceCase(state, payload) {
    const index = state.cases.findIndex((c) => {
      return c._id === payload._id;
    });
    if (index >= 0) {
      state.cases.splice(index, 1, payload);
    }
  },
  setEditingCase(state, payload) {
    state.editingCase = payload;
  },
  setIsCaseProcessing(state, payload) {
    state.isCaseProcessing = payload;
  },
  setIsCaseReprocessing(state, payload) {
    state.isCaseReprocessing = payload;
  },
  setEncounterFilterSelectedCase(state, payload) {
    state.encounterFilterSelectedCase = payload;
  },
  setDateTimeParser(state, payload) {
    state.dateTimeParserValue = payload;
  },
  setActiveMissionId(state, payload) {
    state.activeMissionId = payload;
  },
  setPreviousMissionId(state, payload) {
    state.previousMissionId = payload;
  },
  setSelectedTable(state, payload) {
    state.selectedTable = payload;
  },
  setLoadedOnce(state, payload) {
    state.loadedOnce = payload;
  }
};
