<template>
  <div class="associated-wrapper">
    <div class="associated-candidate">
      <AuthImg
        :src="
          `/rest/v1/image/watchlistedfaces/${candidate.watchlistedFace._id}/tn`
        "
      />
    </div>
    <div class="associated-list">
      <div v-if="associatedWatchlistedFaces.length === 0">
        No associated faces.
      </div>
      <div v-for="face of associatedWatchlistedFaces" class="associated-face">
        <AuthImg :src="`/rest/v1/image/watchlistedfaces/${face._id}/tn`" />
        <div>
          <RocIcon icon="user" color="black" size="sm" />
          {{ face.internalId }}
        </div>
        <div>
          <RocIcon icon="image" color="black" size="sm" />
          {{ face.watchlist?.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import AuthImg from "@/components/ui/AuthImg";
import RocIcon from "@/components/ui/RocIcon";

export default {
  name: "AssociatedImages",
  props: ["candidate"],
  components: {
    AuthImg,
    RocIcon
  },
  setup(props, context) {
    const store = useStore();

    const associatedWatchlistedFaces = ref([]);
    onMounted(async () => {
      const response = await store.dispatch(
        "investigations/getAssociatedWatchlistedFaces",
        { id: props.candidate.watchlistedFace._id }
      );
      if (response.status === "success") {
        associatedWatchlistedFaces.value.push(
          ...response.result.filter(
            (f) => f._id !== props.candidate.watchlistedFace._id
          )
        );
      }
    });

    return {
      associatedWatchlistedFaces
    };
  }
};
</script>

<style scoped lang="scss">
i {
  width: 18px;
}

.associated-wrapper {
  display: flex;
  gap: var(--spacing-s);
  min-width: 790px;
}

.associated-candidate img {
  height: 250px;
}

.associated-list {
  display: grid;
  width: fit-content;
  grid-template-columns: 1fr 1fr 1fr;
  gap: var(--spacing-s);

  max-height: 600px;
  overflow-y: auto;
}

.associated-face {
  display: flex;
  flex-direction: column;

  border: solid 1px var(--overwatch-accent);
  border-radius: 5px;
  padding: var(--spacing-base);
}

.associated-face div {
  display: flex;
  gap: var(--spacing-s);
  align-items: center;

  margin-top: var(--spacing-base);
  margin-left: var(--spacing-base);
}

.associated-face img {
  height: 180px;
}
</style>
