import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

const getDefaultState = () => {
  return {
    watchlists: [],
    importerVisible: false,
    importerType: "face",
    importerMode: "create",
    importerWatchlistId: "",
    importerEventId: "",
    encounterFilterSelectedWatchlists: [],
    noFiles: true,
    maxParallelEnrollments: 16
  };
};

const state = getDefaultState();

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  getDefaultState
};
