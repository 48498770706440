export default {
  getInactivityTimeout(state) {
    return state.inactivityTimeout;
  },
  getLastActivity(state) {
    return state.lastActivity;
  },
  getSharedUserAction(state) {
    return state.sharedUserAction;
  }
}