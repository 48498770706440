class versionInfo {
  constructor() {
    this.version = "examine-adjust-queue-large-knowns";
    this.revision = "d9fdd4a8143e1ee8264f393536daccbb674a2d00";
    this.commitCount = ".1";
    this.buildDate = "Mon Oct 21 16:51:52 UTC 2024";
    this.isProduction = !this.version.startsWith("{{"); // The script for building the production container will substitute the above values

    if (!this.isProduction) {
      // For dev environment determine version from git
      try {
        this.version = require("child_process")
          .execSync("git rev-parse --abbrev-ref HEAD")
          .toString()
          .trim();
      } catch (err) {
        this.version = "DEV";
      }

      try {
        this.revision = require("child_process")
          .execSync("git rev-parse HEAD")
          .toString()
          .trim();
      } catch (err) {
        this.revision = "DEV";
      }

      try {
        this.commitCount = require("child_process")
          .execSync("git rev-list --count origin/master..")
          .toString()
          .trim();
      } catch (err) {
        this.commitCount = "DEV";
      }

      this.buildDate = new Date().toUTCString();
    }
  }
}

module.exports = versionInfo;
