<template>
  <MDBDropdown v-model="isMenuOpen" dropend @mouseleave="closeAfterTime()">
    <RocDropdownToggle @mouseenter="open()" class="toggle">
      <RocDropdownItem>
        <div class="toggle-div">
          <slot></slot>
          <RocIcon color="gray" icon="menuCaret" height="12" width="8" />
        </div>
      </RocDropdownItem>
    </RocDropdownToggle>
    <RocDropdownMenu>
      <slot name="menu"></slot>
    </RocDropdownMenu>
  </MDBDropdown>
</template>

<script>
import { ref } from "vue";
import { MDBDropdown } from "mdb-vue-ui-kit";
import RocDropdownMenu from "@/components/ui/RocDropdownMenu.vue";
import RocDropdownToggle from "@/components/ui/RocDropdownToggle.vue";
import RocDropdownItem from "@/components/ui/RocDropdownItem";
import RocIcon from "@/components/ui/RocIcon";

export default {
  name: "RocDropdownSub",
  components: {
    RocDropdownMenu,
    RocDropdownToggle,
    RocDropdownItem,
    RocIcon,
    MDBDropdown
  },
  setup(props, context) {
    const isMenuOpen = ref(false);

    /**
     * Need timeout to prevent a rare Vue bug
     * that occurs when isMenuOpen is rapidly toggled.
     * Happens frequently without timeout.
     */
    var timeout;

    function open() {
      if (timeout) {
        clearTimeout(timeout);
      }
      isMenuOpen.value = true;
    }

    function closeAfterTime() {
      timeout = setTimeout(() => (isMenuOpen.value = false), 100);
    }

    return {
      isMenuOpen,
      open,
      closeAfterTime
    };
  }
};
</script>

<style scoped lang="scss">
.toggle::after {
  display: none;
}

.toggle-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
