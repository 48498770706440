export default {
  resetState(state, defaultState) {
    Object.assign(state, defaultState);
  },
  setCases(state, payload) {
    state.cases = payload;
  },
  addNewCase(state, payload) {
    state.cases.push(payload);
  },
  setNewCase(state, payload) {
    state.newCase = payload;
  },
  replaceCase(state, payload) {
    const index = state.cases.findIndex((c) => {
      return c._id === payload._id;
    });
    if (index >= 0) {
      state.cases.splice(index, 1, payload);
    }
  },
  deleteCase(state, payload) {
    const index = state.cases.findIndex((c) => {
      return c._id === payload.caseId;
    });
    if (index >= 0) {
      state.cases.splice(index, 1);
    }
  }
};
