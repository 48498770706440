<script setup>
import { ref, watch, defineEmits, defineProps, watchEffect } from "vue";
import { Vue3ColorPicker } from "@cyhnkckali/vue3-color-picker"; //link to api: https://www.npmjs.com/package/@cyhnkckali/vue3-color-picker
import "@cyhnkckali/vue3-color-picker/dist/style.css";

const props = defineProps(["modelValue", "placement"]);
const emits = defineEmits(["update:modelValue"]);

const colorValue = ref(props.modelValue);
watch(colorValue, (newValue) => {
  emits("update:modelValue", newValue);
});

watch(
  () => props.modelValue,
  (newValue) => {
    colorValue.value = newValue;
  }
);

const colorPickerRef = ref(null);
const colorPickerToggleRef = ref(null);
const showColorPicker = ref(false);

watchEffect((onRefInvalidate) => {
  //check if settingsToggle is true before adding event listener.
  if (showColorPicker.value) {
    // Attach click event listener to the document body when div is visible
    document.body.addEventListener("click", handleOutsideClick, {
      passive: true
    });
    // Remove event listener when component is unmounted or div is hidden
    onRefInvalidate(() => {
      document.body.removeEventListener("click", handleOutsideClick, {
        passive: true
      });
    });
  }
});

const handleOutsideClick = (event) => {
  // Check if the clicked element is outside the target div
  if (colorPickerRef.value && !colorPickerRef.value.contains(event.target)) {
    //if the click is on the settings menu icon do not perform function
    if (
      colorPickerToggleRef.value &&
      !colorPickerToggleRef.value.contains(event.target)
    ) {
      showColorPicker.value = false;
    }
  }
};
</script>

<template>
  <div
    class="colorpicker-toggle"
    ref="colorPickerToggleRef"
    @click="showColorPicker = !showColorPicker"
    :style="{ backgroundColor: colorValue }"
  ></div>
  <div
    v-if="showColorPicker"
    class="colorpicker-wrapper"
    ref="colorPickerRef"
    :class="{
      top: placement === 'top',
      bottom: placement === 'bottom',
      left: placement === 'left',
      right: placement === 'right'
    }"
  >
    <Vue3ColorPicker
      v-model="colorValue"
      :mode="'solid'"
      :type="'RGB'"
      :inputType="'RGB'"
      :showColorList="false"
      :showEyeDrop="false"
      :showInputSet="false"
      :showInputMenu="false"
      :showAlpha="false"
    />
  </div>
</template>

<style scoped>
.colorpicker-toggle {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
}

.top {
  position: absolute;
  top: 30px;
  left: -30px;
}

.bottom {
  position: absolute;
  bottom: -150px;
  left: -30px;
}

.left {
  position: absolute;
  left: -200px;
}

.right {
  position: absolute;
  right: -50px;
}

.colorpicker-wrapper :deep(.ck-cp-container) {
  padding: 4px;
  border-radius: 0px;
  background-color: var(--overwatch-secondary);
  --cp-range-shadow: var(--overwatch-overlay);
}

.colorpicker-wrapper :deep(.colour-area-point-circle) {
  box-shadow: 0 2px 4px 0 var(--overwatch-overlay);
  border: solid 2px var(--overwatch-light-neutral-500);
  height: 20px;
  width: 20px;
  pointer-events: none; /*bug fix -- if set to auto, the circle jumps on click*/
}

.colorpicker-wrapper :deep(.colour-area) {
  border-radius: 0px;
}

.colorpicker-wrapper :deep(.picker-hue) {
  margin: var(--spacing-base) 0 0 0;
}

.colorpicker-wrapper :deep(.picker-hue-range-slider) {
  height: 9px;
  border: none;
  border-radius: 10px;
  background-image: linear-gradient(
    to right,
    #f00 0%,
    #ff8a00 14%,
    #52ff00 29%,
    #0ff 43%,
    #0047ff 58%,
    #8000ff 72%,
    #ff00f5 86%,
    #f00 100%
  );
}

.colorpicker-wrapper :deep(input[type="range"]::-webkit-slider-thumb) {
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  color: transparent;
  width: 9px;
  height: 9px;
  box-shadow: 0 2px 4px 0 var(--overwatch-overlay);
  border: solid 1px var(--overwatch-light-neutral-500);
}
</style>
