import mutations from "./mutations.js";
import getters from "./getters.js";

const getDefaultState = () => {
  return {
    lastActivity: null,
    inactivityTimeout: 600,
    sharedUserAction: null
  };
};

const state = getDefaultState();

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  getDefaultState
};
