<template>
  <div>
    <Multiselect
      :allow-absent="true"
      :mode="mode"
      :placeholder="placeholderText"
      :filter-results="true"
      :min-chars="1"
      :resolve-on-load="false"
      :searchable="true"
      :close-on-select="false"
      :noResultsText="noResultsText"
      v-model="selectedOptions"
      :options="availableItems"
      :style="dynamicStyle"
      :groups="groupsEnabled"
      :groupSelect="false"
      :caret="false"
      @open="toggleActive(true)"
      @close="toggleActive(false)"
      ref="multiselectRef"
      @focus.stop.capture
    >
      <!-- this template maintains default styling but allows a selected tag to be readonly if marked as disabled -->
      <template v-slot:tag="{ option, handleTagRemove }">
        <div
          v-if="option.label !== 'Plus Badge'"
          class="multiselect-tag"
          :class="{ 'is-disabled': option.disabled }"
        >
          <img :src="option.image" />
          <span
            style="max-width: 25ch; overflow: hidden; text-overflow: ellipsis;"
            :title="
              option.label && option.label.length >= 15 ? option.label : null
            "
          >
            {{ option.label }}
          </span>
          <span
            v-if="!option.disabled"
            class="multiselect-tag-remove"
            style="cursor: pointer;"
            @click="handleTagRemove(option, $event)"
          >
            <span class="multiselect-tag-remove-icon"></span>
          </span>
        </div>
        <!-- render plus badge icon -->
        <div
          v-else
          class="multiselect-tag"
          style="border: none; background-color: unset; padding-left: 0px;"
        >
          <RocIcon
            size="md"
            color="primary"
            icon="addButton"
            style="cursor: pointer;"
          />
        </div>
      </template>
    </Multiselect>
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import { ref, watch, computed, nextTick } from "vue";
import RocIcon from "@/components/ui/RocIcon.vue";

export default {
  name: "TagGroupMultiselect",
  components: {
    Multiselect,
    RocIcon
  },
  emits: ["selection-changed"],
  props: {
    index: {
      type: Number,
      default: 0
    },
    andOrSelected: {
      type: String,
      default: "or"
    },
    availableItems: Array,
    currentlySelected: {
      type: [Array, String]
    },
    name: String,
    noResultsText: {
      type: String,
      default: "No results found"
    },
    placeholderText: String,
    groupsEnabled: Boolean,
    mode: {
      type: String,
      default: "tags"
    }
  },
  setup(props, context) {
    //selectedOptionsBase is used to keep track of the selected options before the plus badge is added
    const selectedOptionsBase = ref(props.currentlySelected);
    //selectedOptions is used to keep track of the selected options after the plus badge is added
    const selectedOptions = ref(
      Array.isArray(props.currentlySelected) &&
        selectedOptionsBase.value.length > 0
        ? [...props.currentlySelected, "Plus Badge"]
        : []
    );
    const andOrSelected = ref(props.andOrSelected);

    watch(
      () => props.currentlySelected,
      () => {
        selectedOptionsBase.value = props.currentlySelected;
        //add plus badge if multiselect is not enabled
        if (isEnabled.value === false) {
          if (
            selectedOptionsBase.value &&
            selectedOptionsBase.value.length > 0
          ) {
            selectedOptions.value = Array.isArray(props.currentlySelected)
              ? [...props.currentlySelected, "Plus Badge"]
              : ["Plus Badge"];
          }
        } else {
          //props.currentlySelected will not have the plus badge in it, so this is like removing it
          selectedOptions.value = props.currentlySelected;
        }
      }
    );

    watch(
      () => props.andOrSelected,
      (nv) => {
        andOrSelected.value = nv;
        //if user is just changing and/or with no tags selected, don't emit
        if (selectedOptionsBase.value && selectedOptionsBase.value.length > 0) {
          emitToParent();
        }
      }
    );

    watch(
      () => selectedOptions.value,
      () => {
        const tags = selectedOptions.value.filter(
          (item) => item !== "Plus Badge"
        );
        if (!_.isEmpty(_.xor(selectedOptionsBase.value, tags))) {
          //don't send if props passed into multiselect if input field and props are equal
          emitToParent();
          selectedOptionsBase.value = tags;
        }
      }
    );

    function emitToParent() {
      const emitObj = {
        index: props.index,
        andOr: andOrSelected.value,
        selectedTags: selectedOptions.value.filter(
          (item) => item !== "Plus Badge"
        )
      };
      context.emit("selection-changed", emitObj);
    }

    const multiselectRef = ref(null);
    const isEnabled = ref(false);

    function enableSelf() {
      isEnabled.value = !isEnabled.value;
      nextTick(() => {
        if (isEnabled.value) {
          multiselectRef.value.open();
          multiselectRef.value.focus();
        } else {
          multiselectRef.value.close();
        }
      });
    }

    function clearOptions() {
      //this clears selectedOptions, but not selectedOptionsBase
      multiselectRef.value.clear();
      //so if selectedOptions is cleared, and selectedOptionsBase has items, emit to parent
      //because that's saying the user has actually changed something, rather than just clicking clear when no tags are present
      if (selectedOptionsBase.value && selectedOptionsBase.value.length > 0) {
        emitToParent();
      }
    }

    function toggleActive(active) {
      if (active) {
        isEnabled.value = true;
        //when multiselect opens, remove plus badge from selected options
        selectedOptions.value = selectedOptions.value.filter(
          (item) => item !== "Plus Badge"
        );
      } else {
        isEnabled.value = false;
        //when multiselect closes, add plus badge back to selected options
        if (selectedOptionsBase.value && selectedOptionsBase.value.length > 0) {
          selectedOptions.value = Array.isArray(selectedOptions.value)
            ? [...selectedOptions.value, "Plus Badge"]
            : ["Plus Badge"];
          selectedOptions.value = [...new Set(selectedOptions.value)];
        }
      }
    }

    const dynamicStyle = computed(() => {
      const baseStyle = `
                        --ms-tag-radius: 9999px;
                        --ms-tag-font-size: 14px;
                        --ms-ring-color: var(--overwatch-neutral-500);
                        --ms-border-color-active: var(--overwatch-neutral-300);
                        --ms-dropdown-bg: var(--overwatch-secondary);
                        --ms-dropdown-border-color: var(--overwatch-neutral-300);
                        --ms-dropdown-border-width: 1px;
                        --ms-dropdown-radius: 0px;

                        background-color: var(--overwatch-neutral-500);
                        --ms-spinner-color: var(--overwatch-primary);
                        --ms-option-color-pointed: var(--overwatch-neutral-100);
                        --ms-option-color-selected: var(--overwatch-button-text);
                        --ms-option-bg-selected: var(--overwatch-button-primary);
                        --ms-option-bg-selected-pointed: var(--overwatch-button-primary);
                        --ms-option-bg-pointed: var(--overwatch-button-primary-20);
                        --ms-option-font-size: 0.85rem;
                        --ms-group-label-line-height: 2;
                        --ms-group-label-bg-selected: var(--overwatch-button-primary);
                        --ms-group-label-bg-selected-pointed: var(--overwatch-button-primary);
                        box-sizing: border-box;
                        border-radius: 0px;
                        width: 100%;
                        --ms-border-width: 0px;
                        display: flex;
                        align-items: center;
                        height: auto;
                        margin: 0;
                        font-weight: 500;
                        font-size: 16px;
                        `;
      return baseStyle;
    });

    return {
      selectedOptions,
      dynamicStyle,
      enableSelf,
      isEnabled,
      multiselectRef,
      toggleActive,
      clearOptions
    };
  }
};
</script>
