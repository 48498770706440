<template>
  <div class="wrapper">
    <RocIcon
      color="black"
      size="sm"
      icon="previousEncounter"
      @click="$emit('previous')"
    />
    <RocIcon
      color="black"
      size="sm"
      icon="rewind10"
      @click="$emit('rewind10')"
    />
    <RocIcon
      class="margins"
      color="black"
      size="sm"
      icon="rewind"
      @click="$emit('rewind')"
    />
    <RocIcon
      v-show="!isVideoPlaying"
      color="black"
      size="sm"
      icon="play"
      @click="play"
    />
    <RocIcon
      v-show="isVideoPlaying"
      color="black"
      size="sm"
      icon="pause2"
      @click="pause"
    />
    <RocIcon
      class="margins"
      color="black"
      size="sm"
      icon="fastForward"
      @click="$emit('fastForward')"
    />
    <RocIcon
      color="black"
      size="sm"
      icon="forward10"
      @click="$emit('forward10')"
    />
    <RocIcon
      color="black"
      size="sm"
      icon="nextEncounter"
      @click="$emit('next')"
    />

    <div style="margin-left: auto;">
      {{ formattedCurrentTime }} / {{ formattedDuration }}
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import RocIcon from "@/components/ui/RocIcon";

const props = defineProps({
  isVideoPlaying: Boolean,
  currentTime: Number,
  duration: Number
});
const emit = defineEmits([
  "play",
  "pause",
  "forward10",
  "rewind10",
  "rewind",
  "fastForward",
  "next",
  "previous"
]);

function play() {
  emit("play");
}

function pause() {
  emit("pause");
}

const formattedCurrentTime = computed(() => {
  if (props.currentTime) {
    var date = new Date(0);
    date.setSeconds(props.currentTime); // specify value for SECONDS here
    var timeString = date.toISOString().substring(11, 19);
    if (timeString.substring(0, 2) == "00") {
      // cut off hours if possible
      return timeString.substring(4);
    }
    return timeString;
  }
  return "00:00";
});

const formattedDuration = computed(() => {
  if (props.duration) {
    var date = new Date(0);
    date.setSeconds(props.duration); // specify value for SECONDS here
    var timeString = date.toISOString().substring(11, 19);
    if (timeString.substring(0, 2) == "00") {
      // cut off hours if possible
      return timeString.substring(4);
    }
    return timeString;
  }
  return "00:00";
});
</script>

<style scoped lang="scss">
.wrapper {
  display: flex;
  gap: $--spacing-base;

  padding: $--spacing-base 0;

  align-items: center;

  :deep(svg) {
    cursor: pointer;
  }
}

.margins {
  margin: 0 $--spacing-base;
}
</style>
