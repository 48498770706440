<script setup>
import { ref, computed, onMounted } from "vue";
import RocIcon from "../../components/ui/RocIcon.vue";
import { useStore } from "vuex";
import moment from "moment";
import { getVersionInfo } from "@/js/rocHelper.js";
import RocButton from "../../components/ui/RocButton.vue";

const store = useStore();
const showErrorIcon = ref(false);
const windowWidth = ref(window.innerWidth);
const isMobile = ref(windowWidth.value <= 480);
const licenseInformation = ref(getLicenseInfo());
const appVersInfo = getVersionInfo();
const rocWatchVersion = appVersInfo.version;
console.dir(appVersInfo);

onMounted(() => {
  window.addEventListener("resize", () => {
    windowWidth.value = window.innerWidth;
  });
});

function getLicenseInfo() {
  let result = store.getters["settings/getLicenseFlags"];
  return {
    cameras: result.cameras,
    users: result.users,
    gallery: result.watchlist_size.toLocaleString(),
    products: getProducts(result),
    modalites: getModalities(result),
    expiration: result.expirationDate ?? "Expiration date not found",
    status:
      getLicenseStatus(result.expirationDate) ?? "Expiration date not found",
    macAddress: result.macAddress
  };
}

function getLicenseStatus(date) {
  const expirationDate = moment(date);
  const today = moment(new Date());
  const lengthOfLicense = moment.duration(expirationDate.diff(today)).asDays();

  if (lengthOfLicense < 0) {
    showErrorIcon.value = true;
    return "Expired";
  } else if (lengthOfLicense < 30) {
    showErrorIcon.value = true;
    return "Expiring Soon";
  } else return "Active";
}

function getModalities(licenseInfo) {
  let modalites = ["Face"];
  if (licenseInfo.object) modalites.push("Gun,Person,Vehicle");
  if (licenseInfo.tattoo) modalites.push("Tattoo");
  if (licenseInfo.object && licenseInfo.ocr) modalites.push("LPR");
  if (licenseInfo.fingerprint && modalites.length === 4 && isMobile.value) {
    modalites.push("Finge..");
  } else if (licenseInfo.fingerprint) modalites.push("Fingerprint");

  return modalites.toString() ?? "No Modalities Detected";
}

function getProducts(licenseInfo) {
  let products = [];
  if (licenseInfo.watch) products.push("Watch");
  if (licenseInfo.enroll) products.push("Enroll");
  if (licenseInfo.examine) products.push("Examine");

  return products.toString() ?? "No products Detected";
}
</script>

<template>
  <div>
    <div class="licenseInfoPage">
      <div class="overwatch-title-large">
        License
      </div>
      <div class="licenseCard">
        <h2 style="padding-left: var(--spacing-l)" class="overwatch-title-med">
          License Information
        </h2>
        <hr />
        <div class="licenseInfoBox">
          <div class="column">
            <div class="info">
              Products:
              <div class="desc">{{ licenseInformation.products }}</div>
            </div>
            <div class="info">
              Status:
              <div class="desc">{{ licenseInformation.status }}</div>
            </div>
            <div class="info">
              Modalities:
              <div class="desc">{{ licenseInformation.modalites }}</div>
            </div>
            <div class="info">
              Expiration:
              <div class="desc">
                {{ licenseInformation.expiration }}
                <RocIcon
                  v-if="showErrorIcon"
                  color="red"
                  icon="error"
                  style="margin-left: var(--spacing-m);"
                />
              </div>
            </div>
            <div class="info">
              ROC Watch Version Number:
              <div class="desc">{{ rocWatchVersion }}</div>
            </div>
            <div class="info">
              MAC Address:
              <div class="desc">{{ licenseInformation.macAddress }}</div>
            </div>
            <div class="info">
              Allowable # Camera Streams:
              <div class="desc">{{ licenseInformation.cameras }}</div>
            </div>
            <div class="info">
              Allowable # of Users:
              <div class="desc">{{ licenseInformation.users }}</div>
            </div>
            <div class="info">
              Gallery Size:
              <div class="desc">{{ licenseInformation.gallery }}</div>
            </div>
          </div>
        </div>
        <!-- <RocButton type="primary">
          <RocIcon icon="outsideLink" color="primary" />
          Release Notes
        </RocButton> -->
        <hr />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.licenseInfoPage {
  margin-left: var(--spacing-l);
  padding-top: var(--spacing-l);
}

.licenseCard {
  margin-top: var(--spacing-xl);
  padding-top: var(--spacing-l);
  padding-bottom: var(--spacing-l);
  background: var(--overwatch-secondary);
  border: 1px solid var(--overwatch-accent);
  border-radius: 5px;
  width: 95%;
  max-width: 850px;
}

.licenseInfoBox {
  display: flex;
  flex-wrap: wrap;
  padding: var(--spacing-xl) var(--spacing-l);
}

.column {
  flex-direction: column;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 850px;
  padding-bottom: var(--spacing-l);
  flex-wrap: wrap;
  align-content: center;

  @include overwatch-title-xsmall;
}

.desc {
  display: flex;
  max-width: 500px;
  align-items: center;
  width: 80%;
  flex-wrap: wrap;
  @include overwatch-body-small;
}
</style>
