import { useCookies } from "vue3-cookies";

export default class userAccessHelper {
  constructor() {}

  parseUserAccessCookie() {
    try {
      const { cookies } = useCookies();
      return cookies.get("userAccess");
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  getStartPage() {
    try {
      const userAccess = this.parseUserAccessCookie();
      if (userAccess) {
        return userAccess.clientStartPage ?? "/missions";
      }
    } catch (err) {
      console.error(err);
    }
    return "/missions";
  }

  getAllowedPages() {
    try {
      const userAccess = this.parseUserAccessCookie();
      if (userAccess) {
        return userAccess.clientAllowedPages ?? [];
      }
    } catch (err) {
      console.error(err);
    }
    return [];
  }

  isPageAllowed(page) {
    const allowedPages = this.getAllowedPages();
    if (
      !allowedPages ||
      allowedPages.length === 0 ||
      allowedPages.includes(page)
    ) {
      // all pages allowed, or this one specified
      return true;
    } else {
      // iterate allowed pages list, handle prefix paths by looking for wildcard *
      for (let i = 0; i < allowedPages.length; i++) {
        if (allowedPages[i].endsWith("*")) {
          if (
            page.startsWith(
              allowedPages[i].substring(0, allowedPages[i].length - 1)
            )
          ) {
            return true;
          }
        }
      }
      return false;
    }
  }

  isAdminOrPowerUser() {
    try {
      const userAccessCookie = new userAccessHelper().parseUserAccessCookie();
      return (
        userAccessCookie &&
        (userAccessCookie.role === "admin" ||
          userAccessCookie.role === "powerUser")
      );
    } catch (err) {
      return false;
    }
  }

  isDashboardRole() {
    try {
      const userAccess = this.parseUserAccessCookie();
      if (userAccess) {
        return userAccess.role === "enrollDashboard";
      }
    } catch (err) {
      console.error(err);
    }
    return false;
  }

  isEnrollUser() {
    try {
      const userAccess = this.parseUserAccessCookie();
      if (userAccess) {
        return userAccess.role === "enrollUser";
      }
    } catch (err) {
      console.error(err);
    }
    return false;
  }
}
