<script setup>
import { defineProps, ref, watch } from "vue";
import BaseDialog from "@/components/ui/BaseDialog";
import RocSelect from "./RocSelect.vue";
import RocButton from "./RocButton.vue";
import filterMultiSelect from "@/components/ui/filterMultiSelect";

const props = defineProps({
  itemName: {
    type: String,
    default: "",
    note: "Item being moved"
  },
  moveToOptions: {
    type: Array,
    default: []
  },
  objType: {
    type: String,
    default: ""
  },
  singleMove: {
    type: Boolean,
    default: false
  },
  currentlySelectedGroups: {
    type: Array,
    default: []
  }
});

const emits = defineEmits(["onClose", "onSave"]);
const selectedDestination = ref(
  props.singleMove ? "" : props.currentlySelectedGroups
);

function onClose() {
  emits("onClose");
}

function onSave() {
  if (selectedDestination.value != "") {
    emits("onSave", selectedDestination.value);
  } else {
    onClose();
  }
}

function handleSelectionChange(selectedDes) {
  selectedDestination.value = selectedDes.value;
}
</script>

<template>
  <base-dialog :show="true" title="Move to..." @close="onClose">
    <div class="content">
      <span class="overwatch-title-med">{{ objType }}</span>
      <p>{{ itemName }}</p>
      <div v-if="singleMove">
        <RocSelect
          style="width: 100%"
          :availableOptions="moveToOptions"
          :placeholder="'Selected a Destination'"
          :currently-selected="selectedDestination"
          @selection-changed="selectedDestination = $event"
        />
        <slot name="input"></slot>
      </div>
      <div v-else style="width:100%">
        <filterMultiSelect
          style="margin:8px 0px 2px;"
          mode="tags"
          :close-on-select="false"
          placeholder-text="Select Desinations"
          :available-items="moveToOptions"
          :createTags="false"
          :currently-selected="selectedDestination"
          @selection-changed="handleSelectionChange"
          :enableClear="false"
        />
      </div>
      <div class="buttons">
        <rocButton @click="onClose" type="secondary" id="rocMoveCloseButton"
          >Cancel</rocButton
        >
        <rocButton @click="onSave" type="primary" id="rocMoveSaveButton"
          >Save</rocButton
        >
      </div>
      <slot></slot>
    </div>
  </base-dialog>
</template>

<style scoped lang="scss">
.content {
  display: flex;
  flex-direction: column;
  width: 450px;
  height: 125px;
  max-height: 175px;
  border-radius: 5px;
  margin-bottom: 90px;
}

.buttons {
  display: flex;
  margin-top: 20px;
  gap: var(--spacing-xl);
  justify-content: flex-end;
}
</style>
