<template>
  <div class="audContainer">
    <audio id="sound" ref="sound"></audio>
    <div style="color: var(--overwatch-error);">{{ fileSizeError }}</div>
    <div style="min-width: var(--spacing-xl);">{{ fileName }}</div>
    <div class="audioIcons">
      <div style="gap: var(--spacing-m); display: flex; align-items: center;">
        <RocIcon
          style="cursor: pointer;"
          v-if="!fileName && !loading"
          size="sm"
          color="primary"
          icon="upload"
          @click="uploadAudio"
        />
        <RocSpinner v-else-if="loading" size="sm" />
        <RocIcon
          style="cursor: pointer;"
          v-else
          size="sm"
          color="primary"
          icon="exit"
          @click="handleClearAudio"
        />
        <RocIcon
          style="cursor: pointer;"
          color="primary"
          @click="handlePlayPressed"
          size="sm"
          icon="play"
        />
      </div>
    </div>
    <input
      ref="filesIndividual"
      type="file"
      style="display: none"
      @change="selectFile($event)"
      multiple
      :accept="'.mp3'"
    />
  </div>
</template>
<script setup>
import RocIcon from "@/components/ui/RocIcon.vue";
import RocSpinner from "@/components/ui/RocSpinner.vue";
import { ref, watch } from "vue";

const props = defineProps({
  accepts: {
    type: String,
    default: ".mp3"
  },
  id: { //can be watchlist id or modality 
    type: String,
    default: null
  },
  preSelectedFileName: {
    type: String,
    default: undefined,
  },
  audioEndpoint: {
    type: String,
    default: undefined
  },
});

const emits = defineEmits(["fileSelected", "removeAudio"]);
const maxFileSize = 8 * 1024 * 1024; //8MB
const fileSizeError = ref(undefined);
const filesIndividual = ref();
const fileName = ref();
const loading = ref(false);
const sound = ref();
const uploadedFile = ref(false);

watch(() => props.preSelectedFileName, (nv) => {
  if(!nv) return 
    
  if(nv.includes('mp3'))
    return fileName.value = nv;
  return fileName.value = nv + '.mp3';
},{ immediate: true })

function handleClearAudio() {
  fileName.value = undefined;
  sound.value.src = undefined;
  uploadedFile.value = false;
  emits("removeAudio");
}

function handlePlayPressed() {
  if (!sound.value) return;

  if(!uploadedFile.value)
  {
    sound.value.src = props?.audioEndpoint ?? `/rest/v1/media/audio/alarm/${props.id}/stream`;
  }

  playSound();
}

function playSound() {
  sound.value?.play()?.catch(err => console.log(err));
}

async function selectFile(e) {
  loading.value = true;
  const file = e.target.files[0];

  sound.value.src = await audioToBase64(file);
  uploadedFile.value = true;

  if (!file) return;

  if (file.size > maxFileSize) {
    fileSizeError.value = "This file Exceeds 8mb!";
    return;
  } else fileSizeError.value = undefined;


  fileName.value = file.name;

  emits("fileSelected", file);
  loading.value = false;
}

function uploadAudio() {
  filesIndividual.value.click();
}

async function audioToBase64(audioFile) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onerror = reject;
    reader.onload = (e) => resolve(e.target.result);
    reader.readAsDataURL(audioFile);
  });
}
</script>
<style scoped lang="scss">
.audioIcons {
  display: flex;
  justify-content: space-between;
  margin-left: var(--spacing-xxl);
}
.audContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
</style>
