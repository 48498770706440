<template>
  <div>
    <div>
      <div class="TitleText">
        <slot name="title"></slot>
      </div>
      <div class="ConfirmationText">
        <slot></slot>
      </div>
    </div>

    <div
      class="d-flex justify-content-end"
      style="gap: var(--spacing-s); margin-top: var(--spacing-xl)"
    >
      <RocButton
        v-if="showNoButton"
        @click.stop="noButton()"
        type="secondary"
        size="dialogButton"
        >{{ noButtonTitle }}
      </RocButton>
      <RocButton @click.stop="yesButton()" type="primary" size="dialogButton"
        >{{ yesButtonTitle }}
      </RocButton>
    </div>
  </div>
</template>

<script>
import RocButton from "@/components/ui/RocButton.vue";

export default {
  props: {
    yesButtonTitle: {
      type: String,
      default: "Yes"
    },
    noButtonTitle: {
      type: String,
      default: "No"
    },
    showNoButton: {
      type: Boolean,
      default: true
    }
  },
  emits: ["yes", "no", "close"],
  components: {
    RocButton
  },
  setup(props, context) {
    function yesButton() {
      context.emit("yes");
      context.emit("close");
    }

    function noButton() {
      context.emit("no");
      context.emit("close");
    }

    return {
      yesButton,
      noButton
    };
  }
};
</script>

<style scoped lang="scss">
* {
  box-sizing: border-box;
}

.ButtonSecondaryDefault {
  width: 115px;
  height: 45px;
  gap: 10px;
  margin: 20px 0px 10px 30px;
  padding: 12px;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: var(--overwatch-neutral-300);
}

.ButtonPrimaryNormal {
  width: 115px;
  height: 45px;
  gap: 10px;
  margin: 20px 20px 0px 10px;
  padding: 12px;
  object-fit: contain;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: var(--overwatch-button-primary);
}

.ButtonText {
  height: 21px;
  flex-grow: 1;
  @include overwatch-body-small;
  text-align: center;
  color: var(--overwatch-button-text);
}

.ConfirmationText {
  @include overwatch-body-med;
}

.TitleText {
  @include overwatch-title-med;
  display: flex;
  justify-content: center;
  margin-bottom: var(--spacing-m);
}

@media (max-width: 480px) {
  .buttons {
    display: flex;
  }
  .buttons button {
    width: 0;
    flex: 1;
  }
}
</style>
