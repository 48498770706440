function constructSameFrameFilter(encounters) {
  const filter = [];

  for (let encounter of encounters) {
    const cameraId = encounter.track_info.cameraId;
    // const timestamp = encounter.timestamp;
    const startTimestamp = encounter.track_info.start_timestamp;
    const stopTimestamp = encounter.track_info.stop_timestamp;

    filter.push({
      cameraId: cameraId,
      timestamp: {
        start: startTimestamp,
        stop: stopTimestamp
      }
    });
  }

  return filter;
}

export { constructSameFrameFilter };
