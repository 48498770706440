<template>
  <div>
    <filterMultiSelect
      mode="tags"
      :close-on-select="false"
      placeholder-text="Select User Groups"
      :available-items="allUserGroups"
      :currently-selected="selectedUserGroupIds"
      @selection-changed="updateSelectedUserGroups"
      :enableClear="false"
    />
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import filterMultiSelect from "@/components/ui/filterMultiSelect";

const props = defineProps({
  currentlySelected: {
    type: Array,
    default: []
  },
  mode: {
    type: String,
    default: "add"
  }
});
const emit = defineEmits(["selection-changed"]);

const store = useStore();

const selectedUserGroupIds = ref(props.currentlySelected);

const allUserGroups = computed(() => {
  return store.getters["settings/userGroups"];
});

onMounted(() => {
  initUserGroups();
});

function initUserGroups() {
  const selectedUserGroups = [];
  try {
    // iterate available usergroups to check if selected or readonly
    for (let i = 0; i < allUserGroups.value.length; i++) {
      allUserGroups.value[i].value = allUserGroups.value[i]._id;
      // if usergroup is system and user not in system group, mark it disabled
      if (
        allUserGroups.value[i].isSysAdmin &&
        !userGroupContainsCurrentUser(allUserGroups.value[i])
      ) {
        allUserGroups.value[i].disabled = true;
      } else {
        allUserGroups.value[i].disabled = false;
      }
      if (props.mode === "add") {
        selectedUserGroups.push(allUserGroups.value[i].value);
      }
    }

    if (props.mode === "add") {
      selectedUserGroupIds.value = selectedUserGroups;
    }
  } catch (err) {
    console.log(err);
  }
}

// check if currently logged in user is a part of provided usergroup
function userGroupContainsCurrentUser(userGroup) {
  return userGroup.users.find((user) => {
    if (user.email === store.getters["auth/email"]) {
      return true;
    }
  });
}

function updateSelectedUserGroups(selectedUserGroups) {
  selectedUserGroupIds.value = selectedUserGroups.value;
  emit("selection-changed", selectedUserGroupIds.value);
}
</script>

<style scoped lang="scss"></style>
