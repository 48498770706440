import { get as lodashGet } from "lodash";

const MODE_ENROLL_FACE = "enrollFace";
const MODE_ENROLL_ID_PROOF = "enrollIdProof";
const MODE_VERIFY = "verify";
const MODE_IDENTIFY = "identify";
const MODE_DEFAULT = MODE_ENROLL_FACE;

const defaultRegulaConfig = {
  recognizer: {
    processParam: {
      returnUncroppedImage: false,
      scenario: "Locate",
      multipageProcessing: true,
      returnPackageForReprocess: false,
      timeout: 120000,
      timeoutFromFirstDetect: 60000,
      timeoutFromFirstDocType: 20000,
      resultTypeOutput: [],
      imageQa: {
        expectedPass: ["dpiThreshold", "glaresCheck", "focusCheck"],
        dpiThreshold: 150,
        glaresCheck: true,
        focusCheck: true,
        glaresCheckParams: {
          imgMarginPart: 0.05,
          maxGlaringPart: 0.01
        }
      }
    }
  },
  imageProcessor: {
    processParam: {
      scenario: "MrzOrBarcode",
      returnUncroppedImage: false,
      returnPackageForReprocess: false
    }
  }
};

export default {
  faceComplete: (state) => {
    return Boolean(state.capturedFaceData?.faceImage);
  },
  /*
   * returns true if:
   *   ID Proof Mode, ID first: ID capture complete & verified
   *   ID Proof Mode: face and ID capture complete and verified
   *   Enroll Only Mode: ID capture complete
   */
  idComplete: (state, getters) => {
    const identityDocData = getters.capturedIdentityDocData;
    const captured = Boolean(identityDocData.identityDocPageOne);
    if (getters.isIdUseRegulaCapture && captured) {
      return true;
    }
    const captureConfig = getters.captureConfigs;

    const validated =
      identityDocData.identityDocMetrics &&
      (!captureConfig ||
        !captureConfig.isIdAuthenticityEnforced() ||
        getters.capturedIdentityDocPage1Timeout ||
        identityDocData.identityDocMetrics.authentic) &&
      (!captureConfig ||
        !captureConfig.isIdOcrResultEnforced() ||
        (identityDocData.identityDocMetrics.ocrStatus &&
          identityDocData.identityDocMetrics.ocrStatus.ocrSuccess)) &&
      (identityDocData.identityDocBarcodeMrzSkipped ||
        (identityDocData.identityDocBarcodeMrz &&
          identityDocData.identityDocBarcodeMrz.barcodeStatus &&
          identityDocData.identityDocBarcodeMrz.barcodeStatus.barcodeSuccess));

    if (getters.isIdProofMode && getters.isIdFirst) {
      return captured && validated;
    } else if (getters.isIdProofMode && !getters.isDocumentProcessingDisabled) {
      return captured /*&& verified*/ && validated;
    } else if (getters.isIdProofMode) {
      return captured;
    } else {
      return captured;
    }
  },
  /*
   * returns true if:
   *   ID Proof Mode: face and ID capture complete and verified, RWW enrolled (if configured)
   *   Enroll Only Mode: face capture complete, RWW enrolled (if configured)
   */
  enrollComplete: (state, getters) => {
    if (getters.verifyOverride) {
      return getters.faceComplete;
    }
    if (getters.isIdProofMode) {
      if (getters.enrollIntoRww) {
        return getters.faceComplete && getters.idComplete && state.rwwEnrolled;
      } else {
        return getters.faceComplete && getters.idComplete;
      }
    } else {
      if (getters.enrollIntoRww) {
        return getters.faceComplete && state.rwwEnrolled;
      } else {
        return getters.faceComplete;
      }
      if (getters.isIdProofMode) {
        if (getters.enrollIntoRww) {
          return (
            getters.faceComplete && getters.idComplete && state.rwwEnrolled
          );
        } else {
          return getters.faceComplete && getters.idComplete;
        }
      } else {
        if (getters.enrollIntoRww) {
          return getters.faceComplete && state.rwwEnrolled;
        } else {
          return getters.faceComplete;
        }
      }
    }
  },
  captureConfigs: (state) => {
    return state.captureConfigs;
  },
  userSelectedCaptureConfig: (state) => {
    return lodashGet(state, "userConfig.userSelectedCaptureConfig", null);
  },
  selectedCaptureConfig: (state) => {
    return lodashGet(state, "userConfig.selectedCaptureConfig", null);
  },
  headerhideSettings: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowFlags.hideSettings",
      false
    );
  },
  iframeConfigured: (state) => {
    return Boolean(
      lodashGet(state, "sessionConfig.appFlavorConfig.iframeConfig", false)
    );
  },
  iframeEnabled: (state) => {
    const iframeConfig = lodashGet(
      state,
      "sessionConfig.appFlavorConfig.iframeConfig",
      { enabled: false }
    );
    return iframeConfig.enabled;
  },
  iframeExportOrigins: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.iframeConfig.iframeOrigin",
      []
    );
  },
  iframeCancelPayload: (state) => {
    const iframeConfig = lodashGet(
      state,
      "sessionConfig.appFlavorConfig.iframeConfig",
      { enabled: false }
    );
    return {
      integrator: iframeConfig.integrator,
      result: { status: "canceled" }
    };
  },
  iframeFinishedPayload: (state) => {
    const iframeConfig = lodashGet(
      state,
      "sessionConfig.appFlavorConfig.iframeConfig",
      { enabled: false }
    );
    return {
      integrator: iframeConfig.integrator,
      result: { status: "finished" }
    };
  },
  verificationThreshold: (state) => {
    return lodashGet(state, "enrollConfig.thresholds.verify", 0.55);
  },
  ocrFieldsList: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowConfig.ocrFields",
      []
    );
  },
  capturedFaceImage: (state) => {
    return lodashGet(state, "capturedFaceData.faceImage", null);
  },
  capturedFaceThumbnail: (state) => {
    return lodashGet(state, "capturedFaceData.faceThumbnail", null);
  },
  capturedFaceThumbnailNoBackground: (state) => {
    return lodashGet(state, "capturedFaceData.faceThumbnailNoBackground", null);
  },
  themePrimaryColor: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.uiTheme.primaryColor",
      "#000000"
    );
  },
  themeSpinnerColor: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.uiTheme.primaryColor",
      "#000000"
    );
  },
  themeCompleteColor: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.uiTheme.stepperCompleteColor",
      "#1C1E4D"
    );
  },
  themeUi: (state) => {
    return lodashGet(state, "sessionConfig.appFlavorConfig.uiTheme", {});
  },
  themeUiFlags: (state) => {
    return lodashGet(state, "sessionConfig.appFlavorConfig.uiFlags", {});
  },
  themeUiText: (state) => {
    return lodashGet(state, "sessionConfig.appFlavorConfig.uiText", {});
  },
  themeUiAssets: (state) => {
    return lodashGet(state, "sessionConfig.appFlavorConfig.uiAssets", {});
  },
  isUserReady: (state) => {
    return lodashGet(state, "userReadyFlag", false);
  },
  allowManualCaptureOverride: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowFlags.allowManualCaptureOverride",
      false
    );
  },
  allowPhotoUpload: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowFlags.allowPhotoUpload",
      false
    );
  },
  enrollIntoRww: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowFlags.enrollIntoRww",
      true
    );
  },
  rwwSelectedWatchlist: (state) => {
    return lodashGet(state, "userConfig.rwwSelectedWatchlist", null);
  },
  appFlavorConfig: (state) => {
    return lodashGet(state, "sessionConfig.appFlavorConfig", null);
  },
  appFlavorTag: (state) => {
    return lodashGet(state, "userConfig.appFlavorTag", "");
  },
  identityData: (state) => {
    return state.identityData;
  },
  vehicleData: (state) => {
    return state.vehicleData;
  },
  themeUiFieldsCollectedIdentityData: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.uiFields.collectedIdentityData",
      []
    );
  },
  themeUiFieldsAllowEditing: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.uiFields.allowEditing",
      true
    );
  },
  isDevMode: (state) => {
    return state.userConfig.devMode;
  },
  isEnableDeDupe1N: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowFlags.enableDeDupe1N",
      false
    );
  },
  rocSdkAlgoOptions: () => {
    return {
      ROC_NO_ALGORITHM_OPTIONS: 0,
      ROC_FACE_BALANCED_REPRESENTATION: 1,
      ROC_FACE_FAST_REPRESENTATION: 2,
      ROC_FACE_ACCURATE_REPRESENTATION: 8,
      ROC_TATTOO_REPRESENTATION: 16,
      ROC_COLOR_REPRESENTATION: 32,
      ROC_TEXT_REPRESENTATION: 64,
      ROC_FILTER_TEXT: 128,
      ROC_FACE_DETECTION: 256,
      ROC_FACE_LEGACY_DETECTION: 512,
      ROC_TATTOO_DETECTION: 1024,
      ROC_TEXT_DETECTION: 2048,
      ROC_MANUAL_DETECTION: 4096,
      ROC_ENHANCE_CONTRAST: 8192,
      ROC_IGNORE_PARTIAL: 16384,
      ROC_ANALYTICS: 65536,
      ROC_IGNORE_ARTWORK: 131072,
      ROC_LANDMARKS: 262144,
      ROC_PITCH_YAW: 524288,
      ROC_ICAO_METRICS: 1048576,
      ROC_ICAO_BACKGROUND: 4194304,
      ROC_SPOOF: 2097152,
      ROC_THUMBNAIL: 16777216,
      ROC_SERIAL: 33554432
    };
  },
  defaultVerticalCredential: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowFlags.defaultVerticalCredential",
      false
    );
  },
  isEnrollPhotoIdImages: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowFlags.enrollPhotoIdImages",
      true
    );
  },
  isEnrollIdentityDetails: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowFlags.enrollIdentityDetails",
      true
    );
  },
  defaultCaptureConfig: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowConfig.defaultCaptureConfig",
      "Verify"
    );
  },
  prevIdentityData: (state) => {
    return state.prevIdentityData;
  },
  prevIdentityFound: (state) => {
    return state.prevIdentityFound;
  },
  defaultRwwWatchlist: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowConfig.defaultEnrollWatchlist",
      ""
    );
  },
  selectedCameraIndexFace: (state) => {
    return lodashGet(state, "userConfig.selectedCameraIndexFace", 0);
  },
  selectedCameraIndexID: (state) => {
    return lodashGet(state, "userConfig.selectedCameraIndexID", 0);
  },
  selectedCameraIndexBarcode: (state) => {
    return lodashGet(state, "userConfig.selectedCameraIndexBarcode", 0);
  },
  deviceSettings: (state) => {
    return lodashGet(state, "deviceSettings", {});
  },
  webcamList: (state) => {
    return state.webcamList ?? [];
  },
  isIOS: (state) => {
    return state.platform == "iOS";
  },
  platform: (state) => {
    return state.platform;
  },
  metricsFace: (state) => {
    return state.metricsJsonFace;
  },
  capturedFaceUploadFlag: (state) => {
    return state.capturedFaceUploadFlag;
  },
  scannedCacData: (state) => {
    return state.scannedCacData;
  },
  scannedDlData: (state) => {
    return state.scannedDlData;
  },
  isIcaoFallbackEnabled: (state) => {
    return lodashGet(state, "userConfig.icaoFallback", false);
  },
  isIcaoFallbackAllowed: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowFlags.allowIcaoCaptureFallback",
      true
    );
  },
  isIdFirst: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowFlags.idFirst",
      false
    );
  },
  isEnforceFaceLiveness: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowFlags.enforceFaceLiveness",
      false
    );
  },
  capturedFaceMetrics: (state) => {
    return lodashGet(state, "capturedFaceData.faceMetrics", {});
  },
  hideFinishedButton: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowFlags.hideFinishedButton",
      false
    );
  },
  hideNewEnrollmentButton: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowFlags.hideNewEnrollmentButton",
      false
    );
  },
  hideSummary: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowFlags.hideSummary",
      false
    );
  },
  isStageTwoFaceCapture: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowFlags.stageTwoFaceCapture",
      false
    );
  },
  isIdTwoPageCapture: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowFlags.idTwoPageCapture",
      false
    );
  },
  isWorkflowDemoMode: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowFlags.demoMode",
      false
    );
  },
  capturedIdentityDocData: (state) => {
    return state.capturedIdentityDocData;
  },
  capturedIdentityDocFaceMetrics: (state) => {
    return state.capturedIdentityDocData.identityDocFaceMetrics;
  },
  capturedIdentityDocType: (state) => {
    return state.capturedIdentityDocData.identityDocType;
  },
  capturedIdentityDocMetrics: (state) => {
    return state.capturedIdentityDocData.identityDocMetrics;
  },
  capturedIdentityDocPageOne: (state) => {
    return state.capturedIdentityDocData.identityDocPageOne;
  },
  capturedIdentityDocPageTwo: (state) => {
    return state.capturedIdentityDocData.identityDocPageTwo;
  },
  capturedIdentityDocFaceThumbnail: (state) => {
    return state.capturedIdentityDocData.identityDocFaceThumbnail;
  },
  capturedIdentityDocBarcodeMrz: (state) => {
    return state.capturedIdentityDocData.identityDocBarcodeMrz;
  },
  capturedIdentityDocBarcodeMrzSkipped: (state) => {
    return state.capturedIdentityDocData.identityDocBarcodeMrzSkipped;
  },
  capturedIdentityDocImageSegments: (state) => {
    return state.capturedIdentityDocData.identityDocImageSegments;
  },
  overrideDocScenario: (state, getters) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowConfig.overrideDocScenario",
      null
    );
  },
  capturedIdentityDocPage1Timeout: (state) => {
    return state.capturedIdentityDocData.identityDocPage1Timeout;
  },
  captureMetrics: (state) => {
    return state.captureMetrics;
  },
  captureStartTime: (state) => {
    return state.startTime;
  },
  isDocumentProcessingDisabled: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowFlags.disableDocumentProcessing",
      false
    );
  },
  initialCaptureDelaySeconds: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowConfig.initialCaptureDelaySeconds",
      3
    );
  },
  themeBackgroundColor: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.uiTheme.primaryBackgroundColor",
      "#D9D8D6"
    );
  },
  themeBackgroundColorHeader: (state, getters) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.uiTheme.headerBackgroundColor",
      getters.themeBackgroundColor
    );
  },
  themePrimaryFontColor: (state) => {
    return lodashGet(
      state.sessionConfig.appFlavorConfig,
      "uiTheme.primaryFontColor",
      "#000000"
    );
  },
  themeFontFamily: (state, getters) => {
    try {
      return (
        getters.appFlavorConfig.uiTheme.fontFamily ??
        getComputedStyle(document.documentElement).getPropertyValue(
          "--font-family"
        )
      );
    } catch {
      // default if not configured by flavor
      return "sans-serif";
    }
  },
  themeButtonFontColor: (state) => {
    return lodashGet(
      state.sessionConfig.appFlavorConfig,
      "uiTheme.buttonColorText",
      "#D9D8D6"
    );
  },
  themeButtonBackgroundColor: (state) => {
    return lodashGet(
      state.sessionConfig.appFlavorConfig,
      "uiTheme.buttonColorBackground",
      "#1C1E4D"
    );
  },
  themeFontFamilyHeader: (state) => {
    return lodashGet(
      state.sessionConfig.appFlavorConfig,
      "uiTheme.fontFamilyHeader",
      getComputedStyle(document.documentElement).getPropertyValue(
        "--font-family"
      )
    );
  },
  isEntitlement: (state) => {
    return state.isEntitlement;
  },
  entitlementData: (state) => {
    return state.entitlementData;
  },
  captureSplashFace: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowConfig.captureSplashFace",
      { enabled: false }
    );
  },
  captureSplashID: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowConfig.captureSplashID",
      { enabled: false }
    );
  },
  enrollConfig: (state) => {
    return state.enrollConfig;
  },
  enrollDefaultFlavor: (state) => {
    const allowDefault = lodashGet(
      state,
      "enrollConfig.flavor.allowDefault",
      false
    );
    if (allowDefault) {
      return lodashGet(state, "enrollConfig.flavor.default", null);
    }
  },
  isEventWorkflowEnabled: (state) => {
    if (!state.enrollConfig) {
      return true;
    }
    return state.eventWorkflowEnabled;
  },
  isSkipToCapture: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowFlags.skipToCapture",
      false
    );
  },
  isHideCameraButtons: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowFlags.hideCameraButtons",
      false
    );
  },
  isHideStepper: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowFlags.hideStepper",
      false
    );
  },
  isHideHeader: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowFlags.hideHeader",
      false
    );
  },
  isHideDocumentProgress: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowFlags.hideDocumentProgress",
      false
    );
  },
  isHideIdentityDetails: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowFlags.hideIdentityDetails",
      false
    );
  },
  isStageTwoIdCapture: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowFlags.stageTwoIdCapture",
      false
    );
  },
  allowRetry: (state, getters) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowFlags.allowRetry",
      false
    );
  },
  isUsePortal: (state, getters) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.portal.enabled",
      false
    );
  },
  customerId: (state, getters) => {
    return state.customerId;
  },
  isWatchlistSelect: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowFlags.showWatchlistSelect",
      false
    );
  },
  isEventSelect: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowFlags.showEventSelect",
      false
    );
  },
  portalConfig: (state) => {
    return lodashGet(state, "sessionConfig.appFlavorConfig.portal", {});
  },
  rwwEnrollResult: (state) => {
    return state.rwwEnrollResult;
  },
  isFingerprintMode: (state) => {
    return state.sessionConfig.fingerprintMode;
  },
  capturedFingerprintData: (state) => {
    return state.capturedFingerprintData;
  },
  fingerprintCaptureQualityThreshold: (state) => {
    return lodashGet(state, "enrollConfig.fingerprint.thresholds.quality", 1.5);
  },
  fingerprintCaptureMaxDistanceFromCenter: (state) => {
    return lodashGet(
      state,
      "enrollConfig.fingerprint.thresholds.distanceFromCenter",
      0.5
    );
  },
  isEnableLiveStatus: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowFlags.enableLiveStatus",
      false
    );
  },
  regionIdCredential: (state) => {
    // return override regionId if set, or flavor config regionId if set, or empty
    if (state.regionIdCredential) {
      return state.regionIdCredential;
    }
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowConfig.regionIdCredential",
      ""
    );
  },
  regionIdBarcode: (state) => {
    // return override regionId if set, or flavor config regionId if set, or empty
    if (state.regionIdBarcode) {
      return state.regionIdBarcode;
    }
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowConfig.regionIdBarcode",
      ""
    );
  },
  flavorIcaoBackgroundEnabled: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowConfig.enableIcaoBackground",
      false
    );
  },
  flavorIcaoBackgroundRemovalEnabled: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowConfig.enableIcaoBackgroundRemoval",
      false
    );
  },
  isCitizen: (state) => {
    return state.isCitizen;
  },
  documentTypeRegex: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowConfig.documentTypeRegex",
      ""
    );
  },
  isIdUseRegulaCapture: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowFlags.idUseRegulaCapture",
      false
    );
  },
  regulaCaptureRecognizer: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.regulaCapture.recognizer",
      defaultRegulaConfig.recognizer
    );
  },
  regulaCaptureImageProcessor: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.regulaCapture.imageProcessor",
      defaultRegulaConfig.imageProcessor
    );
  },
  regulaCaptureMrzBarcode: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.regulaCapture.enableMrzOrBarcodeParsing",
      false
    );
  },
  regulaCaptureServerProcessingEnabled: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.regulaCapture.serverProcessing.enabled",
      false
    );
  },
  regulaCaptureServerProcessingRetries: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.regulaCapture.serverProcessing.maxRetries",
      1
    );
  },
  regulaCaptureServerProcessingRetryMsg: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.regulaCapture.serverProcessing.msgRetry",
      "Failed to validate document, try again."
    );
  },
  isMpgsEnroll: (state) => {
    return lodashGet(state, "sessionConfig.appFlavorConfig.mpgs.enroll", false);
  },
  isMpgsPay: (state) => {
    return lodashGet(state, "sessionConfig.appFlavorConfig.mpgs.pay", false);
  },
  isMpgsAuthenticate: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.mpgs.authenticate",
      false
    );
  },
  isMpgsVerify: (state) => {
    return lodashGet(state, "sessionConfig.appFlavorConfig.mpgs.verify", false);
  },
  mode: (state) => {
    const flavorMode = lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowConfig.mode",
      null
    );
    if (!flavorMode) {
      const legacyEnrollOnlyMode = lodashGet(
        state,
        "sessionConfig.appFlavorConfig.workflowFlags.enrollOnlyMode",
        false
      );
      const legacyIdProofOnlyMode = lodashGet(
        state,
        "sessionConfig.appFlavorConfig.workflowFlags.idProofMode",
        false
      );
      if (legacyEnrollOnlyMode) {
        return MODE_ENROLL_FACE;
      } else if (legacyIdProofOnlyMode) {
        return MODE_ENROLL_ID_PROOF;
      } else {
        return MODE_DEFAULT;
      }
    } else {
      return flavorMode;
    }
  },
  isEnrollOnlyMode: (state, getters) => {
    return getters.mode === MODE_ENROLL_FACE;
  },
  isIdProofMode: (state, getters) => {
    return getters.mode === MODE_ENROLL_ID_PROOF;
  },
  verifyOverride: (state, getters) => {
    return state.verifyOverride || getters.mode === MODE_VERIFY;
  },
  isIdentifyMode: (state, getters) => {
    return getters.mode === MODE_IDENTIFY;
  },
  paymentAmount: (state) => {
    return state.paymentAmount;
  },
  customerPin: (state) => {
    return state.customerPin;
  },
  customerPinConfig: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowConfig.customerPin",
      {}
    );
  },
  autoFinishTimeout: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowConfig.autoFinishTimeout",
      0
    );
  },
  privacyPolicyUrl: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowConfig.privacyPolicy.url",
      "https://roc.ai/privacy-policy-online-services/"
    );
  },
  privacyPolicyAcceptMsg: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowConfig.privacyPolicy.acceptMsg",
      "By starting the enrollment process, you have reviewed and accept the Privacy Policy and acknowledge the use of information and data."
    );
  },
  privacyPolicyLinkMsg: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowConfig.privacyPolicy.linkMsg",
      "You must accept the privacy policy to continue"
    );
  },
  fingerprintBioMiniUrl: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowConfig.fingerprint.bioMiniUrl",
      null
    );
  },
  fingerprintBioMiniRotateCaptureDegrees: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowConfig.fingerprint.bioMiniRotateCaptureDegrees",
      0
    );
  },
  returnUrl: (state) => {
    return state.returnUrl;
  },
  externalIdentityCheckSource: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowConfig.externalIdentityCheckSource",
      null
    );
  },
  barcodeCustomerId: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowConfig.barcodeCustomerId",
      { enabled: false }
    );
  },
  flavorLogoBase64: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.uiAssets.logoMain",
      null
    );
  },
  addressAutoComplete: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowConfig.addressAutoComplete",
      { enabled: false }
    );
  },
  themeUiFieldsRequiredIdentityData: (state, getters) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.uiFields.requiredIdentityData",
      []
    );
  },
  requiredExternalChecks: (state) => {
    return lodashGet(
      state,
      "sessionConfig.appFlavorConfig.workflowConfig.requiredExternalChecks",
      null
    );
  }
};
