<template>
  <Popper
    v-bind="$attrs"
    :openDelay="openDelay"
    :closeDelay="closeDelay"
    :zIndex="zIndex"
  >
    <slot />
    <template #content="props">
      <slot name="content" v-bind="props" />
    </template>
  </Popper>
</template>

<script>
import { defineComponent } from "vue";
import Popper from "vue3-popper";

export default defineComponent({
  name: "MyPopperWrapper",
  props: {
    openDelay: {
      type: String,
      default: "0"
    },
    closeDelay: {
      type: String,
      default: "0"
    },
    closeOnClick: {
      type: Boolean,
      default: false
    },
    zIndex: {
      type: Number,
      default: 99
    }
  },
  components: {
    Popper
  }
});
</script>
