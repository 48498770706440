<template>
  <!-- TODO: this component still has work to be done, but it is a good start that works if you know how to use it.
        *Mobile support to make it act more like a <select>
        *Clarify what the emit value should be. Whether we should return the whole object or allow the user to specify a key to emit.
        *Then updating all the instances of this component to work with the updates
    `-->
  <div
    :class="[disabled ? 'disabled' : 'custom-select']"
    @click="toggleDropdown"
    ref="selectRef"
  >
    <!-- This is the display -->
    <div class="selected-option" :class="{ placeholder: !selectedOption }">
      {{ selectedOption || placeholder }}
    </div>

    <!-- This is the data handling/dropdown -->
    <div class="dropdown">
      <!-- loop for if just an array -->
      <div
        v-if="isDropdownVisible && !isArrayOfObjects"
        v-for="(option, index) in availableOptions"
        :key="index"
        :value="option"
        :id="option"
        class="dropdown-option"
        @click.stop="selectOption(option)"
      >
        {{ option }}
      </div>

      <!-- loop for an array of objects -->
      <div
        v-if="isDropdownVisible && isArrayOfObjects"
        v-for="(option, index) in availableOptions"
        :key="index"
        :id="option[optionLabel]"
        :value="option"
        class="dropdown-option"
        @click.stop="selectOption(option)"
      >
        {{ option[optionLabel] }}
      </div>
    </div>

    <div class="icon-container">
      <RocIcon
        icon="downArrow"
        :size="'sm'"
        :color="'black'"
        style="transition: transform 0.1s ease;"
        :style="{
          transform: isDropdownVisible ? 'rotate(180deg)' : 'rotate(0deg)'
        }"
      />
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed } from "vue";
import RocIcon from "@/components/ui/RocIcon.vue";

export default {
  name: "RocSelect",
  emits: ["selection-changed"],
  components: {
    RocIcon
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    availableOptions: {
      type: Array,
      required: false,
      default: []
    },
    currentlySelected: {
      type: String,
      required: false,
      default: ""
    },
    optionLabel: {
      type: String,
      required: false,
      default: "label",
      note:
        "If availableOptions is an array of objects, this is the key to display in the dropdown. If not provided, the object itself will be displayed."
    },
    optionValue: {
      type: String,
      required: false,
      default: "",
      note:
        "This will determine what value is emitted when an option is selected. If not provided, the full object will be emitted."
    },
    placeholder: {
      type: String,
      required: false,
      default: ""
    }
  },
  setup(props, context) {
    //add listener for if select box is open or closed
    onMounted(() => {
      window.addEventListener("click", handleSelectContainerClick);
      //get the selected option, and set it to the display value that matches the label key for available options
      if (props.currentlySelected) {
        //ex: props.currentlySelected = '5462382xfwsef', props.availableOptions = [{_id: '5462382xfwsef', name: 'John Doe'}]
        //in that scenario we would want the name to be displayed in the select box, but not the id (making <select> with <div> is fun!)
        const selected = props.availableOptions.find(
          (option) => option[props.optionValue] === props.currentlySelected
        );
        //for array of objects
        if (selected) {
          selectedOption.value = selected[props.optionLabel]
            ? selected[props.optionLabel]
            : selected;
        }
        //for array of strings
        else {
          selectedOption.value = props.currentlySelected;
        }
      }
    });

    //add a watch for props.currentlySelected to update the selected option if it changes
    // Sean 09-16-24: Watching available options here in case availableOptions gets loaded in slowly.
    watch(
      [() => props.currentlySelected, () => props.availableOptions],
      ([newVal, _]) => {
        if (newVal) {
          const selected = props.availableOptions.find(
            (option) => option[props.optionValue] === newVal
          );
          if (selected) {
            selectedOption.value = selected[props.optionLabel]
              ? selected[props.optionLabel]
              : selected;
          } else {
            selectedOption.value = newVal;
          }
        } else {
          selectedOption.value = "";
        }
      }
    );

    const selectRef = ref(null);
    const isDropdownVisible = ref(false);
    const handleSelectContainerClick = (event) => {
      if (selectRef.value && !selectRef.value.contains(event.target)) {
        isDropdownVisible.value = false;
      }
    };

    function toggleDropdown() {
      isDropdownVisible.value = !isDropdownVisible.value;
    }

    const selectedOption = ref("");

    function selectOption(option) {
      //set display value to the display name of the selected option
      selectedOption.value = option[props.optionLabel]
        ? option[props.optionLabel]
        : option;
      //if optionValue is not provided, just emit the full option selected
      let emitValue = option[props.optionValue]
        ? option[props.optionValue]
        : option;
      isDropdownVisible.value = false;
      //emit the full object to the parent component
      context.emit("selection-changed", emitValue);
    }

    const isArrayOfObjects = computed(() => {
      if (Array.isArray(props.availableOptions)) {
        return typeof props.availableOptions[0] === "object";
      }
      return false;
    });

    return {
      selectedOption,
      isDropdownVisible,
      toggleDropdown,
      selectOption,
      selectRef,
      isArrayOfObjects
    };
  }
};
</script>

<style scoped lang="scss">
.custom-select {
  @include overwatch-body-small;
  position: relative;
  cursor: pointer;
  width: 100%;
  padding: var(--spacing-s);
  border: 1px solid var(--overwatch-neutral-300);
  border-radius: 5px;
  background-color: var(--overwatch-neutral-500);
  display: flex;
}

.disabled {
  @include overwatch-body-small;
  position: relative;
  cursor: pointer;
  pointer-events: none;
  width: 100%;
  padding: var(--spacing-s);
  border: 1px solid var(--overwatch-neutral-300);
  border-radius: 5px;
  color: var(--overwatch-neutral-300);
  background-color: var(--overwatch-neutral-400);
  display: flex;
}

.selected-option {
  pointer-events: none; /* Prevent clicking on this element */
}

.icon-container {
  margin-left: auto;
}

.dropdown {
  position: absolute;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
  margin-top: 1px;
  top: 100%;
  left: 0;
  width: 100%;
  border-radius: 5px;
  background-color: var(--overwatch-secondary);
  z-index: 50;
}

.dropdown-option {
  padding: var(--spacing-s);
}

.dropdown-option:focus {
  border: var(--overwatch-focus);
}

.dropdown-option:hover {
  background-color: var(--overwatch-button-primary-20);
}

.dropdown-option:first-child {
  border-radius: 5px 5px 0 0;
}

.dropdown-option:last-child {
  border-radius: 0 0 5px 5px;
}

.placeholder {
  color: var(--overwatch-neutral-300);
}
</style>
