<template>
  <div class="selector justify-content-center">
    <div class="pageSelector align-self-center">
      <RocIcon
        size="sm"
        icon="downArrow"
        color="black"
        :rotate90="true"
        style=" margin-top: var(--spacing-s); margin-right: var(--spacing-l); cursor:pointer;"
        @click="switchPage(currPage - 1)"
      />
      <div class="pages">
        <div v-if="prePages?.length > 0" class="prePages">
          <span
            v-for="page in prePages"
            :key="page"
            @clicked="switchPage(page)"
            style="cursor: pointer;"
          >
            <div v-on:click="switchPage(page)" style="padding: 8px 16px;">
              {{ page }}
            </div>
          </span>
        </div>
        <div class="selectedPage">
          {{ currPage }}
        </div>
        <div v-if="postPages?.length > 0" class="pages">
          <span v-for="page in postPages" :key="page" style="cursor: pointer;">
            <div v-on:click="switchPage(page)" style="padding: 8px 16px;">
              {{ page }}
            </div>
          </span>
        </div>
      </div>
      <RocIcon
        size="sm"
        icon="downArrow"
        color="black"
        style="transform: rotate(270deg); margin-top: var(--spacing-s); margin-left: var(--spacing-l); cursor:pointer;"
        @click="switchPage(currPage + 1)"
      />
    </div>
    <div class="pageBox align-self-center" style="margin-left: -5x;">
      <p
        style="margin-top: 10px; padding-right: var(--spacing-s); color: var(--overwatch-neutral-300)"
      >
        Go to page
      </p>
      <div class="d-flex align-self-center">
        <RocNumberInput
          :min="1"
          :max="lastPage"
          placeholder="Page"
          @number-input-change="jumpToPage = $event"
        />
        <RocButton
          @click="newPage"
          type="primary"
          size="tiny"
          style="margin-left: var(--spacing-base);"
          :disabled="jumpToPage == 0 || jumpToPage == currPage"
          >Go
        </RocButton>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, watch } from "vue";
import RocIcon from "@/components/ui/RocIcon.vue";
import RocNumberInput from "@/components/ui/RocNumberInput.vue";
import RocButton from "@/components/ui/RocButton.vue";

export default {
  name: "RocPageSelector",
  emits: ["goToPage"],
  components: {
    RocIcon,
    RocNumberInput,
    RocButton
  },
  props: {
    currPage: {
      type: Number,
      default: 1
    },
    lastPage: {
      type: Number,
      required: true
    }
  },
  setup(props, context) {
    const currPage = ref(props.currPage);
    const lastPage = ref(props.lastPage);
    const jumpToPage = ref(0);

    let prePages = ref([]);
    let postPages = ref([]);

    prePostPages();

    function prePostPages() {
      postPages.value.splice(0);
      prePages.value.splice(0);
      let modifier = lastPage.value;

      if (currPage.value - 1 > 0) {
        let index = currPage.value - 1;

        while (
          index != 0 &&
          (currPage.value - 3 != index || lastPage.value <= 5)
        ) {
          prePages.value.unshift(index);
          --index;
        }
        if (!prePages.value.includes(1)) prePages.value.unshift(1);
      }

      if (currPage.value != lastPage.value) {
        let index = currPage.value + 1;
        while (lastPage.value >= index) {
          if (modifier % index == 0 && postPages.value.length < 3)
            postPages.value.push(index);
          else if (lastPage.value <= 5) postPages.value.push(index);
          index++;
        }
        if (!postPages.value.includes(lastPage.value))
          postPages.value.push(lastPage.value);
      }
    }

    function newPage() {
      context.emit("goToPage", jumpToPage.value);
    }

    function switchPage(newPage) {
      if (newPage > 0 && newPage <= lastPage.value) {
        currPage.value = newPage;
        context.emit("goToPage", currPage.value);
      }
    }

    watch(
      () => props.currPage,
      (nv) => {
        currPage.value = nv;
        prePostPages();
      }
    );

    watch(
      () => props.lastPage,
      (nv) => {
        lastPage.value = nv;
        prePostPages();
      }
    );

    return {
      currPage,
      jumpToPage,
      switchPage,
      newPage,
      prePages,
      postPages
    };
  }
};
</script>

<style scoped lang="scss">
.pages {
  display: flex;
  gap: var(--spacing-base);
  align-items: center;
}

.selectedPage {
  background-color: var(--overwatch-primary);
  padding: 8px 16px;
  border-radius: 5px;
  color: var(--overwatch-button-text);
}

.pageSelector {
  display: flex;
  padding-left: 16px;
  padding-bottom: var(--spacing-base);
}

.pageBox {
  display: flex;
  padding-left: var(--spacing-l);
}

.selector {
  display: flex;
  text-align: center;
  flex-direction: row;
  margin-top: var(--spacing-xl);
  margin-bottom: var(--spacing-xl);
  width: 100%;
}

.iconDiv {
  gap: var(--spacing-m);
  padding-bottom: var(--spacing-base);
}

.prePages {
  display: contents;
}

@media (max-width: 480px) {
  .selector {
    display: grid;
    max-width: 450px;
  }
}
</style>
