<script setup>
import { computed } from "vue";
import RocSingleSelect from "@/components/ui/RocSingleSelect";

const props = defineProps({
  currentlySelected: {
    type: String,
    required: false,
    default: Intl.DateTimeFormat().resolvedOptions().timeZone
  }
});
const emit = defineEmits(["selection-changed"]);

const availableTimezones = computed(() => {
  const timezones = Intl.supportedValuesOf("timeZone");

  const timezoneToOffset = {};
  timezones.forEach((tz) => {
    const offset = getOffset(tz);
    const offsetValue = parseInt(offset.replace("GMT", "")) || 0;
    timezoneToOffset[tz] = offsetValue;
  });

  timezones.sort((a, b) => {
    return timezoneToOffset[a] - timezoneToOffset[b];
  });

  const output = [];

  timezones.forEach((tz) => {
    output.push({
      name: `(${getOffset(tz)}) - ${tz}`,
      value: tz
    });
  });

  return output;
});

function getOffset(timezone) {
  return new Intl.DateTimeFormat("en", {
    timeZone: timezone,
    timeZoneName: "shortOffset"
  })
    .formatToParts()
    .find((part) => part.type === "timeZoneName").value;
}

function emitSelectedTimezone(v) {
  emit("selection-changed", v.value);
}
</script>

<template>
  <RocSingleSelect
    class="select"
    :availableItems="availableTimezones"
    :currentlySelected="currentlySelected"
    @selection-changed="emitSelectedTimezone"
    valueProp="value"
    :enableClear="false"
  />
</template>

<style scoped lang="scss">
:deep(li.multiselect-option.is-selected) {
  pointer-events: none;
}
</style>
