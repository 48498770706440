<template>
  <div class="disable-select">
    <nav class="nav">
      <router-link class="header-options-dd" to="/">
        <RocLogo style="height: 50px; width: 90px;" />
      </router-link>
      <Teleport to="body" :disabled="!isMobile">
        <Transition name="fade">
          <NavbarItems
            v-if="!isMobile || isMobileNavShowing"
            @close="isMobileNavShowing = false"
          />
        </Transition>
      </Teleport>
      <div class="bento-user">
        <BaseDropdown v-model="isActiveMissionShowing" show align="end" v-if="!isMissionDisabled">
          <MDBDropdownToggle
            @click="isActiveMissionShowing = !isActiveMissionShowing"
            tag="a"
          >
            <div class="active-mission-dropdown">
              <ActiveBubble v-model="activeMission" />
              <RocIcon icon="mission" color="black" size="sm" />
            </div>
          </MDBDropdownToggle>
          <RocDropdownMenu>
            <MDBDropdownItem>
              <div class="active-mission-dropdown-item">
                <div class="title">
                  ACTIVE {{ $store.state.settings.appTerms.Mission }}
                </div>
                <div class="mission">
                  <span v-if="activeMission">
                    {{ activeMission.name }}
                  </span>
                  <span v-else>
                    No Active {{ $store.state.settings.appTerms.Mission }}
                  </span>
                  <RocIcon
                    icon="edit"
                    color="primary"
                    size="sm"
                    style="cursor: pointer;"
                    @click="goToMissions"
                  />
                </div>
              </div>
            </MDBDropdownItem>
          </RocDropdownMenu>
        </BaseDropdown>
        <BaseDropdown v-model="isToolsShowing" show align="end">
          <MDBDropdownToggle
            @click="isToolsShowing = !isToolsShowing"
            class="dropdownToggle"
            tag="a"
          >
            <RocIcon icon="bentoBox" color="black" size="md" />
          </MDBDropdownToggle>
          <RocDropdownMenu
            aria-labelledby="dropdownMenuButton"
            class="dropdownMenu"
            @click="isToolsShowing = false"
          >
            <MDBDropdownItem
              v-if="isPageAllowed('/workflows')"
              router
              :to="{ path: '/workflows' }"
            >
              Workflows
            </MDBDropdownItem>
            <MDBDropdownItem
              v-if="isPageAllowed('/rocenroll/home') && isEnrollButtonEnabled"
              router
              :to="{
                path: '/rocenroll/home',
                query: { flavor: getRocEnrollFlavor() }
              }"
            >
              Enroll
            </MDBDropdownItem>
            <MDBDropdownItem
              v-if="isPageAllowed('/examine') && isExamineButtonEnabled"
              router
              :to="{ path: '/examine' }"
            >
              Examine
            </MDBDropdownItem>
          </RocDropdownMenu>
        </BaseDropdown>
        <BaseDropdown v-model="isUserSettingsShowing" show align="end">
          <MDBDropdownToggle
            class="nameCircle"
            tag="button"
            @click="isUserSettingsShowing = !isUserSettingsShowing"
            >{{ userInitials }}</MDBDropdownToggle
          >
          <RocDropdownMenu
            aria-labelledby="dropdownMenuButton"
            class="dropdownMenu"
            @click="isUserSettingsShowing = false"
          >
            <span v-for="(item, index) in userMenu" :key="item">
              <MDBDropdownItem
                router
                :to="item.routerPath"
                @click="item.clickHandler"
                style="cursor: pointer;"
              >
                {{ item.label }}
              </MDBDropdownItem>
              <MDBDropdownItem divider v-if="index < userMenu.length - 1" />
            </span>
          </RocDropdownMenu>
        </BaseDropdown>
        <router-link class="header-options-dd" to="/License">
          <RocIcon color="black" icon="help" :size="isMobile ? 'sm' : 'md'" />
        </router-link>
        <RocIcon
          v-if="isMobile"
          icon="menu"
          color="black"
          size="md"
          style="cursor: pointer;"
          @click="isMobileNavShowing = !isMobileNavShowing"
        />
      </div>
    </nav>
    <base-dialog
      :show="showRetention"
      title="Retention Settings"
      @close="showRetention = false"
      :style="retentionStyle"
    >
      <retention-settings @close="showRetention = false"></retention-settings>
    </base-dialog>
    <base-dialog
      :show="showServerConfig"
      title="Server Settings"
      @close="showServerConfig = false"
      :style="serverConfigStyle"
    >
      <ServerConfig @close="showServerConfig = false" />
    </base-dialog>
    <base-dialog
      :show="showUISettings"
      title="UI Settings"
      @close="showUISettings = false"
      :style="uiSettingsStyle"
    >
      <UISettings 
        :isAdmin="isAdmin"
        @close="showUISettings = false" 
      />
    </base-dialog>
    <base-dialog
      :show="showUser"
      title="Change Password"
      @close="showUser = false"
      :style="passwordChangeStyle"
    >
      <PasswordChange @close="showUser = false" />
    </base-dialog>
  </div>
</template>

<script setup>
import retentionSettings from "@/components/settings/retentionSettings";
import PasswordChange from "@/components/settings/PasswordChange";
import ServerConfig from "@/components/settings/ServerConfig.vue";
import UISettings from "@/components/settings/UISettings.vue";
import BaseDropdown from "@/components/ui/BaseDropdown.vue";
import RocDropdownMenu from "@/components/ui/RocDropdownMenu.vue";
import NavbarItems from "@/components/layout/NavbarItems.vue";

import { MDBDropdownItem, MDBDropdownToggle } from "mdb-vue-ui-kit";
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Capacitor } from "@capacitor/core";
import RocLogo from "../ui/RocLogo.vue";
import RocIcon from "../ui/RocIcon.vue";
import ActiveBubble from "@/components/ui/ActiveBubble";

import userAccessHelper from "@/js/userAccessHelper";

const store = useStore();
const router = useRouter();
const isUserSettingsShowing = ref(false);
const showRetention = ref(false);
const showUser = ref(false);
const showServerConfig = ref(false);
const showUISettings = ref(false);

const isEnrollButtonEnabled = computed(() => {
  return (
    store.getters["settings/isEnrollButtonEnabled"] || isMobilePlatform.value
  );
});
const isExamineButtonEnabled = computed(() => {
  return store.getters["settings/isExamineButtonEnabled"];
});

const userInitials = computed(function() {
  return store.getters["auth/userInitials"];
});

/* Dialog Styles */
const windowWidth = ref(window.innerWidth);
onMounted(() => {
  window.addEventListener("resize", () => {
    windowWidth.value = window.innerWidth;
  });
});

const isMobile = computed(() => {
  return windowWidth.value <= 480;
});

const isMobilePlatform = computed(() => {
  return Capacitor ? Capacitor.getPlatform() != "web" : false;
});

const retentionStyle = computed(() => {
  if (windowWidth.value <= 480) {
    // Mobile style
    return {
      width: "90%"
    };
  } else {
    return {
      width: "450px"
    };
  }
});

const uiSettingsStyle = computed(() => {
  if (windowWidth.value <= 480) {
    return {
      width: "100%",
      "max-height": "100%"
    };
  } else {
    return {
      width: "500px"
    };
  }
});

const serverConfigStyle = computed(() => {
  if (windowWidth.value <= 480) {
    return {
      width: "100%",
      "max-height": "100%"
    };
  } else {
    return {
      width: "900px"
    };
  }
});

const passwordChangeStyle = computed(() => {
  if (windowWidth.value <= 480) {
    // Mobile style
    return {
      width: "90%"
    };
  } else {
    return {
      width: "700px"
    };
  }
});

function getRocEnrollFlavor() {
  return store.getters["rocenroll/enrollDefaultFlavor"] ?? "rww";
}

function isPageAllowed(page) {
  return new userAccessHelper().isPageAllowed(page);
}

const userMenu = computed(() => {
  const userMenuItems = [];
  if (isAdmin.value) {
    userMenuItems.push({
      routerPath: "",
      label: "Retention Settings",
      clickHandler: () => {
        showRetention.value = !showRetention.value;
      }
    });
    userMenuItems.push({
      routerPath: "/settings/users",
      label: "User Management",
      clickHandler: () => {}
    });
    userMenuItems.push({
      routerPath: "",
      label: "Server Settings",
      clickHandler: () => {
        showServerConfig.value = !showServerConfig.value;
      }
    });
    userMenuItems.push({
      routerPath: "/auditlog",
      label: "Audit Log",
      clickHandler: () => {}
    });
  }
  userMenuItems.push({
      routerPath: "",
      label: "UI Settings",
      clickHandler: () => {
        showUISettings.value = !showUISettings.value;
      }
  });
  userMenuItems.push({
    routerPath: "",
    label: "Change Password",
    clickHandler: () => {
      showUser.value = !showUser.value;
    }
  });
  userMenuItems.push({
    routerPath: "/logout",
    label: "Logout",
    clickHandler: () => {}
  });

  return userMenuItems;
});

const isAdmin = computed(() => {
  try {
    const userAccessCookie = new userAccessHelper().parseUserAccessCookie();
    return userAccessCookie && userAccessCookie.role === "admin";
  } catch (err) {
    return false;
  }
});

const isToolsShowing = ref(false);

const activeMission = computed(() => {
  return store.getters["cases/activeMission"];
});
const isActiveMissionShowing = ref(false);
function goToMissions() {
  isActiveMissionShowing.value = false;
  router.push({ path: "/missions" });
}

const isMobileNavShowing = ref(false);

const isMissionDisabled = computed(() => {
  return store.getters["settings/isMissionsDisabled"]
});
</script>

<style scoped lang="scss">
.nav {
  display: flex;
  align-items: center;

  height: 60px;
  padding-left: var(--spacing-s);
  padding-right: var(--spacing-s);
  box-shadow: var(--overwatch-header-box-shadow);
  background: var(--overwatch-secondary);
}

.actionItemDropdown {
  border: none !important;
}

.dropdownMenu {
  box-shadow: 4px 4px 15px 4px rgba(0, 0, 0, 0.3);
}

.dropdownMenu li {
  cursor: pointer;
}

.dropdown-toggle:after {
  display: none;
}
.header-options-dd {
  @include overwatch-title-small;
  color: #000000;
  display: flex !important;
  align-items: center;
}
.disable-select {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
.nameCircle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  font-size: 14px;
  color: var(--overwatch-button-text);
  background: var(--overwatch-primary);

  padding-left: 0;
  padding-right: 0;
  box-shadow: none;
}
.dropdownToggle {
  color: var(--overwatch-neutral-100);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  display: flex;
  align-items: center;
  gap: 6px;
}
.actionItem {
  @include overwatch-body-med;

  display: flex;
  align-items: center;

  height: 100%;
  margin: 0;
  list-style-type: none;
  cursor: pointer;
}

.actionItem a {
  color: var(--overwatch-neutral-100);
  user-select: none;
}

.toggleArrow {
  transition: transform 0.3s;
}

a {
  font-weight: unset;
}

.bento-user {
  display: flex;
  align-items: center;
  gap: var(--spacing-m);
  margin-left: auto;
}

.disabled {
  opacity: 0.5;
}

.active-mission-dropdown {
  display: flex;
  align-items: center;
  padding: var(--spacing-s);
  gap: var(--spacing-s);
  border: 1px solid var(--overwatch-neutral-400);
  border-radius: 5px;
  cursor: pointer;
  background-color: var(--overwatch-neutral-500);
}

.active-mission-dropdown-item {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-m);
  padding: var(--spacing-m);
  width: 250px;

  .title {
    @include overwatch-title-xsmall;
    color: var(--overwatch-neutral-200);
  }
  .mission {
    @include overwatch-body-med;
    color: var(--overwatch-neutral-100);
    display: flex;
    align-items: center;
    gap: var(--spacing-s);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* MOBILE */
@media (max-width: 480px) {
  .actionItem {
    display: none;
  }
  .actionItem.mobile {
    display: flex;
  }

  .bento-user {
    margin-left: var(--spacing-s);
    flex: 1;
  }

  .bento-user > div:first-child {
    margin-right: auto;
  }
}
</style>
