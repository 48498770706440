<script setup>
import { ref, watch } from 'vue';
import { useStore } from "vuex";
import RocButton from '@/components/ui/RocButton.vue';
import RocInputEmail from '@/components/ui/RocInputEmail.vue';

//Define emits
const emit = defineEmits(['cancel']);
const submitClicked = ref(false);
const email = ref('');
const isLoading = ref(false);
const store = useStore();
const submitMessage = ref('')
async function handleSubmit (){
    if(!email.value){
        return;
    }
    isLoading.value = true;
    await store.dispatch('auth/forgotPasswordRequest', { email: email.value });
    submitMessage.value = 'If you have an account, an email has been sent to the email address provided. Please check your email to reset your password.';
    isLoading.value = false;
    submitClicked.value = true;
}

function cancel(){
    emit('cancel');
}
</script>

<template>
    <div class="forgot-password-form">
        <div style="width: 100%" v-if="!submitClicked">
            <div>Email</div>
            <RocInputEmail
            v-model="email"
            placeholder="Enter your email address"
            type="email" />
        </div>
        <div style="display: flex; flex-wrap:wrap; gap: var(--spacing-s); margin-left: auto; width: 100%; justify-content: center; align-items: center;" v-if="!isLoading && !submitClicked">
            <RocButton @click="handleSubmit" type="primary" style="width: 100%">Send link</RocButton>
            <RocButton @click="cancel" type="ghost" style="width: 100%">Back to login</RocButton>
        </div>
        <div v-else-if="isLoading">
            <roc-spinner/>
        </div>
        <div v-if="submitClicked && !isLoading" class="d-flex flex-column justify-content-center">
            <div class="submit-message">
                {{ submitMessage }}
            </div>
            <RocButton @click="cancel" type="primary">Close</RocButton>
        </div>
    </div>
</template>

<style scoped lang="scss">
.forgot-password-form{
    gap: var(--spacing-s);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 300px;
}

.submit-message{
    margin-bottom: var(--spacing-m);
}
</style>
