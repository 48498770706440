<template>
  <div class="case-card">
    <div class="name-column">
      <div v-if="isImportedCase" class="import-tag">
        Imported
      </div>
      <RocPopper
        arrow
        hover
        popperType="tooltip"
        placement="top"
        v-if="isImportedCase"
      >
        <RocIcon icon="tooltip" color="primary" size="sm" />
        <template #content>
          {{ propCase.description }}
        </template>
      </RocPopper>
      <div v-else>
        {{ name }}
      </div>
    </div>
    <div>
      {{ caseCameras.length }}
      <MDBDropdown v-model="dropdownOptions" style="margin-left: auto;">
        <RocDropdownToggle @click="dropdownOptions = !dropdownOptions">
          <RocIcon color="black" size="sm" icon="kebab" />
        </RocDropdownToggle>
        <RocDropdownMenu @click="dropdownOptions = false">
          <RocDropdownItem @click.prevent="isShowingInfo = true">
            <div>
              View Details
            </div>
          </RocDropdownItem>
          <RocDropdownItem
            @click.prevent="emitEdit()"
            v-if="isAdminOrPowerUser"
          >
            <div>
              Edit
            </div>
          </RocDropdownItem>
          <RocDropdownItem
            @click.prevent="isShowingDelete = true"
            v-if="isAdminOrPowerUser"
          >
            <div>
              Delete
            </div>
          </RocDropdownItem>
        </RocDropdownMenu>
      </MDBDropdown>
    </div>
    <base-dialog
      v-if="isShowingDelete"
      :show="true"
      title="Delete Case"
      @close="isShowingDelete = false"
      :style="computedDialogStyle"
      noPadding
    >
      <DeleteConfirmation
        @close="isShowingDelete = false"
        @delete="handleDelete"
      >
        Are you sure you want to delete case
        <span style="color: var(--overwatch-error)">{{ propCase.name }}</span
        >? This action cannot be undone.
      </DeleteConfirmation>
    </base-dialog>
    <base-dialog
      v-if="isShowingInfo"
      :show="true"
      title="Case Information"
      @close="isShowingInfo = false"
      :style="computedDialogStyle"
      noPadding
    >
      <MissionInformation
        :case="case"
        :statuses="caseStatuses"
        :progress="caseProgress"
        :cameras="caseCameras"
      />
    </base-dialog>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";

import { MDBDropdown } from "mdb-vue-ui-kit";
import RocDropdownMenu from "@/components/ui/RocDropdownMenu.vue";
import RocDropdownToggle from "@/components/ui/RocDropdownToggle.vue";
import RocDropdownItem from "@/components/ui/RocDropdownItem";
import RocPopper from "@/components/ui/RocPopper";
import RocIcon from "@/components/ui/RocIcon";
import DeleteConfirmation from "@/components/settings/DeleteConfirmation";
import MissionInformation from "@/components/cases/MissionInformation.vue";

import userAccessHelper from "@/js/userAccessHelper";

const store = useStore();

const props = defineProps({
  case: Object
});
const propCase = ref(props.case);

const isImportedCase = computed(() => {
  return props.case.imported;
});

const emit = defineEmits(["edit-case"]);

const caseId = ref(props.case._id ? props.case._id : "");

const name = computed(() => {
  return props.case.name ? props.case.name : "";
});

const caseCameras = ref([]);
onMounted(async () => {
  const response = await store.dispatch("cases/getCamerasByCaseId", {
    caseId: props.case._id
  });
  if (response.status === "success") {
    caseCameras.value = response.result;
  }
});

const dropdownOptions = ref(false);

const isShowingDelete = ref(false);
const isShowingInfo = ref(false);
const isShowingEdit = ref(false);

function emitEdit() {
  emit("edit-case");
}

const isAdminOrPowerUser = computed(() => {
  const helper = new userAccessHelper();
  return helper.isAdminOrPowerUser();
});

async function handleDelete() {
  await store.dispatch("cases/deleteCase", { id: caseId.value });
  //set case id to blank to prevent getCaseStatus from erroring
  caseId.value = "";
}

const windowWidth = ref(window.innerWidth);
onMounted(() => {
  window.addEventListener("resize", () => {
    windowWidth.value = window.innerWidth;
  });
});

const isMobile = computed(() => {
  return windowWidth.value <= 480;
});

const computedDialogStyle = computed(() => {
  if (isMobile.value) {
    return {
      width: "90dvw"
    };
  } else {
    return {
      width: "500px"
    };
  }
});
</script>

<style scoped lang="scss">
.case-card {
  @include overwatch-body-med;
}

.import-tag {
  padding: 5px 8px;
  border-radius: 22px;

  width: fit-content;
  height: fit-content;

  /* TODO - consider deleting color, not sure if this class does anything */
  background: var(--overwatch-button-primary-20);
  border: solid 1px var(--overwatch-button-primary);
}

.name-column {
  display: flex;
  align-items: center;
  gap: var(--spacing-s);
}
</style>
