<script setup>
import { ref, computed, onMounted, watch, onUnmounted } from "vue";
import { useStore } from "vuex";
import RocIcon from "@/components/ui/RocIcon";
import RocButton from "@/components/ui/RocButton";
import Confirmation from "@/components/settings/Confirmation.vue";
import moment from "moment";

const props = defineProps({
  caseId: {
    type: String,
    required: true
  },
  targetClusterId: {
    type: String,
    required: true
  }
});
const emit = defineEmits(["close"]);

const store = useStore();

const isExportDisabled = ref(false);
const isLoading = ref(false);
const windowWidth = ref(window.innerWidth);
let reloadTimer;

onMounted(async () => {
  window.addEventListener("resize", () => {
    windowWidth.value = window.innerWidth;
  });
  isLoading.value = true;
  await loadExports();
  reloadTimer = setInterval(() => loadExports(), 1000);
  isLoading.value = false;
});

onUnmounted(() => {
  if (reloadTimer) {
    clearInterval(reloadTimer);
  }
});

const availableExports = ref([]);
async function loadExports() {
  const allExports = await store.dispatch("settings/getEntityExports");
  //for now, this differentiates cluster exports from all other exports
  availableExports.value = allExports?.filter((exportedEntity) => {
    //entityTypes is an array, so we need to check if it includes "cluster"
    return exportedEntity.entityTypes.includes("cluster");
  });
}

async function requestExport() {
  isExportDisabled.value = true;
  isLoading.value = true;
  const response = await store.dispatch("clusters/requestClusterExport", {
    entityTypes: ["cluster"],
    targetClusterId: props.targetClusterId,
    caseId: props.caseId
  });
  await loadExports();

  isLoading.value = false;
  isExportDisabled.value = false;
}

async function deleteExport(id) {
  const response = await store.dispatch("settings/deleteEntityExport", {
    id: id
  });
  await loadExports();
}

async function downloadExport(id) {
  window.location.href = `/rest/v1/migration/export/${id}`;
}

function getFilename(filePath) {
  return filePath ? filePath.replace(/^.*[\\/]/, "") : "";
}

function getDisplayByteSize(bytes) {
  if (!bytes) {
    return "";
  }
  const sizeInKb = bytes / 1024;
  if (sizeInKb > 1024) {
    return `${(sizeInKb / 1024).toFixed(2)}MB`;
  } else {
    return `${sizeInKb.toFixed(2)}KB`;
  }
}

const isConfirmingDeleteOne = ref(false);
const deletingExport = ref(null);
function prepareDeleteOne(exportedEntity) {
  deletingExport.value = exportedEntity;
  isConfirmingDeleteOne.value = true;
}
</script>

<template>
  <div class="export-dialog">
    <div class="overwatch-title-med">
      Export as CSV
    </div>
    <div class="include-section">
      <div class="overwatch-title-small">
        Include
      </div>
      <div class="overwatch-body-small">
        All exports contain a README file that explains metadata and any applied
        filters. CSV files, images, and videos will be packaged together in a
        ZIP file.
      </div>
    </div>
    <div class="previous-exports-section">
    <div class="overwatch-title-small" style="margin-top: var(--spacing-s);">
      Previous Exports
    </div>
    <div class="exportList">
      <div
        v-if="availableExports && availableExports.length > 0"
        v-for="exportedEntity in availableExports"
        :key="exportedEntity._id"
      >
        <div class="migrationEntry">
          <div
            class="overwatch-body-med exportEntryFilePath"
            style="flex: 1.5;"
          >
            {{ getFilename(exportedEntity.filePath) }}
          </div>
          <div
            class="overwatch-body-med"
            style="flex: 0.5; text-align: right; color: var(--overwatch-neutral-200);"
          >
            {{ getDisplayByteSize(exportedEntity.sizeInBytes) }}
          </div>
          <div class="d-flex flex-row flex-grow-1">
            <RocIcon
              v-if="exportedEntity.status === 'complete'"
              icon="check"
              color="primary"
            />
            <div
              v-else-if="exportedEntity.status === 'error'"
              class="d-flex flex-row align-items-center"
              style="gap: var(--spacing-base);"
            >
              <RocIcon icon="error" color="red" />
              <div
                class="overwatch-body-med inlineErrorMsg"
                style="color: var(--overwatch-error);"
                :title="exportedEntity.errorMsg"
              >
                {{ exportedEntity.errorMsg }}
              </div>
            </div>
            <div
              v-else-if="
                exportedEntity.status === 'processing' ||
                  exportedEntity.status === 'pending'
              "
              class="d-flex align-items-start justify-content-start"
            >
              <roc-spinner size="sm" style="margin: 0 !important;" />
            </div>
          </div>
          <div class="exportEntryButtons" style="flex: 0.4;">
            <div
              v-if="exportedEntity.status === 'complete'"
              style="cursor: pointer;"
              @click="downloadExport(exportedEntity._id)"
            >
              <RocIcon icon="download" size="sm" color="primary" />
            </div>
            <!-- <div v-if="exportedEntity.status === 'complete'" style="cursor: pointer;">
              <RocPopper placement="top" :popperType="'tooltip'" hover arrow :locked="true" offsetDistance="4">
                <RocIcon icon="view" size="sm" color="black" />
                <template #content>
                  {{ getLabelFromEntityTypes(exportedEntity.entityTypes) }}
                </template>
              </RocPopper>
            </div> -->
            <div
              v-if="exportedEntity.status != 'processing'"
              style="cursor: pointer;"
              @click="prepareDeleteOne(exportedEntity)"
            >
              <RocIcon icon="exit" size="sm" color="black" />
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="overwatch-body-small">No previous exports available.</div>
      </div>
    </div>
    <roc-spinner v-if="isLoading && availableExports?.length === 0" />
  </div>
    <div class="export-buttons">
      <RocButton size="dialogButton" type="secondary" @click="$emit('close')">
        Cancel
      </RocButton>
      <RocButton
        size="dialogButton"
        @click="requestExport()"
        :disabled="isExportDisabled"
      >
        Export
      </RocButton>
    </div>
  </div>

  <base-dialog
    v-if="isConfirmingDeleteOne"
    :show="true"
    title="Confirm Deletion"
    @close="isConfirmingDeleteOne = false"
    :style="confirmationStyle"
  >
    <Confirmation
      @close="isConfirmingDeleteOne = false"
      @yes="deleteExport(deletingExport._id)"
    >
      <span class="dialogMessage"
        >Are you sure you want to delete this export from [{{
          moment(deletingExport.createdAt).format("MMMM Do YYYY, h:mm:ss a")
        }}]?<br />This action cannot be undone.</span
      >
    </Confirmation>
  </base-dialog>
</template>

<style scoped lang="scss">
.export-dialog {
  @include overwatch-body-med;

  display: flex;
  flex-direction: column;
  gap: var(--spacing-m);

  width: 600px;
}

.include-section {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-base);
}

.previous-exports-section {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-base);
}

.exportList {
  display: flex;
  flex-direction: column;
  max-height: 256px;
  overflow-y: auto;
  gap: var(--spacing-base);
}

.migrationEntry {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-s);
  padding-left: var(--spacing-m);
  padding-right: var(--spacing-m);
  padding-top: var(--spacing-l);
  padding-bottom: var(--spacing-l);
  border: 1px solid var(--overwatch-neutral-400);
  border-radius: 4px;
}

.migrationEntry:hover {
  background-color: var(--overwatch-neutral-400);
}

.exportEntryFilePath {
  text-align: left;
  padding-right: var(--spacing-s);
}

.inlineErrorMsg {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 22ch;
}

.exportEntryButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-s);
  justify-content: end;
}

.export-buttons {
  margin-top: var(--spacing-l);
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-s);
}
</style>
