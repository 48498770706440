<script setup>
import { defineProps, ref } from "vue";
import RocIcon from "@/components/ui/RocIcon";

const props = defineProps({
  files: {
    type: Boolean,
    default: false,
    note: "Allow file upload"
  },
  folders: {
    type: Boolean,
    default: false,
    notes: "Allow folder upload"
  },
  oneFile: {
    type: Boolean,
    default: false
  },
  accepts: {
    type: String,
    default: "image/*"
  },
  csv: {
    type: Boolean,
    default: false
  }
});

const emits = defineEmits(["onDrop"]);

const supportsFileSystemAccessAPI =
  "getAsFileSystemHandle" in DataTransferItem.prototype;
const supportsWebkitGetAsEntry =
  "webkitGetAsEntry" in DataTransferItem.prototype;

const filesIndividual = ref();
const filesDirectory = ref();
const acceptedFileTypes = ref(props.accepts);
const acceptedFileTypesDragNDrop = acceptedFileTypes.value.split(",");

async function onDrop(e) {
  e.preventDefault();

  if (!supportsFileSystemAccessAPI && !supportsWebkitGetAsEntry) {
    const files = e.dataTransfer?.files;
    emits("onDrop", [...files]);
    return;
  }

  const files = await getAllFileEntries(e.dataTransfer.items);
  const flattenFiles = files.reduce((acc, val) => acc.concat(val), []);
  emits("onDrop", [...flattenFiles]);
}

const getAllFileEntries = async (dataTransferItemList) => {
  let fileEntries = [];
  // Use BFS to traverse entire directory/file structure
  let queue = [];
  for (let i = 0; i < dataTransferItemList.length; i++) {
    queue.push(dataTransferItemList[i].webkitGetAsEntry());
  }
  while (queue.length > 0) {
    let entry = queue.shift();
    if (entry.isFile) {
      fileEntries.push(entry);
    } else if (entry.isDirectory && props.folders) {
      let reader = entry.createReader();
      queue.push(...(await readAllDirectoryEntries(reader)));
    }
  }
  // return fileEntries;
  return Promise.all(fileEntries.map((entry) => readEntryContentAsync(entry)));
};

// Get all the entries (files or sub-directories) in a directory by calling readEntries until it returns empty array
const readAllDirectoryEntries = async (directoryReader) => {
  let entries = [];
  let readEntries = await readEntriesPromise(directoryReader);
  while (readEntries.length > 0) {
    entries.push(...readEntries);
    readEntries = await readEntriesPromise(directoryReader);
  }
  return entries;
};

// Wrap readEntries in a promise to make working with readEntries easier
const readEntriesPromise = async (directoryReader) => {
  try {
    return await new Promise((resolve, reject) => {
      directoryReader.readEntries(resolve, reject);
    });
  } catch (err) {
    console.error(err);
  }
};

const readEntryContentAsync = async (entry) => {
  return new Promise((resolve, reject) => {
    let reading = 0;
    const contents = [];

    reading++;
    entry.file(async (file) => {
      reading--;
      const rawFile = file;
      rawFile.path = entry.fullPath;
      if (isAllowedFileType(file) || entry.isDirectory) contents.push(rawFile);
      else console.log("File not supported: " + file.name);
      if (reading === 0) {
        resolve(contents);
      }
    });
  });
};

function isAllowedFileType(file) {
  if (
    (acceptedFileTypesDragNDrop.includes("image/*") &&
      file.type?.split("/")[0] === "image") ||
    acceptedFileTypesDragNDrop.includes(file.type)
  ) {
    return true;
  }
  //eft files dont have a type when uploaded.
  let tempFileArr = file.name.split(".");
  return acceptedFileTypesDragNDrop.includes(
    "." + tempFileArr[tempFileArr.length - 1]
  );
}

function selectIndividual() {
  filesIndividual.value.click();
}

function selectDirectory() {
  filesDirectory.value.click();
}

function selectFile(e) {
  const files = e.target.files;
  emits("onDrop", [...files]);
}

function onOffDragOver(event) {
  event.preventDefault();
}
</script>

<template>
  <div
    class="rectangle-copy"
    @drop.prevent="onDrop"
    @dragover.prevent="onOffDragOver"
    @dragleave.prevent="onOffDragOver"
  >
    <div class="choose-files-or-drag">
      <div>
        <RocIcon icon="upload" color="primary"></RocIcon>
        <input
          ref="filesIndividual"
          type="file"
          style="display: none"
          @change="selectFile($event)"
          multiple
          :accept="acceptedFileTypes"
        />
        <input
          ref="filesDirectory"
          webkitdirectory
          mozdirectory
          msdirectory
          odirectory
          directory
          multiple
          type="file"
          style="display: none"
          @change="selectFile($event)"
        />
      </div>
      <div class="overwatch-title-xsmall">
        Drag & Drop or
        <a
          v-if="files"
          @click.prevent="selectIndividual"
          style="color: var(--overwatch-primary); cursor: pointer;"
          >Choose Files</a
        >
        <a v-if="files && (oneFile || folders || csv)"> or </a>
        <a
          v-if="oneFile"
          @click.prevent="selectIndividual"
          style="color: var(--overwatch-primary); cursor: pointer;"
          >Choose File</a
        >
        <a v-if="oneFile && (folders || csv)"> or </a>
        <a
          v-if="folders"
          @click.prevent="selectDirectory"
          style="color: var(--overwatch-primary); cursor: pointer;"
          >Directory</a
        >
        <a v-if="folders && csv"> or </a>
        <a
          v-if="csv"
          @click.prevent="selectIndividual"
          style="color: var(--overwatch-primary); cursor: pointer;"
          >Choose csv</a
        >
        to upload
      </div>
      <div class="imgTypes overwatch-title-xsmall">{{ accepts }}</div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.rectangle-copy {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: 3px dashed var(--overwatch-light-button-primary-20);
  background-color: var(--overwatch-light-button-primary-20);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.choose-files-or-drag {
  color: var(--overwatch-neutral-100);
  @include overwatch-body-large;
  line-height: 35px;
  text-align: center;

  align-items: center;
}

.imgTypes {
  color: var(--overwatch-light-accent);
}
</style>
