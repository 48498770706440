<template>
  <div>
    <slot name="title"></slot>
    <div v-for="item in items" :key="customKey ? item[customKey] : item.id">
      <slot name="item" :item="item" />
    </div>

    <div
      id="test"
      v-if="setVisibilityAnchor"
      v-observe-visibility="handleScrolledToBottom"
    ></div>
  </div>
</template>

<script>
import { computed, ref } from "vue";

export default {
  name: "InfiniteScroll",
  emits: ["refetch", "setPage"],
  props: {
    items: Array,
    maxPages: Number,
    page: Number,
    mode: String,
    isMoreData: Boolean,
    enabled: Boolean,
    customKey: String
  },
  components: {},
  setup(props, context) {
    const refetchEmitted = ref(false);

    //Note: Extra complexity to hide/show div with 'v-observe-visibility', this forces a recalculation without user input
    const setVisibilityAnchor = computed(function() {
      if (!props.items.length || refetchEmitted.value == true) {
        return false;
      } else {
        return true;
      }
    });

    function handleScrolledToBottom(isVisible) {
      if (!isVisible || !props.enabled) {
        return;
      }

      if (props.mode !== "nopage") {
        if (props.page < props.maxPages) {
          context.emit("setPage", props.page + 1);
          refetchEmitted.value = true;
          context.emit("refetch", { status: refetchEmitted, isAppend: true });
        }
      } else {
        if (props.isMoreData) {
          refetchEmitted.value = true;
          context.emit("refetch", { status: refetchEmitted, isAppend: true });
        }
      }
    }

    return {
      handleScrolledToBottom,
      setVisibilityAnchor
    };
  }
};
</script>

<style scoped></style>
