<template>
  <div v-if="isLoading">
    <roc-spinner />
  </div>
  <div v-else class="apiKeyList">
    <div v-for="apikey in apiKeys" :key="apikey._id">
      <ApiKeyCard
        :apikey="apikey"
        @toggle-state="toggleEnabledState(apikey)"
        @edit="showEditApiKey(apikey)"
        @delete="showDeleteApiKey(apikey)"
      />
    </div>
  </div>
  <div class="buttonRow">
    <div class="statusText">{{ statusText }}</div>
    <RocButton @click="closeDialog" type="secondary" size="dialogButton"
      >Close
    </RocButton>
    <RocButton size="dialogButton" @click="createNew">Create</RocButton>
  </div>
  <base-dialog
    v-if="isShowingAddApiKey"
    :show="true"
    title="New API Key"
    @close="isShowingAddApiKey = false"
    :style="apiKeyCRUDStyle"
  >
    <ApiKeyCRUD @close="isShowingAddApiKey = false" @save="loadApiKeys" />
  </base-dialog>
  <base-dialog
    v-if="isShowingEditApiKey"
    :show="true"
    title="Edit API Key"
    @close="isShowingEditApiKey = false"
    :style="apiKeyCRUDStyle"
  >
    <ApiKeyCRUD
      @close="isShowingEditApiKey = false"
      @save="loadApiKeys"
      :apiKeyEditing="currentEditedApiKey"
      :mode="'edit'"
    />
  </base-dialog>
  <base-dialog
    v-if="isShowingDeleteApiKey"
    :show="true"
    title="Delete API Key"
    @close="isShowingDeleteApiKey = false"
    :style="apiKeyDeleteStyle"
  >
    <DeleteConfirmation
      :includeDisable="false"
      @close="isShowingDeleteApiKey = false"
      @delete="deleteHandler(currentEditedApiKey)"
    >
      Are you sure you want to delete API Key
      <span style="color: var(--overwatch-error);"
        >{{ currentEditedApiKey.label }} </span
      >? This action cannot be undone.
    </DeleteConfirmation>
  </base-dialog>
</template>

<script>
import { ref, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import DeleteConfirmation from "@/components/settings/DeleteConfirmation";
import ApiKeyCard from "@/components/settings/ApiKeyCard.vue";
import ApiKeyCRUD from "@/components/settings/ApiKeyCRUD.vue";
import BaseDialog from "@/components/ui/BaseDialog.vue";
import RocButton from "@/components/ui/RocButton.vue";

export default {
  name: "ApiKeySettings",
  emits: ["close"],
  components: {
    DeleteConfirmation,
    ApiKeyCard,
    ApiKeyCRUD,
    RocButton
  },
  setup(props, context) {
    const store = useStore();
    const isLoading = ref(false);
    const apiKeys = ref([]);
    const isShowingAddApiKey = ref(false);
    const isShowingEditApiKey = ref(false);
    const isShowingDeleteApiKey = ref(false);
    const currentEditedApiKey = ref(null);
    const statusText = ref("");

    const windowWidth = ref(window.innerWidth);
    onMounted(() => {
      window.addEventListener("resize", () => {
        windowWidth.value = window.innerWidth;
      });
      loadApiKeys();
    });

    async function toggleEnabledState(apiKey) {
      isLoading.value = true;
      try {
        const payload = {
          _id: apiKey._id,
          enabled: !apiKey.enabled
        };
        const responseData = await store.dispatch(
          "settings/updateApiKey",
          payload
        );
        if (responseData && responseData.status === "success") {
          console.log(responseData);
          Object.assign(apiKey, responseData.result);
        }
      } catch (err) {
        console.log(err);
      }
      isLoading.value = false;
    }

    function showEditApiKey(apiKey) {
      currentEditedApiKey.value = apiKey;
      isShowingEditApiKey.value = true;
    }

    function showDeleteApiKey(apiKey) {
      currentEditedApiKey.value = apiKey;
      isShowingDeleteApiKey.value = true;
    }

    async function deleteHandler(apiKey) {
      isLoading.value = true;
      try {
        await store.dispatch("settings/deleteApiKey", { _id: apiKey._id });
      } catch (err) {
        console.log(err);
      }
      isLoading.value = false;
      await loadApiKeys();
    }

    async function loadApiKeys() {
      isLoading.value = true;
      try {
        const response = await store.dispatch("settings/getApiKeys");
        if (response.status === "success") {
          apiKeys.value = response.result;
        }
      } catch (err) {
        console.log(err);
      }
      isLoading.value = false;
    }

    const apiKeyDeleteStyle = computed(() => {
      if (windowWidth.value <= 480) {
        return {
          width: "100%",
          "max-height": "100%"
        };
      } else {
        return {
          width: "500px"
        };
      }
    });

    const apiKeyCRUDStyle = computed(() => {
      if (windowWidth.value <= 480) {
        return {
          width: "100%",
          "max-height": "100%"
        };
      } else {
        return {
          width: "750px"
        };
      }
    });

    function createNew() {
      currentEditedApiKey.value = null;
      isShowingAddApiKey.value = true;
    }

    function closeDialog() {
      context.emit("close");
    }

    return {
      isLoading,
      apiKeyDeleteStyle,
      apiKeyCRUDStyle,
      apiKeys,
      showEditApiKey,
      showDeleteApiKey,
      isShowingAddApiKey,
      isShowingEditApiKey,
      isShowingDeleteApiKey,
      currentEditedApiKey,
      deleteHandler,
      toggleEnabledState,
      statusText,
      createNew,
      loadApiKeys,
      closeDialog
    };
  }
};
</script>

<style scoped lang="scss">
.buttonRow {
  display: flex;
  padding-top: 1rem;
  justify-content: right;
  gap: var(--spacing-s);
}

.statusText {
  color: var(--overwatch-error);
}

.apiKeyList {
  overflow-y: auto;
  max-height: 500px;
  min-height: 150px;
}

@media (max-width: 480px) {
  .apiKeyList {
    max-height: 300px;
  }
}
</style>
