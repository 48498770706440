<template>
  <div class="fileSelectBox">
    <RocDragAndDrop @onDrop="handleFileEvent" :csv="true" accepts=".csv" />
  </div>
</template>

<script setup>
import csvHelper from "@/js/csvHelper";
import RocDragAndDrop from "@/components/ui/RocDragAndDrop.vue";

const emits = defineEmits(["csvAdded"]);

async function processFile(manifestFile) {
  const manifestData = await readAsText(manifestFile);
  const manifest = CSVToManifestObjArray(manifestData);
  emits("csvAdded", manifest);
}

function CSVToManifestObjArray(csvString) {
  const delimiter = ",";
  const resultObj = new csvHelper().csvToObject(csvString, delimiter);
  if (resultObj && resultObj.rows.length) {
    try {
      const arrayOfObjs = resultObj.rows.map((entry) => ({
        firstname: entry[0],
        lastname: entry[1],
        email: entry[2],
        phoneNumber: entry[3],
        notes: entry[4]
      }));
      resultObj.rows = arrayOfObjs;
      return resultObj;
    } catch (err) {
      console.log(err);
    }
    return {};
  }
}

function readAsText(file) {
  return new Promise(function(resolve, reject) {
    let fr = new FileReader();
    fr.onload = function() {
      resolve(fr.result);
    };
    fr.onerror = function() {
      reject(fr);
    };
    fr.readAsText(file, "UTF-8");
  });
}

function handleFileEvent(files) {
  files.forEach(async (file) => {
    await processFile(file);
  });
}
</script>

<style scoped lang="scss">
.fileSelectBox {
  box-sizing: border-box;
  @include overwatch-body-small;
  color: black;
  height: 20%;
  width: 100%;
}

@media (max-width: 480px) {
  .choose-files-or-drag {
    font-size: 18px;
  }
}
</style>
