<!-- Reusable component for common camera settings -->

<template>
  <div>
    <div class="settingsSection" v-if="!hiddenSettings.includes('enabled')">
      <div class="d-flex justify-content-between">
        <div class="settingsSectionHeader">Enabled</div>
        <RocSwitch
          id="enabled"
          :isActive="enabled"
          @switch-toggled="enabled = $event"
          :disabled="disabled"
        />
      </div>
      <div class="d-flex justify-content-start settingsSectionDesc">
        Camera is on.
      </div>
    </div>
    <div class="settingsSection" v-if="!hiddenSettings.includes('analytics')">
      <div class="settingsSectionHeader">Analytics</div>
      <div>
        <filterMultiSelect
          :disabled="disabled"
          mode="tags"
          :close-on-select="false"
          no-results-text=""
          placeholder-text="Select Objects"
          :available-items="analytics"
          :currently-selected="selectedAnalytics"
          @selection-changed="updateSelectedAnalytics"
        />
      </div>
      <div class="d-flex justify-content-start settingsSectionDesc">
        Detects faces or object in real-time.
      </div>
    </div>
    <div class="settingsSection" v-if="!hiddenSettings.includes('record')">
      <div class="d-flex justify-content-between">
        <div class="settingsSectionHeader">Record Video</div>
        <RocSwitch
          id="enableRecorder"
          :isActive="enableRecorder"
          @switch-toggled="enableRecorder = $event"
        />
      </div>
      <div class="d-flex justify-content-start settingsSectionDesc">
        Records video for playback.
      </div>
    </div>
    <div class="settingsSection" v-if="!hiddenSettings.includes('threshold')">
      <div class="d-flex justify-content-between">
        <div class="settingsSectionHeader">Match Threshold Override</div>
        <RocSwitch
          id="enableMatchThresholdOverride"
          :isActive="enableMatchThresholdOverride"
          @switch-toggled="enableMatchThresholdOverride = $event"
          :disabled="disabled"
        />
      </div>
      <RocRange
        class="overwatch-body-small"
        v-model="matchThresholdSlider"
        :disabled="!enableMatchThresholdOverride || disabled"
        :label="`${matchThresholdSlider} / 100`"
      />
      <div class="d-flex justify-content-start settingsSectionDesc">
        Allows for Camera Level Match Threshold for Face
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted } from "vue";
import filterMultiSelect from "@/components/ui/filterMultiSelect";
import { get as lodashGet } from "lodash";
import RocSwitch from "../ui/RocSwitch.vue";
import RocRange from "../ui/RocRange.vue";

export default {
  name: "CameraSettings",
  props: [
    "enabled",
    "enableRecorder",
    "enableThreshold",
    "threshold",
    "analytics",
    "hideSettings",
    "disabled",
    "vsConfig"
  ],
  emits: ["change", "start"],
  components: {
    filterMultiSelect,
    RocSwitch,
    RocRange
  },
  setup(props, context) {
    const enabled = ref(props.enabled ? props.enabled : false);
    const enableRecorder = ref(
      props.enableRecorder ? props.enableRecorder : true
    );
    const enableMatchThresholdOverride = ref(
      props.enableThreshold ? props.enableThreshold : false
    );
    const matchThresholdSlider = ref(
      props.threshold ? props.threshold * 100 : 80
    );

    onMounted(() => {
      // Emit changes at least once.
      emitChangesToAll();
    });

    const hiddenSettings = ref(props.hideSettings ? props.hideSettings : []);

    const analytics = ref([
      "Face",
      "License Plate",
      "OCR In-The-Wild",
      "Vehicle",
      "Pedestrian",
      "Airplane",
      "Boat",
      "Gun",
      "Tattoo"
    ]);

    const selectedAnalytics = ref(props.analytics ? props.analytics : []);

    function updateSelectedAnalytics(selection) {
      selectedAnalytics.value = selection.value;
    }

    function generatePayload() {
      const payload = {};

      payload["enabled"] = enabled.value;
      payload["enableRecorder"] = enableRecorder.value;
      payload["matchThresholdOverride"] = {
        enabled: enableMatchThresholdOverride.value,
        threshold: matchThresholdSlider.value / 100
      };
      payload["analytics"] = selectedAnalytics.value;

      return payload;
    }

    function emitChanges() {
      const payload = generatePayload();
      context.emit("change", payload);
    }

    function emitChangesToAll() {
      const payload = generatePayload();
      context.emit("start", payload);
    }

    watch(
      [
        enabled,
        enableRecorder,
        enableMatchThresholdOverride,
        matchThresholdSlider,
        selectedAnalytics
      ],
      () => {
        emitChanges();
      },
      { deep: true }
    );

    function arrayIncludesArrayOfValues(analyticsId, analyticsDefinition) {
      try {
        if (analyticsId.some((id) => analyticsDefinition.includes(id))) {
          return true;
        }
      } catch (err) {
        console.log(err);
      }

      return false;
    }

    if (props.vsConfig) {
      const allAID = getAllAID(props.vsConfig);
      setAnalyticsByAID(allAID);
    }

    function setAnalyticsByAID(analyticsIds) {
      selectedAnalytics.value = [
        ...(arrayIncludesArrayOfValues(analyticsIds, ["ROC_FACE_DETECTION"])
          ? ["Face"]
          : []),
        ...(arrayIncludesArrayOfValues(analyticsIds, [
          "ROC_LICENSE_PLATE_DETECTION"
        ])
          ? ["License Plate"]
          : []),
        ...(arrayIncludesArrayOfValues(analyticsIds, ["ROC_TEXT_DETECTION"])
          ? ["OCR In-The-Wild"]
          : []),
        ...(arrayIncludesArrayOfValues(analyticsIds, ["ROC_CAR_DETECTION"])
          ? ["Vehicle"]
          : []),
        ...(arrayIncludesArrayOfValues(analyticsIds, ["ROC_PERSON_DETECTION"])
          ? ["Pedestrian"]
          : []),
        ...(arrayIncludesArrayOfValues(analyticsIds, ["ROC_AIRPLANE_DETECTION"])
          ? ["Airplane"]
          : []),
        ...(arrayIncludesArrayOfValues(analyticsIds, ["ROC_BOAT_DETECTION"])
          ? ["Boat"]
          : []),
        ...(arrayIncludesArrayOfValues(analyticsIds, ["ROC_GUN_DETECTION"])
          ? ["Gun"]
          : [])
      ];
    }

    function getAllAID(config) {
      const backends = lodashGet(
        config,
        "roc.tracker.analytics-backends",
        null
      );
      const analyticIds = [];
      for (let backend of backends) {
        analyticIds.push(...backend["algorithm-id"]);
      }
      return analyticIds;
    }

    return {
      enabled,
      enableRecorder,
      enableMatchThresholdOverride,
      matchThresholdSlider,
      analytics,
      selectedAnalytics,
      updateSelectedAnalytics,
      hiddenSettings
    };
  }
};
</script>

<style scoped lang="scss">
.settingsSection {
  margin: 1rem 0 1rem 0;
}

.settingsSectionHeader {
  height: 20px;
  width: 299px;
  @include overwatch-title-xsmall;
  letter-spacing: 0;
  line-height: 21px;
}

.settingsSectionDesc {
  width: 343px;
  @include overwatch-body-xsmall;
}
</style>
