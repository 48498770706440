<template>
  <teleport to="body">
    <div v-if="show" class="backdrop" @click="tryClose"></div>
    <transition mode="in-out" name="dialog">
      <dialog
        v-if="show"
        open
        :style="overrideStyle()"
        class="d-flex flex-column hide-scrollbars"
      >
        <div v-if="!hideCloseBtn" class="roc-dialog-close">
          <div class="roc-dialog-close-icon" @click="tryClose">
            <RocIcon color="black" size="sm" icon="exit" id="dialogClose" />
          </div>
        </div>
        <div
          class="roc-dialog-content"
          :class="{
            sectionOverflowHidden: sectionOverflowHidden,
            topPadding: hideCloseBtn || !noPadding
          }"
        >
          <slot name="header">
            <div
              v-if="title"
              class="d-flex justify-content-between title-holder"
            >
              <div class="title">{{ title }}</div>
            </div>
          </slot>
          <slot></slot>
        </div>
      </dialog>
    </transition>
  </teleport>
</template>

<script>
import { onActivated, onMounted, onUnmounted, watch, computed } from "vue";
import { useStore } from "vuex";
import RocIcon from "@/components/ui/RocIcon";

export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    fixed: {
      type: Boolean,
      required: false,
      default: false
    },
    style: {
      type: Object,
      required: false
    },
    hideCloseBtn: {
      type: Boolean,
      default: false
    },
    sectionOverflowHidden: {
      type: Boolean,
      default: false
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    preventCloseOnClickAway: {
      type: Boolean,
      default: false
    }
  },
  components: {
    RocIcon
  },
  emits: ["close"],
  setup(props, context) {
    const store = useStore();
    /* Add escape event listeners and stop body from scrolling when dialog is up. */
    function handleEscape(event) {
      if (event.key === "Escape") {
        tryClose();
      }
    }

    function dialogSetup() {
      let body = document.querySelector("body");
      body.classList.add("dialog-open");

      // Listen for refresh. Run cleanup on refresh so that body is scrollable.
      window.addEventListener("beforeunload", cleanup);
      window.addEventListener("keydown", handleEscape);
    }

    function cleanup() {
      window.removeEventListener("beforeunload", cleanup);
      window.removeEventListener("keydown", handleEscape);

      let body = document.querySelector("body");
      body.classList.remove("dialog-open");
    }

    // show toggle
    watch(
      () => props.show,
      () => {
        if (props.show) {
          dialogSetup();
        } else {
          cleanup();
        }
      }
    );

    // v-if toggle
    onMounted(() => {
      if (props.show) {
        dialogSetup();
      }
    });

    onUnmounted(() => {
      cleanup();
    });

    function overrideStyle() {
      var style = "";
      if (props.style) {
        style = props.style;
        if ("height" in style) {
          if (style.height.includes("100%")) {
            // If height is 100% of body, reduce it so that it clears the iPhone notch -Sean
            style.height = "calc(100% - env(safe-area-inset-top))";
          }
        }
      }
      return style;
    }

    function tryClose() {
      if (props.fixed) {
        return;
      }

      if (props.preventCloseOnClickAway) {
        return;
      }

      cleanup();
      context.emit("close");
    }

    return {
      tryClose,
      overrideStyle
    };
  }
};
</script>

<style>
/* Stop body from scrolling. */
body.dialog-open {
  overflow: hidden;
}
</style>

<style scoped lang="scss">
.title {
  @include overwatch-title-med;
}

.backdrop {
  position: fixed;
  top: env(safe-area-inset-top);
  left: 0;
  height: calc(100vh - env(safe-area-inset-top));
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 100;
}

.roc-dialog-content :deep(.description) {
  margin-bottom: 24px;
}

dialog {
  position: fixed;
  transform: translateX(-50%) translateY(-50%);
  top: calc(50% + env(safe-area-inset-top) / 2);
  /* When top:50%, the dialog is in the middle of the screen and clears half of the notch area.
  Thus add env(safe-area-inset-top)/2 to top calculation. */
  left: 50%;
  z-index: 100;
  border-radius: 5px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  margin: 0;
  color: var(--overwatch-neutral-100);
  background-color: var(--overwatch-secondary);
  overscroll-behavior-y: none;
}

.dialog-enter-from,
.dialog-leave-to {
  opacity: 0;
  transform: translate(-50%, -50%) scale(1);
}

.dialog-enter-active {
  transition: all 0.5s ease-out;
}

.dialog-leave-active {
  transition: all 0.5s ease-out;
}

.dialog-enter-to,
.dialog-leave-from {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}

.hide-scrollbars::-webkit-scrollbar {
  background: transparent; /* Chrome/Safari/Webkit */
  width: 0px;
}

.hide-scrollbars {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

.roc-dialog-close {
  display: flex;
  align-items: flex-end;
  position: sticky;
  top: 0;
  z-index: 50;
  background-color: var(--overwatch-secondary);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.roc-dialog-close-icon {
  margin-left: auto;
  margin-right: var(--spacing-m);
  margin-top: var(--spacing-m);
  cursor: pointer;
}

.roc-dialog-content {
  flex: 1;
  min-height: fit-content;
  padding-left: var(--spacing-xl);
  padding-right: var(--spacing-xl);
  padding-bottom: var(--spacing-xl);
}

.topPadding {
  padding-top: var(--spacing-xl);
}

.sectionOverflowHidden {
  overflow-y: hidden;
}

.title-holder {
  padding-bottom: var(--spacing-l);
  display: flex;
  position: sticky;
  top: 0;
  color: var(--overwatch-neutral-100);
}

/* MOBILE */
@media (max-width: 480px) {
  .roc-dialog-content {
    padding-left: var(--spacing-m);
    padding-right: var(--spacing-m);
    padding-bottom: var(--spacing-m);
  }
}
</style>
