<template>
  <div class="dialog-wrapper">
    <!-- show backdrop and spinner when merging cluster -->
    <div v-if="isSaving">
      <div class="backdrop"></div>
      <roc-spinner class="spinner" />
    </div>
    <div class="overwatch-title-large" v-if="mergeClusterMode">
      Merge With...
    </div>
    <div class="overwatch-title-large" v-else>Move to Existing Cluster</div>
    <div class="cluster-name">
      <span class="overwatch-title-med">Cluster Name</span>
      <span class="overwatch-body-med">{{ cluster.name }}</span>
    </div>
    <div class="select-cluster">
      Cluster
      <RocSingleSelect
        class="select"
        :availableItems="computedClusterOptions"
        @selection-changed="setSelectedCluster"
        valueProp="value"
      />
    </div>
    <div class="buttons">
      <RocButton type="secondary" @click="$emit('close')">
        Cancel
      </RocButton>
      <RocButton
        @click="mergeClusterMode ? mergeClusters() : moveToSelectedCluster()"
        :disabled="!selectedClusterId"
      >
        Save
      </RocButton>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import RocSingleSelect from "@/components/ui/RocSingleSelect";
import RocButton from "@/components/ui/RocButton";

export default {
  name: "MoveToExistingCluster",
  components: {
    RocSingleSelect,
    RocButton
  },
  props: {
    encounters: {
      type: Array,
      required: true,
      default: []
    },
    cluster: {
      type: Object,
      required: true
    },
    mergeClusterMode: {
      type: Boolean,
      required: false
    }
  },
  emits: ["close", "save"],
  setup(props, context) {
    const store = useStore();

    onMounted(async () => {
      if (!props.cluster?.caseId) return;
      await store.dispatch(
        "clusters/getAllClustersByCaseId",
        props.cluster.caseId
      );
    });

    const clusters = computed(() => {
      return store.getters["clusters/allClusters"];
    });

    const computedClusterOptions = computed(() => {
      const output = [];

      // Exclude prop cluster and all modalities that are not the same as the prop cluster
      const filteredClusters = clusters.value.filter(
        (c) =>
          c._id != props.cluster._id && c.modality === props.cluster.modality
      );
      for (let c of filteredClusters) {
        output.push({
          value: c._id,
          name: c.name
        });
      }
      return output;
    });

    const selectedClusterId = ref();
    function setSelectedCluster(c) {
      selectedClusterId.value = c.value;
    }

    async function moveToSelectedCluster() {
      isSaving.value = true;
      const fromClusterId = props.cluster._id;
      const toClusterId = selectedClusterId.value;
      const modality = props.cluster.modality ? props.cluster.modality : "face";
      const encounterIds = props.encounters.map((e) => e._id);

      await store.dispatch("clusters/moveEncountersBetweenClusters", {
        update: {
          fromClusterId: fromClusterId,
          toClusterId: toClusterId,
          encounters: encounterIds,
          modality: modality
        }
      });
      isSaving.value = false;
      context.emit("save");
      context.emit("close");
    }

    //sometimes this function can take a while, adding spinner to saving button to let user know to wait
    const isSaving = ref(false);
    async function mergeClusters() {
      isSaving.value = true;
      const fromClusterId = props.cluster._id;
      const toClusterId = selectedClusterId.value;

      await store.dispatch("clusters/mergeClusters", {
        update: {
          fromClusterId: fromClusterId,
          toClusterId: toClusterId
        }
      });
      isSaving.value = false;
      context.emit("close");
    }

    return {
      computedClusterOptions,
      selectedClusterId,
      setSelectedCluster,
      moveToSelectedCluster,
      mergeClusters,
      isSaving
    };
  }
};
</script>

<style scoped lang="scss">
.dialog-wrapper {
  display: flex;
  flex-direction: column;
  gap: $--spacing-m;
  justify-content: flex-start;
  width: 500px;
  padding: $--spacing-xl $--spacing-xxl;
  position: relative; /* Add this line */
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--overwatch-secondary);
  opacity: 0.8;
  z-index: 2;
}
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-53%, -53%);
  z-index: 2;
  pointer-events: none;
}
.cluster-name {
  display: flex;
  flex-direction: column;
}
.select-cluster {
  @include overwatch-body-large;
  .select {
    height: 48px;
    width: 100%;
  }
}
.buttons {
  display: flex;
  justify-content: flex-end;
  gap: $--spacing-s;
}
</style>
