<script setup>
import { computed, ref, onMounted, onUnmounted, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import { MDBDropdown, MDBSpinner } from "mdb-vue-ui-kit";
import RocIcon from "@/components/ui/RocIcon.vue";
import RocDropdownMenu from "@/components/ui/RocDropdownMenu.vue";
import RocDropdownToggle from "@/components/ui/RocDropdownToggle.vue";
import RocDropdownItem from "@/components/ui/RocDropdownItem.vue";
import RocLogo from "@/components/ui/RocLogo.vue";
import RocButton from "@/components/ui/RocButton.vue";

import ClusterCard from "@/components/clusters/ClusterCard";
import ClusteringFilters from "@/components/clusters/ClusteringFilters";

import BaseDialog from "@/components/ui/BaseDialog";
import SavedClusterFiltersPopup from "@/components/clusters/SavedClusterFiltersPopup.vue";

import AssociatesFilterPopup from "@/components/clusters/AssociatesFilterPopup";
import EnrollEncounter from "@/components/watchlists/EnrollEncounter";
import RocPopper from "../ui/RocPopper.vue";

import { constructSameFrameFilter } from "@/js/associatesHelper";

const store = useStore();
const router = useRouter();
const props = defineProps({
  caseId: {
    type: String,
    required: true
  },
  isPolFilter: {
    type: Boolean,
    required: false
  }
});

const clusters = computed(() => store.getters["clusters/clusters"] ?? []);
const associatesTargetCluster = computed(
  () => store.getters["clusters/associatesTargetCluster"]
); // The selected cluster for which we show associates.
const userConfig = computed(() => store.getters["auth/userConfig"] ?? {});
const savedFilters = computed(
  () => userConfig?.value?.savedClusterFilters ?? []
);

const blockSelectedToolTip = ref(false);
const isLoadingMoreClusters = ref(false);
const isEnrollDialogVisible = ref(false);
const isLPREnrollDialogVisible = ref(false);
const enrollObject = ref();

const isShowingAssociatesFilterPopup = ref(false);
const isSavedFilterMenuOpen = ref(false);
const isShowingSaveFilterPopup = ref(false);
const editingFilter = ref();
const selectedFilter = ref();

async function setSavedFilter(sf) {
  store.dispatch("auth/applySavedClusterFilter", sf);
  selectedFilter.value = sf;
  isSavedFilterMenuOpen.value = false;
  await loadClusters();
}

async function clearSelectedFilter() {
  selectedFilter.value = null;
  store.dispatch("clusters/resetAllFilters");
  await loadClusters();
}

async function handleTimeRangeFilterSave(filter) {
  isShowingAssociatesFilterPopup.value = false;
  store.commit("clusters/setAssociatesTimeRangeFilter", filter);
  store.commit("clusters/setAssociatesSameFrameFilter", []);
  await loadClusters();
}
async function handleSameFrameFilterSave(filter) {
  isShowingAssociatesFilterPopup.value = false;
  store.commit("clusters/setAssociatesSameFrameFilter", filter);
  store.commit("clusters/setAssociatesTimeRangeFilter", []);
  await loadClusters();
}

function editSavedFilter(sf) {
  isSavedFilterMenuOpen.value = false;
  isShowingSaveFilterPopup.value = true;
  editingFilter.value = sf;
}

function enrollFace(eo) {
  enrollObject.value = eo;
  isEnrollDialogVisible.value = true;
}

function enrollLPR(eo) {
  enrollObject.value = eo;
  isLPREnrollDialogVisible.value = true;
}

function showAssociates(cluster) {
  store.commit("clusters/setAssociatesTargetCluster", cluster);
  router.push({
    name: "ClusteringTarget",
    params: { caseId: props.caseId, clusterId: cluster._id }
  });
}

async function handleFilterChange() {
  selectedFilter.value = null;
  await loadClusters();
}

async function loadClusters() {
  // Load mission's clusters, stored in vuex as clusters/clusters
  isLoadingMoreClusters.value = true;
  store.commit("clusters/setClusters", []);
  await store.dispatch("clusters/loadClusters", props.caseId);
  isLoadingMoreClusters.value = false;
}

async function loadMoreClusters() {
  if (store.getters["clusters/moreToFetch"]) {
    isLoadingMoreClusters.value = true;
    await store.dispatch("clusters/loadMoreClusters", props.caseId);
    isLoadingMoreClusters.value = false;
  }
}

function resetState() {
  // Empty clusters must be set here so that page doesn't load any clusters from previous case
  store.commit("clusters/setClusters", []);
  store.commit("clusters/setSorting", "");
  store.dispatch("clusters/resetAllFilters");

  store.commit("clusters/setAssociatesFilterPopupFields", {
    isSameFrameOnly: true,
    isBeforeTargetEnabled: false,
    isAfterTargetEnabled: false,
    beforeTargetUnit: "hrs",
    afterTargetUnit: "hrs",
    beforeTarget: 0,
    afterTarget: 0
  });

  // Abort any existing requests in flight
  const abortController = store.getters["clusters/abortController"];
  if (abortController) {
    abortController.abort();
  }
}

// Sean: Feedback from Christina; associates view should never show all associate clusters.
// Default behavior should be same frame filter.
// We usually get sameFrameFilter from the associatesFilterPopup, but it isn't currently possible
// for the popup to emit upon mount, so manually calling constructSameFrameFilter and handleSameFrameFilter here.
watch(
  associatesTargetCluster,
  async (nv) => {
    if (nv) {
      const filter = constructSameFrameFilter(
        associatesTargetCluster.value.encounters
      );
      await handleSameFrameFilterSave(filter);
    }
  },
  { immediate: true }
);

onMounted(async () => {
  isLoadingMoreClusters.value = true;
  const response = await store.dispatch("cases/getCaseById", props.caseId);
  if (response.status === "success") {
    store.commit("clusters/setMission", response.result);
  }

  await store.dispatch("clusters/loadClusters", props.caseId);
  isLoadingMoreClusters.value = false;
});

onUnmounted(() => {
  resetState();
});

const popperStates = ref({});
function setPopperState(id, isOpen) {
  popperStates.value[id] = isOpen;
}
</script>

<template>
  <div
    class="clustering-box"
    :style="{
      padding: associatesTargetCluster
        ? '0px'
        : 'var(--spacing-l) 0 0 var(--spacing-xxl)'
    }"
  >
    <div class="title-and-filters">
      <span class="title" v-if="!!associatesTargetCluster">
        Associates
        <RocIcon
          color="black"
          size="sm"
          icon="settings"
          style="cursor: pointer; margin-left: var(--spacing-s);"
          @click="isShowingAssociatesFilterPopup = true"
        />

        <RocPopper
          hover
          arrow
          placement="top"
          popper-type="tooltip"
          v-model="blockSelectedToolTip"
          v-if="props.isPolFilter"
          style="cursor: pointer; margin-left: var(--spacing-xs);"
        >
          <RocIcon
            icon="error"
            size="sm"
            style="color: var(--roc-watch-similarity-70);"
          />
          <template #content>
            Pattern-of-Life filter has been applied. Clear the filter to view
            all encounters.
          </template>
        </RocPopper>
      </span>
      <span class="title" v-else>
        Clustering
      </span>

      <MDBDropdown
        v-if="!associatesTargetCluster"
        v-model="isSavedFilterMenuOpen"
      >
        <RocDropdownToggle
          @click="isSavedFilterMenuOpen = !isSavedFilterMenuOpen"
          style="margin-left: var(--spacing-xl)"
        >
          <RocButton
            class="filter-button"
            :type="selectedFilter ? 'primary' : 'white'"
          >
            <div
              style="display: flex; align-items: center; color: var(--overwatch-neutral-100);"
              v-if="!selectedFilter"
            >
              <RocIcon
                color="black"
                size="sm"
                icon="filter"
                style="margin-right: var(--spacing-s)"
              />
              Saved Filters
            </div>
            <div style="display: flex; align-items: center;" v-else>
              <RocIcon
                color="white"
                size="sm"
                icon="filter"
                style="margin-right: var(--spacing-s)"
              />
              {{ selectedFilter.name }}
              <RocIcon
                color="white"
                size="xs"
                icon="exit"
                style="cursor: pointer; margin-left: var(--spacing-s)"
                @click.stop="clearSelectedFilter"
              />
            </div>
          </RocButton>
        </RocDropdownToggle>
        <RocDropdownMenu>
          <RocDropdownItem
            v-if="savedFilters.length === 0"
            @click.stop="isSavedFilterMenuOpen = false"
          >
            No items.
          </RocDropdownItem>
          <RocDropdownItem
            v-for="sf of savedFilters"
            style="cursor: default;"
            @click.stop="setSavedFilter(sf)"
          >
            <div
              style="display: flex; align-items: center; width: 230px; justify-content: space-between; height: 40px;"
            >
              {{ sf.name }}
              <RocIcon
                icon="edit"
                color="primary"
                size="sm"
                style="cursor: pointer;"
                @click.stop="editSavedFilter(sf)"
              />
            </div>
          </RocDropdownItem>
        </RocDropdownMenu>
      </MDBDropdown>

      <ClusteringFilters
        class="dashboard-filters"
        :isPolFilter="props.isPolFilter"
        :isAssociatesMode="!!associatesTargetCluster"
        @sort-change="loadClusters"
        @filter-change="handleFilterChange"
        @text-filter-change="loadClusters"
      />
    </div>

    <div class="clustering-grid" :class="{ nogrid: clusters.length === 0 }">
      <div
        class="cluster-card-wrapper"
        v-for="c in clusters"
        :class="{ isOpen: popperStates[c._id] }"
      >
        <ClusterCard
          :cluster="c"
          :key="c._id"
          @show-associates="showAssociates(c)"
          @enroll-face="enrollFace"
          @enroll-lpr="enrollLPR"
          @popper-open="(isOpen) => setPopperState(c._id, isOpen)"
        />
      </div>
      <div
        v-if="!isLoadingMoreClusters && clusters.length === 0"
        class="no-clusters-found"
      >
        <RocLogo height="300px" />
        There are no clusters to display, try adjusting your search filters.
      </div>
      <div class="spinner-holder" v-observe-visibility="loadMoreClusters">
        <MDBSpinner v-if="isLoadingMoreClusters" />
      </div>
    </div>
  </div>
  <BaseDialog
    :show="isShowingSaveFilterPopup"
    @close="isShowingSaveFilterPopup = false"
    hideCloseBtn
  >
    <SavedClusterFiltersPopup
      @close="isShowingSaveFilterPopup = false"
      :editingFilter="editingFilter"
    />
  </BaseDialog>
  <BaseDialog
    :show="isShowingAssociatesFilterPopup"
    @close="isShowingAssociatesFilterPopup = false"
    hideCloseBtn
  >
    <AssociatesFilterPopup
      :cluster="associatesTargetCluster"
      @close="isShowingAssociatesFilterPopup = false"
      @save-time-range="handleTimeRangeFilterSave"
      @save-same-frame="handleSameFrameFilterSave"
    />
  </BaseDialog>
  <BaseDialog
    :show="isEnrollDialogVisible"
    @close="isEnrollDialogVisible = false"
  >
    <EnrollEncounter
      mode="enroll"
      :faceThumbnail="enrollObject.thumbnail"
      :enrollFacePersonId="enrollObject.personId"
      :firstName="enrollObject.firstName"
      :lastName="enrollObject.lastName"
      :templateId="enrollObject.templateId"
    />
  </BaseDialog>
  <BaseDialog
    :show="isLPREnrollDialogVisible"
    @close="isLPREnrollDialogVisible = false"
  >
    <EnrollEncounter
      mode="enroll"
      :faceThumbnail="enrollObject.thumbnail"
      :elementWatchlistType="enrollObject.watchlistType"
      :lprText="enrollObject.text"
      :lprState="enrollObject.state"
    />
  </BaseDialog>
</template>

<style scoped lang="scss">
.clustering-box {
  flex: 1;
  margin-right: var(--spacing-m);
  display: flex;
  flex-direction: column;
  height: 100%;

  .title-and-filters {
    display: flex;

    margin-bottom: $--spacing-s;
    align-items: center;

    .dashboard-filters {
      margin-left: auto;
    }

    .title {
      @include overwatch-title-large;
    }
  }

  .clustering-grid {
    //changed grid display to flex to keep spacing consistent and to create responsive sizing - Brittany
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    gap: $--spacing-s;
    height: 100%;
    overflow-y: auto;
  }

  .clustering-grid.nogrid {
    display: block;
    height: 300px;

    .no-clusters-found {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      align-items: center;
      justify-content: center;
      gap: var(--spacing-l);
    }
  }

  // Sean: Originally, borders were being set at ClusterCard.vue level.
  // However, I moved these borders out here to allow border to be
  // considered by the flex box gaps.
  // We could do a border transparent trick in ClusterCard.vue level,
  // but the transparent color is incorrect and messes with box-shadow.
  .cluster-card-wrapper {
    border-radius: 7px;
    border: solid 2px transparent;
  }
  .cluster-card-wrapper.isOpen {
    border: solid 2px var(--overwatch-primary);
  }
}
</style>
