<script setup>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import userAccessHelper from "@/js/userAccessHelper";
import BaseDropdown from "@/components/ui/BaseDropdown.vue";
import RocDropdownMenu from "@/components/ui/RocDropdownMenu.vue";
import RocIcon from "@/components/ui/RocIcon.vue";
import RocPopper from "@/components/ui/RocPopper.vue";

import { MDBDropdownItem, MDBDropdownToggle } from "mdb-vue-ui-kit";

const emit = defineEmits(["close"]);

const store = useStore();

const isMobile = computed(() => {
  return windowWidth.value <= 480;
});

const windowWidth = ref(window.innerWidth);
onMounted(() => {
  window.addEventListener("resize", () => {
    windowWidth.value = window.innerWidth;
  });
});

function isPageAllowed(page) {
  return new userAccessHelper().isPageAllowed(page);
}

const isLiveActiveMission = computed(() => {
  const mission = store.getters["cases/activeMission"];
  return mission?.type === "live";
});

const isDataSourcesShowing = ref(false);

const isEventButtonEnabled = computed(() => {
  return store.getters["rocenroll/isEventWorkflowEnabled"];
});
const isMissionDisabled = computed(() => {
  return store.getters["settings/isMissionsDisabled"]
});
</script>

<template>
  <div class="navbar-nav">
    <div class="navbar-nav-close" v-if="isMobile">
      <RocIcon
        icon="exit"
        color="black"
        size="sm"
        style="cursor: pointer; margin-left: auto;"
        @click="emit('close')"
      />
    </div>
    <div class="actionItem" v-if="!isMissionDisabled">
      <router-link :to="{ path: '/missions' }" @click="emit('close')">
        {{ $store.state.settings.appTerms.Mission }}
      </router-link>
    </div>
    <div v-if="isPageAllowed('/encounters')" class="actionItem mobile">
      <router-link :to="{ path: '/encounters' }" @click="emit('close')">
        Encounters
      </router-link>
    </div>
    <div v-if="isPageAllowed('/cameras')" class="actionItem">
      <router-link
        :to="{ path: '/cameras' }"
        v-if="isLiveActiveMission"
        @click="emit('close')"
      >
        Cameras
      </router-link>
      <RocPopper arrow hover popperType="tooltip" placement="bottom" v-else>
        <a class="disabled">
          Cameras
        </a>
        <template #content>
          Please select an active Live
          {{ $store.state.settings.appTerms.Mission }} to access camera
          settings.
        </template>
      </RocPopper>
    </div>
    <div class="actionItem mobile">
      <BaseDropdown v-model="isDataSourcesShowing" show>
        <MDBDropdownToggle
          @click="isDataSourcesShowing = !isDataSourcesShowing"
          tag="a"
          class="dropdownToggle"
        >
          Data Sources
          <RocIcon
            class="toggleArrow"
            icon="inputArrow"
            size="xs"
            color="black"
            :style="{
              transform: isDataSourcesShowing
                ? 'rotate(0deg)'
                : 'rotate(180deg)'
            }"
          />
        </MDBDropdownToggle>
        <RocDropdownMenu
          aria-labelledby="dropdownMenuButton"
          class="dropdownMenu"
          @click="isDataSourcesShowing = false"
        >
          <MDBDropdownItem
            v-if="isPageAllowed('/watchlists')"
            router
            :to="{ path: '/watchlists' }"
            @click="emit('close')"
          >
            {{ $store.state.settings.appTerms.Watchlist }}
          </MDBDropdownItem>
          <MDBDropdownItem
            v-if="isPageAllowed('/events') && isEventButtonEnabled"
            router
            :to="{ path: '/events' }"
            @click="emit('close')"
          >
            Events
          </MDBDropdownItem>
          <MDBDropdownItem
            v-if="isPageAllowed('/investigations')"
            router
            :to="{ path: '/investigations' }"
            @click="emit('close')"
          >
            Investigations
          </MDBDropdownItem>
        </RocDropdownMenu>
      </BaseDropdown>
    </div>
  </div>
</template>

<style scoped lang="scss">
.navbar-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: var(--spacing-xl);
  gap: var(--spacing-xl);
}

.actionItemDropdown {
  border: none !important;
}

.dropdownMenu {
  box-shadow: 4px 4px 15px 4px rgba(0, 19, 58, 0.3);
}

.dropdownMenu li {
  cursor: pointer;
}

.dropdown-toggle:after {
  display: none;
}

.dropdownToggle {
  color: var(--overwatch-neutral-100);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  display: flex;
  align-items: center;
  gap: 6px;
}
.actionItem {
  @include overwatch-body-med;

  display: flex;
  align-items: center;

  height: 100%;
  margin: 0;
  list-style-type: none;
  cursor: pointer;
}

.actionItem a {
  color: var(--overwatch-neutral-100);
  user-select: none;
}

.toggleArrow {
  transition: transform 0.3s;
}

a {
  font-weight: unset;
}

.disabled {
  opacity: 0.5;
}

.navbar-nav-close {
  width: 100%;
  padding-top: var(--spacing-l);
  padding-right: var(--spacing-m);
  display: flex;
}

/* MOBILE */
@media (max-width: 480px) {
  .navbar-nav {
    flex-direction: column;
    background-color: var(--overwatch-background);
    margin-left: 0;
    justify-content: flex-start;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 101;
    gap: var(--spacing-xl);
    height: 100dvh;
    width: 100%;
  }

  .actionItem {
    height: var(--spacing-xl);
  }

  .dropdownMenu {
    background-color: var(--overwatch-background);
    box-shadow: none;
  }

  .dropdownMenu li {
    padding-top: var(--spacing-m);
  }
}
</style>
