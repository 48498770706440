<template>
  <svg :height="size" :width="size" :viewbox="`0 0 ${size} ${size}`">
    <circle
      class="foreground"
      :cx="origin"
      :cy="origin"
      :r="radius"
      :stroke-width="2"
      :style="
        `
        transform-origin: ${origin}px ${origin}px;
        stroke-dasharray: ${filledCircumference} ${circumference -
          filledCircumference};

      `
      "
    />
    <slot />
  </svg>
</template>

<script>
import { computed } from "vue";

export default {
  name: "ProgressCircle",
  props: {
    size: {
      type: Number,
      required: true
    },
    value: {
      type: Number,
      required: true
    }
  },
  setup(props, context) {
    const origin = computed(() => {
      return props.size / 2;
    });

    const radius = computed(() => {
      return (props.size - 10) / 2;
    });

    /**
     * Filled circumference is the portion of circumference that is filled.
     */
    const circumference = computed(() => {
      return radius.value * 2 * Math.PI;
    });

    const propValue = computed(() => {
      return props.value / 100;
    });

    const filledCircumference = computed(() => {
      return radius.value * 2 * Math.PI * propValue.value;
    });

    return {
      origin,
      radius,
      circumference,
      filledCircumference
    };
  }
};
</script>

<style scoped lang="scss">
.foreground {
  fill-opacity: 0;
  stroke: var(--roc-global-light-button-primary);
  transform: rotate(-90deg);
  transition: stroke-dasharray 0.3s;
}
</style>
