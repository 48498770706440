<template>
  <div>
    <div class="description">
      <slot></slot>
    </div>

    <div class="buttons">
      <RocButton @click="close" type="secondary" style="width: 125px;"
        >{{ cancelMessage }}
      </RocButton>
      <RocButton @click="deleteUser" type="primary" style="width: 125px;"
        >{{ deleteMessage }}
      </RocButton>
      <RocButton v-if="includeDisable" @click="disableUser"
        >Disable Instead
      </RocButton>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import RocButton from "../ui/RocButton.vue";

export default {
  props: {
    message: String,
    includeDisable: Boolean,
    cancelMessage: String
  },
  emits: ["delete", "disable", "close"],
  components: {
    RocButton
  },
  setup(props, context) {
    const cancelMessage = computed(() => {
      if (props.cancelMessage) {
        return props.cancelMessage;
      } else {
        return "Cancel";
      }
    });

    const deleteMessage = computed(() => {
      if (props.message) {
        return props.message;
      } else {
        return "Delete Permanently";
      }
    });

    function close() {
      context.emit("close");
    }

    function deleteUser() {
      context.emit("delete");
      context.emit("close");
    }

    function disableUser() {
      context.emit("disable");
      context.emit("close");
    }

    return {
      deleteUser,
      disableUser,
      deleteMessage,
      close,
      cancelMessage
    };
  }
};
</script>

<style scoped lang="scss">
* {
  box-sizing: border-box;
}

.description {
  @include overwatch-body-large;
}

.buttons {
  @include overwatch-body-small;
}

.buttons {
  display: flex;
  gap: var(--spacing-s);
  justify-content: center;
}

@media (max-width: 480px) {
  .buttons {
    display: flex;
  }
  .buttons button {
    width: 0;
    flex: 1;
  }
}
</style>
