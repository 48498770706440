<template>
  <div
    v-if="additionalInfo && additionalInfo.length > 0"
    style="max-width: 100%;"
  >
    <div
      v-if="!hideTitle"
      class="info-label-small overwatch-body-med"
      :style="
        useTableStyle
          ? 'text-transform: uppercase; color: gray; margin-bottom: 0.5rem;'
          : null
      "
    >
      Additional Info
    </div>
    <div
      class="d-flex flex-column"
      style="max-height: 250px; overflow-y: auto;"
    >
      <div
        v-for="(item, index) in additionalInfo"
        :key="index"
        style="padding-top: 0.5rem; max-width: 100%; white-space: normal;"
      >
        <div
          class="info-value"
          :style="
            useTableStyle && !(index % 2)
              ? 'background-color: lightgray;'
              : null
          "
        >
          <span v-if="item.label && !hideLabel" style="margin-right: 1rem;">{{
            `${item.label}:`
          }}</span>
          <a
            v-if="item.type === 'url'"
            class="infoText"
            :href="item.value"
            target="_blank"
            rel="noopener noreferrer"
            >{{ item.value }}</a
          >
          <span v-else-if="item.type === 'int'" class="infoText">{{
            parseInt(item.value)
          }}</span>
          <span v-else-if="item.type === 'number'" class="infoText">{{
            parseFloat(item.value).toFixed(2)
          }}</span>
          <span v-else-if="item.type === 'date'" class="infoText">{{
            new Date(item.value).toLocaleString()
          }}</span>
          <span v-else class="infoText">{{ item.value }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { get as lodashGet } from "lodash";

export default {
  name: "WatchlistedFaceAdditionalInfo",
  props: {
    watchlistedFace: Object,
    hideTitle: {
      type: Boolean,
      default: false
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    useTableStyle: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  setup(props, context) {
    const store = useStore();

    const additionalInfo = computed(() => {
      const additionalInfoArray = [];
      const fields = store.getters["settings/watchlistAdditionalInfoFields"];
      if (fields && fields.length > 0) {
        for (let i = 0; i < fields.length; i++) {
          let propertyValue = lodashGet(
            props.watchlistedFace,
            fields[i].propertyPath,
            null
          );
          if (propertyValue !== null) {
            if (typeof propertyValue === "object") {
              // for object just stringify, its not user friendly, but its better than '[Object object]' being displayed
              propertyValue = JSON.stringify(propertyValue);
            }
            additionalInfoArray.push({
              type: fields[i].type,
              label: fields[i].label,
              value: propertyValue
            });
          }
        }
      }
      return additionalInfoArray;
    });

    return {
      additionalInfo
    };
  }
};
</script>

<style scoped lang="scss">
.info-label-small {
  width: 100%;
  color: var(--overwatch-neutral-100);
  @include overwatch-body-med;
  line-height: 18px;
}

.infoText {
  overflow-wrap: break-word;
  white-space: initial;
}

.info-value {
  font-size: 14px;
  padding: 0;
  max-width: 100%;
  white-space: normal;
}

/* MOBILE */
@media (max-width: 480px) {
  .info-label-small {
    font-size: 14px;
  }

  .info-value {
    font-size: 12px;
  }
}
</style>
