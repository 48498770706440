<script setup>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import RocInput from "@/components/ui/RocInput";
import RocTextArea from "@/components/ui/RocTextArea.vue";
import WatchlistColorPicker from "@/components/watchlists/WatchlistColorPicker";
import filterMultiSelect from "@/components/ui/filterMultiSelect";
import RocButton from "@/components/ui/RocButton";
import WatchlistedLicensePlateCRUD from "@/components/watchlists/WatchlistedLicensePlateCRUD";
import WatchlistFileSelector from "./WatchlistFileSelector.vue";
import RocCheckbox from "@/components/ui/RocCheckbox";
import { MDBProgress, MDBProgressBar } from "mdb-vue-ui-kit";
import RocIcon from "@/components/ui/RocIcon.vue";
import EditWatchlistPermissions from "@/components/watchlists/EditWatchlistPermissions.vue";
import RocSingleAudioSelect from "@/components/ui/RocSingleAudioSelect.vue"

const emits = defineEmits(["newEnrollment", "close"]);
const store = useStore();
const watchlistName = ref("");
const watchlistDetails = ref("");
const watchlistColor = ref("#FCFCFC");
const selectedUserGroupIds = ref([]);
const enrollmentPreviews = ref([]);
const enrollmentDetails = ref("");
const reverseMatch = ref(false);
const isEnrolling = ref(false);
const totalFilesSelected = ref(0);
const enrollmentProgress = ref(0);
const enrollmentResults = ref([]);
const canCreate = ref(false);
const vehicles = ref([]);
const vehicleSpinner = ref(false);
const vehicleEnrollmentErr = ref(undefined);
const imageObjects = ref([]); //Todo make this not a ref. backend issue.
const imgPages = ref(0);
const enableEnrollment = ref(true);
const playAudio = ref(true);
const audioFile = ref(null);
const audioEndpoint = ref();

const showEditPermissions = ref(false);
const userAccessList = ref([]);
const restricted = ref(false);
const editPermissionsDialog = ref();

const mode = computed(() => {
  return store.getters["watchlists/importerMode"];
});
const id = computed(() => {
  return store.getters["watchlists/importerWatchlistId"];
});
const watchlistType = computed(function() {
  return store.getters["watchlists/importerType"];
});
const watchlistTitle = computed(() =>
  watchlistType.value === "text" ? "Plate" : getTitle(watchlistType.value)
);

const watchlistAudioId = computed(() => {

  watchlistType.value === "text" ? 'license_plate' : watchlistType.value
});

const allUserGroups = computed(() => {
  return store.getters["settings/userGroups"];
});

watch(allUserGroups, () => {
  initUserGroups();
});

watch(watchlistTitle, (nv) => {
  reset(false);
});

function initUserGroups() {
  try {
    selectedUserGroupIds.value = [];
    // iterate available usergroups to check if selected or readonly
    for (let i = 0; i < allUserGroups.value.length; i++) {
      allUserGroups.value[i].value = allUserGroups.value[i]._id;
      // if usergroup is system and user not in system group, mark it disabled
      allUserGroups.value[i].disabled = !!(
        allUserGroups.value[i].isSysAdmin &&
        !userGroupContainsCurrentUser(allUserGroups.value[i])
      );
      if (mode.value === "create") {
        // if creating, auto select all available usergroups
        selectedUserGroupIds.value.push(allUserGroups.value[i].value);
      }
    }
  } catch (err) {
    console.log(err);
  }
}

function getTitle(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function userGroupContainsCurrentUser(userGroup) {
  return userGroup.users.find((user) => {
    if (user.email === store.getters["auth/email"]) {
      return true;
    }
  });
}

watch([watchlistName, selectedUserGroupIds], () => {
  canCreate.value = !!(
    watchlistName.value && selectedUserGroupIds.value.length > 0
  );
});

watch(
  [imageObjects, vehicles, canCreate],
  () => {
    enableEnrollment.value = !(
      (imageObjects.value?.length > 0 || vehicles.value?.length > 0) &&
      (canCreate.value || mode.value !== "create")
    );
  },
  { deep: true }
);

watch(enrollmentResults.value, () => {
  if (enrollmentResults.value.length) {
    emits("newEnrollment", enrollmentResults.value.pop());
  }
});

function reset(handleClose) {
  store.commit("watchlists/setNoFiles", true);
  watchlistName.value = "";
  watchlistDetails.value = "";
  enrollmentDetails.value = "";
  audioFile.value = undefined;
  vehicleEnrollmentErr.value = undefined;
  isEnrolling.value = false;
  enrollmentPreviews.value = [];
  vehicles.value = [];
  imageObjects.value = [];
  imgPages.value = 0;
  enrollmentProgress.value = 0;
  if (mode.value === "create" && handleClose) {
    hideComponent();
  } else if (handleClose) {
    editPermissionsDialog.value.clear();
    emits("close");
    store.dispatch("watchlists/toggleWatchlistImporter", false);
  }
}

function hideComponent() {
  store.dispatch("watchlists/toggleWatchlistImporter", false);
}

function closeComponent() {
  reset(true);
}

function updateSelectedUserGroups(selectedUserGroups) {
  selectedUserGroupIds.value = selectedUserGroups.value;
}

function setWatchlistColor(colorCode) {
  watchlistColor.value = colorCode;
}

//handles changes to faces/tattoo objects
function imageEnrollment(obj) {
  imageObjects.value = obj.files;
  imgPages.value = obj.page;
}

//handles plates enrollments.deletes etc..
function plateEnrollment(obj) {
  vehicles.value = obj;
}

function getPayload() {
  return {
    name: watchlistName.value,
    description: watchlistDetails.value,
    enabled: true,
    type: watchlistType.value,
    color: watchlistColor.value,
    userGroups: selectedUserGroupIds.value,
    editPermissions: [...userAccessList.value],
    restricted: restricted.value,
    audibleAlarmEnabled: playAudio.value,
  }; 
}

/**
* @async
* @function handleAudioUpload
* @param {string} id
* @return {Promise<undefined>}
*/
async function handleAudioUpload(id){
    if(id === undefined) return;
    if(!audioFile.value) return;
    const response = await store.dispatch("settings/uploadAlarm", {
        file: audioFile.value,
        audioId: id,
    });
    audioEndpoint.value = response?.result;
}

async function handleEmptyEnroll() {
  try {
    await store.dispatch("watchlists/createWatchlist", { info: getPayload() }).then(res => handleAudioUpload(res?.watchlist?._id));
    store.commit("watchlists/setEncounterFilterSelectedWatchlists", []);
    reset(true);
  } catch (error) {
    console.error("Something went wrong!" + error);
  }
}

async function handleVehicleEnrollment() {
  isEnrolling.value = true;
  try {
    if (mode.value === "create") {
      await store.dispatch("watchlists/createWatchlist", {
        info: getPayload(),
        licensePlates: vehicles.value
      }).then(res => handleAudioUpload(res?.watchlist?._id));
      store.commit("watchlists/setEncounterFilterSelectedWatchlists", []);
    } else {
      await store.dispatch("watchlists/enrollPlates", {
        watchlistId: id.value,
        licensePlates: vehicles.value
      });
      store.commit("watchlists/setEncounterFilterSelectedWatchlists", []);
    }
  } catch (err) {
    console.error(err);
  }
}

async function handleEnrollment() {
  for (let i = 0; i < imageObjects.value.length; i++) {
    if (!imageObjects.value[i].notes && enrollmentDetails.value) {
      imageObjects.value[i].notes = enrollmentDetails.value;
    }
  }
  imgPages.value = 1;
  totalFilesSelected.value = imageObjects.value.length;
  isEnrolling.value = true;
  try {
    if (mode.value === "create") {
      await store.dispatch("watchlists/createWatchlist", {
        info: getPayload(),
        files: imageObjects,
        progress: enrollmentProgress,
        enrollmentPreviews: enrollmentPreviews,
        page: imgPages,
        reverseMatch: reverseMatch.value
      }).then(res => handleAudioUpload(res?.watchlist?._id));
      store.commit("watchlists/setEncounterFilterSelectedWatchlists", []);
    } else {
      const dispatchType =
        watchlistType.value === "face"
          ? "watchlists/enrollFacesToWatchlist"
          : "watchlists/enrollTattoosToWatchlist";
      await store.dispatch(dispatchType, {
        watchlistId: id.value,
        files: imageObjects,
        progress: enrollmentProgress,
        enrollmentPreviews: enrollmentPreviews,
        page: imgPages,
        enrollmentResults: enrollmentResults,
        reverseMatch: reverseMatch.value
      });
    }
  } catch (err) {
    console.error(err);
  }
}

function handleEditWatchlistClose(user) {
  showEditPermissions.value = false;
}

function handleCustomAudio(file){
  audioFile.value = file;
  playAudio.value = true; 
}

</script>

<template>
  <div>
  <EditWatchlistPermissions
    ref="editPermissionsDialog"
    :key="watchlistTitle"
    v-show="showEditPermissions"
    v-model:userAccessList="userAccessList"
    v-model:restricted="restricted"
    @close="handleEditWatchlistClose"
    id="watchlistEditPermissions"
  />
  <div
    v-show="!showEditPermissions"
    class="watchlistAddEdit"
    id="watchlistAddEdit"
  >
    <div class="titleGroup stick-to-top">
      Add {{ watchlistTitle }}s
      <div
        v-if="!isEnrolling"
        class="editPermissions"
        @click="showEditPermissions = true"
      >
        <RocIcon
          icon="lock"
          size="sm"
          style="color: var(--overwatch-primary)"
        />
        Edit Permissions
      </div>
      <RocButton
        type="tertiary"
        floating
        style="box-shadow: none !important"
        @click="hideComponent"
      >
        <RocIcon icon="minimize" size="sm" />
      </RocButton>
    </div>
    <div class="watchlist-details" v-if="!isEnrolling">
      <div v-if="mode == 'create'">
        <div style="flex: 1">
          <div class="inputTitle">Name</div>
          <RocInput
            :placeholder="`Enter a name for the ${store.state.settings.appTerms.Watchlist}`"
            v-model.trim="watchlistName"
            :maxLength="60"
          />
          <div class="fieldCounter">{{ watchlistName?.length }} / {{ 60 }}</div>
        </div>
        <div>
          <div class="inputTitle">{{store.state.settings.appTerms.Watchlist}} Description</div>
          <RocTextArea
            placeholder="Add notes for all enrolled items"
            rows="3"
            v-model.trim="watchlistDetails"
          />
        </div>
        <div>
          <div class="inputTitle">{{store.state.settings.appTerms.Watchlist}} Color</div>
          <WatchlistColorPicker
            :showPicker="true"
            :selectedColor="watchlistColor"
            @color-picked="setWatchlistColor"
            @showing-picker="true"
          />
        </div>
        <div>
          <div class="inputTitle">User Groups</div>
          <filterMultiSelect
            mode="tags"
            :close-on-select="false"
            placeholder-text="Select User Groups"
            :available-items="allUserGroups"
            :currently-selected="selectedUserGroupIds"
            @selection-changed="updateSelectedUserGroups"
            :enableClear="false"
          />
        </div>
        <div
        class="d-flex justify-content-between align-content-center"
        style="padding-top: var(--spacing-xl)"
      >
        <div
          class="d-flex align-items-center"
          style="gap: var(--spacing-m)"
        >
          <RocCheckbox
            v-model="playAudio"
            :formCheck="false"
          />
          <div>Play Alert Sound</div>
        </div>
        <div style="width: 30%;"> 
          <RocSingleAudioSelect
            :id="watchlistAudioId"
            :audioEndpoint="audioEndpoint"
            @fileSelected="handleCustomAudio"
          />
        </div>
      </div>
      </div>
      <div
        class="d-flex justify-content-between align-content-center"
        style="padding-top: var(--spacing-m)"
      >
        <div
          v-if="watchlistType !== 'text'"
          class="d-flex align-items-center"
          style="gap: var(--spacing-m)"
        >
          <RocCheckbox
            ref="reverseMatchCheckbox"
            v-model="reverseMatch"
            :formCheck="false"
          />
          <div>Enable Reverse Match</div>
        </div>
        <RocButton
          v-if="mode == 'create'"
          :disabled="!canCreate"
          type="primary"
          @click="handleEmptyEnroll()"
        >
          Create Without Content
        </RocButton>
      </div>
      <div v-if="watchlistType !== 'text'">
        <div class="inputTitle">Enrollment Details</div>
        <RocTextArea
          placeholder="Add notes for all enrolled faces"
          rows="3"
          v-model.trim="enrollmentDetails"
        />
      </div>
    </div>
    <div
      v-else-if="watchlistType !== 'text'"
      class="d-flex justify-content-center"
    >
      <div style="width: 94.5%">
        <auth-img
          v-for="enrollment in enrollmentPreviews"
          :key="enrollment.id"
          :src="enrollment.url"
          style="max-height: 55px; width: auto"
        />
        <MDBProgress :height="20" style="margin-top: var(--spacing-base)">
          <MDBProgressBar :value="enrollmentProgress" :max="totalFilesSelected">
            {{ Math.round((enrollmentProgress / totalFilesSelected) * 100) }}%
          </MDBProgressBar>
        </MDBProgress>
      </div>
    </div>
    <hr />
    <div>
      <div class="watchlistUpload">
        <div v-if="watchlistType == 'text'">
          <watchlistedLicensePlateCRUD
            mode="adder"
            @handleChange="plateEnrollment"
            :vehicles="vehicles"
            :showSpinner="vehicleSpinner"
            :isEnrolling="isEnrolling"
            :errMessage="vehicleEnrollmentErr"
          />
        </div>
        <div class="watchlistUpload" v-else>
          <WatchlistFileSelector @enroll="imageEnrollment" style="flex: 1" />
        </div>
      </div>
      <div
        class="d-flex justify-content-end"
        style="gap: var(--spacing-m); padding-top: var(--spacing-m)"
      >
        <RocButton type="secondary" @click="closeComponent">Close</RocButton>
        <RocButton
          v-if="!isEnrolling"
          :disabled="enableEnrollment"
          type="primary"
          @click="
            watchlistType == 'text'
              ? handleVehicleEnrollment()
              : handleEnrollment()
          "
          >Start Enrollment</RocButton
        >
      </div>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.pill {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 48px;
  border: 1px solid var(--overwatch-success);
  background-color: var(--overwatch-success-20);
  height: 26px;
  gap: 4px;
  width: 82px;
}

.nameCircle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  font-size: 14px;
  color: var(--overwatch-button-text);
  background: var(--overwatch-primary);

  padding-left: 0;
  padding-right: 0;
  box-shadow: none;
}

.watchlist-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include overwatch-body-med;
  gap: var(--spacing-l);
}

.fieldCounter {
  display: flex;
  justify-content: end;
  color: var(--overwatch-neutral-300);
  font-size: 12px;
  margin-bottom: 0;
}

.inputTitle {
  @include overwatch-body-large;
  margin-bottom: 4px;
  margin-top: 12px;
  text-align: start;
}

.buttonGroups {
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-m);
}

.titleGroup {
  background: var(--overwatch-secondary);
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: -2%;
}

.watchlistAddEdit {
  overflow: auto;
  position: absolute;
  min-width: 750px;
  max-height: 91vh;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  z-index: 2;
  border: 1px solid var(--overwatch-neutral-300);
  background: var(--overwatch-secondary);
  padding: 12px;
}

.watchlistUpload {
  min-height: 200px;
  display: grid;
}

.editPermissions {
  gap: var(--spacing-xs);
  color: var(--overwatch-primary);
  text-decoration: underline;
  align-items: center;
  cursor: pointer;
}

@media (max-width: 480px) {
  .watchlistAddEdit {
    min-width: 480px;
  }
}
</style>
