<template>
  <div class="dialog-wrapper">
    <div
      class="modality-row"
      v-for="(modality, index) of modalities"
      :style="index === 0 ? 'margin-top: 0;' : null"
    >
      <div class="outer-circle">
        <div
          class="inner-circle"
          :class="{
            person: modality === 'Person Detected',
            face: modality === 'Face Match',
            weapon: modality === 'Weapon Detected',
            license: modality === 'License Plate Detected',
            vehicle: modality === 'Vehicle Detected',
            nomatch: modality === 'No Match Found'
          }"
        ></div>
      </div>
      <div>{{ modality }}</div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "ModalityColorIndex",
  setup(props, context) {
    const modalities = ref([
      "Person Detected",
      "Face Match",
      "Weapon Detected",
      "License Plate Detected",
      "Vehicle Detected",
      "No Match Found"
    ]);

    return {
      modalities
    };
  }
};
</script>

<style scoped lang="scss">
.dialog-wrapper {
  width: 350px;
  color: var(--overwatch-neutral-100);
}

.modality-row {
  @include overwatch-body-med;
  display: flex;
  align-items: center;

  gap: $--spacing-m;
  margin-top: $--spacing-s;
}

.outer-circle {
  border-radius: 50%;

  height: 30px;
  width: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
}

.inner-circle {
  border-radius: 50%;

  height: 25px;
  width: 25px;
}

.person {
  background-color: var(--modality-color-person);
}

.face {
  background-color: var(--modality-color-face);
}

.weapon {
  background-color: var(--modality-color-weapon);
}

.license {
  background-color: var(--modality-color-license);
}

.vehicle {
  background-color: var(--modality-color-vehicle);
}

.nomatch {
  background-color: var(--modality-color-no-match);
}
</style>
