<template>
  <div
    class="button"
    :class="{
      unset: adjudicationState === 'unset' || isShowingSelectionButtons,
      confirm: adjudicationState === 'confirmed',
      reject: adjudicationState === 'rejected'
    }"
    @click="handleMainButtonClick"
  >
    <transition name="iconFade" mode="out-in">
      <RocIcon
        key="1"
        v-if="isShowingSelectionButtons"
        class="main-icon"
        color="white"
        icon="exit"
        size="sm"
      />
      <RocIcon
        key="2"
        v-else-if="
          !isShowingSelectionButtons &&
            (adjudicationState === 'unset' || adjudicationState === 'confirmed')
        "
        class="main-icon"
        color="white"
        icon="thumbsUp"
      />
      <RocIcon
        key="3"
        v-else-if="
          !isShowingSelectionButtons && adjudicationState === 'rejected'
        "
        class="main-icon"
        color="white"
        icon="thumbsUp"
        flip
      />
    </transition>
    <div
      class="selection-buttons hidden"
      :class="{
        showing: isShowingSelectionButtons
      }"
    >
      <div class="button confirm" @click.stop="handleConfirmButtonClick">
        <RocIcon color="white" icon="thumbsUp" />
      </div>
      <div class="button reject" @click.stop="handleRejectButtonClick">
        <RocIcon color="white" icon="thumbsUp" flip />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import RocIcon from "@/components/ui/RocIcon.vue";

export default {
  name: "AdjudicationButtons",
  components: {
    RocIcon
  },
  props: {
    adjudication: {
      type: String,
      default: "unset",
      validator(value) {
        return ["unset", "confirmed", "rejected"].includes(value);
      }
    }
  },
  emits: ["change"],
  setup(props, context) {
    /* 'confirmed', 'rejected', 'unset'*/
    const adjudicationState = ref(props.adjudication);

    const isShowingSelectionButtons = ref(false);

    function handleMainButtonClick() {
      if (
        adjudicationState.value === "confirmed" ||
        adjudicationState.value === "rejected"
      ) {
        adjudicationState.value = "unset";
      } else {
        if (isShowingSelectionButtons.value) {
          isShowingSelectionButtons.value = false;
        } else {
          isShowingSelectionButtons.value = true;
        }
      }
    }

    function handleConfirmButtonClick() {
      isShowingSelectionButtons.value = false;
      adjudicationState.value = "confirmed";
    }

    function handleRejectButtonClick() {
      isShowingSelectionButtons.value = false;
      adjudicationState.value = "rejected";
    }

    watch(adjudicationState, (nv) => {
      context.emit("change", nv);
    });

    return {
      adjudicationState,
      handleMainButtonClick,
      isShowingSelectionButtons,
      handleConfirmButtonClick,
      handleRejectButtonClick
    };
  }
};
</script>

<style scoped>
.button {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  position: relative;

  transition: background-color 150ms;
}

.confirm {
  background-color: var(--overwatch-success);
}

.reject {
  background-color: var(--overwatch-error);
}

.unset {
  background-color: var(--overwatch-neutral-300);
}

.selection-buttons {
  display: flex;
  gap: var(--spacing-s);
  position: absolute;
  top: -40px;
  z-index: 100;

  visibility: hidden;
  opacity: 0;
  transition: opacity 150ms, visibility 150ms;
}

.showing {
  visibility: visible;
  opacity: 1;
}

.iconFade-enter-active,
.iconFade-leave-active {
  transition: opacity 150ms ease;
}

.iconFade-enter-from,
.iconFade-leave-to {
  opacity: 0;
}

@media (max-width: 480px) {
  /* leaving this is since it might have a use
  opens the buttons to the left of the button stacked on top of each other */
  /* .selection-buttons{
    right: 40px;
    top: -25px;
    display: flex;
    flex-direction: column;
    z-index: 100;
  } */
}
</style>
