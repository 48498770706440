<template>
  <div>
    <span class="filterBoxTitle overwatch-body-large">{{ name }}</span>
    <Multiselect
      :mode="mode"
      :placeholder="placeholderText"
      :close-on-select="closeOnSelect"
      :filter-results="true"
      :min-chars="1"
      :resolve-on-load="false"
      :searchable="true"
      :noResultsText="noResultsText"
      v-model="selectedItems"
      :options="availableItems"
      :style="dynamicStyle"
      :groups="groupsEnabled"
      :groupSelect="groupSelect"
      :disabled="disabled"
      :canClear="enableClear"
      @open="toggleActive('open')"
      @close="toggleActive('close')"
      ref="multiselectRef"
      :createTag="createTags"
      :createOption="createTags"
      @focus.stop.capture
      :id="fetchID()"
    >
      <!-- this template maintains default styling but allows a selected tag to be readonly if marked as disabled -->
      <template v-slot:tag="{ option, handleTagRemove }">
        <div>
          <div
            class="multiselect-tag is-user"
            :class="{ 'is-disabled': option.disabled || disabled }"
          >
            <img :src="option.image" />
            <span
              class="overwatch-body-med"
              style="max-width: 25ch; overflow: hidden; text-overflow: ellipsis;"
              :title="
                option.label && option.label.length > 22 ? option.label : null
              "
            >
              {{ option.label }}
            </span>
            <span
              v-if="!option.disabled && !disabled"
              class="multiselect-tag-remove"
              style="cursor: pointer;"
              @click="handleTagRemove(option, $event)"
            >
              <span class="multiselect-tag-remove-icon"></span>
            </span>
          </div>
        </div>
      </template>

      <!-- v-if's have to be in template for default caret to show in other instances of multi-select -->
      <!-- the v-slot calls are for custom additions to the multi-select -->
      <template v-slot:caret>
        <div
          @mousedown.prevent.stop="enableSelf()"
          style="display: flex; align-items: center"
        >
          <div v-if="isError">
            <RocIcon color="red" size="md" icon="error" />
          </div>
          <!-- big button + carets on watchlist/cameras/analyics -->
          <div v-if="encounterCamMode || encounterWatchlistMode">
            <RocButton
              type="secondary"
              size="tiny"
              style="height: 140px; border-radius: 0 5px 5px 0; margin: 0; display: flex; align-items: center; justify-content: center; box-shadow: none"
            >
              <RocIcon
                size="sm"
                color="white"
                icon="downArrow"
                :style="rotateCaret"
              />
            </RocButton>
          </div>
          <!-- just a caret for inputs -->
          <div v-else style="margin: var(--spacing-s);">
            <RocIcon
              size="sm"
              color="black"
              icon="downArrow"
              :style="rotateCaret"
            />
          </div>
        </div>
      </template>
      <template v-slot:clear>
        <div v-if="enableClear && !encounterCamMode && !encounterWatchlistMode">
          <RocIcon
            color="black"
            size="xs"
            icon="exit"
            @click="clearOptions = true"
          />
        </div>
      </template>
    </Multiselect>
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import { ref, watch, computed, nextTick } from "vue";
import RocButton from "./RocButton.vue";
import RocIcon from "./RocIcon.vue";

export default {
  name: "filterMultiSelect",
  emits: ["selection-changed"],
  props: {
    availableItems: Array,
    currentlySelected: {
      type: [Array, String]
    },
    createTags: {
      type: Boolean,
      default: false
    },
    name: String,
    noResultsText: {
      type: String,
      default: "No results found"
    },
    placeholderText: String,
    groupsEnabled: Boolean,
    disabled: Boolean,
    mode: {
      type: String,
      default: "tags"
    },
    closeOnSelect: {
      type: Boolean,
      default: true
    },
    encounterTagsMode: {
      type: Boolean,
      default: false
    },
    watchlistEnrollMode: {
      type: Boolean,
      default: false
    },
    encounterWatchlistMode: {
      type: Boolean,
      default: false
    },
    encounterCamMode: {
      type: Boolean,
      default: false
    },
    enableClear: {
      type: Boolean,
      default: true
    },
    clearOptions: {
      type: Boolean,
      default: false
    },
    selectAllOptions: {
      type: Boolean,
      default: false
    },
    showError: {
      type: Boolean,
      default: false
    },
    groupSelect: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Multiselect,
    RocButton,
    RocIcon
  },
  setup(props, context) {
    const selectedItems = ref(props.currentlySelected);
    const isError = ref(props.showError);

    watch(
      () => props.currentlySelected,
      (nv) => {
        selectedItems.value = nv;
      }
    );

    watch(
      () => props.showError,
      (nv) => {
        isError.value = nv;
      }
    );

    watch(selectedItems, () => {
      context.emit("selection-changed", selectedItems);
    });

    const multiselectRef = ref(null);
    const isEnabled = ref(false);
    function enableSelf() {
      isEnabled.value = !isEnabled.value;
      nextTick(() => {
        if (isEnabled.value) {
          multiselectRef.value.open();
          multiselectRef.value.focus();
        } else {
          multiselectRef.value.close();
        }
      });
    }

    const selectAllOptions = ref(false);
    watch(
      () => props.selectAllOptions,
      () => {
        selectAllOptions.value = props.selectAllOptions;
        if (selectAllOptions.value === true) {
          multiselectRef.value.selectAll();
          selectAllOptions.value = false;
        }
      }
    );

    const clearOptions = ref(false);
    watch(
      () => props.clearOptions,
      () => {
        clearOptions.value = props.clearOptions;
        if (clearOptions.value) {
          multiselectRef.value.clear();
          clearOptions.value = false;
        }
      }
    );

    //this only affects the direction of the custom carets
    function toggleActive(state) {
      if (state === "open") {
        isEnabled.value = true;
      }
      if (state === "close") {
        isEnabled.value = false;
      }
    }

    function fetchID() {
      return props.placeholderText
        ? props.placeholderText.replaceAll(" ", "-")
        : "filterMultiSelect";
    }

    const borderColor = computed(() => {
      if (isError.value) {
        return "var(--overwatch-error)";
      } else {
        return "var(--overwatch-neutral-300)";
      }
    });

    const rotateCaret = computed(() => {
      if (isEnabled.value) {
        return {
          transform: "rotate(180deg)",
          transition: "transform 0.2s ease-in-out"
        };
      } else {
        return {
          transform: "rotate(0deg)",
          transition: "transform 0.2s ease-in-out"
        };
      }
    });

    const dynamicStyle = computed(() => {
      // baseStyle matches general input box style around RWW
      // square grey border, white background, fk grotesk font, etc
      const baseStyle = `
                    --ms-bg: var(--overwatch-neutral-500);
                    --ms-tag-bg: var(--overwatch-button-primary-20);
                    --ms-tag-color: var(--overwatch-button-primary);
                    --ms-tag-radius: 9999px;
                    --ms-tag-font-size: 16px;
                    --ms-tag-font-family: """;
                    --ms-ring-color: none;
                    --ms-border-color: ${borderColor.value};
                    --ms-dropdown-bg: var(--overwatch-secondary) !u;
                    --ms-dropdown-border-color: var(--overwatch-neutral-300);
                    --ms-dropdown-border-width: 1px;
                    --ms-dropdown-radius: 0px;
                    --ms-option-font-size: 16px;
                    --ms-spinner-color: var(--overwatch-primary);
                    --ms-option-color-pointed: var(--overwatch-neutral-100);
                    --ms-option-color-selected: var(--overwatch-button-text);
                    --ms-option-bg-selected: var(--overwatch-button-primary);
                    --ms-option-bg-selected-pointed: var(--overwatch-button-primary);
                    --ms-option-bg-pointed: var(--overwatch-button-primary-20);
                    background-color: var(--overwatch-neutral-500);
                    border-radius: 5px;
                    font-size: 16px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: left;
                    color: var(--overwatch-neutral-100);
                    `;
      // tagging multiselect hides border and makes a few other tweaks to
      // fit this dropdown within a larger input box
      if (props.encounterTagsMode) {
        return (
          baseStyle +
          `
                    --ms-spinner-color: var(--overwatch-primary);
                    --ms-option-font-size: 0.85rem;
                    --ms-group-label-line-height: 2;
                    --ms-group-label-bg: var(--overwatch-neutral-300);
                    --ms-group-label-bg-pointed: var(--overwatch-neutral-300);
                    box-sizing: border-box;
                    border-radius: 0px;
                    width: 100%;
                    --ms-border-width: 0px;
                    display: flex;
                    align-items: center;
                    height: auto;
                    margin: 0;
                    font-weight: 500 !important;
                    font-size: 16px;
                    `
        );
      } else if (props.watchlistEnrollMode) {
        return (
          baseStyle +
          `
                --ms-border-color: var(--overwatch-neutral-300);
                `
        );
      } else {
        return (
          baseStyle +
          `
                --ms-max-height: 200px;
                --ms-radius: 5px;
                --ms-group-label-bg-selected: var(--overwatch-button-primary);
                --ms-group-label-bg-selected-pointed: var(--overwatch-button-primary);
                `
        );
      }
    });

    return {
      selectedItems,
      dynamicStyle,
      enableSelf,
      isEnabled,
      multiselectRef,
      toggleActive,
      rotateCaret,
      isError,
      borderColor,
      fetchID
    };
  }
};
</script>

<style src="@vueform/multiselect/themes/default.css">
.filterBoxTitle {
  height: 25px;
  width: 86px;
  color: var(--overwatch-neutral-100);
  line-height: 27px;
}

.multiselect-tag.is-user {
  padding: 5px 8px;
  border-radius: 22px;
  /* TODO - consider deleting color, not sure if this class does anything */
  background: var(--overwatch-button-primary-20);
  margin: 3px 3px 8px;
  border: solid 1px var(--overwatch-button-primary);
}

.multiselect-tag.is-user :deep(.multiselect-tag) {
  border: solid 1px var(--overwatch-button-primary);
}

.multiselect-tag.is-user i:before {
  color: var(--overwatch-button-primary);
  border-radius: 50%;
}
</style>
