<template>
  <div class="disable-select">
    <div
      class="d-flex justify-content-start align-items-start"
      style="width: 100%; padding-bottom: var(--spacing-base);"
    >
      <div
        v-if="!isHidden('firstname')"
        class="d-flex align-items-center"
        style="flex: 1; margin-right: 10px;"
      >
        <RocInput
          :disabled="readonly || isDisabled('firstname')"
          v-model.lazy="firstname"
          :class="'inputAdder'"
          :placeholder="'First Name'"
          type="text"
        />
      </div>
      <div
        v-if="!isHidden('lastname')"
        class="d-flex align-items-center"
        style="flex: 1; margin-right: 10px;"
      >
        <RocInput
          :disabled="readonly || isDisabled('lastname')"
          v-model.lazy="lastname"
          :class="'inputAdder'"
          :placeholder="'Last Name'"
          type="text"
        />
      </div>
      <div
        v-if="!isHidden('email')"
        class="d-flex align-items-center"
        style="flex: 1;  margin-right: 10px;"
      >
        <RocInput
          :disabled="readonly || isDisabled('email')"
          :errorMessage="emailErrorMessage"
          v-model.lazy="email"
          :class="'inputAdder'"
          :placeholder="'Email'"
          type="email"
        />
      </div>
      <div
        v-if="!isHidden('phoneNumber')"
        class="d-flex align-items-center"
        style="flex: 1;"
      >
        <RocInput
          :errorMessage="phoneErrorMessage"
          :disabled="readonly || isDisabled('phoneNumber')"
          v-model.lazy="phoneNumber"
          :class="'inputAdder'"
          :placeholder="'Phone Number'"
          type="tel"
        />
      </div>
    </div>
    <div v-if="!collapsed || mode == 'adder'">
      <div style="width: 100%; margin-top: var(--spacing-xl);">
        <RocTextArea
          :disabled="readonly"
          v-model.lazy="notes"
          :placeholder="mode == 'adder' ? 'Notes' : ''"
          rows="3"
          type="text"
          :class="mode == 'adder' ? 'textAreaAdder' : 'textAreaAdded'"
        >
        </RocTextArea>
      </div>
    </div>
    <div class="d-flex" style="margin-top: 20px;">
      <RocButton
        type="primary"
        @click="isAddable() ? entitlementAdded() : ''"
        :disabled="disableAdd"
        >+ Add
      </RocButton>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import RocInput from "@/components/ui/RocInput.vue";
import RocTextArea from "@/components/ui/RocTextArea.vue";
import RocButton from "@/components/ui/RocButton.vue";

const props = defineProps({
  refIndex: {
    type: Number
  },
  mode: {
    type: String
  },
  firstname: {
    type: String
  },
  lastname: {
    type: String
  },
  email: {
    type: String
  },
  phoneNumber: {
    type: String
  },
  notes: {
    type: String
  },
  readonly: {
    type: Boolean
  }
});

const store = useStore();
const emits = defineEmits(["add"]);
const firstname = ref(props.firstname ? props.firstname : "");
const lastname = ref(props.lastname ? props.lastname : "");
const email = ref(props.email ? props.email : "");
const phoneNumber = ref(props.phoneNumber ? props.phoneNumber : "");
const notes = ref(props.notes ? props.notes : "");
const collapsed = ref(true);
const phoneErrorMessage = ref(undefined);
const emailErrorMessage = ref(undefined);
const disableAdd = ref(!isHidden("phoneNumber") || !isHidden("email"));

const phoneRegex = computed(() => {
  const enrollConfig = store.getters["rocenroll/enrollConfig"];
  return _.get(enrollConfig, "entitlements.phoneRegex", ""); // default to no matching
});
const emailRegex = computed(() => {
  const enrollConfig = store.getters["rocenroll/enrollConfig"];
  return _.get(enrollConfig, "entitlements.emailRegex", ""); // default to no matching
});

watch([phoneNumber, email], () => {
  if (
    (!isHidden("phoneNumber") && testPhoneNumber(phoneNumber.value)) ||
    (!isHidden("email") && testEmail(email.value))
  ) {
    disableAdd.value = false;
  } else disableAdd.value = true;
});

function toJSON() {
  return {
    firstname: firstname.value,
    lastname: lastname.value,
    email: email.value,
    phoneNumber: phoneNumber.value,
    notes: notes.value
  };
}

function entitlementAdded() {
  emits("add", toJSON());

  // Clear values after adding
  firstname.value = "";
  lastname.value = "";
  email.value = "";
  phoneNumber.value = "";
  notes.value = "";
}

function testEmail(value) {
  if (value.trim() === "") return false;

  if (emailRegex.value && emailRegex.value.length > 0) {
    const regexVal = new RegExp(emailRegex.value).test(value);
    if (regexVal) {
      emailErrorMessage.value = "Invalid Email address.";
      return false;
    } else {
      emailErrorMessage.value = undefined;
      return true;
    }
  } else {
    return true;
  }
}

function testPhoneNumber(value) {
  if (value.trim() === "") return false;

  if (phoneRegex.value && phoneRegex.value.length > 0) {
    const regexValue = new RegExp(phoneRegex.value).test(value);
    if (!regexValue) {
      phoneErrorMessage.value = "Invalid Phone Number.";
      return false;
    } else {
      phoneErrorMessage.value = undefined;
      return true;
    }
  } else {
    return true;
  }
}

function isRequired(field) {
  const enrollConfig = store.getters["rocenroll/enrollConfig"];
  const requiredFields = _.get(enrollConfig, "entitlements.requiredFields", []); // default to not required
  return requiredFields.includes(field);
}

function isDisabled(field) {
  const enrollConfig = store.getters["rocenroll/enrollConfig"];
  const disabledFields = _.get(enrollConfig, "entitlements.disabledFields", []); // default to not disabled
  return disabledFields.includes(field);
}

function isHidden(field) {
  const enrollConfig = store.getters["rocenroll/enrollConfig"];
  const hiddenFields = _.get(enrollConfig, "entitlements.hiddenFields", []); // default to not hidden
  return hiddenFields.includes(field);
}

function isAddable() {
  const adderOk = props.mode === "adder";
  const firstnameOk = isRequired("firstname")
    ? firstname.value.length > 0
    : true;
  const lastnameOk = isRequired("lastname") ? lastname.value.length > 0 : true;
  const emailOk = isRequired("email")
    ? email.value.length > 0 && testEmail(email.value)
    : true;
  const phoneNumberOk = isRequired("phoneNumber")
    ? phoneNumber.value.length > 0 && testPhoneNumber(phoneNumber.value)
    : true;
  return adderOk && firstnameOk && lastnameOk && emailOk && phoneNumberOk;
}
</script>

<style scoped>
.disable-select {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.addRemoveBtn {
  font-size: 14px;
  margin-left: 2px;
  margin-left: 10px;
  cursor: pointer;
  width: auto;
}
</style>
