<template>
  <div class="d-flex flex-column justify-content-center overwatch-body-small">
    <div>
      <div>
        <div style="flex: 1; margin-bottom: var(--spacing-m);">
          <div class="inputTitle">Event Name</div>
          <RocInput
            placeholder="Enter a name for the event"
            v-model="eventName"
            :maxLength="maxLengthEventName"
            :errorMessage="validEventName"
            @blur="validateFields($event, 'eventName')"
          />
          <div
            class="d-flex justify-content-end subtitle"
            :class="{ bumpUp: validEventName }"
            style="margin-bottom: 0; font-size: 10px;"
          >
            {{ eventName?.length }} / {{ maxLengthEventName }}
          </div>
        </div>
        <div class="dates">
          <div class="dateField">
            <div class="inputTitle">Start Date</div>
            <RocSingleDatePicker v-model="startDate" />
          </div>
          <div class="dateField">
            <div class="inputTitle">End Date</div>
            <RocSingleDatePicker
              v-model="endDate"
              :minDate="startDate"
              :disabled="!startDate"
              :key="endDate"
            />
          </div>
        </div>
        <div class="multilineText" style="margin-top: var(--spacing-m);">
          <div class="inputTitle">Event Details</div>
          <RocTextArea
            placeholder="Enter the details for this event"
            label="Event Details"
            rows="3"
            v-model="notes"
          />
        </div>
        <div class="inputTitle">User Groups</div>
        <filterMultiSelect
          style="margin:0px 0px 2px; width: 100%;"
          mode="tags"
          :close-on-select="false"
          placeholder-text="Select User Groups"
          :available-items="allUserGroups"
          :currently-selected="selectedUserGroupIds"
          :enableClear="false"
          :showError="validUserGroup != undefined"
          @selection-changed="validateFields($event, 'userGroup')"
        />
        <span v-if="validUserGroup" style="color: var(--overwatch-error)"
          >{{ "Enter at least one valid user group." }}
        </span>
      </div>
      <div class="d-flex flex-row" style="margin-top: var(--spacing-l);">
        <div class="overwatch-title-small">Enroll Invite Details</div>
      </div>
      <div class="border"></div>
      <div>
        <div
          class="d-flex"
          style="width: 100%; justify-content: space-between;"
        >
          <div class="inputTitle">Invite Message</div>
          <div class="subtitle">This will be followed by a link.</div>
        </div>
        <RocTextArea
          placeholder="Enter a short message"
          rows="2"
          v-model="inviteMessage"
          :maxlength="inviteMessageMaxLength"
        />
        <div
          class="d-flex justify-content-end subtitle"
          style="width: 100%; margin-bottom: 0; font-size: 10px;"
        >
          {{ inviteMessage?.length }} / {{ inviteMessageMaxLength }}
        </div>
      </div>
      <div class="border"></div>
      <!-- <div class="d-flex flex-row align-items-center">
        <div class="sectionLabel">Enroll link will expire in </div>
        <div style="width:4rem; margin-right: 10px; margin-left: 10px;"><MDBInput v-model="inviteTimeoutDays" type="number" /></div>
        <div class="sectionLabel">days</div>
      </div> -->
    </div>
    <div
      v-if="!saving"
      class="d-flex flex-row"
      style="width: 100%; margin-top: 15px;"
    >
      <div class="d-flex justify-content-start" style="flex: 1;"></div>
      <div
        v-if="errorMsg"
        class="d-flex align-items-center"
        style="color: var(--overwatch-error);"
      >
        {{ errorMsg }}
      </div>
      <div
        class="d-flex justify-content-end"
        style="flex: 1; gap: var(--spacing-s)"
      >
        <RocButton @click="discard" type="secondary">Discard</RocButton>
        <RocButton class="saveButton" :disabled="!isValid" @click="save"
          >Save
        </RocButton>
      </div>
    </div>
    <div
      v-else
      class="d-flex justify-content-end"
      style="flex: 1; margin-top: 15px; padding-right: 25px"
    >
      <roc-spinner />
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { cloneDeep } from "lodash";
import filterMultiSelect from "@/components/ui/filterMultiSelect";
import RocButton from "@/components/ui/RocButton";
import RocInput from "@/components/ui/RocInput";
import RocTextArea from "@/components/ui/RocTextArea.vue";
import RocSingleDatePicker from "@/components/ui/RocSingleDatePicker";
import moment from "moment";

export default {
  name: "EventCRUD",
  props: {
    eventName: String,
    notes: String,
    startDate: String,
    endDate: String,
    inviteTimeoutDays: Number,
    inviteMessage: String,
    saving: Boolean, // parent can send prop for saving to help update dialog for better UX
    errorMsg: String,
    rawObject: Object // copy of the original object to avoid dropping properties on update
  },
  emits: ["save", "close"],
  components: {
    filterMultiSelect,
    RocButton,
    RocInput,
    RocTextArea,
    RocSingleDatePicker
  },
  setup(props, context) {
    const store = useStore();
    const inviteTimeoutDays = ref(
      props.inviteTimeoutDays && props.inviteTimeoutDays > 0
        ? props.inviteTimeoutDays
        : 7
    );
    const inviteMessage = ref(props.inviteMessage ?? "");
    const eventName = ref(props.eventName ?? "");
    const notes = ref(props.notes ?? "");
    const startDate = ref(
      props.startDate ? moment(props.startDate).startOf("day").format() : undefined
    );
    const endDate = ref(
      props.endDate ? moment(props.endDate).endOf("day").format() : undefined
    );
    const isValid = computed(() => {
      return (
        inviteTimeoutDays.value > 0 &&
        eventName?.value?.length > 0 &&
        startDate?.value?.length > 0 &&
        endDate?.value?.length > 0 &&
        selectedUserGroupIds?.value?.length > 0
      );
    });
    const selectedUserGroupIds = ref([]);
    const inviteMessageMaxLength = ref(120);

    const validEventName = ref(undefined);
    const validUserGroup = ref(undefined);

    async function save() {
      // start with original object, update properties we edited in this component, emit changed object for parent to save
      const updatedObject = cloneDeep(props.rawObject);
      if (!updatedObject.inviteConfig) {
        updatedObject.inviteConfig = {};
      }
      updatedObject.inviteConfig.timeoutDays = inviteTimeoutDays.value;
      updatedObject.inviteConfig.message = inviteMessage.value;
      updatedObject.eventName = eventName.value;
      updatedObject.notes = notes.value;
      updatedObject.startDate = moment(startDate.value).startOf("day");
      updatedObject.endDate = moment(endDate.value).endOf("day");
      updatedObject.userGroups = selectedUserGroupIds.value;
      context.emit("save", updatedObject);
    }

    function discard() {
      context.emit("close");
    }

    function validateFields(event, field) {
      switch (field) {
        case "eventName":
          validEventName.value =
            event.trim() == "" ? "Enter a name for the event" : undefined;
          break;
        case "startDate":
        case "endDate":
        case "userGroup":
          selectedUserGroupIds.value = event.value;
          validUserGroup.value =
            event.value == "" ? "Enter details for the event." : undefined;
          break;
        default:
          console.info("Validation not implemented for field");
      }
    }

    watch(startDate, () => {
      const currStartDate = moment(startDate.value).startOf("day");
      const currEndDate = moment(endDate.value).endOf("day");

      if (currEndDate.isBefore(currStartDate, "day")) {
        endDate.value = currStartDate.format();
      }
    });

    const maxLengthEventName = computed(() => {
      const enrollConfig = store.getters["rocenroll/enrollConfig"];
      return _.get(enrollConfig, "events.maxLengthEventName", 75); // default 75
    });

    const allUserGroups = computed(() => {
      return store.getters["settings/userGroups"];
    });
    initUserGroups();

    function initUserGroups() {
      try {
        // iterate available usergroups to check if selected or readonly
        for (let i = 0; i < allUserGroups?.value?.length; i++) {
          allUserGroups.value[i].value = allUserGroups.value[i]._id;
          // if usergroup is system and user not in system group, mark it disabled
          if (
            allUserGroups.value[i].isSysAdmin &&
            !userGroupContainsCurrentUser(allUserGroups.value[i])
          ) {
            allUserGroups.value[i].disabled = true;
          } else {
            allUserGroups.value[i].disabled = false;
          }
          if (!props.rawObject.userGroups) {
            // if creating, auto select all available usergroups
            selectedUserGroupIds.value.push(allUserGroups.value[i].value);
          }
        }
        if (props.rawObject.userGroups) {
          // if editing, select current usergroups
          selectedUserGroupIds.value.push(...props.rawObject.userGroups);
        }
      } catch (err) {
        console.log(err);
      }
    }

    // check if currently logged in user is a part of provided usergroup
    function userGroupContainsCurrentUser(userGroup) {
      return userGroup.users.find((user) => {
        if (user.email === store.getters["auth/email"]) {
          return true;
        }
      });
    }

    return {
      save,
      discard,
      inviteTimeoutDays,
      inviteMessage,
      eventName,
      notes,
      startDate,
      endDate,
      isValid,
      maxLengthEventName,
      allUserGroups,
      selectedUserGroupIds,
      inviteMessageMaxLength,
      validateFields,
      validEventName,
      validUserGroup
    };
  }
};
</script>

<style scoped lang="scss">
.saveButton {
  background: var(--overwatch-primary);
  color: var(--overwatch-button-text) !important;
  font-size: 14px;
}

.multilineText {
  margin-bottom: 20px;
}

.border {
  margin-top: var(--spacing-s);
  margin-bottom: var(--spacing-m);
}

.subtitle {
  color: var(--overwatch-neutral-300);
  text-align: left;
  @include overwatch-body-med;
}

.inputTitle {
  margin-bottom: var(--spacing-base);
  @include overwatch-body-large;
}

.bumpUp {
  margin-top: -15px;
}

.dates {
  display: flex;
  gap: 0.5rem;
}

.dateField {
  width: 315px;
}

.baseSelector {
  height: auto;
  width: auto;
  cursor: pointer;
  @include overwatch-body-med;
  background-color: var(--overwatch-neutral-500);
  border: 1px solid var(--overwatch-neutral-300);
  border-radius: 4px;
  color: black;
}

select * {
  background-color: var(--overwatch-neutral-500);
  border: 1px solid var(--overwatch-neutral-300);
  border-radius: 0px;
}

@media (max-width: 480px) {
  .dates {
    display: flow;
  }
  .dateField {
    width: 100%;
    padding-top: 10px;
  }
}
</style>
