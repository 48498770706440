export default {
  setWorkflows(state, payload) {
    state.workflows = payload;
  },
  addWorkflow(state, payload) {
    state.workflows.push(payload);
  },
  setEditingWorkflow(state, payload) {
    state.editingWorkflow = payload;
  },
  deleteWorkflow(state, payload) {
    const index = state.workflows.findIndex((w) => {
      return w._id === payload.id;
    });
    if (index >= 0) {
      state.workflows.splice(index, 1);
    }
  },
  replaceWorkflow(state, payload) {
    const index = state.workflows.findIndex((w) => {
      return w._id === payload._id;
    });
    if (index >= 0) {
      state.workflows.splice(index, 1, payload);
    }
  }
};
