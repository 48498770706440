<script setup>
import RocIcon from "@/components/ui/RocIcon.vue";
import Confirmation from "./Confirmation.vue";
import { ref, computed, watchEffect, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import {
  throttle as lodashThrottle
} from "lodash";

const ACTIVITY_THROTTLE = 3000;
const COUNTDOWN_DEFAULT = 60;
const store = useStore();
const router = useRouter();
const showDialog = ref(false);
const countdownTimer = ref(COUNTDOWN_DEFAULT);
let countdownInterval = undefined;
let sessionTimeout = undefined;
const loggedOutUser = ref(false);
let timerStarted = 0;

const sessionTimer = ref(store.getters["shared/getInactivityTimeout"] - COUNTDOWN_DEFAULT);

watch(() => store.getters["auth/isAuthenticated"], () => {
  init();
});

watch(() => store.getters["shared/getInactivityTimeout"], (inactivityTimeout) => {
  sessionTimer.value = (inactivityTimeout - COUNTDOWN_DEFAULT);
  init();
});

watch(() => store.getters["shared/getLastActivity"], () => {
  startSessionLogic();
});

watch(() => store.getters["shared/getSharedUserAction"], (action) => {
  switch (action?.type) {
    case "logout":
      handleLogout(action.source, false);
      break;
    case "inactivityContinue":
      handleReAuth(false);
      startSessionLogic();
      break;
    default:
      // ignore
      break;
  }
});

onMounted(() => {
  init();
});

// use lodash throttle here to broadcast activity at most once every ACTIVITY_THROTTLE ms
const broadcastActivity = lodashThrottle(() => {
    store.commit("shared/setLastActivity", Date.now());
  },
  ACTIVITY_THROTTLE
);

function init() {
  // remove every time even if it may not be subscribed yet
  window.removeEventListener("click", broadcastActivity);
  window.removeEventListener("keypress", broadcastActivity);
  if (store.getters["auth/isAuthenticated"] && sessionTimer.value > 0) {
    broadcastActivity();
    window.addEventListener("click", broadcastActivity);
    window.addEventListener("keypress", broadcastActivity);
  } else {
    reset();
  }
}

function startSessionTimeout() {
  if (sessionTimeout) {
    return;
  }
  sessionTimeout = setTimeout(() => {
    startTimer();
    showDialog.value = true;
  }, sessionTimer.value * 1000);
  timerStarted = Date.now();
}

function startTimer() {
  if (countdownInterval) {
    return;
  }
  countdownInterval = setInterval(() => {
    if (countdownTimer.value === 0) {
      handleLogout("inactivity", true);
    } else {
      countdownTimer.value--;
    }
  }, 1000);
}

function startSessionLogic() {
  if (showDialog.value) return;

  clearTimeout(sessionTimeout);
  sessionTimeout = undefined;
  startSessionTimeout();
}

function handleReAuth(emit=true) {
  reset();
  showDialog.value = false;
  if (emit) {
    store.commit("shared/setSharedUserAction", { type: "inactivityContinue" });
  }
}

function reset() {
  clearInterval(countdownInterval);
  countdownInterval = undefined;
  clearTimeout(sessionTimeout);
  sessionTimeout = undefined;
  countdownTimer.value = COUNTDOWN_DEFAULT;
}

function handleLogout(logoutSrc, signal) {
  logoutSrc === "user" ? showDialog.value == false : loggedOutUser.value = true;
  if (signal) {
    store.commit("shared/setSharedUserAction", { type: "logout", source: "user" });
  }
  reset();
  // hack to avoid redundantly pushing router to /logout if it already did
  const appPath = router.currentRoute.value.path;
  if (appPath !== "/logout" && appPath !== "/login") {
    router.push({ path: "/logout" });
  }
}

function handleSessionExpAck() {
  loggedOutUser.value = false;
  countdownTimer.value = COUNTDOWN_DEFAULT;
  showDialog.value = false;
}

const productName = computed(() => {
  const appPath = router.currentRoute.value.path;
  if (appPath.startsWith("/rocenroll")) {
    return "Enrolling";
  } else if (appPath.startsWith("/examine")) {
    return "Examining";
  } else {
    return "Watching";
  }
});
</script>

<template>
  <base-dialog :show="true" :hideCloseBtn="true" v-if="showDialog">
    <Confirmation
      @yes="handleReAuth(true)"
      @no="handleLogout('user', true)"
      :yesButtonTitle="`Continue ${productName}`"
      :noButtonTitle="'Logout'"
      v-if="!loggedOutUser"
    >
      <template #title>
        <RocIcon
          color="red"
          size="md"
          icon="unknown"
          style="margin-right: var(--spacing-m);"
        />
        {{ `Are you still ${productName}?` }}
      </template>
      <div class="d-flex flex-row" style="margin-top:var(--spacing-xl)">
        <div
          class="overwatch-body-large"
          style="width:300px; flex-wrap: wrap; margin-bottom: var(--spacing-s)"
        >
          Your Session is about to expire. You will be logged out in
          <span style="font-weight: bold;"> {{ countdownTimer }}</span>
        </div>
      </div>
    </Confirmation>
    <Confirmation
      @yes="handleSessionExpAck"
      :yesButtonTitle="'Ok'"
      :showNoButton="false"
      v-else
    >
      <template #title>
        <RocIcon
          color="red"
          size="md"
          icon="error"
          style="margin-right: var(--spacing-m);"
        />
        Your session has expired.
      </template>
      <div class="d-flex flex-row" style="margin-top:var(--spacing-xl)">
        <div
          class="overwatch-body-large"
          style="width:300px; flex-wrap: wrap; margin-bottom: var(--spacing-s)"
        >
          {{ `Your session has timed out. Please log in again to continue ${productName}.` }}
        </div>
      </div>
    </Confirmation>
  </base-dialog>
</template>

<style></style>
