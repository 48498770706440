<template>
  <div class="wrapper">
    <div class="overwatch-title-med">
      Example
    </div>
    <div class="box">
      <div class="input-row">
        Split filename into camera name and datetime by:
        <input
          disabled
          class="inline overwatch-body-med"
          style="width: 100px; margin-top: 10px; border-radius: 5px;"
          v-model="splitString"
          title="Split string"
        />
      </div>
      <div class="input-row">
        Use its
        <input disabled v-model="occurrenceSelection" style="width: 100px" />
        occurrence from the
        <input disabled v-model="orderSelection" style="width: 100px" />
        of the filename.
      </div>
      <div class="input-row">
        The datetime format is
        <input disabled class="inline" v-model="dateFormatString" /> .
      </div>
      <div class="input-row">
        The camera name is
        <input disabled v-model="cameraNameOrder" style="width: 100px" />
        the datetime.
      </div>
    </div>
    <div class="explanation">
      <div>
        The wizard must parse the various camera names and the datetimes from
        the filenames of each media.
      </div>
      <div>
        We will use the following filename as an example:
      </div>
      <div class="center">
        <span class="italic">20240306_110000_Auditorium</span>
      </div>
      <div>
        This filename contains a datetime <span>20240306_110000</span> and a
        camera name <span>Auditorium</span>. We must specify the format of the
        filenames so that the wizard can appropriately parse them.
      </div>
      <div>
        First, we specify that the string that separates the datetime from the
        cameraname is <span>'_'</span>.
      </div>
      <div>
        However, there are multiple instances of <span>'_'</span>. The instance
        of <span>'_'</span> in the datetime
        <span class="italic">20240306_110000</span> should be ignored, since we
        want the parser to look at the datetime string as a whole.
      </div>
      <div>
        Thus we specify which occurrence of the string <span>'_'</span> the
        parser must use. The <span>second</span> occurrence from the
        <span>start</span> of the filename is correct, as that would specify the
        '_' betweeen the datetime and the camera name.
      </div>
      <div>
        Another correct configuration would be <span>first</span> occurrence
        from the <span>end</span> of the filename.
      </div>
      <div>
        We must also specify whether the datetime comes before or after the
        split string '_'. In this example, the camera name comes
        <span>after</span> the split string '_'.
      </div>
      <div>
        Finally, the wizard requires a string to parse the datetime correctly.
        In the example, 2024 is the year, 03 the month, 06 the day, 11 is the
        hour, 00 is the minute, and 00 is the second. The string
        <span>yyyyMMdd-HHmmss</span> can thus specify the format in which the
        datetimes are structured in our example.
      </div>
      <div>
        The wizard will output the camera name and datetime as these settings
        are configured at the bottom as <span>Example camera name</span> and
        <span>Example start datetime</span>. Check these to ensure wizard is
        parsing correctly.
      </div>
    </div>
    <div style="margin-top: var(--spacing-m)" class="tokens">
      <div class="overwatch-title-small">Common datetime parsing tokens</div>
      <div
        style="display: flex; gap: var(--spacing-l); margin-top: var(--spacing-s)"
      >
        <div>
          <div>yyyy: 4-digit year '2019'</div>
          <div>yy: 2-digit year '19'</div>
          <div>MMMM: Full-length month 'June'</div>
          <div>MMM: 3 character month 'Jun'</div>
          <div>MM: Month of the year, zero-padded '06'</div>
          <div>M: Month of the year '6'</div>
          <div>dd: Day of the month, zero-padded '01'</div>
          <div>d: Day of the month '1'</div>
        </div>
        <div>
          <div>HH: hour of day from 0-24, zero-padded, '14'</div>
          <div>H: hour of day from 0-24, '14'</div>
          <div>hh: hour of day on 12-hour clock, zero-padded, '02'</div>
          <div>h: hour of the day on 12 hour clock, '2'</div>
          <div>mm: minute, zero-padded, '04'</div>
          <div>m: minute, '4'</div>
          <div>ss: second, zero-padded</div>
          <div>s: second</div>
          <div>a: 'AM' or 'PM'</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  setup(props, context) {
    const splitString = ref("_");
    const dateFormatString = ref("yyyyMMdd_HHmmss");
    const occurrenceSelection = ref("second");
    const orderSelection = ref("start");
    const cameraNameOrder = ref("after");

    return {
      splitString,
      dateFormatString,
      occurrenceSelection,
      orderSelection,
      cameraNameOrder
    };
  }
};
</script>

<style scoped lang="scss">
.wrapper {
  max-height: 90vh;
  overflow-y: auto;
}

.input-row {
  width: 100%;
  margin-bottom: var(--spacing-base);
}

.occurrence {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  background-image: linear-gradient(
      45deg,
      transparent 50%,
      var(--overwatch-neutral-400) 50%
    ),
    linear-gradient(135deg, var(--overwatch-neutral-400) 50%, transparent 50%),
    linear-gradient(to right, transparent, transparent);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

.inline {
  width: revert;
  display: inline;
}

.box {
  margin-top: var(--spacing-m);
  border: 1px dotted var(--overwatch-neutral-100);
  padding: var(--spacing-s);
}

.explanation {
  @include overwatch-body-small;
  margin-top: var(--spacing-m);

  div {
    margin-top: var(--spacing-s);
  }

  span {
    font-style: italic;
  }
}

.center {
  width: 100%;
  padding: var(--spacing-base);
  display: flex;
  align-items: center;
  justify-content: center;
}

.tokens {
  @include overwatch-body-small;
}
</style>
