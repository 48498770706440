<template>
  <!-- Calendar Popup -->
  <div>
    <div>
      <!-- offsetDistance = margin-top for popup, offsetSkid = margin-left for poppup, but for some reason 73 is like only 10 px
      locked="true" keeps the popper from moving when the page runs out of space-->
      <MyPopperWrapper
        :locked="true"
        offsetDistance="2"
        offsetSkid="-71"
        :show="togglePopper"
        ref="popperWrapper"
        @close:popper="togglePopper = false"
        @click="handleWrapperClick"
        style="display: block"
      >
        <div>
          <!-- popper header div -->
          <RocButton
            v-if="clusterPageMode"
            :type="dateRangeDisplay?.length > 0 ? 'primary' : 'white'"
            @click="togglePopperWindow"
            style="
                        padding-left: var(--spacing-s);
                        padding-right: var(--spacing-s);
                        display: flex;
                        cursor: default;
                        height: 48px;
                    "
          >
            <div class="d-flex align-items-center">
              <RocIcon
                :color="dateRangeDisplay?.length > 0 ? 'white' : 'black'"
                size="sm"
                icon="calendar"
                style="margin-right: var(--spacing-s)"
              />

              <span v-if="dateRangeDisplay?.length > 0">
                {{ dateRangeDisplay }}
              </span>
              <RocIcon
                v-if="dateRangeDisplay?.length > 0"
                color="white"
                size="xs"
                icon="exit"
                style="margin-left: var(--spacing-s); cursor: pointer;"
                @click.stop="resetToDefaults(true)"
              />

              <span v-else style=" color: var(--overwatch-neutral-100);">
                Date Range
              </span>
            </div>
          </RocButton>

          <div @click="togglePopperWindow" v-else>
            <div class="input-rectangle" style="cursor: pointer;">
              <RocIcon color="primary" size="sm" icon="calendar" />
              <input
                class="date-display-input"
                :style="{ fontSize: computedDateRangeFontSize }"
                placeholder="Add date and time"
                :value="dateRangeDisplay"
                readonly
              />
            </div>
          </div>
        </div>
        <template #content v-if="togglePopper">
          <div
            :class="{
              'small-Popper': showCalendarOnly,
              'Popper-Rectangle': !showCalendarOnly
            }"
          >
            <!-- calendar div -->
            <div
              class="my-calendar"
              style="padding-left: 0px; padding-top: 10px;"
            >
              <div class="d-flex">
                <DatePicker
                  v-model="range.range"
                  transition="none"
                  :masks="range.masks"
                  is-range
                  is24hr
                  mode="date"
                  :key="datePickerKey"
                >
                </DatePicker>
              </div>
              <div
                v-if="!showCalendarOnly"
                class="d-flex"
                style="margin-left: 14px"
              >
                <RocCheckbox
                  v-model="allDatesCheckbox"
                  @click="setAllDatesCheckbox(!allDatesCheckbox)"
                ></RocCheckbox>
                <div
                  class="overwatch-body-small"
                  style="margin-left: 10px; margin-top: 3px; color: var(--overwatch-neutral-100)"
                >
                  All dates
                </div>
              </div>
            </div>
            <!-- timepicker div -->
            <div
              v-if="!showCalendarOnly"
              id="timepicker-div"
              class="timepicker-container"
            >
              <!-- From -->
              <div id="from-div">
                <div class="d-flex flex-column">
                  <span
                    style="color: var(--overwatch-neutral-100); font-size: 14px;"
                  >
                    From
                  </span>
                  <span style="color: var(--overwatch-neutral-100)">
                    {{ startDateValue }}
                  </span>
                </div>
                <div class="d-flex">
                  <!-- start hour input-->
                  <RocNumberInput
                    class="number-input"
                    :inputType="'time'"
                    :numberInput="startHourValue"
                    :min="0"
                    :max="23"
                    @number-input-change="updateStartHourValue"
                  />
                  <!-- start minute input-->
                  <RocNumberInput
                    :inputType="'time'"
                    :numberInput="startMinuteValue"
                    :min="0"
                    :max="59"
                    @number-input-change="updateStartMinuteValue"
                  />
                </div>
              </div>
              <!-- To -->
              <div id="to-div" style="margin-top: 24px;">
                <div class="d-flex flex-column">
                  <span
                    style="color: var(--overwatch-neutral-100); font-size: 14px;"
                  >
                    To
                  </span>
                  <span style="color: var(--overwatch-neutral-100)">
                    {{ endDateValue }}
                  </span>
                </div>
                <div class="d-flex">
                  <!-- end hour input-->
                  <RocNumberInput
                    class="number-input"
                    :inputType="'time'"
                    :numberInput="endHourValue"
                    :min="0"
                    :max="23"
                    @number-input-change="updateEndHourValue"
                  />
                  <!-- end minute input-->
                  <RocNumberInput
                    :inputType="'time'"
                    :numberInput="endMinuteValue"
                    :min="0"
                    :max="59"
                    @number-input-change="updateEndMinuteValue"
                  />
                </div>
              </div>
              <div class="d-flex" style="margin-top:40px;">
                <RocCheckbox
                  v-model="allTimesCheckbox"
                  @click.stop="setAllTimesCheckbox(!allTimesCheckbox)"
                ></RocCheckbox>
                <div
                  class="overwatch-body-small"
                  style="margin-left: 10px; margin-top: 11px; color:var(--overwatch-neutral-100)"
                >
                  Any time
                </div>
              </div>
            </div>
            <!-- footer div -->
            <div v-if="!showCalendarOnly" class="footer-container">
              <div class="line-breaker" style="width: 100%"></div>
              <div
                style="color: var(--overwatch-neutral-100); padding-left: 24px"
                class="overwatch-body-small"
              >
                Only on these days
              </div>
              <div
                class="d-flex justify-content-start"
                style="width: 100%; padding-left: 24px"
              >
                <RocButton
                  v-if="daysOfWeekSelected"
                  size="match"
                  :class="
                    daysOfWeekSelected.includes(0)
                      ? 'WeekdayButton'
                      : 'WeekdayButtonUnselected'
                  "
                  :disabled="daysOfWeekInRange.includes(0) ? false : true"
                  @click="selectDaysOfWeek(0)"
                  style="border-radius: 5px 0 0 5px"
                >
                  <span class="overwatch-body-small">S</span>
                </RocButton>
                <RocButton
                  v-if="daysOfWeekSelected"
                  size="match"
                  :class="
                    daysOfWeekSelected.includes(1)
                      ? 'WeekdayButton'
                      : 'WeekdayButtonUnselected'
                  "
                  :disabled="daysOfWeekInRange.includes(1) ? false : true"
                  @click="selectDaysOfWeek(1)"
                >
                  <span class="overwatch-body-small">M</span>
                </RocButton>
                <RocButton
                  v-if="daysOfWeekSelected"
                  size="match"
                  :class="
                    daysOfWeekSelected.includes(2)
                      ? 'WeekdayButton'
                      : 'WeekdayButtonUnselected'
                  "
                  :disabled="daysOfWeekInRange.includes(2) ? false : true"
                  @click="selectDaysOfWeek(2)"
                >
                  <span class="overwatch-body-small">T</span>
                </RocButton>
                <RocButton
                  v-if="daysOfWeekSelected"
                  size="match"
                  :class="
                    daysOfWeekSelected.includes(3)
                      ? 'WeekdayButton'
                      : 'WeekdayButtonUnselected'
                  "
                  :disabled="daysOfWeekInRange.includes(3) ? false : true"
                  @click="selectDaysOfWeek(3)"
                >
                  <span class="overwatch-body-small">W</span>
                </RocButton>
                <RocButton
                  v-if="daysOfWeekSelected"
                  size="match"
                  :class="
                    daysOfWeekSelected.includes(4)
                      ? 'WeekdayButton'
                      : 'WeekdayButtonUnselected'
                  "
                  :disabled="daysOfWeekInRange.includes(4) ? false : true"
                  @click="selectDaysOfWeek(4)"
                >
                  <span class="overwatch-body-small">T</span>
                </RocButton>
                <RocButton
                  v-if="daysOfWeekSelected"
                  size="match"
                  :class="
                    daysOfWeekSelected.includes(5)
                      ? 'WeekdayButton'
                      : 'WeekdayButtonUnselected'
                  "
                  :disabled="daysOfWeekInRange.includes(5) ? false : true"
                  @click="selectDaysOfWeek(5)"
                >
                  <span class="overwatch-body-small">F</span>
                </RocButton>
                <RocButton
                  v-if="daysOfWeekSelected"
                  size="match"
                  :class="
                    daysOfWeekSelected.includes(6)
                      ? 'WeekdayButton'
                      : 'WeekdayButtonUnselected'
                  "
                  :disabled="daysOfWeekInRange.includes(6) ? false : true"
                  @click="selectDaysOfWeek(6)"
                  style="border-radius:0 5px 5px 0"
                >
                  <span class="overwatch-body-small">S</span>
                </RocButton>
              </div>
              <div class="d-flex justify-content-end overwatch-body-small">
                <span
                  @click="daysOfWeekSelected = []"
                  style="color: var(--overwatch-button-primary); cursor: pointer; padding-right: 24px"
                >
                  Clear All
                </span>
              </div>
            </div>
            <div class="line-breaker" style="width: 100%"></div>
            <div class="buttons-footer">
              <!-- cluster page does not need store value updated on click of reset, encounters filter does -->
              <div
                v-if="!showCalendarOnly"
                @click="resetToDefaults(!clusterPageMode)"
                class="reset-btn-container"
              >
                <span
                  class="overwatch-body-small"
                  style="color:var(--overwatch-error)"
                  >Reset</span
                >
              </div>
              <div class="cancel-apply-container">
                <RocButton
                  class="cancelApplyButton"
                  type="secondary"
                  size="match"
                  @click="togglePopper = false"
                >
                  <span
                    class="overwatch-body-small"
                    style="color: var(--overwatch-button-text)"
                    >Cancel</span
                  >
                </RocButton>
                <RocButton
                  class="cancelApplyButton"
                  type="primary"
                  size="match"
                  @click="applyDateRange()"
                >
                  <span
                    class="overwatch-body-small"
                    style="color: var(--overwatch-button-text)"
                    >Apply</span
                  >
                </RocButton>
              </div>
            </div>
            <div
              v-if="showErrorMessage"
              class="d-flex justify-content-end"
              style="padding: 0px 24px 12px 0px"
            >
              <span
                class="overwatch-body-small"
                style="color:var(--overwatch-error)"
                >Select valid date range</span
              >
            </div>
          </div>
        </template>
      </MyPopperWrapper>
    </div>

    <!-- Quick Date Selection Buttons -->
    <!-- Note: for future dev, these can be factored out of this component if need be
         They were created before more reactive functionality, but still useful enough to be in this component -->
    <div v-if="quickDateButtonsShow">
      <RocButton
        size="match"
        type="secondary"
        class="ButtonSecondaryDefault"
        @click="setQuickDateTime('today')"
      >
        <span
          style="color: var(--overwatch-secondary);"
          class="overwatch-body-small"
          >Today</span
        >
      </RocButton>
      <RocButton
        size="match"
        type="secondary"
        class="ButtonSecondaryDefault"
        @click="setQuickDateTime('yesterday')"
      >
        <span
          style="color: var(--overwatch-secondary);"
          class="overwatch-body-small"
          >Yesterday</span
        >
      </RocButton>
      <RocButton
        size="match"
        type="secondary"
        class="ButtonSecondaryDefault"
        @click="setQuickDateTime('last7')"
      >
        <span
          style="color: var(--overwatch-secondary);"
          class="overwatch-body-small"
          >Last 7 Days</span
        >
      </RocButton>
      <div>
        <RocButton
          size="match"
          class="ButtonSecondaryDefault"
          @click="resetToDefaults(true)"
          style="margin-top: 10px;"
        >
          <span
            style="color: var(--overwatch-secondary);"
            class="overwatch-body-small"
            >Clear All</span
          >
        </RocButton>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, computed, watch, onMounted } from "vue";
import MyPopperWrapper from "../ui/MyPopperWrapper.vue";
import { DatePicker } from "v-calendar";
import RocCheckbox from "@/components/ui/RocCheckbox.vue";
import moment from "moment";
import {
  determineDaysOfWeekSelected,
  findDaysOfWeekInRange,
  dateRangeDisplayCalcHelper
} from "@/js/dateRangeHelper.js";
import RocNumberInput from "@/components/ui/RocNumberInput.vue";
import RocIcon from "@/components/ui/RocIcon.vue";
import RocButton from "@/components/ui/RocButton.vue";

export default {
  name: "RocCalendar",
  components: {
    MyPopperWrapper,
    DatePicker,
    RocCheckbox,
    RocNumberInput,
    RocIcon,
    RocButton
  },
  emits: ["time-filter-change", "clear-date-selection", "popper-open"],
  props: {
    dateValue: {
      type: Object,
      default: { start: null, end: null }
    },
    quickDateButtonsShow: {
      type: Boolean,
      default: false
    },
    clusterPageMode: {
      type: Boolean,
      default: false
    },
    showCalendarOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props, context) {
    onMounted(() => {
      //set event listener for click away
      document.addEventListener("click", handleWrapperClick);
      //update calendar UI, this is called twice because the first time it doesn't update the UI without this
      updateCalendarUI();
    });

    //manually controlling popper wrapper, so have to include event listener
    //to close popup when click away occurs
    const popperWrapper = ref(null);

    //bool for toggling popper
    const togglePopper = ref(false);

    const handleWrapperClick = (event) => {
      // Check if the click is outside the popper content
      if (
        popperWrapper.value &&
        !popperWrapper.value.$el.contains(event.target)
      ) {
        togglePopper.value = false;
        context.emit("popper-open", togglePopper.value);
      }
    };

    function togglePopperWindow() {
      togglePopper.value = !togglePopper.value;
      //emit that window is open
      context.emit("popper-open", togglePopper.value);
    }

    //select all input text on click for time elements, that way user can type over existing text quickly
    function selectAllText(inputId) {
      document.getElementById(inputId).select();
    }

    //Evan - updating the key of the datepicker component forces it to update
    //this is a workaround for the issue where the datepicker doesn't update when the date range is manually changed
    //https://github.com/nathanreyes/v-calendar/issues/389
    const datePickerKey = ref(0);

    //computed prop that's the main value for handling incoming date range from parent function
    const dateRange = computed(() => {
      return props.dateValue;
    });

    //watcher for dateValue prop, update calendar UI if it changes
    watch([dateRange], () => {
      updateCalendarUI();
    });

    const range = reactive({
      range: {
        //first range.start = first start value available in the dateValue prop
        //second range.start = last end value available in the dateValue prop
        //the ? inside the condition check is a chaining null check, which throws an error if any junction of the object is null
        start: dateRange.value?.[0]?.start
          ? new Date(dateRange.value[0].start)
          : null,
        end: dateRange.value?.[dateRange.value.length - 1]?.end
          ? new Date(dateRange.value[dateRange.value.length - 1].end)
          : null
      },
      masks: {
        input: "YYYY-MM-DD h:mm A",
        weekdays: "WWW"
      }
    });

    //connected to input values
    const startHourValue = ref();
    const startMinuteValue = ref();
    const endHourValue = ref();
    const endMinuteValue = ref();

    //emit update for time inputs handling
    function updateStartHourValue(value) {
      startHourValue.value = value;
      checkIfEndTimeIsGreaterThanStartTime();
    }

    //emit update for time inputs handling
    function updateStartMinuteValue(value) {
      startMinuteValue.value = value;
      checkIfEndTimeIsGreaterThanStartTime();
    }

    //emit update for time inputs handling
    function updateEndHourValue(value) {
      endHourValue.value = value;
      checkIfEndTimeIsGreaterThanStartTime();
    }

    //emit update for time inputs handling
    function updateEndMinuteValue(value) {
      endMinuteValue.value = value;
      checkIfEndTimeIsGreaterThanStartTime();
    }

    //connected to date range display
    const dateRangeDisplay = ref();

    function updateCalendarUI() {
      /*Update range object which is connected to the datepicker component and the To/From date*/
      //array check prevents regression bug where old dateValue was an object
      if (Array.isArray(props.dateValue) && props.dateValue.length > 0) {
        //set refs to dateValue
        range.range.start = props.dateValue?.[0]?.start
          ? new Date(props.dateValue[0].start)
          : null;
        range.range.end = props.dateValue?.[props.dateValue.length - 1]?.end
          ? new Date(props.dateValue[props.dateValue.length - 1].end)
          : null;

        //set daysOfWeekSelected to any weekday that is in the dateRange prop
        daysOfWeekSelected.value = determineDaysOfWeekSelected(props.dateValue);

        /*Update time input fields*/
        startHourValue.value = range.range.start
          ? range.range.start.getHours()
          : "";
        startMinuteValue.value = range.range.start
          ? range.range.start.getMinutes()
          : "";
        endHourValue.value = range.range.end ? range.range.end.getHours() : "";
        endMinuteValue.value = range.range.end
          ? range.range.end.getMinutes()
          : "";
      }
      // if range is null, reset calendar to defaults
      else {
        resetToDefaults(false);
      }

      /*Update display calc*/
      dateRangeDisplay.value = dateRangeDisplayCalcHelper(
        range.range,
        daysOfWeekSelected.value
      );

      //reset error message flag
      showErrorMessage.value = false;

      /*Update datepicker key to force visual update*/
      datePickerKey.value++;
    }

    function convertTimeInputToInt() {
      //if any values are empty, set to 00
      if (!startHourValue.value) {
        startHourValue.value = "00";
      }
      if (!startMinuteValue.value) {
        startMinuteValue.value = "00";
      }
      //if endHourValue or endMinuteValue is empty, set to 00
      if (!endHourValue.value) {
        endHourValue.value = "00";
      }
      if (!endMinuteValue.value) {
        endMinuteValue.value = "00";
      }

      //convert input values to numbers, because inputs are sometimes strings which helps UI functionality
      //but not when dealing with dates, so convert to numbers
      startHourValue.value = parseInt(startHourValue.value);
      startMinuteValue.value = parseInt(startMinuteValue.value);
      endHourValue.value = parseInt(endHourValue.value);
      endMinuteValue.value = parseInt(endMinuteValue.value);
    }

    function checkIfEndTimeIsGreaterThanStartTime() {
      if (range.range.start && range.range.end) {
        //if date range is just for one day, start time is greater than end time, set end time to start time
        if (
          range.range.start.toLocaleString().split(",")[0] ===
          range.range.end.toLocaleString().split(",")[0]
        ) {
          //convert time input fields to numbers for comparison
          let startHour = startHourValue.value;
          let startMinute = startMinuteValue.value;
          let endHour = endHourValue.value;
          let endMinute = endMinuteValue.value;
          if (parseInt(startHour) > parseInt(endHour)) {
            endHourValue.value = startHourValue.value;
          }
          if (parseInt(startHour) === parseInt(endHour)) {
            if (parseInt(startMinute) > parseInt(endMinute)) {
              endMinuteValue.value = startMinuteValue.value;
            }
          }
        }
      }
    }

    //computed prop that creates an array of weekdays from the date range
    //this controls whether buttons are disabled or not
    const daysOfWeekInRange = computed(() => {
      let weekdays = [];
      if (range.range.start && range.range.end) {
        weekdays = findDaysOfWeekInRange(range.range);
      }
      //if no date range is selected, set weekdays to all days of the week
      else {
        weekdays = [0, 1, 2, 3, 4, 5, 6];
      }
      return weekdays;
    });

    const startDateValue = computed(function() {
      if (range.range.start) {
        return range.range.start.toLocaleString().split(",")[0];
      } else {
        return "—";
      }
    });

    const endDateValue = computed(function() {
      if (range.range.end) {
        return range.range.end.toLocaleString().split(",")[0];
      } else {
        return "—";
      }
    });

    //one time flag that prevents watcher from firing one time when date range is updated on submit
    //this is mainly an issue with selecting large date ranges, and then deselecting certain days of the week
    //example: on submit, range.range = 12-21/22 - 12/21/23, but the Wednesday button (W) is deselected
    //when the UI refreshes, then that range.range value is 12/22/22 - 12/21/23, causing the watcher to fire
    //which is correct, but not good for UI functionality
    const afterUpdatePreventWatcherOneTimeFlag = ref(false);

    //Error handling - if date range selected does not contain certain days of the week, disable those days of the week buttons
    watch([startDateValue, endDateValue], () => {
      //checks to prevent daysOfWeekSelected from being updated at the wrong time
      if (
        range.range.start &&
        range.range.end &&
        !afterUpdatePreventWatcherOneTimeFlag.value &&
        togglePopper.value === true
      ) {
        //get list of dates from date range, returns an array of dates between the two dates
        let weekdays = findDaysOfWeekInRange(range.range);

        //set this value to weekdays to remove any date selections not in the date range
        daysOfWeekSelected.value = [...weekdays];
      } else {
        //reset one time flag
        afterUpdatePreventWatcherOneTimeFlag.value = false;
      }

      //if allDatesCheckbox is true, and the range value is not one year from today, set allDatesCheckbox to false
      if (allDatesCBValue.value) {
        let start, end;
        start = new Date(new Date().setDate(new Date().getDate() - 365));
        end = new Date(new Date().setDate(new Date().getDate()));
        end.setHours(
          end.getHours(),
          end.getMinutes(),
          end.getSeconds(),
          end.getMilliseconds()
        );

        if (range.range.start !== start || range.range.end !== end) {
          allDatesCheckbox.value = false;
        }
      }
    });

    const showErrorMessage = ref(false);

    function resetToDefaults(applyToStore) {
      range.range.start = null;
      range.range.end = null;
      daysOfWeekSelected.value = [0, 1, 2, 3, 4, 5, 6];
      allDatesCheckbox.value = false;
      allDatesCBValue.value = false;
      allTimesCheckbox.value = false;
      allTimesCBValue.value = false;
      startHourValue.value = "";
      startMinuteValue.value = "";
      endHourValue.value = "";
      endMinuteValue.value = "";
      showErrorMessage.value = false;

      if (applyToStore) {
        context.emit("clear-date-selection");
      }

      datePickerKey.value++;
    }

    //check if all date filters in popper are default values
    const isDataDefaults = computed(() => {
      if (
        range.range.start === null &&
        range.range.end === null &&
        startHourValue.value === "" &&
        startMinuteValue.value === "" &&
        endHourValue.value === "" &&
        endMinuteValue.value === "" &&
        daysOfWeekSelected.value?.length === 7
      ) {
        return true;
      } else {
        return false;
      }
    });

    //computed style that changes fontsize of date range display based on length of string
    const computedDateRangeFontSize = computed(() => {
      //when the most dates are selected, the string 49 characters long
      if (dateRangeDisplay.value && dateRangeDisplay.value.length > 48) {
        return "10px";
      }
      //when days of the week start getting selected, length is > 38
      if (dateRangeDisplay.value && dateRangeDisplay.value.length > 38) {
        return "12px";
      } else {
        return "14px";
      }
    });

    //vuex store value that caches the days of the week selected
    const daysOfWeekSelected = ref();

    //function that adds or removes days of the week from daysOfWeekSelected buttons
    function selectDaysOfWeek(selectedDay) {
      //if selectedDay is in daysOfWeekSelected, remove it
      if (daysOfWeekSelected.value.includes(selectedDay)) {
        let index = daysOfWeekSelected.value.indexOf(selectedDay);
        if (index > -1) {
          daysOfWeekSelected.value.splice(index, 1);
        }
      } else {
        //if selectedDay is not in daysOfWeekSelected, add it
        daysOfWeekSelected.value.push(selectedDay);
      }
    }

    //when apply is selected, set date range values
    function applyDateRange() {
      //if all date filters are default values, clear date selection on Apply
      console.log(isDataDefaults.value);
      if (isDataDefaults.value) {
        context.emit("clear-date-selection");
        togglePopper.value = false;
        return;
      }

      //if user no start or end date is selected, show error message
      if (!range.range.start || !range.range.end) {
        showErrorMessage.value = true;
        return;
      }

      //convert time input fields
      convertTimeInputToInt();

      //set time values to date range
      range.range.start.setHours(
        startHourValue.value,
        startMinuteValue.value,
        0,
        0
      );
      range.range.end.setHours(
        endHourValue.value,
        endMinuteValue.value,
        59,
        999
      );

      //check if all values in daysOfWeekSelected are the same as daysOfWeekInRange
      let allDatesSelectedAreInRange = false;
      if (daysOfWeekSelected.value.length === daysOfWeekInRange.value.length) {
        let sortedDaysOfWeekSelected = [...daysOfWeekSelected.value].sort(
          (a, b) => a - b
        );
        let sortedDaysOfWeekInRange = [...daysOfWeekInRange.value].sort(
          (a, b) => a - b
        );
        if (
          JSON.stringify(sortedDaysOfWeekSelected) ===
          JSON.stringify(sortedDaysOfWeekInRange)
        ) {
          allDatesSelectedAreInRange = true;
        }
      }

      let dateFilter;
      //if all dates selected are in date range, send just one date range
      //this is for efficiency so the back-end doesn't have to process multiple date ranges when possible
      //no dates selected is the same as all dates selected
      if (
        allDatesSelectedAreInRange === true ||
        daysOfWeekSelected.value.length === 0
      ) {
        dateFilter = [range.range];
      } else {
        //if selected days of week is not all days of week, send multiple date ranges
        //this is because the date range picker only allows one date range at a time
        //so if the user selects multiple days of the week, we have to create multiple date ranges
        dateFilter = createMultipleDateRanges();
      }

      //emit event to parent component
      context.emit("time-filter-change", dateFilter);

      //close popper
      togglePopper.value = false;

      //one time flag for preventing watch for startDateValue and endDateValue from firing on submit
      //if date range is greater than 7 days, and the first or last day is not selected, set one time flag
      if (dateFilter.length > 1) {
        if (
          dateFilter[0].start.toLocaleString().split(",")[0] !==
            startDateValue.value ||
          dateFilter[dateFilter.length - 1].end
            .toLocaleString()
            .split(",")[0] !== endDateValue.value
        ) {
          //check if start date has date selected in daysOfWeekSelected
          let startDateDayOfWeek = moment(range.range.start).day();
          let endDateDayOfWeek = moment(range.range.end).day();
          if (
            !daysOfWeekSelected.value.includes(startDateDayOfWeek) ||
            !daysOfWeekSelected.value.includes(endDateDayOfWeek)
          ) {
            afterUpdatePreventWatcherOneTimeFlag.value = true;
          }
        }
      }
    }

    /*
    Function: creates multiple date ranges from the selected days of the week

    Steps:
    1.) Get a list of dates from selected date range
    2.) Filter those dates with the selected days of the week
    3.) Create array that contains objects of the filtered dates
        i.e. [0: {start: startDateTime, end: endDateTime}, 1: ...

    Note: The back-end processes the date ranges by using an OR operator to search through MongoDB.
    So it filters by date OR date OR date, etc.
    */
    function createMultipleDateRanges() {
      //1.) Get list of dates from date range, returns an array of dates between the two dates
      let dateArray = [];
      const startDate = new Date(range.range.start);
      const endDate = new Date(range.range.end);

      while (startDate <= endDate) {
        dateArray.push(new Date(startDate));
        startDate.setDate(startDate.getDate() + 1);
      }

      //2.) Create array of dates that match the days of the week selected
      let weekdays = [];
      //get dates that match the days of the week selected
      for (let i = 0; i < dateArray.length; i++) {
        for (let k = 0; k < daysOfWeekSelected.value.length; k++) {
          if (moment(dateArray[i]).day() === daysOfWeekSelected.value[k]) {
            weekdays.push(dateArray[i]);
          }
        }
      }

      //3.) Make loop that pushes multiple time ranges
      //this will be what creates date ranges from the filtered selected dates
      let multipleDateRangeArray = []; //this is the array that will be sent to the store
      for (let i = 0; i < weekdays.length; i++) {
        //myControlDate is a copy of the date object so date doesn't get overwritten when setHours() is called
        //https://stackoverflow.com/questions/6609574/javascript-date-variable-assignment
        let myControlDate = weekdays[i];
        let startDateTime = new Date(myControlDate);

        //combine myControlDate and set the hours/minutes to those in range.range.end to create new endDateTime
        let end = new Date(range.range.end);
        let x = myControlDate.setHours(
          end.getHours(),
          end.getMinutes(),
          end.getSeconds(),
          end.getMilliseconds()
        );
        let endDateTime = new Date(x);

        let startEndObj = {
          start: startDateTime,
          end: endDateTime
        };

        multipleDateRangeArray.push(startEndObj);
      }

      return multipleDateRangeArray;
    }

    const allTimesCBValue = ref(false);
    //two CB values intentional, MDB checkbox being annoying
    const allTimesCheckbox = ref(false);

    async function setAllTimesCheckbox(bool) {
      if (bool === true) {
        startHourValue.value = "00";
        startMinuteValue.value = "00";
        endHourValue.value = "23";
        endMinuteValue.value = "59";
        allTimesCBValue.value = true;
      } else {
        startHourValue.value = "00";
        startMinuteValue.value = "00";
        endHourValue.value = "00";
        endMinuteValue.value = "00";
        allTimesCBValue.value = false;
      }
    }

    //UI handling - if allTimesCheckbox is true, and the times are not 00:00 && 23:59, set allTimesCheckbox to false
    watch(
      [startHourValue, startMinuteValue, endHourValue, endMinuteValue],
      () => {
        if (allTimesCBValue.value === true) {
          parseInt(startHourValue.value);
          parseInt(startMinuteValue.value);
          parseInt(endHourValue.value);
          parseInt(endMinuteValue.value);
          if (
            startHourValue.value !== 0 &&
            startMinuteValue.value !== 0 &&
            endHourValue.value !== 23 &&
            endMinuteValue.value !== 59
          ) {
            allTimesCheckbox.value = false;
          }
          if (
            startHourValue.value === 0 &&
            startMinuteValue.value === 0 &&
            endHourValue.value === 0 &&
            endMinuteValue.value === 0
          ) {
            allTimesCheckbox.value = false;
          }
        }
      }
    );

    const allDatesCBValue = ref(false);
    //two CB values intentional, MDB checkbox being annoying
    const allDatesCheckbox = ref(false);

    function setAllDatesCheckbox(bool) {
      if (bool === true) {
        //set date range to one year from today
        let start, end;
        start = new Date(new Date().setDate(new Date().getDate() - 365));
        end = new Date(new Date().setDate(new Date().getDate()));

        if (range.range.start !== start || range.range.end !== end) {
          range.range.start = start;
          range.range.end = end;
        }
        allDatesCBValue.value = true;
      } else {
        range.range.start = null;
        range.range.end = null;
        allDatesCBValue.value = false;
      }
      datePickerKey.value++;
    }

    //quick date selection buttons
    function setQuickDateTime(dtOption) {
      if (dtOption === "today") {
        const start = new Date();
        start.setHours(0, 0, 0, 0);
        const end = new Date();
        end.setHours(
          end.getHours(),
          end.getMinutes(),
          end.getSeconds(),
          end.getMilliseconds()
        );

        startHourValue.value = "00";
        startMinuteValue.value = "00";
        endHourValue.value = end.getHours();
        endMinuteValue.value = end.getMinutes();

        daysOfWeekSelected.value = [moment().day()];

        if (range.range.start != start || range.range.end != end) {
          range.range.start = start;
          range.range.end = end;
        }
      } else if (dtOption === "yesterday") {
        let start, end;
        start = new Date(new Date().setDate(new Date().getDate() - 1));
        start.setHours(0, 0, 0, 0);
        end = new Date(new Date().setDate(new Date().getDate() - 1));
        end.setHours(23, 59, 59, 999);

        startHourValue.value = "00";
        startMinuteValue.value = "00";
        endHourValue.value = "23";
        endMinuteValue.value = "59";

        daysOfWeekSelected.value = [moment().day() - 1];

        if (range.range.start !== start || range.range.end !== end) {
          range.range.start = start;
          range.range.end = end;
        }
      } else if (dtOption === "last7") {
        let start, end;
        start = new Date(new Date().setDate(new Date().getDate() - 7));
        start.setHours(
          start.getHours(),
          start.getMinutes(),
          start.getSeconds(),
          start.getMilliseconds()
        );
        end = new Date(new Date().setDate(new Date().getDate()));
        end.setHours(
          end.getHours(),
          end.getMinutes(),
          end.getSeconds(),
          end.getMilliseconds()
        );

        daysOfWeekSelected.value = [0, 1, 2, 3, 4, 5, 6];

        startHourValue.value = start.getHours();
        startMinuteValue.value = start.getMinutes();
        endHourValue.value = end.getHours();
        endMinuteValue.value = end.getMinutes();

        if (range.range.start != start || range.range.end != end) {
          range.range.start = start;
          range.range.end = end;
        }
      }

      context.emit("time-filter-change", [range.range]);
    }

    return {
      handleWrapperClick,
      popperWrapper,
      datePickerKey,
      togglePopper,
      resetToDefaults,
      computedDateRangeFontSize,
      setQuickDateTime,
      range,
      dateRangeDisplay,
      startDateValue,
      endDateValue,
      startHourValue,
      startMinuteValue,
      endHourValue,
      endMinuteValue,
      daysOfWeekSelected,
      selectDaysOfWeek,
      applyDateRange,
      allTimesCheckbox,
      allDatesCheckbox,
      showErrorMessage,
      daysOfWeekInRange,
      selectAllText,
      setAllTimesCheckbox,
      setAllDatesCheckbox,
      updateStartHourValue,
      updateStartMinuteValue,
      updateEndHourValue,
      updateEndMinuteValue,
      togglePopperWindow
    };
  }
};
</script>

<style scoped lang="scss">
.Popper-Rectangle {
  width: 475px; //300
  min-height: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;

  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  background-color: var(--overwatch-secondary);
}

.small-Popper {
  width: 300px;
  min-height: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;

  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  background-color: var(--overwatch-secondary);
}

.input-rectangle {
  box-sizing: border-box;
  height: 45px;
  width: 100%;
  border-radius: 5px;
  border: solid 1px var(--overwatch-neutral-300);
  color: var(--overwatch-neutral-100);
  background-color: var(--overwatch-neutral-500);

  display: flex;
  align-items: center;
  padding: var(--spacing-s) var(--spacing-m) var(--spacing-s) var(--spacing-m);

  gap: 10px;
}

.date-display-input {
  width: 289px;
  border: 0px;
  margin: 0 auto;
  text-align: left;
  color: var(--overwatch-neutral-100);
  background-color: var(--overwatch-neutral-500);
  cursor: pointer;
}

.date-display-input::placeholder {
  color: var(--overwatch-neutral-300);
}

.my-calendar :deep(.vc-container) {
  --rounded-full: 5px;
  background-color: var(--overwatch-secondary);
  --blue-600: var(--overwatch-button-primary);
  --blue-200: var(--overwatch-neutral-400);
  --blue-700: var(--overwatch-primary);
  --slide-duration: 0px;
  --slide-timing: 0s;
  --slide-translate: steps(5, end);
  border: none;
}

.my-calendar :deep(.vc-slide-down-leave-active) {
  position: relative !important;
  width: 50%;
}

.my-calendar :deep(.vc-header) {
  /* Change padding here for title */
  padding: 3px 16px 0px 16px;
}

.my-calendar :deep(.vc-weekday) {
  @include overwatch-body-xsmall;
  line-height: normal;
  letter-spacing: normal;
  color: var(--overwatch-neutral-100);
  display: flex;
  justify-content: center;
  text-transform: uppercase;
}

/* TODO - seems like this doesn't do anything */
.my-calendar :deep(.vc-day-content) {
  @include overwatch-body-med;
  line-height: normal;
  letter-spacing: normal;
}

.my-calendar :deep(.vc-day-content.vc-focusable) {
  color: var(--overwatch-button-hover);
  --blue-900: var(--overwatch-button-text);
  --white: var(--overwatch-button-text);
}

.my-calendar :deep(.vc-day-content:hover) {
  background-color: var(--overwatch-button-hover);
  /* opacity: 0.6; */
  color: var(--overwatch-button-text);
  /* --blue-900: var(--overwatch-neutral-100); */
}

.my-calendar :deep(.vc-day-content:focus) {
  background-color: var(--overwatch-button-primary);
  color: var(--overwatch-button-text);
  --blue-900: var(--overwatch-button-text);
}

.my-calendar :deep(.vc-pane-container) {
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  padding-top: 10px;
  margin-right: 36px;
  border: none;
}

.my-calendar :deep(.vc-svg-icon) {
  color: var(--overwatch-button-primary);
}

.my-calendar :deep(.vc-title) {
  @include overwatch-body-med;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--overwatch-neutral-100);
}

.my-calendar :deep(.vc-time-icon) {
  visibility: hidden;
}

.my-calendar :deep(.vc-time-icon) {
  visibility: hidden;
}

/* TODO - delete, don't think it does anything */
/* .my-calendar :deep(.vc-time-picker.vc-bordered) {
    border: none;
    display: flex;
    justify-content: center;
    @include overwatch-body-small;
    line-height: normal;
    letter-spacing: normal;
    color: #000;
} */

.my-calendar :deep(.vc-select select) {
  background-color: var(--overwatch-error);
  border: solid 1px var(--overwatch-neutral-300);
  border-radius: 5px;
}

.my-calendar :deep(.vc-time-select span:nth-child(2)) {
  display: none;
}

.my-calendar :deep(.vc-time-select) {
  justify-content: space-evenly;
}

/* From/To div */
.my-calendar :deep(.vc-time-weekday) {
  color: var(--overwatch-neutral-100);
  display: flex;
  font-size: 12px;
  /* width 100% to get the date to the next line */
  width: 100%;
}

.my-calendar :deep(.vc-time-year) {
  color: var(--overwatch-neutral-100);
  margin-left: 0px;
}

.my-calendar :deep(.vc-time-day) {
  color: var(--overwatch-neutral-100);
  margin-left: 0px;
}

.my-calendar :deep(.vc-time-month) {
  color: var(--overwatch-neutral-100);
  margin-left: 0px;
}

.my-calendar :deep(.vc-time-date) {
  display: flex;
  flex-wrap: wrap;
  color: var(--overwatch-neutral-100);
}

.my-calendar :deep(.vc-select-arrow) {
  color: var(--overwatch-button-primary);
}

.timepicker-container {
  display: flex;
  flex-direction: column;
  padding-right: 24px;
  padding-top: 21px;
  box-shadow: none;
}

.timepicker-container .number-input {
  margin-right: 4px;
}

.buttons-footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: 10px 0px 10px 0px;
  padding: 0px 24px 0px 24px;
}

.footer-container {
  width: 100%;
}

.WeekdayButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px var(--overwatch-secondary);
  background-color: var(--overwatch-primary);
  color: var(--overwatch-button-text);
  width: 50px;
  height: 40px;
  padding: 0 var(--spacing-base);
  border-radius: 0;
  margin: 0;
  box-shadow: none;
}

.WeekdayButtonUnselected {
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px var(--overwatch-secondary);
  background-color: var(--overwatch-neutral-300);
  color: var(--overwatch-button-text);
  width: 50px;
  height: 40px;
  padding: 0 var(--spacing-base);
  border-radius: 0;
  margin: 0;
  box-shadow: none;
}

.WeekdayButtonUnselected:disabled {
  color: var(--overwatch-neutral-300);
  background-color: var(--overwatch-neutral-400);
  cursor: not-allowed;
  border: solid 1px var(--overwatch-secondary);
}

.reset-btn-container {
  margin-right: auto;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
}

.cancel-apply-container {
  display: flex;
  justify-content: end;
  width: 50%;
  margin-left: auto;
  gap: var(--spacing-s);
}

.cancelApplyButton {
  height: 45px;
  width: 100%;
}

/* make button text not all caps */
.btn {
  text-transform: unset !important;
}

.ButtonSecondaryDefault {
  width: 100%;
  height: 45px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 5px 0 4px;
}

.line-breaker {
  box-sizing: border-box;
  height: 1px;
  width: 100%;
  border: 1px solid var(--overwatch-accent);
  margin-top: 8px;
  margin-bottom: 8px;
}

/* MOBILE STYLING */
@media (max-width: 480px) {
  .Popper-Rectangle {
    width: 100vw;
    height: 500px;
    min-height: fit-content;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    overflow: auto;
  }

  .my-calendar :deep(.vc-pane-container) {
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    padding-top: 0px;
    border: none;
  }

  .timepicker-container {
    padding-right: 2px;
    padding-top: 14px;
  }

  .reset-btn-container {
    margin-bottom: 12px;
  }

  .cancel-apply-container {
    margin-bottom: 12px;
  }
}
</style>
