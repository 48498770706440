export default {
  watchlists(state) {
    return state.watchlists;
  },
  importerVisible(state) {
    return state.importerVisible;
  },
  memberSummaryCount: (state) => (wlId) => {
    const index = state.watchlists.findIndex(
      (watchlist) => watchlist._id === wlId
    );
    if (index >= 0) {
      if (!state.watchlists[index].memberSummary) {
        return 0;
      }
      const count = state.watchlists[index].memberSummary.length;
      return count;
    }
  },
  encounterFilterSelectedWatchlists(state) {
    return state.encounterFilterSelectedWatchlists;
  },
  importerType(state) {
    return state.importerType;
  },
  importerMode(state) {
    return state.importerMode;
  },
  importerWatchlistId(state) {
    return state.importerWatchlistId;
  },
  importerEventId(state) {
    return state.importerEventId;
  },
  noFiles(state) {
    return state.noFiles;
  },
  maxParallelEnrollments(state) {
    return state.maxParallelEnrollments;
  }
};
