<template>
  <!-- This component is a single select dropdown with searchable input for an array of objects -->
  <div class="wrapper">
    <RocIcon v-if="searchable" color="black" size="sm" icon="search" />
    <Multiselect
      class="multiselect"
      :class="{
        'search-mode': customSearchSelectMode
      }"
      mode="single"
      :searchable="searchable"
      :placeholder="placeholderText"
      v-model="selectedItems"
      :options="availableItems"
      :close-on-select="closeOnSelect"
      :canClear="enableClear"
      :label="label"
      :valueProp="valueProp"
      ref="multiselectRef"
    >
      <template #option="{ option }">
        <div
          style="display: flex; align-items: center; justify-content: center; height: 24px;"
        >
          <span class="multiselect-option">{{ option[label] }}</span>
        </div>
      </template>
      <template #caret="{isOpen}">
        <div>
          <RocIcon color="black" icon="downArrow" size="xs" :flip="isOpen" />
        </div>
      </template>
    </Multiselect>
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import { ref, watch, nextTick } from "vue";
import { MDBBtn } from "mdb-vue-ui-kit";
import RocIcon from "@/components/ui/RocIcon";

export default {
  name: "RocSingleSelect",
  components: {
    Multiselect,
    MDBBtn,
    RocIcon
  },
  emits: ["selection-changed"],
  props: {
    availableItems: Array,
    name: String,
    noResultsText: {
      type: String,
      default: "No results found"
    },
    placeholderText: String,
    closeOnSelect: {
      type: Boolean,
      default: true
    },
    customSearchSelectMode: {
      type: Boolean,
      default: false
    },
    enableClear: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: "name"
    },
    valueProp: {
      type: String,
      default: "name"
    },
    searchable: {
      type: Boolean,
      default: true
    },
    currentlySelected: {
      type: String,
      required: false
    }
  },
  setup(props, context) {
    const selectedItems = ref(props.currentlySelected);

    watch(
      () => props.currentlySelected,
      (nv) => {
        selectedItems.value = nv;
      }
    );

    watch(
      selectedItems,
      () => {
        context.emit("selection-changed", selectedItems);
      },
      { immediate: true }
    );

    const multiselectRef = ref(null);
    const isEnabled = ref(false);

    function enableSelf() {
      isEnabled.value = !isEnabled.value;
      nextTick(() => {
        if (isEnabled.value) {
          multiselectRef.value.open();
          multiselectRef.value.focus();
        } else {
          multiselectRef.value.close();
        }
      });
    }

    return {
      selectedItems,
      enableSelf,
      isEnabled,
      multiselectRef
    };
  }
};
</script>

<style scoped lang="scss">
.wrapper {
  display: flex;
  align-items: center;
  padding-left: $--spacing-s;
  padding-right: $--spacing-s;
  border: 1px solid var(--overwatch-neutral-300);
  border-radius: 5px;
  background-color: var(--overwatch-neutral-500);
}

.multiselect {
  @include overwatch-body-med;
  --ms-border-width: 0;
  --ms-ring-width: 0;

  --ms-dropdown-bg: #ffffff;
  --ms-dropdown-border-color: #9da8be;
  --ms-dropdown-border-width: 1px;
  --ms-dropdown-border-radius: 0px;
  --ms-dropdown-radius: 5px;
  --ms-max-height: 200px;

  --ms-bg: var(--overwatch-neutral-500);

  --ms-option-color-pointed: var(--overwatch-neutral-100);
  --ms-option-color-selected: var(--overwatch-button-text);
  --ms-option-color-selected-pointed: var(--overwatch-button-text);
  --ms-option-bg-selected: var(--overwatch-button-primary);
  --ms-option-bg-selected-pointed: var(--overwatch-button-primary);
  --ms-option-bg-pointed: var(--overwatch-button-primary-20);

  color: var(--overwatch-neutral-100);

  height: 100%;
}

.multiselect :deep(.multiselect-search) {
  position: static;
}

.multiselect :deep(.multiselect-dropdown) {
  border-radius: 0;
  width: calc(100% + 16px + $--spacing-s * 2 + 1px);
  left: calc(0px - $--spacing-s - 16px - 1px);

  // Width: 100% of width + icon width + left/right padding + border
}

.multiselect.search-mode {
  padding-left: 0;
}

.multiselect-option {
  margin-top: 1px;
}

.optionImage {
  height: 16px;
  width: 16px;
  /* margin-right: 30px; */
}
</style>
