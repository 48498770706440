<template>
  <div>
    <div>
      <div
        style="color: var(--overwatch-neutral-100); margin-bottom: var(--spacing-base);"
        class="d-flex justify-content-between overwatch-body-large"
      >
        Title
      </div>
      <div class="d-flex " v-if="!availableViews">
        <RocInput
          class="search-input"
          v-model="nameValue"
          :maxLength="60"
          type="text"
          :errorMessage="errorMessage"
          :placeholder="'Write a memorable name'"
        />
      </div>
      <div
        class="d-flex select-rectangle"
        v-if="
          availableViews &&
            (popupType === 'load' || popupType === 'filterUpdate')
        "
      >
        <div
          v-if="!selectedView"
          style="height: var(--spacing-m); width: var(--spacing-m); margin: 0 0 10px 7px"
        >
          <RocIcon size="sm" color="gray" icon="search" />
        </div>
        <filterSingleSelect
          :availableItems="availableViews"
          @selection-changed="updateSelectedView"
          placeholder-text="Start searching"
          no-results-text=""
        />
      </div>
      <div class="d-flex">
        <div
          v-if="popupType !== 'load'"
          style="margin-left: auto; margin-top: var(--spacing-base);"
          class="d-flex justify-content-end overwatch-body-xsmall"
        >
          {{ nameCharCount + "/60" }}
        </div>
      </div>
    </div>
    <div>
      <div
        v-if="(selectedView && popupType === 'load') || popupType !== 'load'"
        class="d-flex descHeader justify-content-between overwatch-body-large"
      >
        Description
      </div>
      <div v-if="popupType !== 'load'" class="d-flex justify-content-between">
        <RocTextArea
          class="search-input"
          v-model="descValue"
          type="text"
          :rows="6"
          :maxLength="120"
          :readonly="isReadOnly"
          :placeholder="descPlaceholderText"
        />
      </div>
      <div
        v-if="selectedView && popupType === 'load'"
        class="overwatch-body-med"
        style="color: var(--overwatch-neutral-100); margin-top:10px"
      >
        {{ descValue }}
      </div>
      <div
        v-if="popupType !== 'load'"
        class=" d-flex justify-content-end overwatch-body-xsmall"
        style="margin-top:var(--spacing-base); color:var(--overwatch-neutral-100)"
      >
        {{ descCharCount + "/120" }}
      </div>
    </div>
    <div>
      <div
        v-if="popupType !== 'cardUpdate' && popupType !== 'load'"
        class="d-flex overwatch-body-large"
      >
        <div
          class="d-flex justify-content-start"
          style="gap: var(--spacing-base)"
        >
          <RocIcon
            size="md"
            color="primary"
            icon="starFilled"
            style="margin-top: var(--spacing-l);"
          />
          <span class="priority-star-spacing">Set as a priority?</span>
        </div>
        <div class="buttonGroup">
          <RocButton
            @click="isPriority = true"
            :type="isPriority ? 'primary' : 'secondary'"
            >Yes
          </RocButton>
          <RocButton
            @click="isPriority = false"
            :type="!isPriority ? 'primary' : 'secondary'"
            >No
          </RocButton>
        </div>
      </div>
      <div
        v-if="
          (popupType !== 'cardUpdate' && popupType !== 'load') ||
            (popupType === 'load' && selectedView)
        "
        class="d-flex"
      >
        <div style="margin-top: var(--spacing-m); width: 100%">
          <SavedViewBadges
            :savedView="savedViewValues"
            :filter="filterValues"
            :popupType="popupType"
          ></SavedViewBadges>
        </div>
      </div>
      <div
        class="d-flex justify-content-end"
        style="margin-top: 10px; gap: var(--spacing-s)"
      >
        <RocButton @click="closePopup('close')" type="secondary"
          >Discard
        </RocButton>
        <RocButton v-if="popupType === 'load'" @click="loadView()"
          >Load
        </RocButton>
        <RocButton v-else @click="saveView()">Save</RocButton>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import SavedViewBadges from "./SavedViewBadges.vue";
import BaseDialog from "../ui/BaseDialog.vue";
import filterSingleSelect from "../ui/filterSingleSelect.vue";
import { get as lodashGet } from "lodash";
import RocIcon from "@/components/ui/RocIcon.vue";
import RocButton from "@/components/ui/RocButton.vue";
import RocInput from "@/components/ui/RocInput.vue";
import RocTextArea from "@/components/ui/RocTextArea.vue";

export default {
  name: "SavedViewPopup",
  emits: ["save", "close", "load-view"],
  props: ["filterValues", "popupType"],
  components: {
    SavedViewBadges,
    BaseDialog,
    filterSingleSelect,
    RocIcon,
    RocButton,
    RocInput,
    RocTextArea
  },
  setup(props, context) {
    const store = useStore();
    const userConfig = ref(
      store.getters["auth/userConfig"] ? store.getters["auth/userConfig"] : {}
    );
    const nameValue = ref(props.filterValues.name);
    const nameCharCount = computed(() =>
      nameValue.value && nameValue.value.length ? nameValue.value.length : 0
    );
    const descValue = ref(props.filterValues.desc);
    const descCharCount = computed(() =>
      descValue.value && descValue.value.length ? descValue.value.length : 0
    );
    const isPriority = ref(false);
    const savedViewValues = ref(props.filterValues);

    const errorMessage = ref(undefined);

    const isReadOnly = ref(props.popupType === "load" ? true : false);
    const descPlaceholderText = ref(
      props.popupType !== "load"
        ? "Write a detailed description of your new View"
        : ""
    );

    const availableViews = computed(function() {
      if (props.popupType === "load" || props.popupType === "filterUpdate") {
        if (userConfig.value && userConfig.value.savedViews) {
          return userConfig.value.savedViews;
        }
      } else {
        return null;
      }
    });

    //for comparing name values in update popup
    const nameValueCopy = ref(props.filterValues.name);
    const isNameUnique = computed(() => {
      let nameField = nameValue.value.trim();
      //if a saved view doesn't exist, return true in the else block below
      if (userConfig.value && userConfig.value.savedViews) {
        let x = userConfig.value.savedViews.filter((x) => x.name === nameField);
        if (x && x.length > 0) {
          if (
            props.popupType === "filterUpdate" ||
            props.popupType === "cardUpdate"
          ) {
            //if popup is in update mode, allow user to use same name for update
            if (nameValueCopy.value.trim() === nameField) {
              return true;
            }
            //if name differs from original input, make sure it doesn't match another saved view name
            else if (x[0].name === nameField) {
              return false;
            }
          }
          //if there is a match, return false
          return false;
        }
      } else {
        return true;
      }
    });

    async function saveView() {
      //name check tests, skip if it's from filter update bc user has to choose existing name
      if (props.popupType !== "filterUpdate") {
        if (!nameValue.value) {
          errorMessage.value = "Name required!";
          return;
        }

        if (nameValue.value.trim() === "") {
          errorMessage.value = "Name must have non-whitespace characters!";
          return;
        }

        if (isNameUnique.value === false) {
          errorMessage.value = "Name must be unique!";
          return;
        }
        savedViewValues.value.name = nameValue.value.trim();
      }

      savedViewValues.value.desc = descValue.value;

      //overwrite entry in card
      if (props.popupType === "cardUpdate") {
        const indx = userConfig.value.savedViews.findIndex(
          (x) => x.name === props.filterValues.name
        );
        userConfig.value.savedViews[indx] = savedViewValues.value;
      }
      //overwrite entry in ddlb from filter
      if (props.popupType === "filterUpdate") {
        const indx = userConfig.value.savedViews.findIndex(
          (x) => x.name === selectedView.value.name
        );
        savedViewValues.value.name = selectedView.value.name;
        savedViewValues.value.priority = isPriority.value;
        userConfig.value.savedViews[indx] = savedViewValues.value;
      }
      //add entry
      if (props.popupType === "save") {
        savedViewValues.value.priority = isPriority.value;

        //if savedViews doesn't exist yet, create a space for it
        if (
          userConfig.value &&
          typeof userConfig.value.savedViews === "undefined"
        ) {
          userConfig.value.savedViews = [];
        }

        //add object to end of savedViews array
        userConfig.value.savedViews.push(savedViewValues.value);
      }

      //update config in db and store
      console.dir(userConfig.value);
      await store.dispatch("auth/updateUserConfig", userConfig.value);

      //reset input values so they don't stick around after save
      savedViewValues.value.name = "";
      savedViewValues.value.desc = "";
      savedViewValues.value.priority = false;

      closePopup(props.popupType);
    }

    const selectedView = ref("");

    async function updateSelectedView(payload) {
      //payload.value is name value from select ddlb
      let returnedView = userConfig.value.savedViews.filter(
        (x) => x.name === payload
      );

      nameValue.value = lodashGet(returnedView[0], "name", null);
      descValue.value = lodashGet(returnedView[0], "desc", null);
      isPriority.value = lodashGet(returnedView[0], "priority", null);
      if (props.popupType === "load") {
        savedViewValues.value = lodashGet(returnedView, [0], null);
      }
      selectedView.value = lodashGet(returnedView, [0], null);
    }

    function loadView() {
      context.emit("load-view", selectedView.value);
    }

    function closePopup(payload) {
      context.emit("close", payload);
    }

    return {
      nameValue,
      descValue,
      saveView,
      closePopup,
      isPriority,
      savedViewValues,
      availableViews,
      selectedView,
      updateSelectedView,
      loadView,
      nameCharCount,
      descCharCount,
      isReadOnly,
      descPlaceholderText,
      isNameUnique,
      errorMessage
    };
  }
};
</script>

<style scoped lang="scss">
.PopupContainer {
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  padding: 0rem;
  margin: 0rem var(--spacing-base);
  max-width: 40rem;
}

.descHeader {
  margin-top: 15px;
  margin-bottom: var(--spacing-base);
  color: var(--overwatch-neutral-100);
}

.select-rectangle {
  box-sizing: border-box;
  height: 50px;
  width: 368px;
  border-radius: 5px;
  border: solid 1px var(--overwatch-neutral-300);
  background-color: var(--overwatch-neutral-500);
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 10px;
}

.input-rectangle {
  width: 100%;
  height: 50px;
  flex-grow: 0;
  padding: var(--spacing-s) 222px var(--spacing-s) var(--spacing-m);
  border-radius: 5px;
  border: solid 1px var(--overwatch-neutral-300);
  background-color: var(--overwatch-neutral-500);
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 10px;
}

.buttonGroup {
  margin-left: auto;
  gap: var(--spacing-s);
  margin-top: var(--spacing-m);
  display: flex;
  justify-content: end;
}

.input-description {
  width: 100%;
  height: 170px;
  border-radius: 5px;
  border: solid 1px var(--overwatch-neutral-300);
  background-color: var(--overwatch-neutral-500);
  padding: 10px 30px 10px 10px;
}

.search-input {
  border: 0px;
  height: 100%;
  margin: auto 0;
  width: 100%;
  background-color: var(--overwatch-neutral-500);
  color: var(--overwatch-neutral-100);
  @include overwatch-body-med;
  line-height: 25px;
  resize: none;
}

.search-input::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.search-input::placeholder {
  width: 300px;
  height: 21px;
  flex-grow: 0;
  margin: 0 0 0 var(--spacing-s);
  text-align: left;
  color: var(--overwatch-neutral-300);
  opacity: 0.6;
  @include overwatch-body-small;
  letter-spacing: normal;
}

.priority-star-spacing {
  margin: var(--spacing-l) var(--spacing-s) 0 0;
  color: var(--overwatch-neutral-100);
}
</style>
