<template>
  <div class="d-flex flex-column">
    <div class="container">
      <div class="plate">
        <div
          style="position: relative; border: 0px solid var(--overwatch-error); margin-right: 4px;"
        >
          <img src="@/assets/img/blank-plate-gray.png" />
          <div class="plate-number center">{{ rawObject.plateNumber }}</div>
        </div>
      </div>
      <div class="d-flex flex-column info-box">
        <div class="info-entry">
          <div class="info-label-small">
            License Plate Name
          </div>
          <div v-if="readOnly" class="overwatch-body-med">
            {{ rawObject.fullname }}
          </div>
          <div v-else>
            <div class="d-flex">
              <input
                class="textInput"
                style="margin-right: 7px;"
                type="text"
                v-model="fullnameRef"
              />
            </div>
          </div>
        </div>
        <div class="info-entry">
          <div class="info-label-small">
            Color
          </div>
          <div v-if="readOnly" class="overwatch-body-med">
            {{ rawObject.color }}
          </div>
          <div v-else>
            <div class="d-flex">
              <input
                class="textInput"
                style="margin-right: 7px;"
                type="text"
                v-model="colorRef"
              />
            </div>
          </div>
        </div>
        <div class="info-entry">
          <div class="info-label-small">
            Make
          </div>
          <div v-if="readOnly" class="overwatch-body-med">
            {{ rawObject.make }}
          </div>
          <div v-else>
            <div class="d-flex">
              <input
                class="textInput"
                style="margin-right: 7px;"
                type="text"
                v-model="makeRef"
              />
            </div>
          </div>
        </div>
        <div class="info-entry">
          <div class="info-label-small">
            Model
          </div>
          <div v-if="readOnly" class="overwatch-body-med">
            {{ rawObject.model }}
          </div>
          <div v-else>
            <div class="d-flex">
              <input
                class="textInput"
                style="margin-right: 7px;"
                type="text"
                v-model="modelRef"
              />
            </div>
          </div>
        </div>
        <div class="info-entry">
          <div class="info-label-small">
            Year
          </div>
          <div v-if="readOnly" class="overwatch-body-med">
            {{ rawObject.year }}
          </div>
          <div v-else>
            <div class="d-flex">
              <input
                class="textInput"
                style="margin-right: 7px;"
                type="text"
                v-model="yearRef"
              />
            </div>
          </div>
        </div>
        <div class="info-entry">
          <div class="info-label-small">
            Notes
          </div>
          <div v-if="readOnly" class="overwatch-body-med">
            {{ rawObject.notes }}
          </div>
          <div v-else>
            <div class="d-flex">
              <textarea
                class="textInput"
                style="margin-right: 7px;"
                rows="3"
                v-model="notesRef"
              />
            </div>
          </div>
        </div>

        <div class="info-entry">
          <div class="d-flex">
            <!-- <div style="flex: 2; padding-top: 20px;">
              <div class="info-label-meta">
                Face Template Metadata
              </div>
              <table>
                <tr v-for="item in metaDataValues" :key="item">
                  <td>{{item}}</td>
                </tr>
              </table>
            </div> -->
            <div style="flex: 1;">
              <div v-if="!readOnly" style="width: 100%; text-align: right">
                <RocButton v-if="!isSaving" @click="saveChanges"
                  >Update
                </RocButton>
                <roc-spinner v-if="isSaving" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import AuthImg from "@/components/ui/AuthImg";
import RocButton from "@/components/ui/RocButton";

export default {
  name: "WatchlistedFaceDetails.vue",
  emits: ["close"],
  props: {
    readOnly: Boolean,
    rawObject: Object
  },
  components: {
    AuthImg,
    RocButton
  },
  setup(props, context) {
    const store = useStore();
    const isSaving = ref(false);

    const plateNumberRef = ref(props.rawObject.plateNumber);
    const fullnameRef = ref(props.rawObject.fullname);
    const colorRef = ref(props.rawObject.color);
    const makeRef = ref(props.rawObject.make);
    const modelRef = ref(props.rawObject.model);
    const yearRef = ref(props.rawObject.year);
    const notesRef = ref(props.rawObject.notes);

    async function saveChanges() {
      isSaving.value = true;
      props.rawObject.plateNumber = plateNumberRef.value;
      props.rawObject.fullname = fullnameRef.value;
      props.rawObject.color = colorRef.value;
      props.rawObject.make = makeRef.value;
      props.rawObject.model = modelRef.value;
      props.rawObject.year = yearRef.value;
      props.rawObject.notes = notesRef.value;

      console.log(notesRef.value, props.rawObject.notes);

      const payload = {
        type: "text",
        watchlistedElement: props.rawObject
      };
      const responseData = await store.dispatch(
        "watchlists/updateWatchlistedElement",
        payload
      );
      isSaving.value = false;
      console.log(responseData);
      if (responseData && responseData.status == "success") {
        context.emit("close");
      }
    }

    return {
      isSaving,
      saveChanges,
      plateNumberRef,
      makeRef,
      yearRef,
      colorRef,
      modelRef,
      fullnameRef,
      notesRef
    };
  }
};
</script>

<style scoped lang="scss">
img {
  max-height: 100%;
  max-width: 100%;
}

.container {
  display: flex;
}

.face-img {
  width: 225px;
  height: auto;
}

.info-box {
  width: 100%;
}

.info-entry {
  margin-bottom: 10px;
}

.info-label-small {
  width: 100%;
  color: var(--overwatch-neutral-200);
  @include overwatch-body-small;
  line-height: 18px;
}

.info-value {
  width: 100%;
  color: var(--overwatch-neutral-100);
  @include overwatch-body-small;
  line-height: 21px;
  background-color: var(--overwatch-secondary);
  padding: 7px;
}

.info-label-meta {
  opacity: 0.5;
  color: var(--overwatch-neutral-100);
  @include overwatch-body-med;
  line-height: 25px;
  margin-bottom: 10px;
}

.info-image {
  color: var(--overwatch-neutral-100);
  @include overwatch-body-med;
  line-height: 27px;
  padding: 7px;
}

.textInput {
  width: 100%;
}

textarea {
  resize: none;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  text-align: left;
  padding: 8px;
}

tr:nth-child(odd) {
  background-color: var(--overwatch-secondary);
}

.encountersList {
  height: 300px;
  border-top: 2px solid lightgray;
  padding-top: 7px;
  overflow-y: auto;
}

.plate-number {
  color: var(--overwatch-light-neutral-100);
  font-family: Sans-serif;
  font-size: 20px;
}

.center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 5px;
}

.plate {
  margin-right: 10px;
}

/* MOBILE */
@media (max-width: 480px) {
  .container {
    flex-direction: column;
  }
  .info-box {
    margin: 0;
  }

  .plate {
    display: flex;
    align-items: center;

    margin-right: 0px;
    margin-bottom: 10px;
  }
}
</style>
